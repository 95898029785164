export const BoardingStatus = {
  BoardingAndDisembarking: 'Є посадка та висадка',
  BoardingOnly: 'Тільки посадка',
  DisembarkingOnly: 'Тільки висадка',
  NoBoardingAndDisembarking: 'Немає посадки та висадки',
} as const

export const RaceType = {
  Interurban: 'Міжміський',
  Interregional: 'Міжобласний',
  Suburban: 'Приміський',
  International: 'Міжнародний',
} as const

export const RaceRegularity = {
  ByWeekdays: 'По днях тижня',
} as const

export const Weekdays = {
  Mon: 'Пн',
  Tue: 'Вт',
  Wed: 'Ср',
  Thu: 'Чт',
  Fri: 'Пт',
  Sat: 'Сб',
  Sun: 'Нд',
} as const

export const Currency = {
  UAH: 'UAH',
  USD: 'USD',
  EUR: 'EUR',
} as const

export const TariffType = {
  fixed: 'Фіксований',
}

export const ACCESS_TOKEN = 'access_token'
export const REFRESH_TOKEN = 'refresh_token'
export const USER_INFO_TOKEN = 'user_info'

export const timezones = [
  { label: 'Україна GMT+02:00', value: 'GMT+02:00' },
  { label: 'Центральноєвропейський час GMT+01:00', value: 'GMT+01:00' },
]

export const TRIP_REQUEST_STATUS: {
  [key: string]: string
} = {
  created: 'Створено',
  booked: 'Підтверджено',
  canceled: 'Відхилено',
  ongoing: 'В процесі',
  completed: 'Завершено',
} as const

export const CONNECTION_TYPE: {
  [key: string]: string
} = {
  Call: 'Дзвінок',
  viber: 'Viber',
  telegram: 'Telegram',
  whatIsApp: `What's App`,
} as const
