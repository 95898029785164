import { api } from '@/api'

// ? interfaces
import { IStopDto } from '@/interfaces/stop'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.STOPS)

export const stops_api = {
  createStop: async (body: IStopDto) => await requestCreator(api, '', 'POST', body),
  getStops: async (bus_stop_location?: number) =>
    await requestCreator(api, '', 'GET', undefined, { bus_stop_location }),
  updateStop: async (id: number, body: IStopDto) => await requestCreator(api, `${id}`, 'PUT', body),
  deleteStop: async (id: number) => await requestCreator(api, `${id}`, 'DELETE'),
}
