// ? interfaces
import { IErrorValidation } from '@/interfaces/general'
import { IDriverCreateDTO } from '@/interfaces/dirvers'
import { ITransportCreateDTO } from '@/interfaces/transport'

// ? validation utils
import {} from './general'

interface ITRANSPORT_VALIDATION {
  CREATE: (data: ITransportCreateDTO) => IErrorValidation[]
  EDIT: (data: Partial<IDriverCreateDTO>) => IErrorValidation[]
  DELETE: (id: string) => IErrorValidation[]
}

export const TRANSPORT_VALIDATION: ITRANSPORT_VALIDATION = {
  CREATE: (data) => {
    const errors: IErrorValidation[] = []

    if (!data.vehicle_type) {
      errors.push({
        field: 'vehicle_type',
        message: `Поле "vehicle_type" обов'язкове для заповнення`,
      })
    }

    if (!data.vehicle_brand) {
      errors.push({
        field: 'vehicle_brand',
        message: `Поле "vehicle_brand" обов'язкове для заповнення`,
      })
    }

    if (!data.vehicle_license_plates) {
      errors.push({
        field: 'vehicle_license_plates',
        message: `Поле "vehicle_license_plates" обов'язкове для заповнення`,
      })
    }

    if (!data.vehicle_amenities) {
      errors.push({
        field: 'vehicle_amenities',
        message: `Поле "vehicle_amenities" обов'язкове для заповнення`,
      })
    }

    if (!data.vehicle_capacity) {
      errors.push({
        field: 'vehicle_capacity',
        message: `Поле "vehicle_capacity" обов'язкове для заповнення`,
      })
    }

    // if (!data.description) {
    //     errors.push({
    //         field: "description",
    //         message: `Поле "description" обов'язкове для заповнення`,
    //     });
    // }

    return errors
  },

  EDIT: (data) => {
    const errors: IErrorValidation[] = []

    if (Object.keys(data).length === 0) {
      errors.push({
        field: 'all',
        message: `Не вказано даних для редагування`,
      })
    }

    for (const key in data) {
      if (!data[key as keyof IDriverCreateDTO]) {
        errors.push({
          field: key,
          message: `Поле "${key}" обов'язкове для заповнення`,
        })
      }
    }

    return errors
  },
  DELETE: (id) => {
    const errors: IErrorValidation[] = []

    if (!id) {
      errors.push({
        field: 'id',
        message: `Не вказано id водія`,
      })
    }

    return errors
  },
}
