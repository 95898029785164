// ? hooks
import {useEffect} from "react";

import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import useDebounce from "@/hooks/useDebounce";

// ? store
import {adminStore} from "@/store/adminStore";

// ? utils
import {inputsChanger} from "@/utils/inputsChanger";
import {isObjectValuesEmpty} from "@/utils/isObjectValuesEmpty";

// ? components
import {
    Paper,
    FormControl,
    Input,
    InputAdornment,
    Tooltip,
    IconButton,
    Divider,
    Button,
    Box,
} from "@mui/material";

// ? icons
import {Close} from "@mui/icons-material";

const AdminTableFastFilter = () => {
    const {getAllAdmins, meta, filterQuery, setFilterQuery} = adminStore();

    const getAdminsApiHandler = useAsyncWrapper(
        getAllAdmins,
        "getAdminsApiHandler"
    );
    const debouncedSearchTerm = useDebounce(filterQuery, 500) as any;

    const handleChangeInputs = inputsChanger("inputWithTrim", filterQuery, setFilterQuery) as any;

    const clearFilter = async () => {
        const {error} = await getAdminsApiHandler([meta, filterQuery]);

        if (error) {
            return false;
        }

        setFilterQuery({
            email: null,
        });
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            getAdminsApiHandler([meta, filterQuery]);
        }
    }, [debouncedSearchTerm]);

    return (
        <Box
            sx={{
                width: "100%",
            }}
        >
            <Paper
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: {
                        xs: "wrap-reverse",
                        xl: "nowrap",
                    },
                }}
            >
                <Box
                    sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        maxWidth: "600px",
                        mr: "15px",
                        my: "2px",
                    }}
                >
                    <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                            width: "300px",
                        }}
                    >
                        <Input
                            placeholder="Пошук по електронній пошті"
                            size="small"
                            value={filterQuery?.email || ""}
                            onChange={handleChangeInputs}
                            name="email"
                            sx={{ml: 1, my: 0, flex: 1}}
                            endAdornment={
                                <InputAdornment position="end">
                                    {filterQuery?.email && filterQuery?.email?.length > 0 && (
                                        <Tooltip title="Clear filter">
                                            <IconButton
                                                onClick={() =>
                                                    setFilterQuery({
                                                        ...filterQuery,
                                                        email: "",
                                                    })
                                                }
                                                edge="end"
                                                size="small"
                                            >
                                                <Close/>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    {!isObjectValuesEmpty(filterQuery) && (
                        <>
                            <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
                            <Tooltip title="Очистити фільтр">
                                <Button size="small" onClick={clearFilter}>
                                    Очистити фільтр
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default AdminTableFastFilter;
