import {IErrorValidation} from "@/interfaces/general";

export const isEmptyFields = (formValues: any): IErrorValidation[] => {
    const errors: IErrorValidation[] = [];
    Object.keys(formValues).forEach((field) => {
        if (!formValues[field]) {
            errors.push({
                field,
                message: `Field "${field}" is required`,
            });
        }
    });
    return errors;
};

export const isPasswordMatch = (
    password: string,
    passwordConfirm: string
): IErrorValidation[] => {
    const errors: IErrorValidation[] = [];
    if (password !== passwordConfirm) {
        errors.push({
            field: "passwordConfirm",
            message: "Passwords do not match",
        });
    }
    return errors;
};

export const isEmail = (value: string): IErrorValidation[] => {
    const errors: IErrorValidation[] = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value);
    if (!isValid) {
        errors.push({
            field: "email",
            message: "Email is not valid",
        });
    }
    return errors;
};
