// ? hooks
import {useState} from "react";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";

// ? store
import {adminStore} from "@/store/adminStore";
import {messageStore} from "@/store/store";

// ? components
import {Box, Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import ModalContainer from "@/components/UI/Modal/ModalContainer";

const AdminDeleteModal = () => {
    const [isLoading, setIsLoading] = useState(false);

    const {deleteAdminModal, deleteAdmin, getAllAdmins, meta, filterQuery} =
        adminStore();

    const {isOpenModal, toggleDeleteModal, deletedAdmin} = deleteAdminModal;

    const {addMessage} = messageStore();

    const handleDeleteAdminApiHandler = useAsyncWrapper(
        deleteAdmin,
        "handleDeleteAdminApiHandler"
    );

    const getAdminsApiHandler = useAsyncWrapper(
        getAllAdmins,
        "getAdminsApiHandler"
    );

    const handleCloseForm = () => {
        toggleDeleteModal();
    };

    const handleDeleteAdmin = async () => {
        setIsLoading(true);
        if (!deletedAdmin?._id) {
            setIsLoading(false);
            return false;
        }

        const {error: deleteError} = await handleDeleteAdminApiHandler([
            deletedAdmin._id,
        ]);

        if (deleteError) {
            setIsLoading(false);
            return false;
        }

        const {error: getAdminsError} = await getAdminsApiHandler([
            meta,
            filterQuery,
        ]);

        if (getAdminsError) {
            setIsLoading(false);
            return false;
        }

        handleCloseForm();

        addMessage({
            severity: "success",
            message: `Адміна видалено`,
            isOpen: true,
        });

        setIsLoading(false);
    };

    return (
        <ModalContainer
            width={800}
            isOpen={isOpenModal}
            handleClose={handleCloseForm}
            title={`Видалити адміна ${deletedAdmin?.email}`}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 1,
                }}
            >
                <LoadingButton
                    loading={isLoading}
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleDeleteAdmin}
                >
                    Видалити
                </LoadingButton>

                <Button
                    onClick={handleCloseForm}
                    color="error"
                    variant="contained"
                    size="small"
                    sx={{ml: 1}}
                >
                    Відмінити
                </Button>
            </Box>
        </ModalContainer>
    );
};

export default AdminDeleteModal;
