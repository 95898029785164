import { TableRow, TableCell } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const RacesTabelHeader = () => {
  const { t } = useTranslation()

  const i18nTranslateHeaderString = 'racesPage'

  const headerTitles = [
    {
      title: t(`${i18nTranslateHeaderString}.raceNum`), // ? "ID"
      name: 'raceNum',
    },
    {
      title: t(`${i18nTranslateHeaderString}.departure`), // ? "звідки"
      name: 'departure',
    },
    {
      title: 'Час відправлення', // ? 'Час відправлення
      name: 'departure-time',
    },
    {
      title: t(`${i18nTranslateHeaderString}.arrival`), // ? "звідки"
      name: 'arrival',
    },
    {
      title: 'Час прибуття', // ? 'Час прибуття
      name: 'arrival-time',
    },
    {
      title: t(`${i18nTranslateHeaderString}.status`), // ?"статус"
      name: 'status',
    },
    {
      title: 'Ціна', // ? 'Ціна
      name: 'price',
    },
  ]
  return (
    <TableRow>
      {headerTitles.map(({ title, name }) => (
        <TableCell
          key={name}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default RacesTabelHeader
