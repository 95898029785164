import {formatDate} from "@/utils/formatDate";
import {
    TableRow,
    TableCell,
    Typography,
} from "@mui/material";
import React from "react";

interface IProps {
    consultation: any;
}

const ConsultationTabelRow = ({consultation}: IProps) => {
    return (
        <TableRow hover>
            <TableCell align="left">
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    {consultation?._id}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    {consultation?.name}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    {consultation?.phoneNumber}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    {consultation?.race}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    {consultation?.createdAt
                        ? formatDate(consultation?.createdAt, true)
                        : ""}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default ConsultationTabelRow;
