// ? hooks
import {useState} from "react";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import useValidation from "@/hooks/useValidation";

// ? store
import {adminStore} from "@/store/adminStore";
import {messageStore} from "@/store/store";

// ? interfaces
import {IRegisterFormDTO} from "@/interfaces/auth";

// ? utils
import {inputsChanger} from "@/utils/inputsChanger";

// ? validation
import {AUTH_VALIDATION} from "@/validation/auth";

// ? components
import {Button, Box, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import ModalContainer from "@/components/UI/Modal/ModalContainer";


const initialState: IRegisterFormDTO = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
};

const AdminCreateModal = () => {
    const [formValues, setFormValues] = useState(initialState);

    const {create, registerAdmin, getAllAdmins, meta, filterQuery} =
        adminStore();
    const {isOpenModal, isLoading, toggleCreateAdmin} = create;
    const {addMessage} = messageStore();

    const validationRegisterHandler = useValidation(AUTH_VALIDATION.REGISTER);

    const getAdminsApiHandler = useAsyncWrapper(
        getAllAdmins,
        "getAdminsApiHandler"
    );
    const registerAdminApiHandler = useAsyncWrapper(
        registerAdmin,
        "registerAdminApiHandler"
    );

    const handleCloseForm = () => {
        toggleCreateAdmin();
    };

    const handleChangeInputs = inputsChanger("input", formValues, setFormValues) as any;

    const handleCreateNewAdmin = async () => {
        if (!validationRegisterHandler(formValues)) {
            return false;
        }

        const {error: registerError} = await registerAdminApiHandler([
            formValues,
        ]);

        if (registerError) {
            return false;
        }

        const {error} = await getAdminsApiHandler([meta, filterQuery]);

        if (error) {
            return false;
        }

        handleCloseForm();
        addMessage({
            type: "success",
            message: "Адміна успішно створено",
            isOpen: true,
        });
    };

    return (
        <ModalContainer
            width={800}
            isOpen={isOpenModal}
            handleClose={handleCloseForm}
            title={`Створити нового адміна`}
        >
            <TextField
                fullWidth
                label={"Ім'я"}
                onChange={handleChangeInputs}
                name="firstName"
                variant="standard"
                placeholder={"Serhii"}
            />

            <TextField
                fullWidth
                label={"Прізвище"}
                onChange={handleChangeInputs}
                name="lastName"
                variant="standard"
                placeholder={"Lesyk"}
            />

            <TextField
                fullWidth
                label={"Імейл"}
                onChange={handleChangeInputs}
                name="email"
                variant="standard"
                placeholder={"test@test.com"}
            />

            <TextField
                fullWidth
                label={"Пароль"}
                onChange={handleChangeInputs}
                name="password"
                type="password"
                variant="standard"
                placeholder={"Test1234"}
            />

            <TextField
                fullWidth
                label={"Номер телефону"}
                onChange={handleChangeInputs}
                name="phoneNumber"
                variant="standard"
                placeholder={"380999999999"}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 1,
                }}
            >
                <LoadingButton
                    loading={isLoading}
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleCreateNewAdmin}
                >
                    Створити
                </LoadingButton>

                <Button
                    onClick={handleCloseForm}
                    color="error"
                    variant="contained"
                    size="small"
                    sx={{ml: 1}}
                >
                    Відмінити
                </Button>
            </Box>
        </ModalContainer>
    );
};

export default AdminCreateModal;
