import {FormValues, FormErrors} from '@/interfaces/form';

interface ValidationErrors {
    [key: string]: string;
}

export const validateForm = (values: FormValues, validationRules: FormErrors) => {
    let errors: ValidationErrors = {};

    for (const field in validationRules) {
        const value = values[field];
        const rules = validationRules[field];

        if (rules?.required && !value) {
            errors[field] = 'Поле обов\'язкове';
        }

        if (rules?.minLength && value?.length < rules?.minLength) {
            errors[field] = `Мінімальна кількість символів ${rules?.minLength}`;
        }

        if (rules?.notHaveCheckedValues && !value && value?.length === 0) {
            errors[field] = `Виберіть хоча б одне значення`;
        }
    }

    return errors;
}