import { TableRow, TableCell } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const RaceTripsTabelHeader = () => {
  const { t } = useTranslation()

  const i18nTranslateHeaderString = 'raceTripsPage'

  const headerTitles = [
    {
      title: 'Дата відправлення',
      name: 'departure_date',
    },
    {
      title: 'День',
      name: 'day',
    },
    {
      title: 'Місце відправлення',
      name: 'departure_full_name',
    },
    {
      title: 'Місце прибуття',
      name: 'destination_full_name',
    },
    {
      title: 'Назва авто',
      name: 'car_name',
    },
    {
      title: 'Кількість місць',
      name: 'seats_count',
    },
    {
      title: 'Зайняті місця',
      name: 'occupied_seats',
    },
    {
      title: 'Дії',
      name: 'actions',
    },
  ]
  return (
    <TableRow>
      {headerTitles.map(({ title, name }) => (
        <TableCell
          key={name}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default RaceTripsTabelHeader
