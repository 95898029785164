import ConsultationTabel from '@/components/Tabels/ConsultationTabel/ConsultationTabel'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import {Typography} from '@mui/material'
import React from 'react'

const Consultations = () => {
    return (
        <PageWrapper>
            {true ? (
                <ConsultationTabel/>
            ) : (
                <Typography variant='h4' color='error'>
                    You don&apos;t have permission to view Country
                </Typography>
            )}
        </PageWrapper>
    )
}

export default Consultations