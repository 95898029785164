import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { messageStore } from '@/store/store'
import { tripRequestsStore } from '@/store/tripRequestsStore'
import { Typography, Button, Box } from '@mui/material'
import { useParams } from 'react-router-dom'

const TripItemApproveModal = () => {
  const { addMessage } = messageStore()

  const { approveModal, searchParams, editTripRequest, getTripRequests, tripRequestsMeta } = tripRequestsStore()
  const { handleClose, isOpen, tripRequest } = approveModal

  const handleCloseForm = () => {
    handleClose()
  }

  const handleEditTripRequest = () => {
    if (tripRequest?.order_trip_id) {
      editTripRequest(tripRequest.order_trip_id, { status: 'booked' })
        .then(() => {
          getTripRequests({
            status: 'created',
          })
            .then(() => {
              addMessage({
                isOpen: true,
                message: 'Бронювання підтверджено',
                severity: 'success',
              })
              handleCloseForm()
            })
            .catch((err: any) => {
              console.log(err, 'err')
              addMessage({
                isOpen: true,
                message: 'Щось пішло не так',
                severity: 'error',
              })
            })
        })
        .catch((err: any) => {
          console.log(err, 'err')
          addMessage({
            isOpen: true,
            message: 'Щось пішло не так',
            severity: 'error',
          })
        })
    }
  }

  return (
    <ModalContainer width={800} isOpen={isOpen} handleClose={handleCloseForm} title={'Підтвердити бронювання'}>
      <Typography variant="subtitle1" color="text.primary">
        Ви впевнені що хочете підтвердити бронювання?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <Button color="primary" variant="contained" size="small" onClick={handleEditTripRequest}>
          Так
        </Button>
        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Ні
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default TripItemApproveModal
