import {Typography} from '@mui/material'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import TripTariffs from "@/components/Tabels/TripTariffs/TripTariffs";

const TripTariffsPage = () => {
    return (
        <PageWrapper>
            {true ? (
                <TripTariffs/>
            ) : (
                <Typography variant="h4" color="error">
                    You don&apos;t have permission to view traders
                </Typography>
            )}
        </PageWrapper>
    )
}

export default TripTariffsPage