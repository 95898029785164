import { TableRow, TableCell } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TransportTabelHeader = () => {
  const { t } = useTranslation()

  const i18nTranslateHeaderString = 'transportsPage'

  const headerTitles = [
    {
      title: t(`${i18nTranslateHeaderString}.type`), // ? "Тип"
      name: 'vehicle_type',
    },
    {
      title: t(`${i18nTranslateHeaderString}.name`), // ? "Назва"
      name: 'vehicle_brand',
    },
    {
      title: t(`${i18nTranslateHeaderString}.busNumber`), // ? "Номер автобусу"
      name: 'vehicle_license_plates',
    },
    {
      title: t(`${i18nTranslateHeaderString}.quantitySeats`), // ?"Кількість місць"
      name: 'vehicle_capacity',
    },
    {
      title: 'Зручності',
      name: 'vehicle_amenities',
    },
    {
      title: 'Фото',
      name: 'photos',
    },
    {
      title: t(`${i18nTranslateHeaderString}.actions`), // ?"Кількість місць"
      name: '',
    },
  ]
  return (
    <TableRow>
      {headerTitles.map(({ title, name }) => (
        <TableCell
          key={title}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default TransportTabelHeader
