// ? store
import {messageStore} from "@/store/store";

// ? interfaces
import {IErrorValidation} from "@/interfaces/general";

export default function useValidation(fn: Function) {
    const {addMessage} = messageStore();

    const runFn = (validationData: unknown) => {
        if (typeof fn !== "function") {
            throw new Error("Function is required");
        }

        const errors: IErrorValidation[] = fn(validationData);
        if (errors.length) {
            errors.forEach((error: IErrorValidation) => {
                addMessage({
                    severity: "error",
                    message: error.message,
                    isOpen: true,
                });
            });
            return false;
        }
        return true;
    };

    return runFn;
}
