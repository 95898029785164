import {create} from "zustand";

import {REQ} from "@/api";
import {IMeta} from "@/interfaces/general";
import {IPlan} from "@/interfaces/planning";
import {IRace, IRaceStop} from "@/interfaces/races";

const {planning_tasks_api} = REQ;

interface IStore {
    isLoading: boolean;
    onePlan: IPlan;
    plans: IPlan[];
    planningMeta: IMeta;
    createPlanModal: {
        isOpenModal: boolean;
        createdPlan: any;
        setCreatedPlan: (value: any) => void;
        handleOpenModal: () => void;
        handleCloseModal: () => void;
        createPlanApiHandler: (body: IPlan) => Promise<any>;
    };
    editPlanModal: {
        isOpenModal: boolean;
        editedItem: IPlan | null;
        handleOpenModal: (item: IPlan) => void;
        handleCloseModal: () => void;
        editPlanApiHandler: (id: string, body: IPlan) => Promise<any>;
    };
    deletePlanModal: {
        isOpenModal: boolean;
        deletedItem: IPlan | null;
        handleOpenModal: (item: IPlan) => void;
        handleCloseModal: () => void;
        deletePlanApiHandler: (id: string) => Promise<any>;
    };
    addRaceParamsModal: {
        isOpenModal: boolean;
        editedItem: IPlan | null;
        raceParams: IRace | null;
        handleOpenModal: (plan: IPlan, item: IRace) => void;
        handleCloseModal: () => void;
        addRaceParamsApiHandler: (id: string, body: IPlan) => Promise<any>;
    };
    editRaceParamsModal?: {
        isOpenModal: boolean;
        handleOpenModal: () => void;
        handleCloseModal: () => void;
        editRaceParamsApiHandler: (id: string, body: IPlan) => Promise<any>;
    };
    createRaceStop: {
        isOpenModal: boolean;
        editedItem: IRace | IPlan | null;
        handleOpenModal: (item: IRace | IPlan) => void;
        handleCloseModal: () => void;
        createRaceStopsApiHandler: (id: string, body: Partial<IPlan>) => Promise<any>;
    };
    editRaceStop: {
        isOpenModal: boolean;
        editedItem: IRaceStop | null;
        raceStopId: string;
        handleOpenModal: (item: IRaceStop, raceStopId: string | undefined) => void;
        handleCloseModal: () => void;
        editRaceStopsApiHandler: (id: string, body: Partial<IPlan>) => Promise<any>;
    };
    deleteRaceStop: {
        isOpenModal: boolean;
        deletedItem: IRaceStop | null;
        raceStopId: string;
        handleOpenModal: (item: IRaceStop, raceStopId: string | undefined) => void;
        handleCloseModal: () => void;
        deleteRaceStopsApiHandler: (id: string, body: Partial<IPlan>) => Promise<any>;
    };
    editPlan: (id: string, body: IPlan) => Promise<any>;
    getPlanById: (id: string) => Promise<any>;
    getAllRacePlans: (raceId: string, {count, page}: IMeta) => Promise<any>;
}

export const planningStore = create<IStore>((set) => ({
    isLoading: false,
    onePlan: {} as IPlan,
    plans: [],
    planningMeta: {
        count: 10,
        page: 1,
        total: 0,
    },

    createPlanModal: {
        isOpenModal: false,
        createdPlan: {},
        setCreatedPlan: (value) => {
            set((state) => ({
                ...state,
                createPlanModal: {
                    ...state.createPlanModal,
                    createdPlan: value,
                },
            }));
        },
        handleOpenModal: () => {
            set((state) => ({
                ...state,
                createPlanModal: {
                    ...state.createPlanModal,
                    isOpenModal: true,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                createPlanModal: {
                    ...state.createPlanModal,
                    isOpenModal: false,
                    createdPlan: {},
                },
            }));
        },
        createPlanApiHandler: async (body: IPlan) => {
            const response = await planning_tasks_api.createTask(body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 201) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    editPlanModal: {
        isOpenModal: false,
        editedItem: null,
        handleOpenModal: () => {
            set((state) => ({
                ...state,
                editPlanModal: {
                    ...state.editPlanModal,
                    isOpenModal: true,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                editPlanModal: {
                    ...state.editPlanModal,
                    isOpenModal: false,
                },
            }));
        },
        editPlanApiHandler: async (id: string, body: IPlan) => {
            const response = await planning_tasks_api.editTask(id, body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 201) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    deletePlanModal: {
        isOpenModal: false,
        deletedItem: null,
        handleOpenModal: (item: IPlan) => {
            set((state) => ({
                ...state,
                deletePlanModal: {
                    ...state.deletePlanModal,
                    isOpenModal: true,
                    deletedItem: item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                deletePlanModal: {
                    ...state.deletePlanModal,
                    isOpenModal: false,
                    deletedItem: null,
                },
            }));
        },
        deletePlanApiHandler: async (id: string) => {
            const response = await planning_tasks_api.deleteTask(id);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    addRaceParamsModal: {
        isOpenModal: false,
        editedItem: null,
        raceParams: null,
        handleOpenModal: (plan: IPlan, item: IRace) => {
            set((state) => ({
                ...state,
                addRaceParamsModal: {
                    ...state.addRaceParamsModal,
                    isOpenModal: true,
                    editedItem: plan,
                    raceParams: plan?.raceParams ? plan?.raceParams : item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                addRaceParamsModal: {
                    ...state.addRaceParamsModal,
                    isOpenModal: false,
                    editedItem: null,
                    raceParams: null,
                },
            }));
        },
        addRaceParamsApiHandler: async (id: string, body: IPlan) => {
            console.log("ids: " + id)
            const response = await planning_tasks_api.editTask(id, body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 201) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    createRaceStop: {
        isOpenModal: false,
        editedItem: null,
        handleOpenModal: (item: IRace | IPlan) => {
            set((state) => ({
                ...state,
                createRaceStop: {
                    ...state.createRaceStop,
                    isOpenModal: true,
                    editedItem: item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                createRaceStop: {
                    ...state.createRaceStop,
                    isOpenModal: false,
                    editedItem: null,
                    isLoading: true
                },
            }));
        },
        createRaceStopsApiHandler: async (id: string, body: Partial<IPlan>) => {
            console.log("RESP 1", body)
            const response = await planning_tasks_api.editTask(id, body);
            console.log("RESP 2", response)
            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    editRaceStop: {
        isOpenModal: false,
        editedItem: null,
        raceStopId: "",
        handleOpenModal: (item: IRaceStop, raceStopId: string | undefined) => {
            set((state) => ({
                ...state,
                editRaceStop: {
                    ...state.editRaceStop,
                    isOpenModal: true,
                    editedItem: item,
                    raceStopId: raceStopId!
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                editRaceStop: {
                    ...state.editRaceStop,
                    isOpenModal: false,
                    editedItem: null,
                    isLoading: true
                },
            }));
        },
        editRaceStopsApiHandler: async (id: string, body: Partial<IPlan>) => {
            const response = await planning_tasks_api.editTask(id, body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    deleteRaceStop: {
        isOpenModal: false,
        deletedItem: null,
        raceStopId: "",
        handleOpenModal: (item: IRaceStop, raceStopId: string | undefined) => {
            set((state) => ({
                ...state,
                deleteRaceStop: {
                    ...state.deleteRaceStop,
                    isOpenModal: true,
                    deletedItem: item,
                    raceStopId: raceStopId!
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                deleteRaceStop: {
                    ...state.deleteRaceStop,
                    isOpenModal: false,
                    deletedItem: null,
                },
            }));
        },
        deleteRaceStopsApiHandler: async (id: string, body: Partial<IPlan>) => {
            const response = await planning_tasks_api.editTask(id, body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    editPlan: async (id: string, body: IPlan) => {
        const response = await planning_tasks_api.editTask(id, body);

        const {status, data} = response;

        console.log("data", data)

        if (!status || !data) {
            throw new Error("No status code or data returned from server.");
        }

        if (status !== 200) {
            throw new Error(data);
        } else {
            return response;
        }
    },

    getPlanById: async (id) => {
        const response = await planning_tasks_api.getById(id);

        const {status, data} = response;

        if (!status || !data) {
            throw new Error("No status code or data returned from server.");
        }

        if (status !== 200) {
            throw new Error(data);
        } else {
            set((state) => ({...state, onePlan: data}));
            return response;
        }
    },

    getAllRacePlans: async (raceId, meta) => {
        set((state) => ({...state, isLoading: true}));
        const response = await planning_tasks_api.getAllPlanningTasks(raceId, meta);

        const {status, data} = response;

        if (!status || !data) {
            set((state) => ({...state, isLoading: false}));
            throw new Error("No status code or data returned from server.");
        }

        if (status !== 200) {
            set((state) => ({...state, isLoading: false}));

            throw new Error(data);
        } else {
            set((state) => ({...state, plans: data.data, plansMeta: data.meta}));

            setTimeout(() => {
                set((state) => ({...state, isLoading: false}));
            }, 350);
            return response;
        }
    },

}));
