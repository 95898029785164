// ? hooks
import React, { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { countryStore } from '@/store/countryStore'
import { townStore } from '@/store/townStore'
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'

// ? components
import {
  Box,
  Button,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
  Grid,
} from '@mui/material'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'
import { inputsChanger } from '@/utils/inputsChanger'
import { RaceRegularity, Weekdays } from '@/constants/constants'
import { validateForm } from '@/utils/formValidation'
import { driversStore } from '@/store/driverStore'
import { transportStore } from '@/store/transportStore'
import { formatDateString } from '@/utils/formatDate'

const EditRaceModal = () => {
  const { getAllRaces, racesMeta, editRace } = racesStore()

  const { isOpenModal, editedItem, handleCloseModal } = editRace

  const { addMessage } = messageStore()
  const { getAllCountries, countryMeta } = countryStore()
  const { drivers, getAllDrivers } = driversStore()
  const { getAllTransports, transports } = transportStore()

  const [formValues, setFormValues] = useState<any>({
    ...editedItem,
    regularity: editedItem?.regularity ? 'ByWeekdays' : '',
    regularityValues: editedItem?.regularity.split(', ') || '',
    begins_at: formatDateString(editedItem?.begins_at!),
    expires_at: formatDateString(editedItem?.expires_at!),
  })
  const [showWeekdaysFields, setShowWeekdaysFields] = useState<boolean>(false)

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    race_number: { required: true },
    status: { required: true },
    departure_time: { required: true },
    destination_time: { required: true },
    race_type: { required: true },
    regularity: { required: true },
    regularityValues: { notHaveCheckedValues: true },
    driver: { required: true },
    vehicle_id: { required: true },
    price: { required: true },
    currency: { required: true },
  }

  const editRaceApiHandler = useAsyncWrapper(editRace.editRaceApiHandler, 'editRaceApiHandler')
  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')
  const getAllCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getAllCountriesApiHandler')
  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')
  const getAllTransportsApiHandler = useAsyncWrapper(getAllTransports, 'getAllTransportsApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const editRaceHandler = async () => {
    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      return
    }

    const body = {
      ...formValues,
      destination_time: formValues.destination_time,
      destination_location: formValues.toLocationCityStop,
      departure_location: formValues.fromLocationCityStop,
      vehicle_id: Number(formValues.vehicle_id),
      driver: Number(formValues.driver),
      transplantation: formValues.race_type,
      price: Number(formValues.price),
      currency: formValues.currency,
      active: Number(formValues.status),
      regularity: formValues.regularityValues.join(', '),
    }

    const { error: editRaceError } = await editRaceApiHandler([editedItem?.flight_id, body])

    if (editRaceError) {
      return
    }

    const { error: getAllRacesError } = await getAllRacesApiHandler([racesMeta])

    if (getAllRacesError) {
      return
    }

    addMessage({
      severity: 'success',
      message: 'Рейс успішно відредаговано',
      isOpen: true,
    })
    handleCloseForm()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllCountriesError } = await getAllCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getAllCountriesError) return
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (formValues?.regularity === 'ByWeekdays') {
      setShowWeekdaysFields(true)
    } else {
      setShowWeekdaysFields(false)
    }
  }, [formValues?.regularity])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getDriversError } = await getDriversApiHandler([])

      if (getDriversError) {
        return false
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTransportsError } = await getAllTransportsApiHandler([])

      if (getAllTransportsError) {
        addMessage({
          severity: 'error',
          message: 'Помилка при завантаженні транспорту',
          isOpen: true,
        })
      }
    }

    fetchData()
  }, [])

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleCheckboxChange = (e: any) => {
    let name = e.target.name.split('-')
    if (name[0] === 'weekday') {
      let newArr = formValues.regularityValues ?? []
      if (e.target.checked === true) {
        newArr.push(name[1])
      } else if (e.target.checked === false) {
        let index = newArr.indexOf(name[1])
        if (index > -1) {
          newArr.splice(index, 1)
        }
      }
      setFormValues({
        ...formValues,
        regularityValues: newArr,
      })
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.checked })
    }
  }

  const handleRadioChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleCloseForm} title="Редагування рейсу">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>№ рейсу</InputLabel>
              <TextField
                name="race_number"
                variant="standard"
                type="text"
                value={formValues?.race_number}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.race_number}
                helperText={formErrors?.race_number}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-start',
              }}>
              <InputLabel shrink>Дійсний</InputLabel>
              <RadioGroup
                aria-label="status"
                name="status"
                value={formValues?.status}
                onChange={handleRadioChange}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-start',
                }}>
                <FormControlLabel value="1" control={<Radio />} label="Так" />
                <FormControlLabel value="0" control={<Radio />} label="Ні" />
              </RadioGroup>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
            }}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Час відправлення</InputLabel>
              <TextField
                name="departure_time"
                variant="standard"
                type="time"
                value={formValues?.departure_time || '00:00'}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.departure_time}
                helperText={formErrors?.departure_time}
              />
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Час прибуття</InputLabel>
              <TextField
                name="destination_time"
                variant="standard"
                type="time"
                value={formValues?.destination_time || '00:00'}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.destination_time}
                helperText={formErrors?.destination_time}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
            }}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Курсує з</InputLabel>
              <TextField
                name="begins_at"
                variant="standard"
                type="date"
                value={formValues?.begins_at || ''}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.begins_at}
                helperText={formErrors?.begins_at}
              />
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Курсує по</InputLabel>
              <TextField
                name="expires_at"
                variant="standard"
                type="date"
                value={formValues?.expires_at || ''}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.expires_at}
                helperText={formErrors?.expires_at}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.race_type} fullWidth variant="standard">
            <InputLabel>Тип рейсу</InputLabel>
            <Select value={formValues?.race_type} name="race_type" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              <MenuItem value={1}>Рейс з автобільним перетином кордону (автобусом)</MenuItem>
              <MenuItem value={2}>Рейс з автомобільним перетином кордону (мінібусом)</MenuItem>
              <MenuItem value={3}>Рейс з пішим перетином кордону (автобусом)</MenuItem>
              <MenuItem value={4}>Рейс з пішим перетином кордону (мінібусом)</MenuItem>
            </Select>
            {formErrors?.race_type && <FormHelperText>{formErrors?.race_type}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.regularity} fullWidth variant="standard">
            <InputLabel>Регулярність</InputLabel>
            <Select value={formValues?.regularity || 'initial'} name="regularity" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {Object.entries(RaceRegularity).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.regularity && <FormHelperText>{formErrors?.regularity}</FormHelperText>}
          </FormControl>
        </Box>
        {(showWeekdaysFields || formValues.regularityValues?.length !== 0) && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '5px',
            }}>
            <FormControl
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'row wrap',
                gap: '5px',
              }}
              error={!!formErrors?.regularityValues}>
              {Object.entries(Weekdays).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      name={`weekday-${key}`}
                      checked={formValues['regularityValues']?.find((value: string) => value === key) ? true : false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={value}
                />
              ))}
              {formErrors?.regularityValues && (
                <FormHelperText sx={{ m: '0' }}>{formErrors?.regularityValues}</FormHelperText>
              )}
            </FormControl>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.driver} fullWidth variant="standard">
            <InputLabel>Водій</InputLabel>
            <Select value={formValues?.driver || 'initial'} name="driver" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {drivers?.length
                ? drivers.map((driver) => {
                    return (
                      <MenuItem key={driver.user_id} value={driver.user_id}>
                        {driver.pib}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.driver && <FormHelperText>{formErrors?.driver}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.vehicle_id} fullWidth variant="standard">
            <InputLabel>Транспорт</InputLabel>
            <Select value={formValues?.vehicle_id || 'initial'} name="vehicle_id" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {transports?.length
                ? transports.map((transport) => {
                    return (
                      <MenuItem key={transport.vehicle_id} value={transport.vehicle_id}>
                        {transport.vehicle_brand}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.vehicle_id && <FormHelperText>{formErrors?.vehicle_id}</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Ціна</InputLabel>
            <TextField
              name="price"
              variant="standard"
              type="number"
              value={formValues?.price || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.price}
              helperText={formErrors?.price}
            />
          </FormControl>
          <FormControl error={!!formErrors?.currency} fullWidth variant="standard">
            <InputLabel>Валюта</InputLabel>
            <Select value={formValues?.currency || 'initial'} name="currency" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              <MenuItem value={'UAH'}>UAH</MenuItem>
              <MenuItem value={'USD'}>USD</MenuItem>
              <MenuItem value={'EUR'}>EUR</MenuItem>
            </Select>
            {formErrors?.currency && <FormHelperText>{formErrors?.currency}</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton color="primary" variant="contained" size="small" onClick={editRaceHandler}>
            Зберегти
          </LoadingButton>
          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default EditRaceModal
