// ? hooks
import React, { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { countryStore } from '@/store/countryStore'
import { townStore } from '@/store/townStore'
import { racesStore } from '@/store/racesStore'
import { planningStore } from '@/store/planningStore'
import { messageStore } from '@/store/store'

// ? components
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { inputsChanger } from '@/utils/inputsChanger'
import { IRaceStop } from '@/interfaces/races'
import { BoardingStatus } from '@/constants/constants'
import { useParams } from 'react-router-dom'

const CreateRaceStopModal = () => {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const { getRaceById, oneRace } = racesStore()

  const { onePlan, createRaceStop, getPlanById } = planningStore()
  const { isOpenModal, editedItem, handleCloseModal } = createRaceStop

  const { addMessage } = messageStore()
  const { getAllCountries, countryMeta } = countryStore()
  const { towns, townMeta, getAllTowns } = townStore()

  const [formValues, setFormValues] = useState<any>({
    fromLocationCity: '',
    cityStop: '',
    boardingStatus: '',
    stopPosition: '',
    arrivalTime: '',
    departureTime: '',
    arrivalDay: 0,
    departureDay: 0,
    platformNumber: '',
    distanceFromStart: 0,
    borderCross: false,
    changeTransfer: false,
    changeBus: false,
  })

  const [cityStops, setCityStops] = useState<any>([])
  const [raceStops, setRaceStops] = useState<any>([])

  const createRaceStopsApiHandler = useAsyncWrapper(
    createRaceStop.createRaceStopsApiHandler,
    'createRaceStopsApiHandler'
  )

  const getRaceByIdApiHandler = useAsyncWrapper(getRaceById, 'getRaceByIdApiHandler')

  const getAllCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getAllCountriesApiHandler')

  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const createRaceStopHandler = async () => {
    setIsLoading(true)

    const data = {
      type: 'createRaceStop',
      raceStops: { ...formValues },
    }

    const { error: editError } = await createRaceStopsApiHandler([editedItem?.flight_id, data])

    if (editError) {
      setIsLoading(false)
      return
    }

    const { error: getPlanByIdError } = await getPlanById(params.planId ?? '')

    if (getPlanByIdError) {
      console.log(getPlanByIdError)
    }

    setIsLoading(true)

    addMessage({
      severity: 'success',
      message: 'Зупинку успішно створено',
      isOpen: true,
    })

    handleCloseForm()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllCountriesError } = await getAllCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getAllCountriesError) {
        return
      }

      const { error: getAllTownsError } = await getAllTownsApiHandler([
        {
          ...townMeta,
          count: 1000,
        },
      ])

      if (getAllTownsError) {
        return
      }
    }

    fetchData()
  }, [])

  // useEffect(() => {
  //   if (oneRace?.raceStops?.length) {
  //     const stops = oneRace?.raceStops?.map((stop: IRaceStop) => {
  //       const city = towns.find((town) => town._id === stop.fromLocationCity)
  //       const cityStop = city?.cityStops?.find((s) => s._id === stop?.cityStop)
  //       return {
  //         _id: stop?._id,
  //         city: city,
  //         stop: cityStop,
  //       }
  //     })

  //     setRaceStops(stops)
  //   }
  // }, [oneRace])

  useEffect(() => {
    setCityStops(towns.find((town) => town._id === formValues.fromLocationCity)?.cityStops)
  }, [formValues?.fromLocationCity])

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleCheckboxChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.checked })
  }

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleCloseForm} title="Додавання зупинки">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Населений пункт</InputLabel>
            <Select
              value={formValues?.fromLocationCity || 'initial'}
              name="fromLocationCity"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {towns?.length
                ? towns.map((town) => {
                    return (
                      <MenuItem key={town._id} value={town._id}>
                        {getTranslateValue(town, 'UA')}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel>Зупинка</InputLabel>
            <Select value={formValues?.cityStop || 'initial'} name="cityStop" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {cityStops?.length
                ? cityStops.map((cityStop: any, index: number) => {
                    return (
                      <MenuItem key={index} value={cityStop._id}>
                        {getTranslateValue(cityStop, 'UA')}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Стан Посадка/Висадка</InputLabel>
            <Select value={formValues?.boardingStatus || 'initial'} name="boardingStatus" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {Object.entries(BoardingStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel>Створити після зупинки</InputLabel>
            <Select value={formValues?.stopPosition || 'initial'} name="stopPosition" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {raceStops?.length
                ? raceStops?.map((stop: any) => (
                    <MenuItem key={`stop-pos-${stop._id}`} value={stop._id}>
                      {getTranslateValue(stop?.city, 'UA')}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Прибуття</InputLabel>
            <TextField
              name="arrivalTime"
              variant="standard"
              type="time"
              value={formValues?.arrivalTime || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Відправлення</InputLabel>
            <TextField
              name="departureTime"
              variant="standard"
              type="time"
              value={formValues?.departureTime || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Доба рейсу для прибуття</InputLabel>
            <TextField
              name="arrivalDay"
              variant="standard"
              type="number"
              value={formValues?.arrivalDay || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Доба рейсу для відправлення</InputLabel>
            <TextField
              name="departureDay"
              variant="standard"
              type="number"
              value={formValues?.departureDay || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Номер платформи</InputLabel>
            <TextField
              name="platformNumber"
              variant="standard"
              value={formValues?.platformNumber || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Відстань від початкової</InputLabel>
            <TextField
              name="distanceFromStart"
              variant="standard"
              type="number"
              value={formValues?.distanceFromStart || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '20px',
          }}>
          <FormControlLabel
            control={<Checkbox name="borderCross" checked={formValues.borderCross} onChange={handleCheckboxChange} />}
            label="Кордон"
          />
          <FormControlLabel
            control={
              <Checkbox name="changeTransfer" checked={formValues.changeTransfer} onChange={handleCheckboxChange} />
            }
            label="Пересадка"
          />
          <FormControlLabel
            control={<Checkbox name="changeBus" checked={formValues.changeBus} onChange={handleCheckboxChange} />}
            label="Заміна автобуса"
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            // loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createRaceStopHandler}>
            Створити
          </LoadingButton>

          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateRaceStopModal
