import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { format, parse } from 'date-fns'

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatDate = (date: number | string, type?: boolean) => {
  const dayjsLocal = dayjs(date) //assumes UTC

  return dayjs(dayjsLocal).format(type ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')
}

export const formatDate2 = (date: number | string, type?: boolean) => {
  return dayjs(date)
    .tz('Europe/London')
    .format(type ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')
}

export const formatDateString = (dateString: string) => {
  const parsedDate = parse(dateString, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date())

  return format(parsedDate, 'yyyy-MM-dd')
}

export const formatDateWithTimeZone = (date: number, zoneName: string) => {
  const dayjsLocal = dayjs(date).tz(zoneName).format('DD.MM.YYYY') //assumes UTC

  return dayjsLocal
}

export const formatTimeWithTimeZone = (date: number, zoneName: string) => {
  const dayjsLocal = dayjs(date).tz(zoneName).format('HH:mm a') //assumes UTC

  return dayjsLocal
}

export const formatDateAndTimeWithTimeZone = (date: number, zoneName: string) => {
  const dayjsLocal = dayjs(date).tz(zoneName).format('DD.MM.YYYY HH:mm') //assumes UTC

  return dayjsLocal
}

export const minutesToHoursTransformer = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const minutesLeft = minutes % 60
  return `${hours}г ${minutesLeft}хв`
}

export const getDateRangeIn24Hours = (date: number) => {
  // get date range in 24 hours from 00:00 to 23:59
  const startDate = new Date(date)
  startDate.setHours(0, 0, 0, 0)

  const endDate = new Date(date)
  endDate.setHours(23, 59, 59, 999)

  return { startDate: startDate.getTime(), endDate: endDate.getTime() }
}
