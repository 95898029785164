import { api } from '@/api'
import { ITripDto, ITripRequest } from '@/interfaces/tripRequests'

import axios from 'axios'

export const apiBlob = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_DEV_BASE_API_URL,
  responseType: 'blob',
})
export const tripRequests_api = {
  getTripRequests: ({
    status,
    trip_id,
    phone,
    pib,
  }: {
    status: string
    trip_id?: number
    phone?: string
    pib?: string
  }) => {
    return api.get('/admin/orders', {
      params: {
        status,
        phone,
        pib,
        trip_id,
      },
    })
  },
  editTripRequest: (id: number, changes: Partial<ITripRequest>) => {
    return api.put(`/admin/orders/${id}`, changes)
  },
  exportToExcel: async (id: number) => {
    try {
      const response = await api.get(`/admin/orders`, {
        params: {
          trip_id: id,
          format: 'file',
          status: 'booked',
        },
        responseType: 'blob', // Ensure the response is received as a blob
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const url = window.URL.createObjectURL(response.data)

      const newTab = window.open(url, '_blank')
      if (newTab) {
        newTab.focus()
      } else {
        alert('Please allow popups for this website')
      }

      setTimeout(() => window.URL.revokeObjectURL(url), 100)

      return response
    } catch (error) {
      console.error('Error exporting to Excel:', error)
    }
  },
  exportToPDF: (id: number, status: string) => {
    return apiBlob.get(`/tripRequest/admin/generate-pdf`, {
      params: {
        tripId: id,
        status: status,
      },
      headers: {
        'Content-Type': 'blob',
      },
    })
  },
  getTripRequestById: (id: number) => {
    return api.get(`/tripRequest/admin/get-one/${id}`)
  },
  createTripRequest: (data: ITripDto) => {
    return api.post(`/order/trips/create`, data)
  },
}
