import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box, Typography } from '@mui/material'
import { Add, West } from '@mui/icons-material'
import ScheduleTabelHeader from './ScheduleTabelHeader'
import ScheduleTabelRow from './ScheduleTabelRow'
import CreateRaceStopModal from './modals/CreateRaceStopModal/CreateRaceStopModal'
import { racesStore } from '@/store/racesStore'
import { useTranslation } from 'react-i18next'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import EditRaceStopModal from './modals/EditRaceStopModal/EditRaceStopModal'
import DeleteRaceStopModal from './modals/DeleteRaceStopModal'

const ScheduleTabel = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const raceId = params.raceId

  const { races, isLoading, createRaceStop, getAllRaces, editRaceStop, deleteRaceStop, oneRace, setOneRace } =
    racesStore()
  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')

  const handleOpenCreateModal = () => {
    createRaceStop.handleOpenModal(oneRace)
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error } = await getAllRacesApiHandler([])

      if (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (raceId && races) {
      const foundRace = races.find((race) => race.flight_id === +raceId)

      if (foundRace) {
        const racesData =
          typeof foundRace.stops_along === 'string' ? JSON.parse(foundRace.stops_along) : foundRace.stops_along
        setOneRace({
          ...foundRace,
          stops_along: racesData,
        })
      }
    }
  }, [raceId, races])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createRaceStop.isOpenModal && <CreateRaceStopModal />}
      {editRaceStop?.isOpenModal && <EditRaceStopModal />}
      {deleteRaceStop?.isOpenModal && <DeleteRaceStopModal />}
      <Grid item xs={12}>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            align: 'center',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            startIcon={<West className="goBack" />}
            onClick={() => navigate(-1)}>
            {t('common.goBack')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            {t('schedulePage.addStopPoint')}
          </Button>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`Рейси / ${oneRace?.race_number ?? ''} 
                ${oneRace?.departure_full_name} - 
                ${oneRace?.destination_full_name} 
                / Розклад`}
          </Typography>
        </Box>
        <TableContainer>
          <Table
            stickyHeader
            sx={{
              maxHeight: 'calc(100vh - 250px)',
              width: '100%',
              maxWidth: '100%',
              overflow: 'scroll',
              mt: 1,
            }}>
            <TableHead>
              <ScheduleTabelHeader />
            </TableHead>
            <TableBody>
              {isLoading ? (
                <LoadingTableData rows={5} columns={11} />
              ) : (
                Object.keys(oneRace).length > 0 &&
                oneRace.stops_along?.stops?.length > 0 &&
                oneRace.stops_along.stops.map((stop: any, index: number) => (
                  <ScheduleTabelRow key={index} race={oneRace} raceStop={stop} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default ScheduleTabel
