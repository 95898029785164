// ? hooks
import React, { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { countryStore } from '@/store/countryStore'
import { townStore } from '@/store/townStore'
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'

// ? components
import {
  Box,
  Button,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  FormHelperText,
} from '@mui/material'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'
import useValidation from '@/hooks/useValidation'
import { RACE_VALIDATION } from '@/validation/race'
import { inputsChanger } from '@/utils/inputsChanger'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { Currency, RaceRegularity, RaceType, TariffType, Weekdays } from '@/constants/constants'
import { validateForm } from '@/utils/formValidation'

interface LeftHeaderDataGridProps {
  rows: any[]
}

const LeftHeaderDataGrid = ({ rows }: LeftHeaderDataGridProps) => (
  <DataGrid rows={rows} columns={[{ field: 'stop', headerName: 'від    до' }]} hideFooter disableColumnMenu />
)

interface MainDataGridProps {
  rows: { id: number; [key: string]: any }[]
  columns: GridColDef[]
}

const MainDataGrid = ({ rows, columns }: MainDataGridProps) => (
  <DataGrid rows={rows} columns={columns} hideFooter disableColumnMenu />
)

const RaceTariff = {
  currency: 'euro',
  tariffType: 'fixed',
  insuranceFee: '',
  insuranceFeeType: 'percentage',
}

const TariffsModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { getAllRaces, racesMeta, tariffsModal } = racesStore()
  const { towns, townMeta, getAllTowns } = townStore()

  const { isOpenModal, editedItem, handleCloseModal } = tariffsModal

  const { addMessage } = messageStore()

  const [formValues, setFormValues] = useState<any>({
    currency: editedItem?.currency || 'euro',
    tariffType: editedItem?.tariffType || 'fixed',
    insuranceFee: editedItem?.insuranceFee || '',
    insuranceFeeType: editedItem?.insuranceFeeType || 'percentage',
    raceStops: editedItem?.raceStops || [],
  })

  const [cityStops, setCityStops] = useState<any>([])
  const [startPoint, setStartPoint] = useState<any>()

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    currency: { required: true },
    tariffType: { required: true },
    insuranceFee: { required: true },
  }

  const editRaceTariffsApiHandler = useAsyncWrapper(tariffsModal.editRaceTariffsApiHandler, 'editRaceTariffsApiHandler')

  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')

  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const editRaceTariffsHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      return
    }

    const data = {
      type: 'editRaceTariffs',
      ...formValues,
    }

    const { error: editRaceSalesError } = await editRaceTariffsApiHandler([editedItem?._id, data])

    if (editRaceSalesError) {
      setIsLoading(false)
      return
    }

    const { error: getAllRacesError } = await getAllRacesApiHandler([racesMeta])

    if (getAllRacesError) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    addMessage({
      severity: 'success',
      message: 'Тарифи успішно оновлено',
      isOpen: true,
    })
    handleCloseForm()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTownsError } = await getAllTownsApiHandler([
        {
          ...townMeta,
          count: 1000,
        },
      ])

      if (getAllTownsError) return
    }

    fetchData()

    const raceStops = editedItem?.raceStops.map((stop: any, index: number) => {
      const town = towns.find((city) => city?._id === stop?.fromLocationCity)
      let specificStop
      if (town && town.cityStops) {
        specificStop = town?.cityStops.find((s) => s?._id === stop?.cityStop)
      }

      if (index === 0) {
        setStartPoint(getTranslateValue(specificStop, 'ua'))
      }

      return {
        ...stop,
        id: stop?._id,
        name: getTranslateValue(specificStop, 'ua'),
        price: stop?.price ?? 0,
      }
    })
    raceStops?.shift()
    setCityStops(raceStops)
  }, [])

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleRadioChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const processCityStopUpdate = (newRow: any) => {
    const updatedRow = { ...newRow }
    const updatedRaceStops = formValues?.raceStops?.map((stop: any) => (stop._id === newRow._id ? newRow : stop))

    setFormValues({
      ...formValues,
      raceStops: updatedRaceStops,
    })
    return updatedRow
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleCloseForm} title="Тарифи рейсу">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.currency} fullWidth variant="standard">
            <InputLabel>Валюта</InputLabel>
            <Select value={formValues?.currency || 'euro'} name="currency" onChange={handleChangeSelect}>
              {Object.entries(Currency).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.currency && <FormHelperText>{formErrors?.currency}</FormHelperText>}
          </FormControl>

          <FormControl error={!!formErrors?.tariffType} fullWidth variant="standard">
            <InputLabel>Фіксований</InputLabel>
            <Select value={formValues?.tariffType || 'fixed'} name="tariffType" onChange={handleChangeSelect}>
              {Object.entries(TariffType).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.tariffType && <FormHelperText>{formErrors?.tariffType}</FormHelperText>}
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Страховий збір</InputLabel>
            <TextField
              name="insuranceFee"
              variant="standard"
              type="number"
              value={formValues?.insuranceFee || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.insuranceFee}
              helperText={formErrors?.insuranceFee}
            />
          </FormControl>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'flex-start',
            }}>
            <RadioGroup
              aria-label="insuranceFeeType"
              name="insuranceFeeType"
              value={formValues?.insuranceFeeType || 'percentage'}
              onChange={handleRadioChange}
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-start',
              }}>
              <FormControlLabel value="percentage" control={<Radio />} label="в %" />
              <FormControlLabel value="fixed" control={<Radio />} label="фіксований" />
            </RadioGroup>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
          }}>
          <InputLabel shrink>Таблиця вартості проїзду</InputLabel>
          <Box
            sx={{
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <DataGrid
              rows={cityStops}
              columns={[
                {
                  field: 'name',
                  headerName: 'від \\ до',
                  editable: true,
                  sortable: false,
                  flex: 1,
                },
                {
                  field: 'price',
                  headerName: `${startPoint}`,
                  editable: true,
                  sortable: false,
                  flex: 1,
                },
              ]}
              localeText={{ noRowsLabel: 'Зупинок поки немає' }}
              getRowId={(row: any) => row.id}
              processRowUpdate={processCityStopUpdate}
              editMode="row"
              disableColumnMenu
              hideFooterSelectedRowCount
              sx={{
                width: '100%',
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            // loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={editRaceTariffsHandler}>
            Зберегти
          </LoadingButton>

          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default TariffsModal
