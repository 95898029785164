import { api } from '@/api'

// ? interfaces
import { IDriver } from '@/interfaces/dirvers'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.DRIVER)

export const drivers_api = {
  getAllDrivers: async () => await requestCreator(api, '', 'GET'),
  createDriver: async (body: Partial<IDriver>) => await requestCreator(api, '', 'POST', body),
  editDriver: async (id: string, body: Partial<IDriver>) => await requestCreator(api, `${id}`, 'PUT', body),
  deleteDriver: async (id: string) => await requestCreator(api, `${id}`, 'DELETE'),
  getById: async (id: string) => await requestCreator(api, `getOne/${id}`, 'GET'),
}
