import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRace } from '@/interfaces/races'
import { racesStore } from '@/store/racesStore'
import { TableRow, TableCell, Typography, Collapse, Box, Button, Checkbox } from '@mui/material'

import { Delete, Edit } from '@mui/icons-material'
import { BoardingStatus } from '@/constants/constants'
import { townStore } from '@/store/townStore'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

interface IProps {
  race: IRace
  raceStop: any
}

const ScheduleTabelRow = ({ race, raceStop }: IProps) => {
  const { t } = useTranslation()

  const { oneRace, editRaceStop, deleteRaceStop } = racesStore()

  const [open, setOpen] = useState(false)
  const [boardingStatus, setBoardingStatus] = useState('')
  const { getAllTowns, towns } = townStore()

  const getAllRacesApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  useEffect(() => {
    const fetchData = async () => {
      const { error } = await getAllRacesApiHandler([])

      if (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (raceStop?.boardingStatus in BoardingStatus) {
      setBoardingStatus(BoardingStatus[raceStop?.boardingStatus as keyof typeof BoardingStatus])
    }
  }, [race])

  const handleOpenEditStopModal = () => {
    editRaceStop.handleOpenModal(oneRace, raceStop)
  }

  const handleOpenDeleteStopModal = () => {
    deleteRaceStop.handleOpenModal({
      ...race,
      stop_name_ua: raceStop.stop_name_ua,
    })
  }

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {
              towns.find((town) => {
                return town.city_id === raceStop.city_id
              })?.name_ukr
            }
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {raceStop.stop_name_ua}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${raceStop?.distanceFromStart ?? ''}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${raceStop?.arrivalTime ?? ''}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${raceStop?.departureTime ?? ''}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${raceStop?.arrivalDay ?? ''}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${raceStop?.departureDay ?? ''}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${raceStop?.platformNumber ?? ''}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {boardingStatus}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox name="borderCross" checked={raceStop.borderCross} disabled />
        </TableCell>
        <TableCell align="center">
          <Checkbox name="changeTransfer" checked={raceStop.changeTransfer} disabled />
        </TableCell>
        <TableCell align="center">
          <Checkbox name="changeBus" checked={raceStop.changeBus} disabled />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                display: 'flex',
                gap: '10px',
              }}>
              {/*<Button*/}
              {/*    id="edits-button"*/}
              {/*    variant="contained"*/}
              {/*    color="primary"*/}
              {/*    size="small"*/}
              {/*    className="addBeforeStop"*/}
              {/*    startIcon={<North className="addBeforeIcon" />}*/}
              {/*    aria-controls={openEditsMenu ? 'edits-menu' : undefined}*/}
              {/*    aria-haspopup="true"*/}
              {/*    aria-expanded={openEditsMenu ? 'true' : undefined}*/}
              {/*    onClick={handleEditsMenuClick}*/}
              {/*>*/}
              {/*    {t("schedulePage.scheduleMenu.addBefore")}*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*    variant="contained"*/}
              {/*    color="primary"*/}
              {/*    size="small"*/}
              {/*    className="addAfterStop"*/}
              {/*    startIcon={<South className="addAfterIcon"/>}*/}
              {/*>*/}
              {/*    {t("schedulePage.scheduleMenu.addAfter")}*/}
              {/*</Button>*/}
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="editRaceStop"
                startIcon={<Edit className="editIcon" />}
                onClick={handleOpenEditStopModal}>
                {t('schedulePage.scheduleMenu.edit')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="deleteRaceStop"
                startIcon={<Delete className="deleteIcon" />}
                onClick={handleOpenDeleteStopModal}>
                {t('schedulePage.scheduleMenu.delete')}
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ScheduleTabelRow
