import { api } from '@/api'

export const users_api = {
  getUsers: async ({ page, count }: any, IUsersFilter: any | null | undefined) => {
    return await api.get('/admin/getAllUsers', {
      params: {
        page: page,
        count,
        filter: IUsersFilter ? JSON.stringify(IUsersFilter) : null,
      },
    })
  },

  getCurrentUser: async (accountNumber: number) => {
    return await api.get(`v2/users/${accountNumber}`)
  },
  editCurrentUser: async (accountNumber: number, body: Partial<any>) => {
    return await api.patch(`v2/users/updateInfo/${accountNumber}`, body)
  },
  resetPassword: async (accountNumber: number, body: { newPassword: string }) => {
    return await api.patch(`/users/resetPasswordForAdmin/${accountNumber}`, body)
  },
  changeAmount: async (
    accountNumber: number,
    body: {
      balance?: number
      bonuses?: number
      credit?: number
    }
  ) => {
    return await api.patch(`v2/users/changeAmounts/${accountNumber}`, body)
  },
  getUsersDocs: async (accountNumber: number) => {
    return await api.get(`v2/users/viewDocument/${accountNumber}`)
  },
  uploadDocument: async (accountNumber: number, body: FormData) => {
    return await api.post(`v2/users/uploadDocument/${accountNumber}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  deleteUsersDocs: async (accountNumber: number, documentId: string) => {
    console.log(accountNumber, documentId)
    return await api.delete(`/v2/users/deleteFile/${accountNumber}?documents=${documentId}`)
  },
  changeStatusDocument: async (accountNumber: number, documentId: string, status: string) => {
    return await api.patch(`/v2/users/changeDocumentStatus/${accountNumber}?documentId=${documentId}&status=${status}`)
  },
}
