import { create } from 'zustand'

import { REQ } from '@/api'
import { IAdmin } from '@/interfaces/auth'
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_INFO_TOKEN } from '@/constants/constants'

const { auth_api, admins_api } = REQ

interface IStore {
  admins: IAdmin[]
  //   currentAdmin: IAdmin | null
  //   setCurrentAdmin: (admin: IAdmin | null) => void
  currentAdmin: boolean | null
  setCurrentAdmin: (admin: boolean | null) => void
  meta: any
  filterQuery: any
  setFilterQuery: (filterQuery: any) => void
  isLoading: boolean
  create: {
    isOpenModal: boolean
    isLoading: boolean
    toggleCreateAdmin: () => void
  }
  resetPassword: {
    isOpenModal: boolean
    isLoading: boolean
    resetedAdmin: any | null
    toggleResetPassword: (admin?: any | null | undefined) => void
  }
  deleteAdminModal: {
    isOpenModal: boolean
    isLoading: boolean
    deletedAdmin: any | null
    toggleDeleteModal: (admin?: any | null | undefined) => void
  }
  edit: {
    isOpenModal: boolean
    isLoading: boolean
    editedAdmin: any | null
    toggleEditAdmin: (admin?: any | null | undefined) => void
  }
  login: (loginData: { email: string; password: string }) => Promise<any>
  checkAuth: () => Promise<any>
  logout: () => void
  getAllAdmins: (meta: any, filterQuery?: any) => Promise<any>
  registerAdmin: (body: Partial<any>) => Promise<any>
  deleteAdmin: (id: string) => Promise<any>
}

export const adminStore = create<IStore>((set) => ({
  admins: [],
  currentAdmin: null,
  setCurrentAdmin: (admin) => {
    set((state) => ({ ...state, currentAdmin: admin }))
  },
  isLoading: false,
  meta: {
    total: 0,
    page: 1,
    rowsPerPage: 10,
  },
  filterQuery: {},
  setFilterQuery: (filterQuery) => {
    set((state) => ({ ...state, filterQuery }))
  },
  create: {
    isOpenModal: false,
    isLoading: false,
    toggleCreateAdmin: () => {
      set((state) => ({
        ...state,
        create: { ...state.create, isOpenModal: !state.create.isOpenModal },
      }))
    },
  },
  resetPassword: {
    isOpenModal: false,
    isLoading: false,
    resetedAdmin: null,
    toggleResetPassword: (admin) => {
      if (admin) {
        set((state) => ({
          ...state,
          resetPassword: {
            ...state.resetPassword,
            isOpenModal: true,
            resetedAdmin: admin,
          },
        }))
      } else {
        set((state) => ({
          ...state,
          resetPassword: {
            ...state.resetPassword,
            isOpenModal: false,
            resetedAdmin: null,
          },
        }))
      }
    },
  },
  deleteAdminModal: {
    isOpenModal: false,
    isLoading: false,
    deletedAdmin: {},
    toggleDeleteModal: (admin) => {
      if (admin) {
        set((state) => ({
          ...state,
          deleteAdminModal: {
            ...state.deleteAdminModal,
            isOpenModal: true,
            deletedAdmin: admin,
          },
        }))
      } else {
        set((state) => ({
          ...state,
          deleteAdminModal: {
            ...state.deleteAdminModal,
            isOpenModal: false,
            deletedAdmin: null,
          },
        }))
      }
    },
  },
  edit: {
    isOpenModal: false,
    isLoading: false,
    editedAdmin: {},
    toggleEditAdmin: (admin) => {
      if (admin) {
        set((state) => ({
          ...state,
          edit: {
            ...state.edit,
            isOpenModal: true,
            editedAdmin: admin,
          },
        }))
      } else {
        set((state) => ({
          ...state,
          edit: {
            ...state.edit,
            isOpenModal: false,
            editedAdmin: null,
          },
        }))
      }
    },
  },

  login: async (loginData) => {
    const response = await auth_api.login(loginData)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    set((state) => ({ ...state, currentAdmin: data }))

    localStorage.setItem(ACCESS_TOKEN, data.access_token)
    localStorage.setItem(REFRESH_TOKEN, data.refresh_token)
    localStorage.setItem(USER_INFO_TOKEN, JSON.stringify(data.userInfo))

    window.location.href = '/'

    return response
  },
  checkAuth: async () => {
    const response = await admins_api.checkAuth()

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    set((state) => ({ ...state, currentAdmin: true }))
    return response
  },
  logout: async () => {
    localStorage.removeItem(ACCESS_TOKEN)

    set((state) => ({ ...state, currentAdmin: null }))
  },
  getAllAdmins: async (meta, filterQuery) => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await admins_api.getAllAdmins(meta, filterQuery)

    const { status, data } = response

    setTimeout(() => {
      set((state) => ({ ...state, isLoading: false }))
    }, 500)

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        admins: data.data,
        meta: data.meta,
      }))
      return response
    }
  },
  registerAdmin: async (body) => {
    set((state) => ({
      ...state,
      create: { ...state.create, isLoading: true },
    }))

    const response = await admins_api.registerAdmin(body)
    const { status, data } = response

    set((state) => ({
      ...state,
      create: { ...state.create, isLoading: false },
    }))

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 201) {
      throw new Error(data)
    } else {
      return response
    }
  },

  deleteAdmin: async (id) => {
    const response = await admins_api.deleteAdmin(id)
    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      return response
    }
  },
}))
