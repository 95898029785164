import {useState} from "react";

import {
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    IconButton,
} from "@mui/material";
import {VisibilityOff, Visibility} from "@mui/icons-material";

interface IProps {
    name: string;
    label: string;
    handleChange: any;
    value: string;
    required?: boolean;
    style?: {};
    size?: "small" | "medium";
    tabIndex?: number;
}

const InputPassword = ({
                           name,
                           label,
                           handleChange,
                           value,
                           required = false,
                           size = "small",
                           style = {},
                           tabIndex = 0,
                       }: IProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl
            variant="standard"
            fullWidth
            required={required}
            size={size}
            sx={style}
            tabIndex={tabIndex}
        >
            <InputLabel>{label}</InputLabel>
            <Input
                type={showPassword ? "text" : "password"}
                name={name}
                value={value}
                onChange={handleChange}
                autoComplete="off"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            style={{padding: 3}}
                            size="small"
                            tabIndex={-1}
                            onClick={handleClickShowPassword}
                        >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default InputPassword;
