import ModalContainer from "@/components/UI/Modal/ModalContainer";
import {newsStore} from "@/store/newsStore";
import {messageStore} from "@/store/store";
import {LoadingButton} from "@mui/lab";
import {Button, Typography} from "@mui/material";
import {Box} from "@mui/system";
import React, {useState} from "react";

const DeleteNewsModal = () => {
    const {deleteNew, getAllNews, newsMeta, filter} = newsStore();
    const {isOpenModal, handleCloseModal, deletedItem, deleteNewApiHandler} =
        deleteNew;

    const {addMessage} = messageStore();

    const [isLoading, setIsLoading] = useState(false);

    const deleteNewsHandler = async () => {
        if (!deletedItem?._id) return;

        setIsLoading(true);
        await deleteNewApiHandler(deletedItem?._id);
        await getAllNews(newsMeta, filter);
        setIsLoading(false);
        handleCloseModal();

        addMessage({
            severity: "success",
            message: "Новину успішно видалено",
        });
    };
    return (
        <ModalContainer
            width={1000}
            isOpen={isOpenModal}
            handleClose={handleCloseModal}
            title="Видалення новини"
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "80vh",
                    overflow: "auto",
                    p: 1,
                }}
            >
                <Typography variant="h6" gutterBottom component="div">
                    Ви впевнені, що хочете видалити новину?
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={deleteNewsHandler}
                    >
                        Видалити
                    </LoadingButton>
                    <Button
                        onClick={handleCloseModal}
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{ml: 1}}
                    >
                        Закрити
                    </Button>
                </Box>
            </Box>
        </ModalContainer>
    );
};

export default DeleteNewsModal;
