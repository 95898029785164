import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";
import {headerModalStyles} from "./styles";
import {Close} from "@mui/icons-material";

interface IProps {
    title: string;
    tooltipTitle?: string;
    isMouseOver?: boolean;
    setIsMouseOver?: (value: boolean) => void | (() => void);
    handleClose: () => void;
}

const ModalHeader = ({title, handleClose, tooltipTitle = "Закрити"}: IProps) => {
    const [isMouseOver, setIsMouseOver] = useState(false);
    return (
        <Box sx={headerModalStyles}>
            <Typography variant="h5" component="h2" color="primary">
                {title}
            </Typography>
            <Tooltip title={tooltipTitle} placement="top" arrow>
                <IconButton
                    onClick={handleClose}
                    size="small"
                    color={isMouseOver ? "error" : "inherit"}
                    onMouseOut={() => setIsMouseOver(false)}
                    onMouseOver={() => setIsMouseOver(true)}
                    sx={{
                        transition: "all .4s ease-in-out",
                        "&:hover": {
                            transform: "scale(1.2) rotate(90deg)",
                        },
                    }}
                >
                    <Close/>
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ModalHeader;
