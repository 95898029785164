// ? hooks
import React, { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { countryStore } from '@/store/countryStore'
import { townStore } from '@/store/townStore'
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'

// ? components
import {
  Box,
  Button,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  FormHelperText,
} from '@mui/material'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'
import { inputsChanger } from '@/utils/inputsChanger'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { RaceRegularity, RaceType, Weekdays } from '@/constants/constants'
import { IRace } from '@/interfaces/races'
import { validateForm } from '@/utils/formValidation'
import { planningStore } from '@/store/planningStore'
import { useParams } from 'react-router-dom'

const RaceParamsModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { raceId } = useParams()

  const { oneRace } = racesStore()
  const { onePlan, planningMeta, getAllRacePlans, addRaceParamsModal } = planningStore()

  const { editedItem, isOpenModal, raceParams, handleCloseModal } = addRaceParamsModal

  const { addMessage } = messageStore()
  const { getAllCountries, countryMeta } = countryStore()
  const { towns, townMeta, getAllTowns } = townStore()

  const [formValues, setFormValues] = useState<any>(editedItem?.raceData?.flight_id ? editedItem?.raceData : raceParams)

  const [fromCityStops, setFromCityStops] = useState<any>([])
  const [toCityStops, setToCityStops] = useState<any>([])
  const [showWeekdaysFields, setShowWeekdaysFields] = useState<boolean>(false)

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    raceNum: { required: true },
    status: { required: true },
    departureTime: { required: true },
    fromDate: { required: true },
    toDate: { required: false },
    fromLocationCity: { required: true },
    fromLocationCityStop: { required: true },
    toLocationCity: { required: true },
    toLocationCityStop: { required: true },
    raceType: { required: true },
    regularity: { required: true },
    regularityValues: { notHaveCheckedValues: true },
    description: { required: true },
  }

  const addRaceParamsApiHandler = useAsyncWrapper(addRaceParamsModal.addRaceParamsApiHandler, 'addRaceParamsApiHandler')

  const getAllRacePlansApiHandler = useAsyncWrapper(getAllRacePlans, 'getAllRacePlansApiHandler')

  const getAllCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getAllCountriesApiHandler')
  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const editRaceHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      return
    }

    const data = {
      type: 'raceParams',
      params: {
        ...formValues,
      },
    }

    const { error: editRaceError } = await addRaceParamsApiHandler([editedItem?._id, data])

    if (editRaceError) {
      setIsLoading(false)
      return
    }

    const { error: getRacePlansError } = await getAllRacePlans(raceId ?? '', planningMeta)

    if (getRacePlansError) {
      console.log(getRacePlansError)
    }

    setIsLoading(false)
    addMessage({
      severity: 'success',
      message: 'План успішно відредаговано',
      isOpen: true,
    })
    handleCloseForm()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllCountriesError } = await getAllCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getAllCountriesError) {
        return
      }

      const { error: getAllTownsError } = await getAllTownsApiHandler([
        {
          ...townMeta,
          count: 1000,
        },
      ])

      if (getAllTownsError) {
        return
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (formValues?.fromLocationCity) {
      const town = towns.find((town) => town._id === (formValues.fromLocationCity?._id || formValues.fromLocationCity))

      const cityStop = town?.cityStops?.find((stop) => stop._id === formValues.fromLocationCityStop)
      setFromCityStops(town?.cityStops)

      setFormValues((formValues: any) => ({
        ...formValues,
        fromLocationCountry: town?.country?.country_id,
        fromLocationCity: town?._id,
        fromLocationCityStop: cityStop?._id ?? cityStop,
      }))
    }
  }, [formValues?.fromLocationCity, towns])

  useEffect(() => {
    if (formValues?.toLocationCity) {
      const town = towns.find((town) => town._id === (formValues.toLocationCity?._id || formValues.toLocationCity))

      const cityStop = town?.cityStops?.find((stop) => stop._id === formValues.toLocationCityStop)
      setToCityStops(town?.cityStops)

      setFormValues((formValues: any) => ({
        ...formValues,
        toLocationCountry: town?.country?.country_id,
        toLocationCity: town?._id,
        toLocationCityStop: cityStop?._id ?? cityStop,
      }))
    }
  }, [formValues?.toLocationCity, towns])

  useEffect(() => {
    let raceStops = formValues?.raceStops.map((stop: any, index: number) => {
      if (index === 0) {
        return {
          ...stop,
          fromLocationCity: formValues?.fromLocationCity,
          cityStop: formValues?.fromLocationCityStop,
          departureTime: formValues?.departureTime,
          stopPosition: stop?._id,
        }
      } else if (index === formValues?.raceStops.length - 1) {
        return {
          ...stop,
          fromLocationCity: formValues?.toLocationCity,
          cityStop: formValues?.toLocationCityStop,
          stopPosition: stop?._id,
        }
      } else {
        return {
          ...stop,
        }
      }
    })

    setFormValues({
      ...formValues,
      raceStops: raceStops,
    })
  }, [formValues?.departureTime, formValues?.fromLocationCityStop, formValues?.toLocationCityStop])

  useEffect(() => {
    if (formValues?.regularity === 'ByWeekdays') {
      setShowWeekdaysFields(true)
    } else {
      setShowWeekdaysFields(false)
    }
  }, [formValues?.regularity])

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleCheckboxChange = (e: any) => {
    let name = e.target.name.split('-')
    if (name[0] === 'weekday') {
      let newArr = formValues.regularityValues ?? []
      if (e.target.checked === true) {
        newArr.push(name[1])
      } else if (e.target.checked === false) {
        let index = newArr.indexOf(name[1])
        if (index > -1) {
          newArr.splice(index, 1)
        }
      }
      setFormValues({
        ...formValues,
        regularityValues: newArr,
      })
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.checked })
    }
  }

  const handleRadioChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  console.log('formValues', formValues, editedItem)

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleCloseForm} title="Редагування рейсу">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>№ рейсу</InputLabel>
            <TextField
              name="raceNum"
              variant="standard"
              type="text"
              value={formValues?.raceNum || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.raceNum}
              helperText={formErrors?.raceNum}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Час відправлення</InputLabel>
            <TextField
              name="departureTime"
              variant="standard"
              type="time"
              value={formValues?.departureTime || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.departureTime}
              helperText={formErrors?.departureTime}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'flex-start',
            }}>
            <InputLabel shrink>Дійсний</InputLabel>
            <RadioGroup
              aria-label="status"
              name="status"
              value={formValues?.status || 'inactive'}
              onChange={handleRadioChange}
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-start',
              }}>
              <FormControlLabel value="active" control={<Radio />} label="Так" />
              <FormControlLabel value="inactive" control={<Radio />} label="Ні" />
            </RadioGroup>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
            }}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Курсує з</InputLabel>
              <TextField
                name="fromDate"
                variant="standard"
                type="date"
                value={formValues?.fromDate || ''}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.fromDate}
                helperText={formErrors?.fromDate}
              />
            </FormControl>

            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Курсує по</InputLabel>
              <TextField
                name="toDate"
                variant="standard"
                type="date"
                value={formValues?.toDate || ''}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.toDate}
                helperText={formErrors?.toDate}
              />
            </FormControl>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.fromLocationCity} fullWidth variant="standard">
            <InputLabel>Від населеного пункту</InputLabel>
            <Select
              value={formValues?.fromLocationCity?._id || formValues?.fromLocationCity || 'initial'}
              name="fromLocationCity"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {towns?.length
                ? towns.map((town) => {
                    return (
                      <MenuItem key={town._id} value={town._id}>
                        {getTranslateValue(town, 'UA')}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.fromLocationCity && <FormHelperText>{formErrors?.fromLocationCity}</FormHelperText>}
          </FormControl>

          <FormControl error={!!formErrors?.toLocationCity} fullWidth variant="standard">
            <InputLabel>До населеного пункту</InputLabel>
            <Select
              value={formValues?.toLocationCity?._id || formValues?.toLocationCity || 'initial'}
              name="toLocationCity"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {towns?.length
                ? towns.map((town) => {
                    return (
                      <MenuItem key={town._id} value={town._id}>
                        {getTranslateValue(town, 'UA')}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.toLocationCity && <FormHelperText>{formErrors?.toLocationCity}</FormHelperText>}
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.fromLocationCityStop} fullWidth variant="standard">
            <InputLabel>Від зупинки</InputLabel>
            <Select
              value={formValues?.fromLocationCityStop || 'initial'}
              name="fromLocationCityStop"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {fromCityStops?.length
                ? fromCityStops.map((cityStop: any, index: number) => {
                    return (
                      <MenuItem key={index} value={cityStop._id}>
                        {getTranslateValue(cityStop, 'UA')}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.fromLocationCityStop && <FormHelperText>{formErrors?.fromLocationCityStop}</FormHelperText>}
          </FormControl>

          <FormControl error={!!formErrors?.toLocationCityStop} fullWidth variant="standard">
            <InputLabel>До зупинки</InputLabel>
            <Select
              value={formValues?.toLocationCityStop || 'initial'}
              name="toLocationCityStop"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {toCityStops?.length
                ? toCityStops.map((cityStop: any, index: number) => {
                    return (
                      <MenuItem key={index} value={cityStop._id}>
                        {getTranslateValue(cityStop, 'UA')}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.toLocationCityStop && <FormHelperText>{formErrors?.toLocationCityStop}</FormHelperText>}
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.raceType} fullWidth variant="standard">
            <InputLabel>Тип рейсу</InputLabel>
            <Select value={formValues?.raceType || 'initial'} name="raceType" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              <MenuItem value={1}>Рейс з автобільним перетином кордону (автобусом)</MenuItem>
              <MenuItem value={2}>Рейс з автомобільним перетином кордону (мінібусом)</MenuItem>
              <MenuItem value={3}>Рейс з пішим перетином кордону (автобусом)</MenuItem>
              <MenuItem value={4}>Рейс з пішим перетином кордону (мінібусом)</MenuItem>
            </Select>
            {formErrors?.raceType && <FormHelperText>{formErrors?.raceType}</FormHelperText>}
          </FormControl>

          <FormControl error={!!formErrors?.regularity} fullWidth variant="standard">
            <InputLabel>Регулярність</InputLabel>
            <Select value={formValues?.regularity || 'initial'} name="regularity" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {Object.entries(RaceRegularity).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.regularity && <FormHelperText>{formErrors?.regularity}</FormHelperText>}
          </FormControl>
        </Box>

        {showWeekdaysFields && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '5px',
            }}>
            <FormControl
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'row wrap',
                gap: '5px',
              }}
              error={!!formErrors?.regularityValues}>
              {Object.entries(Weekdays).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      name={`weekday-${key}`}
                      checked={formValues['regularityValues']?.find((value: string) => value === key) ? true : false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={value}
                />
              ))}
              {formErrors?.regularityValues && (
                <FormHelperText sx={{ m: '0' }}>{formErrors?.regularityValues}</FormHelperText>
              )}
            </FormControl>
          </Box>
        )}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Опис рейсу</InputLabel>
            <TextField
              name="description"
              variant="standard"
              defaultValue={formValues?.description || ''}
              type="text"
              onChange={handleInputChange}
              fullWidth
              multiline
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.description}
              helperText={formErrors?.description}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton color="primary" variant="contained" size="small" onClick={editRaceHandler}>
            Зберегти
          </LoadingButton>

          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default RaceParamsModal
