import { create } from 'zustand'

import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { IRace, IRaceStop } from '@/interfaces/races'

const { races_api } = REQ

interface IStore {
  isLoading: boolean
  races: IRace[]
  oneRace: IRace
  setOneRace: (value: IRace) => void
  racesMeta: IMeta
  infoRaceModal: boolean
  setInfoRaceModal: (value: boolean) => void
  createRaceModal: {
    isOpenModal: boolean
    createdRace: any
    setCreatedRace: (value: any) => void
    handleOpenModal: () => void
    handleCloseModal: () => void
    activeStep: number
    setActiveStep: (value: number) => void
    generatedSteps: any[]
    setGeneratedSteps: (value: any[]) => void
  }
  editRace: {
    isOpenModal: boolean
    editedItem: IRace | null
    handleOpenModal: (item: IRace) => void
    handleCloseModal: () => void
    editRaceApiHandler: (id: number, body: Partial<IRace>) => Promise<any>
  }
  deleteRace: {
    isOpenModal: boolean
    deletedItem: IRace | null
    handleOpenModal: (item: IRace) => void
    handleCloseModal: () => void
    deleteRaceApiHandler: (id: string) => Promise<any>
  }
  raceSalesModal: {
    isOpenModal: boolean
    editedItem: IRace | null
    handleOpenModal: (item: IRace) => void
    handleCloseModal: () => void
    editRaceSalesApiHandler: (id: number, body: Partial<IRace>) => Promise<any>
  }
  tariffsModal: {
    isOpenModal: boolean
    editedItem: IRace | null
    handleOpenModal: (item: IRace) => void
    handleCloseModal: () => void
    editRaceTariffsApiHandler: (id: number, body: Partial<IRace>) => Promise<any>
  }
  createRace: (body: Partial<IRace>) => Promise<any>
  getAllRaces: ({ count, page }: IMeta) => Promise<any>
  getRaceById: (id: string) => Promise<any>
  createRaceStop: {
    isOpenModal: boolean
    editedItem: IRace | null
    handleOpenModal: (item: IRace) => void
    handleCloseModal: () => void
    createRaceStopsApiHandler: (id: number, body: Partial<IRace>) => Promise<any>
  }
  editRaceStop: {
    isOpenModal: boolean
    editedItem: IRace | null
    raceStop: IRaceStop
    handleOpenModal: (item: IRace, raceStop?: IRaceStop) => void
    handleCloseModal: () => void
    editRaceStopsApiHandler: (id: number, body: Partial<IRace>) => Promise<any>
  }
  deleteRaceStop: {
    isOpenModal: boolean
    deletedItem: IRace | null
    raceStopId: number
    handleOpenModal: (item: IRace, raceStopId?: number) => void
    handleCloseModal: () => void
    deleteRaceStopsApiHandler: (id: number, body: Partial<IRace>) => Promise<any>
  }
}

export const racesStore = create<IStore>((set) => ({
  isLoading: false,
  races: [],
  oneRace: {} as IRace,
  setOneRace: (value) => {
    set((state) => ({ ...state, oneRace: value }))
  },
  racesMeta: {
    count: 10,
    page: 1,
    total: 0,
  },
  infoRaceModal: false,
  setInfoRaceModal: (value) => {
    set((state) => ({ ...state, infoRaceModal: value }))
  },
  createRaceModal: {
    isOpenModal: false,
    createdRace: {
      raceNum: '',
      fromLocationCountry: '',
      fromLocationCity: '',
      toLocationCountry: '',
      toLocationCity: '',
      status: 'active',
    },
    setCreatedRace: (value) => {
      set((state) => ({
        ...state,
        createRaceModal: {
          ...state.createRaceModal,
          createdRace: value,
        },
      }))
    },
    handleOpenModal: () => {
      set((state) => ({
        ...state,
        createRaceModal: {
          ...state.createRaceModal,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createRaceModal: {
          ...state.createRaceModal,
          isOpenModal: false,
          createdRace: {
            raceNum: '',
            fromLocationCountry: '',
            fromLocationCity: '',
            toLocationCountry: '',
            toLocationCity: '',
            status: 'active',
          },
          generatedSteps: [],
          activeStep: 0,
        },
      }))
    },
    activeStep: 0,
    setActiveStep: (value) => {
      set((state) => ({
        ...state,
        createRaceModal: {
          ...state.createRaceModal,
          activeStep: value,
        },
      }))
    },
    generatedSteps: [],
    setGeneratedSteps: (value) => {
      set((state) => ({
        ...state,
        createRaceModal: {
          ...state.createRaceModal,
          generatedSteps: value,
        },
      }))
    },
  },

  editRace: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: IRace) => {
      set((state) => ({
        ...state,
        editRace: {
          ...state.editRace,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editRace: {
          ...state.editRace,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editRaceApiHandler: async (id: number, body: Partial<IRace>) => {
      const response = await races_api.editRace(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteRace: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item: IRace) => {
      set((state) => ({
        ...state,
        deleteRace: {
          ...state.deleteRace,
          isOpenModal: true,
          deletedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteRace: {
          ...state.deleteRace,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteRaceApiHandler: async (id: string) => {
      const response = await races_api.deleteRace(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  raceSalesModal: {
    isOpenModal: false,
    editedItem: null,
    raceStopId: '',
    handleOpenModal: (item: IRace) => {
      set((state) => ({
        ...state,
        raceSalesModal: {
          ...state.raceSalesModal,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        raceSalesModal: {
          ...state.raceSalesModal,
          isOpenModal: false,
          editedItem: null,
          isLoading: true,
        },
      }))
    },
    editRaceSalesApiHandler: async (id: number, body: Partial<IRace>) => {
      const response = await races_api.editRace(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  tariffsModal: {
    isOpenModal: false,
    editedItem: null,
    raceStopId: '',
    handleOpenModal: (item: IRace) => {
      set((state) => ({
        ...state,
        tariffsModal: {
          ...state.tariffsModal,
          //isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        tariffsModal: {
          ...state.tariffsModal,
          //isOpenModal: false,
          editedItem: null,
          isLoading: true,
        },
      }))
    },
    editRaceTariffsApiHandler: async (id: number, body: Partial<IRace>) => {
      const response = await races_api.editRace(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  createRace: async (body) => {
    const response = await races_api.createRace(body)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 201) {
      throw new Error(data)
    } else {
      return response
    }
  },

  getAllRaces: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await races_api.getAllRaces()

    const { status, data } = response

    if (!status || !data) {
      set((state) => ({ ...state, isLoading: false }))
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      set((state) => ({ ...state, isLoading: false }))

      throw new Error(data)
    } else {
      set((state) => ({ ...state, races: data.flights }))

      setTimeout(() => {
        set((state) => ({ ...state, isLoading: false }))
      }, 350)
      return response
    }
  },

  getRaceById: async (id) => {
    const response = await races_api.getById(id)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, oneRace: data }))
      return response
    }
  },

  createRaceStop: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: IRace) => {
      set((state) => ({
        ...state,
        createRaceStop: {
          ...state.createRaceStop,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createRaceStop: {
          ...state.createRaceStop,
          isOpenModal: false,
          editedItem: null,
          isLoading: true,
        },
      }))
    },
    createRaceStopsApiHandler: async (id: number, body: Partial<IRace>) => {
      console.log('RESP 1', body)
      const response = await races_api.editRace(id, body)
      console.log('RESP 2', response)
      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  editRaceStop: {
    isOpenModal: false,
    editedItem: null,
    raceStop: {} as IRaceStop,
    handleOpenModal: (item: IRace, raceStop?: IRaceStop) => {
      set((state) => ({
        ...state,
        editRaceStop: {
          ...state.editRaceStop,
          isOpenModal: true,
          editedItem: item,
          raceStop: raceStop!,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editRaceStop: {
          ...state.editRaceStop,
          isOpenModal: false,
          editedItem: null,
          isLoading: true,
        },
      }))
    },
    editRaceStopsApiHandler: async (id: number, body: Partial<IRace>) => {
      const response = await races_api.editRace(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteRaceStop: {
    isOpenModal: false,
    deletedItem: null,
    raceStopId: 0,
    handleOpenModal: (item: IRace, raceStopId?: number) => {
      set((state) => ({
        ...state,
        deleteRaceStop: {
          ...state.deleteRaceStop,
          isOpenModal: true,
          deletedItem: item,
          raceStopId: raceStopId!,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteRaceStop: {
          ...state.deleteRaceStop,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteRaceStopsApiHandler: async (id: number, body: Partial<IRace>) => {
      const response = await races_api.editRace(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },
}))
