export const getTranslateValue = (
    data: any,
    key: string
) => {
    if (!data) {
        return "";
    }

    return (
        data?.translates?.find(
            (item: any) => item?.code?.toLowerCase() === key.toLowerCase()
        )?.translate || ""
    );
};