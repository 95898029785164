import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Add, West } from '@mui/icons-material'
import PlanningScheduleHeader from './PlanningScheduleHeader'
import PlanningScheduleRow from './PlanningScheduleRow'

import { racesStore } from '@/store/racesStore'
import { planningStore } from '@/store/planningStore'

import CreateRaceStopModal from './modals/CreateRaceStopModal/CreateRaceStopModal'
import EditRaceStopModal from './modals/EditRaceStopModal/EditRaceStopModal'
import DeleteRaceStopModal from './modals/DeleteRaceStopModal'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import { IRaceStop } from '@/interfaces/races'
import { getTranslateValue } from '@/utils/getTranslateValue'

const PlanningScheduleTabel = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [stops, setStops] = useState<IRaceStop[]>([])

  const { getRaceById, oneRace } = racesStore()

  const { onePlan, getPlanById, isLoading, createRaceStop, editRaceStop, deleteRaceStop } = planningStore()

  const getRaceByIdApiHandler = useAsyncWrapper(getRaceById, 'getRaceByIdApiHandler')

  const handleOpenCreateModal = () => {
    createRaceStop.handleOpenModal(onePlan)
  }

  const fetchRaceData = async () => {
    const { error: getRaceByIdError } = await getRaceById(params.raceId ?? '')

    if (getRaceByIdError) {
      console.log(getRaceByIdError)
    }

    const { error: getPlanByIdError } = await getPlanById(params.planId ?? '')

    if (getPlanByIdError) console.log(getPlanByIdError)
  }

  useEffect(() => {
    fetchRaceData()
  }, [params.raceId])

  // useEffect(() => {
  //     if (onePlan?.raceData?.raceStops) {
  //         setStops(onePlan?.raceData?.raceStops);
  //     } else if (oneRace?.raceStops) {
  //         setStops(oneRace?.raceStops);
  //     }
  // }, [oneRace, onePlan]);

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createRaceStop?.isOpenModal && <CreateRaceStopModal />}
      {editRaceStop?.isOpenModal && <EditRaceStopModal fetchData={fetchRaceData} />}
      {deleteRaceStop?.isOpenModal && <DeleteRaceStopModal />}

      <Grid item xs={12}>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            align: 'center',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            startIcon={<West className="goBack" />}
            onClick={() => navigate(-1)}>
            {t('common.goBack')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            {t('schedulePage.addStopPoint')}
          </Button>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`Рейси / ${oneRace?.race_number ?? ''} 
                            ${oneRace?.departure_location} - 
                            ${oneRace?.destination_location} 
                            / Планування / Розклад`}
          </Typography>
        </Box>

        <TableContainer>
          <Table
            stickyHeader
            sx={{
              maxHeight: 'calc(100vh - 250px)',
              width: '100%',
              maxWidth: '100%',
              overflow: 'scroll',
              mt: 1,
            }}>
            <TableHead>
              <PlanningScheduleHeader />
            </TableHead>
            <TableBody>
              {isLoading ? (
                <LoadingTableData rows={5} columns={11} />
              ) : (
                stops?.map((stop: IRaceStop, index: number) => {
                  return <PlanningScheduleRow stop={stop} key={`race-stop-${index}`} />
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/*
                    * <TablePaginationCustom
                    getData={getAllRacesApiHandler}
                    meta={racesMeta}
                  />
                    * */}
      </Grid>
    </Grid>
  )
}

export default PlanningScheduleTabel
