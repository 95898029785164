// ? hooks
import { useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? store
import { driversStore } from '@/store/driverStore'
import { messageStore } from '@/store/store'

// ? interfaces
import { IDriver } from '@/interfaces/dirvers'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? validation
import { DRIVER_VALIDATION } from '@/validation/driver'

// ? components
import { TextField, Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

const DriverEditModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState({} as Partial<IDriver>)

  const { addMessage } = messageStore()
  const { editDriver, getAllDrivers, driversMeta } = driversStore()
  const { isOpenModal, handleCloseModal, editedItem } = editDriver

  const editDriverValidation = useValidation(DRIVER_VALIDATION.EDIT)

  const handleChangeInput = inputsChanger('input', formValues, setFormValues)

  const editDriverApiHandler = useAsyncWrapper(editDriver.editDriverApiHandler, 'editDriverApiHandler')

  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')

  const handleCloseForm = () => {
    setFormValues({} as Partial<IDriver>)
    handleCloseModal()
  }

  const editDriverHandler = async () => {
    setIsLoading(true)

    if (!editDriverValidation(formValues)) {
      setIsLoading(false)
      return false
    }

    const id = editedItem?.user_id

    const { error: editDriverError } = await editDriverApiHandler([
      id,
      {
        ...editedItem,
        ...formValues,
      },
    ])

    if (editDriverError) {
      setIsLoading(false)
      return false
    }

    const { error: getDriversError } = await getDriversApiHandler([driversMeta])

    if (getDriversError) {
      setIsLoading(false)
    }

    addMessage({
      severity: 'success',
      message: 'Водія успішно відредаговано',
      isOpen: true,
    })

    handleCloseForm()
    setIsLoading(false)
  }

  return (
    <ModalContainer width={600} isOpen={isOpenModal} handleClose={handleCloseForm} title="Редагування водія">
      <TextField
        fullWidth
        label="ПІБ"
        onChange={handleChangeInput}
        name="pib"
        value={formValues.pib || editedItem?.pib}
        variant="standard"
        placeholder={'Ivan'}
      />

      <TextField
        fullWidth
        label="Номер телефону"
        onChange={handleChangeInput}
        name="phone"
        value={formValues.phone || editedItem?.phone}
        variant="standard"
        placeholder={'380991800911'}
      />

      {/* <TextField
        fullWidth
        label="Шкідливі звички"
        onChange={handleChangeInput}
        value={formValues.badHabit || editedItem?.badHabit}
        name="badHabit"
        multiline
        variant="standard"
        placeholder={'Куріння 20 сигарет на день'}
      />

      <TextField
        fullWidth
        label="Особливості водія"
        onChange={handleChangeInput}
        name="driverFeatures"
        variant="standard"
        value={formValues.driverFeatures || editedItem?.driverFeatures}
        multiline
        placeholder={'Куріння 20 сигарет на день'}
      /> */}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton loading={isLoading} color="primary" variant="contained" size="small" onClick={editDriverHandler}>
          Зберегти
        </LoadingButton>

        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Закрити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DriverEditModal
