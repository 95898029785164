import {TableRow, TableCell} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const PlanningTabelHeader = () => {
    const {t} = useTranslation();

    const i18nTranslateHeaderString = "racePlanningPage";

    const headerTitles = [
        {
            title: t(`${i18nTranslateHeaderString}.startDate`),
            name: "startDate"
        },
        {
            title: t(`${i18nTranslateHeaderString}.labels`),
            name: "labels",
        },
        {
            title: t(`${i18nTranslateHeaderString}.note`),
            name: "note",
        }
    ];
    return (
        <TableRow>
            {headerTitles.map(({title, name}) => (
                <TableCell
                    key={name}
                    align="center"
                    sx={{
                        backgroundColor: "#222222 !important",
                    }}
                >
                    {title}
                </TableCell>
            ))}
        </TableRow>
    );
};

export default PlanningTabelHeader;
