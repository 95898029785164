import {messageStore} from "@/store/store";

const useCopy = () => {
    const {addMessage} = messageStore();

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);

        addMessage({
            type: "success",
            message: "Скопійовано в буфер обміну",
            isOpen: true,
        });
    };

    return handleCopy;
};

export default useCopy;
