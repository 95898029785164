import {messageStore} from "@/store/store";
import MessageItem from "@/components/UI/Message/MessageItem";

const Message = () => {
    const {data} = messageStore();

    return (
        <>
            {data.map((item, key) => (
                <MessageItem
                    item={item}
                    key={key}
                    index={key}
                    last={key + 1 === data.length}
                />
            ))}
        </>
    );
};

export default Message;
