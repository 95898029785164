import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Box, Button, TableContainer, Table, TableHead, TableBody, Typography } from '@mui/material'
import { West } from '@mui/icons-material'
import { tripsStore } from '@/store/tripStore'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import RaceTripsTabelHeader from '@/components/Tabels/RaceTripsTabel/RaceTripsTabelHeader'
import RaceTripsTabelRow from '@/components/Tabels/RaceTripsTabel/RaceTripsTabelRow'

import TariffsModal from './modals/TariffsModal'
import DeleteTripModal from './modals/DeleteTripModal'
import InfoTransportModal from './modals/InfoTransportModal'
import InfoDriverModal from './modals/InfoDriverModal'

const RaceTripsTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const {
    trips,
    getAllTrips,
    tripsMeta,
    isLoading,
    tripTariffs,
    tripBusModal,
    tripDriversModal,
    deleteTrip,
    searchParams,
  } = tripsStore((trips) => trips)

  const getAllRaceTripsApiHandler = useAsyncWrapper(getAllTrips, 'getAllRaceTripsApiHandler')

  useEffect(() => {
    const fetchRaceData = async () => {
      const { error: getAllTripsError } = await getAllTrips(Number(params.raceId))

      if (getAllTripsError) {
        console.log(getAllTripsError)
      }
    }

    fetchRaceData().catch(console.error)
  }, [params.raceId])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {tripTariffs?.isOpenModal && <TariffsModal />}
      {tripBusModal?.isOpenModal && <InfoTransportModal />}
      {tripDriversModal?.isOpenModal && <InfoDriverModal />}
      {deleteTrip?.isOpenModal && <DeleteTripModal />}
      <Grid item xs={12}>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            align: 'center',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            startIcon={<West className="goBackIcon" />}
            onClick={() => navigate(-1)}>
            {t('common.goBack')}
          </Button>
          <Typography variant="subtitle1" color="text.secondary">
            Рейси / Поїздки
          </Typography>
        </Box>
        <TableContainer>
          <Table
            stickyHeader
            sx={{
              maxHeight: 'calc(100vh - 250px)',
              width: '100%',
              maxWidth: '100%',
              overflow: 'scroll',
              mt: 1,
            }}>
            <TableHead>
              <RaceTripsTabelHeader />
            </TableHead>
            <TableBody>
              {isLoading ? (
                <LoadingTableData rows={5} columns={11} />
              ) : (
                trips.map((trip, index) => <RaceTripsTabelRow trip={trip} key={`tripid-${trip?._id}-${index}`} />)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationCustom getData={getAllRaceTripsApiHandler} meta={tripsMeta} filterQuery={searchParams} />
      </Grid>
    </Grid>
  )
}

export default RaceTripsTable
