import {create} from "zustand";

import {IMeta} from "@/interfaces/general";
import {IDriver} from "@/interfaces/dirvers";
import {articles_api} from "@/api/articles.api";

interface IStore {
    articles: IDriver[];
    oneArticle: IDriver;
    articlesMeta: IMeta;
    filter: any;
    setFilter: (filter: any) => void;
    isLoading: boolean;
    createArticle: {
        isOpenModal: boolean;
        handleOpenModal: () => void;
        handleCloseModal: () => void;
        createArticleApiHandler: (body: Partial<IDriver>) => Promise<any>;
    };
    editArticle: {
        isOpenModal: boolean;
        editedItem: IDriver | null;
        handleOpenModal: (item: IDriver) => void;
        handleCloseModal: () => void;
        editArticleApiHandler: (id: string, body: Partial<IDriver>) => Promise<any>;
    };
    photoArticle: {
        isOpenModal: boolean;
        editedItem: IDriver | null;
        handleOpenModal: (item: IDriver) => void;
        handleCloseModal: () => void;
        deletePhotoArticle: (id: string, fileName: string) => Promise<any>;
        uploadPhotoArticle: (id: string, body: any) => Promise<any>;
    };
    deleteArticle: {
        isOpenModal: boolean;
        deletedItem: IDriver | null;
        handleOpenModal: (item: IDriver) => void;
        handleCloseModal: () => void;
        deleteArticleApiHandler: (id: string) => Promise<any>;
    };
    getAllArticles: ({count, page}: IMeta, filter: any) => Promise<any>;
    getArticleById: (id: string) => Promise<any>;
}

export const articlesStore = create<IStore>((set) => ({
    articles: [],
    oneArticle: {} as IDriver,
    filter: {},
    setFilter: (filter) => {
        set((state) => ({
            ...state,
            filter,
        }));
    },
    articlesMeta: {
        count: 10,
        page: 1,
        total: 0,
    },
    isLoading: false,

    createArticle: {
        isOpenModal: false,
        handleOpenModal: () => {
            set((state) => ({
                ...state,
                createArticle: {
                    ...state.createArticle,
                    isOpenModal: true,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                createArticle: {
                    ...state.createArticle,
                    isOpenModal: false,
                },
            }));
        },
        createArticleApiHandler: async (body: Partial<IDriver>) => {
            console.log("body", body)
            const response = await articles_api.createArticle(body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 201) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    editArticle: {
        isOpenModal: false,
        editedItem: null,
        handleOpenModal: (item: IDriver) => {
            set((state) => ({
                ...state,
                editArticle: {
                    ...state.editArticle,
                    isOpenModal: true,
                    editedItem: item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                editArticle: {
                    ...state.editArticle,
                    isOpenModal: false,
                    editedItem: null,
                },
            }));
        },
        editArticleApiHandler: async (id, body) => {
            const response = await articles_api.editArticle(id, body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },
    photoArticle: {
        isOpenModal: false,
        editedItem: null,
        handleOpenModal: (item: IDriver) => {
            set((state) => ({
                ...state,
                photoArticle: {
                    ...state.photoArticle,
                    isOpenModal: true,
                    editedItem: item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                photoArticle: {
                    ...state.photoArticle,
                    isOpenModal: false,
                    editedItem: null,
                },
            }));
        },
        deletePhotoArticle: async (id, fileName) => {
            const response = await articles_api.deletePhotoArticle(id, fileName);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                set((state) => ({
                    ...state,
                    photoArticle: {
                        ...state.photoArticle,
                        editedItem: response.data,
                    },
                }));
                return response;
            }
        },
        uploadPhotoArticle: async (id, body) => {
            const response = await articles_api.uploadPhotoArticle(id, body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 201) {
                throw new Error(data);
            } else {
                set((state) => ({
                    ...state,
                    photoArticle: {
                        ...state.photoArticle,
                        editedItem: response.data,
                    },
                }));
                return response;
            }
        },
    },
    deleteArticle: {
        isOpenModal: false,
        deletedItem: null,
        handleOpenModal: (item: IDriver) => {
            set((state) => ({
                ...state,
                deleteArticle: {
                    ...state.deleteArticle,
                    isOpenModal: true,
                    deletedItem: item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                deleteArticle: {
                    ...state.deleteArticle,
                    isOpenModal: false,
                    deletedItem: null,
                },
            }));
        },
        deleteArticleApiHandler: async (id) => {
            const response = await articles_api.deleteArticle(id);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },
    getAllArticles: async ({count, page}: IMeta, filter) => {
        set((state) => ({...state, isLoading: true}));
        const response = await articles_api.getAllArticles({count, page}, filter);

        set((state) => ({...state, isLoading: false}));

        const {status, data} = response;

        if (!status || !data) {
            throw new Error("No status code or data returned from server.");
        }

        if (status !== 200) {
            throw new Error(data);
        } else {
            set((state) => ({
                ...state,
                articles: data.data,
                articlesMeta: data.meta,
            }));
        }
    },
    getArticleById: async (id) => {
        const response = await articles_api.getCurrentArticle(id);

        const {status, data} = response;

        if (!status || !data) {
            throw new Error("No status code or data returned from server.");
        }

        if (status !== 200) {
            throw new Error(data);
        } else {
            set((state) => ({
                ...state,
                oneArticle: data,
            }));
        }
    },
}));
