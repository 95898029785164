import FileUploader from "@/components/UI/FileUploader/FileUploader";
import ModalContainer from "@/components/UI/Modal/ModalContainer";
import {articlesStore} from "@/store/articlesStore";
import {messageStore} from "@/store/store";
import {inputsChanger} from "@/utils/inputsChanger";
import {LoadingButton} from "@mui/lab";
import {Editor} from '@tinymce/tinymce-react';
import {
    TextField,
    Button,
    Box,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
} from "@mui/material";
import React, {useState, useRef} from "react";

const initialState = {
    title: "",
    description: "",
    language: "UA",
    isHidden: false,
    type: "article",
    entity: "passengerTransportation",
    images: [],
};

const CreateArticleModal = () => {
    const {createArticle, getAllArticles, articlesMeta, filter} = articlesStore();
    const {isOpenModal, handleCloseModal} = createArticle;

    const {addMessage} = messageStore()

    const [formValues, setFormValues] = useState<any>(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<any>([]);

    const handleChangeInput = inputsChanger("input", formValues, setFormValues);

    const handleChangeSelect = inputsChanger(
        "selectChanger",
        formValues,
        setFormValues
    );
    const handleChangeSwitch = inputsChanger(
        "switchChanger",
        formValues,
        setFormValues
    );

    const handleEditorChange = (content: any, editor: any) => {
        setFormValues({
            ...formValues,
            description: content
        });
    };

    const createArticlesHandler = async () => {
        console.log("formValues", formValues);

        const formData = new FormData();

        for (const key in formValues) {
            formData.append(key, formValues[key]);
        }

        files.forEach((file: any) => {
            const blob = new Blob([file], {type: file.type});

            formData.append("images", blob);
        });

        if (formValues.title.length < 3) {
            return alert("Заголовок має бути більше 3 символів");
        }

        // if (formValues.description.length < 3) {
        //     return alert("Опис має бути більше 3 символів");
        // }

        setIsLoading(true);

        await createArticle.createArticleApiHandler(formData);
        await getAllArticles(articlesMeta, filter);

        setIsLoading(false);

        setFormValues(initialState);
        handleCloseModal();

        addMessage({
            severity: "success",
            message: "Статтю успішно створено",
        });
    };

    return (
        <ModalContainer
            width={1000}
            isOpen={isOpenModal}
            handleClose={handleCloseModal}
            title="Створення статті"
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "80vh",
                    overflow: "auto",
                    p: 1,
                }}
            >
                <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    label="Заголовок"
                    onChange={handleChangeInput}
                    name="title"
                    variant="outlined"
                    value={formValues.title}
                    sx={{mt: 2}}
                />

                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    minRows={3}*/}
                {/*    multiline*/}
                {/*    label="Опис"*/}
                {/*    onChange={handleChangeInput}*/}
                {/*    sx={{mt: 2}}*/}
                {/*    name="description"*/}
                {/*    value={formValues.description}*/}
                {/*    variant="outlined"*/}
                {/*/>*/}
                <Box
                    sx={{
                        mt: 2,
                    }}
                >
                    {/* @ts-ignore*/}
                    <Editor
                        apiKey="g93cp8hk6azd3m8ujy8vcwu4ddtccgh3r1gz7mon35gtbnnq"
                        value={formValues.description || ""}
                        init={{
                            icons: "thin",
                            placeholder: "Текст статті...",
                            height: 300,
                            menubar: true,
                            plugins: [
                                "advlist autolink lists link image charmap preview anchor",
                                "searchreplace visualblocks code fullscreen textcolor ",
                                "insertdatetime media table paste code help wordcount"
                            ],
                            textcolor_rows: "5",
                            toolbar:
                                "undo redo | styleselect | fontsizeselect| code | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent "
                        }}
                        onEditorChange={handleEditorChange}
                        //outputFormat="html"
                        // toolbar="code"
                    />
                </Box>

                <Select
                    fullWidth
                    name="language"
                    onChange={handleChangeSelect}
                    value={formValues.language}
                    variant="standard"
                    sx={{
                        mt: 1,
                    }}
                >
                    <MenuItem value="UA">UA</MenuItem>
                    <MenuItem value="EN">EN</MenuItem>
                </Select>

                <Select
                    fullWidth
                    name="type"
                    onChange={handleChangeSelect}
                    variant="standard"
                    value={formValues.type}
                    sx={{
                        mt: 1,
                    }}
                >
                    <MenuItem value="articles">Новина</MenuItem>
                    <MenuItem value="discount">Знижка</MenuItem>
                </Select>

                <Select
                    fullWidth
                    name="entity"
                    variant="standard"
                    onChange={handleChangeSelect}
                    value={formValues.entity}
                    sx={{
                        mt: 1,
                    }}
                >
                    <MenuItem value="passengerTransportation">
                        Пасажирські перевезення
                    </MenuItem>
                    <MenuItem value="cargoTransportation">Вантажні перевезення</MenuItem>
                </Select>

                <FormControlLabel
                    control={
                        <Switch
                            name="isHidden"
                            value={formValues.isHidden}
                            onChange={handleChangeSwitch}
                        />
                    }
                    label="Приховати новину"
                />

                <FileUploader setFiles={setFiles} files={files}/>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={createArticlesHandler}
                    >
                        Створити
                    </LoadingButton>
                    <Button
                        onClick={handleCloseModal}
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{ml: 1}}
                    >
                        Закрити
                    </Button>
                </Box>
            </Box>
        </ModalContainer>
    );
};

export default CreateArticleModal;
