import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { Table, TableBody, TableContainer, TableHead } from '@mui/material'
import { tripRequestsStore } from '@/store/tripRequestsStore'
import { messageStore } from '@/store/store'
import { useEffect } from 'react'
import UserRacesRow from '../UserRacesRow'
import UserRacesHeader from '../UserRacesHeader'
import { usersStore } from '@/store/usersStore'

export function UserRacesModal({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) {
  const { tripRequests, getTripRequests } = tripRequestsStore()
  const { currentUser } = usersStore()

  const { addMessage } = messageStore()

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTripRequestsError } = await getTripRequests({
        status: '',
        pib: currentUser.pib,
      })

      if (getAllTripRequestsError) {
        console.log(getAllTripRequestsError)

        addMessage({
          isOpen: true,
          message: 'Щось пішло не так',
          severity: 'error',
        })
      }
    }

    if (currentUser) {
      fetchData()
    }
  }, [currentUser])
  return (
    <ModalContainer
      styles={{
        width: '90%',
        height: '90%',
        maxWidth: '100%',
        overflow: 'scroll',
      }}
      height="auto"
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Поїздки користувача'}>
      <TableContainer>
        <Table
          stickyHeader
          sx={{
            width: '100%',
            maxWidth: '100%',
          }}>
          <TableHead>
            <UserRacesHeader />
          </TableHead>
          <TableBody>
            {tripRequests.map((tripRequest) => (
              <UserRacesRow tripRequest={tripRequest} key={tripRequest?.order_trip_id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  )
}
