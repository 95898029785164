// ? interfaces
import { IErrorValidation } from '@/interfaces/general'
import { IDriverCreateDTO } from '@/interfaces/dirvers'

// ? validation utils
import {} from './general'

interface IDRIVER_VALIDATION {
  CREATE: (data: IDriverCreateDTO) => IErrorValidation[]
  EDIT: (data: Partial<IDriverCreateDTO>) => IErrorValidation[]
  DELETE: (id: string) => IErrorValidation[]
}

export const DRIVER_VALIDATION: IDRIVER_VALIDATION = {
  CREATE: (data) => {
    const errors: IErrorValidation[] = []

    if (!data.pib) {
      errors.push({
        field: 'pib',
        message: `Поле "pib" обов'язкове для заповнення`,
      })
    }

    // if (!data.lastName) {
    //   errors.push({
    //     field: "lastName",
    //     message: `Поле "lastName" обов'язкове для заповнення`,
    //   });
    // }

    if (!data.phone) {
      errors.push({
        field: 'phone',
        message: `Поле "phoneNumber" обов'язкове для заповнення`,
      })
    }

    // if (!data.badHabit) {
    //   errors.push({
    //     field: "badHabit",
    //     message: `Поле "badHabit" обов'язкове для заповнення`,
    //   });
    // }

    // if (!data.driverFeatures) {
    //   errors.push({
    //     field: "driverFeatures",
    //     message: `Поле "driverFeatures" обов'язкове для заповнення`,
    //   });
    // }

    return errors
  },

  EDIT: (data) => {
    const errors: IErrorValidation[] = []

    if (Object.keys(data).length === 0) {
      errors.push({
        field: 'all',
        message: `Не вказано даних для редагування`,
      })
    }

    for (const key in data) {
      if (!data[key as keyof IDriverCreateDTO]) {
        errors.push({
          field: key,
          message: `Поле "${key}" обов'язкове для заповнення`,
        })
      }
    }

    return errors
  },
  DELETE: (id) => {
    const errors: IErrorValidation[] = []

    if (!id) {
      errors.push({
        field: 'id',
        message: `Не вказано id водія`,
      })
    }

    return errors
  },
}
