import {create} from "zustand";


interface IMessageStore {
    data: any[];
    addMessage: (message: any) => void;
    clearMessages: () => void;

    messageAlert: any;
    setMessageAlert: (message: any) => void;
}

export const messageStore = create<IMessageStore>((set) => ({
    data: [],
    addMessage: (message: any) => {
        set((state) => ({...state, data: [...state.data, message]}));
    },
    clearMessages: () => set((state) => ({...state, data: []})),

    messageAlert: {
        message: "",
        severity: "success",
        isOpen: false,
    },
    setMessageAlert: (message: any) => {
        set((state) => ({...state, messageAlert: message}));
    },

}));
