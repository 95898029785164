import {useEffect, useState} from "react";

import {
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    IconButton,
    Tooltip,
} from "@mui/material";
import {WarningAmber, VerifiedUser} from "@mui/icons-material";
import useDebounce from "@/hooks/useDebounce";
import {isEmail} from "@/validation/general";

interface IProps {
    name: string;
    label: string;
    handleChange: any;
    value: string;
    required?: boolean;
    style?: {};
    size?: "small" | "medium";
    customError?: string;
    tabIndex?: number;
}

const InputEmail = ({
                        name,
                        label,
                        handleChange,
                        value,
                        required = false,
                        style = {},
                        size = "small",
                        customError = "",
                        tabIndex = 0,
                    }: IProps) => {
    const [isEmailValid, setIsEmailValid] = useState(false);

    const debouncedSearchTerm = useDebounce(value, 500) as any;

    useEffect(() => {
        if (debouncedSearchTerm) {
            setIsEmailValid(!Boolean(isEmail(value).length));
        }
    }, [debouncedSearchTerm]);

    return (
        <FormControl
            sx={style}
            variant="standard"
            fullWidth
            required={required}
            size={size}
            tabIndex={tabIndex}
        >
            <InputLabel>{label}</InputLabel>
            <Input
                type="email"
                name={name}
                value={value}
                placeholder="example@mail.com"
                autoComplete="off"
                onChange={handleChange}
                endAdornment={
                    <InputAdornment position="end">
                        {customError && value?.length !== 0 && isEmailValid && (
                            <Tooltip title={customError} placement="top" arrow tabIndex={-1}>
                                <IconButton size="small" color="error">
                                    <WarningAmber/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {!isEmailValid && value?.length !== 0 && (
                            <Tooltip
                                title="Email is not valid, example: test@gmail.com"
                                placement="top"
                                arrow
                            >
                                <IconButton size="small" color="error" tabIndex={-1}>
                                    <WarningAmber/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {isEmailValid && value?.length !== 0 && (
                            <IconButton size="small" color="success" tabIndex={-1}>
                                <VerifiedUser/>
                            </IconButton>
                        )}
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default InputEmail;
