import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { messageStore } from '@/store/store'
import { townStore } from '@/store/townStore'
import { tripRequestsStore } from '@/store/tripRequestsStore'
import { tripsStore } from '@/store/tripStore'
import { inputsChanger } from '@/utils/inputsChanger'
import { Button, Box, Grid, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const TripItemUsersEditModal = () => {
  const { tripId } = useParams()

  const { addMessage } = messageStore()

  const { editModal, editTripRequest, getTripRequests } = tripRequestsStore()
  const { isOpen, handleClose, tripRequest } = editModal
  const [disembarkingStops, setDisembarkingStops] = useState<any>([])
  const [boardingStops, setBoardingStops] = useState<any>([])
  const [disembarkingCities, setDisembarkingCities] = useState<any>([])
  const [boardingCities, setBoardingCities] = useState<any>([])

  const [formData, setFormData] = useState<any>({})

  const { getAllTowns, towns, townMeta } = townStore()
  const { getAllTrips, trips } = tripsStore()

  const getAllTripsApiHandler = useAsyncWrapper(getAllTrips, 'getAllTripsApiHandler')
  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  const editTripRequestApiHandler = useAsyncWrapper(editTripRequest, 'editTripRequestApiHandler')

  const getTripRequestsApiHandler = useAsyncWrapper(getTripRequests, 'getTripRequestsApiHandler')

  const handleCloseForm = () => {
    setFormData({})
    handleClose()
  }

  useEffect(() => {
    if (isOpen && tripRequest) {
      initializeFormData()
    }
  }, [isOpen, tripRequest, towns])

  const handleInputChange = inputsChanger('input', formData, setFormData)
  const selectChangeHandler = inputsChanger('selectChanger', formData, setFormData) as (e: any) => void

  const handleEditTripRequest = async () => {
    if (tripRequest?.trip_id) {
      const dataToSend: any = {
        connectionType: formData?.connectionType || '',
        connectPhoneNumber: formData?.connectPhoneNumber || '',
        baggageAmount: formData?.baggageAmount || '',
        departureType: formData?.departureType || '',
        departurePlace: formData?.departurePlace || '',
        arrivalPlaceType: formData?.arrivalPlaceType || '',
        arrivalPlace: formData?.arrivalPlace || '',
        adultPassengerAmount: +formData?.adultPassengerAmount || '',
        childPassengerAmount: +formData?.childPassengerAmount || '',
        childMiddlePassengerAmount: +formData?.childMiddlePassengerAmount || '',
        note: formData?.note || '',
        fromLocationCity: formData?.fromLocationCity || '',
        toLocationCity: formData?.toLocationCity || '',
      }

      for (let key in dataToSend) {
        if (dataToSend[key] === '') {
          delete dataToSend[key]
        }
      }

      if (dataToSend.status === tripRequest.status) {
        delete dataToSend.status
      }

      const { error: editTripRequestError } = await editTripRequestApiHandler([tripRequest.order_trip_id, dataToSend])

      if (editTripRequestError) {
        addMessage({
          isOpen: true,
          message: 'Щось пішло не так',
          severity: 'error',
        })
        return false
      }
      addMessage({
        isOpen: true,
        message: 'Заявку успішно відредаговано',
        severity: 'success',
      })

      await getTripRequestsApiHandler([
        {
          status: 'booked',
          trip_id: Number(tripId),
        },
      ])

      handleCloseForm()
    }
  }

  useEffect(() => {
    getAllTripsApiHandler([undefined, tripId])
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTownsError } = await getAllTownsApiHandler([
        {
          ...townMeta,
          count: 1000,
        },
      ])

      if (getAllTownsError) return
    }

    fetchData()
  }, [])

  const initializeFormData = () => {
    if (!tripRequest) return

    const initialData = {
      fromLocationCity: getCityIdByName(towns, tripRequest.fromLocationCity.city_name_ua),
      toLocationCity: getCityIdByName(towns, tripRequest.toLocationCity.city_name_ua),
      departureType: '',
      arrivalPlaceType: '',
      departurePlace: '',
      arrivalPlace: '',
    }

    const departureStopId = findStopByName(boardingStops, tripRequest.departurePlace.bus_stop_name_ua)
    const arrivalStopId = findStopByName(disembarkingStops, tripRequest.arrivalPlace.bus_stop_name_ua)

    initialData.departureType = departureStopId ? 'fixedPlace' : 'address'
    initialData.departurePlace = departureStopId?.stop_id || tripRequest.departurePlace.bus_stop_name_ua

    initialData.arrivalPlaceType = arrivalStopId ? 'fixedPlace' : 'address'
    initialData.arrivalPlace = arrivalStopId?.stop_id || tripRequest.arrivalPlace.bus_stop_name_ua

    setFormData({ ...tripRequest, ...initialData })
  }

  const getCityIdByName = (towns: any, name: any) => towns.find((town: any) => town.name_ukr === name)?.city_id
  const findStopByName = (stops: any, name: any) => stops.find((stop: any) => stop.stop_name_ua === name)

  useEffect(() => {
    if (trips.length && formData.fromLocationCity && formData.toLocationCity) {
      const currentTrip = trips[0]
      //@ts-ignore
      const stopsObject = JSON.parse(currentTrip?.stops_along || '{}').stops || []

      setBoardingStops(stopsObject.filter((stop: any) => stop.city_id === formData.fromLocationCity))
      setDisembarkingStops(stopsObject.filter((stop: any) => stop.city_id === formData.toLocationCity))
    }
  }, [trips, formData.fromLocationCity, formData.toLocationCity])

  useEffect(() => {
    if (trips.length && isOpen) {
      const currentTrip = trips[0]
      //@ts-ignore
      const stopsObject = JSON.parse(currentTrip?.stops_along || '{}').stops || []

      const disembarkingCities = stopsObject
        .filter(
          (stop: any) => stop.boardingStatus === 'DisembarkingOnly' || stop.boardingStatus === 'BoardingAndDisembarking'
        )
        .map((stop: any) => stop.city_id)

      const disembarkingCitiesData = towns.filter((town: any) => disembarkingCities.includes(town.city_id))

      setDisembarkingCities(disembarkingCitiesData)

      const boardingCities = stopsObject
        .filter(
          (stop: any) => stop.boardingStatus === 'BoardingAndDisembarking' || stop.boardingStatus === 'BoardingOnly'
        )
        .map((stop: any) => stop.city_id)

      const boardingCitiesData = towns.filter((town: any) => boardingCities.includes(town.city_id))

      setBoardingCities(boardingCitiesData)
    }
  }, [trips, isOpen, towns])

  return (
    <ModalContainer width={800} isOpen={isOpen} handleClose={handleCloseForm} title={'Редагування бронювання місця'}>
      <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div" color="primary">
            Вид зв'язку
          </Typography>
          <FormControl fullWidth variant="standard">
            <InputLabel>Вид зв'язку</InputLabel>
            <Select
              value={formData?.connectionType || ''}
              onChange={selectChangeHandler}
              name="connectionType"
              label="Країна">
              <MenuItem value="">
                <em>Виберіть тип зв'язку</em>
              </MenuItem>
              <MenuItem value="viber">
                <em>Viber</em>
              </MenuItem>
              <MenuItem value="telegram">
                <em>Telegram</em>
              </MenuItem>
              <MenuItem value="whatIsApp">
                <em>WhatsApp</em>
              </MenuItem>
              <MenuItem value="Call">
                <em>Звінок</em>
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Номер для зв'язку"
            variant="standard"
            name="connectPhoneNumber"
            onChange={handleInputChange}
            value={formData?.connectPhoneNumber || ''}
          />
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color="primary"
            sx={{
              mt: 4,
            }}>
            Кількість багажу
          </Typography>
          {/* @ts-ignore */}
          {formData?.trip?.transportType === 'bus' ? (
            <FormControl fullWidth variant="standard">
              <InputLabel>Вага багажу</InputLabel>
              <Select
                value={formData.baggageAmount || ''}
                onChange={selectChangeHandler}
                name="baggageAmount"
                label="Країна">
                <MenuItem value="">
                  <em>Виберіть тип зв'язку</em>
                </MenuItem>
                <MenuItem value={21}>
                  <em>Більше 20кг</em>
                </MenuItem>
                <MenuItem value={19}>
                  <em>Менше 20кг</em>
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <TextField
              fullWidth
              label="Кількість багажу"
              variant="standard"
              name="baggageAmount"
              type="number"
              onChange={handleInputChange}
              value={formData.baggageAmount || ''}
            />
          )}
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div" color="primary">
            Місце посадки
          </Typography>
          <FormControl fullWidth variant="standard">
            <InputLabel>Місто посадки</InputLabel>
            <Select
              value={formData.fromLocationCity || ''}
              onChange={selectChangeHandler}
              name="fromLocationCity"
              label="Країна">
              <MenuItem value="">
                <em>Виберіть місто</em>
              </MenuItem>
              {boardingCities.map((city: any) => (
                <MenuItem key={`boarding-${city.city_id}`} value={city.city_id}>
                  {city.name_ukr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formData?.fromLocationCity ? (
            <>
              <FormControl fullWidth variant="standard">
                <InputLabel>Місце посадки</InputLabel>
                <Select
                  value={formData.departureType || ''}
                  onChange={selectChangeHandler}
                  name="departureType"
                  label="Країна">
                  <MenuItem value="">
                    <em>Виберіть тип посадки</em>
                  </MenuItem>
                  <MenuItem value="fixedPlace">
                    <em>Фіксоване місце посадки</em>
                  </MenuItem>
                  <MenuItem value="address">
                    <em>Адреса</em>
                  </MenuItem>
                </Select>
              </FormControl>
              {formData?.departureType === 'address' ? (
                <TextField
                  fullWidth
                  label="Адреса"
                  variant="standard"
                  name="departurePlace"
                  onChange={handleInputChange}
                  value={formData.departurePlace}
                />
              ) : (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Місце посадки</InputLabel>
                  <Select
                    value={formData.departurePlace || ''}
                    onChange={selectChangeHandler}
                    name="departurePlace"
                    label="Країна">
                    <MenuItem value="">
                      <em>Виберіть тип посадки</em>
                    </MenuItem>
                    {boardingStops.map((stop: any) => (
                      <MenuItem key={`boarding-${stop.stop_id}`} value={stop.stop_id}>
                        {stop.stop_name_ua}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          ) : null}
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color="primary"
            sx={{
              mt: 4,
            }}>
            Місце висадки
          </Typography>
          <FormControl fullWidth variant="standard">
            <InputLabel>Місто висадки</InputLabel>
            <Select
              value={formData.toLocationCity || ''}
              onChange={selectChangeHandler}
              name="toLocationCity"
              label="Країна">
              <MenuItem value="">
                <em>Виберіть місто</em>
              </MenuItem>
              {disembarkingCities.map((city: any) => (
                <MenuItem key={`disembarking-${city.city_id}`} value={city.city_id}>
                  {city.name_ukr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formData?.toLocationCity ? (
            <>
              <FormControl fullWidth variant="standard">
                <InputLabel>Місце висадки</InputLabel>
                <Select
                  value={formData.arrivalPlaceType || ''}
                  onChange={selectChangeHandler}
                  name="arrivalPlaceType"
                  label="Країна">
                  <MenuItem value="">
                    <em>Виберіть тип висадки</em>
                  </MenuItem>
                  <MenuItem value="fixedPlace">
                    <em>Фіксоване місце висадки</em>
                  </MenuItem>
                  <MenuItem value="address">
                    <em>Адреса</em>
                  </MenuItem>
                </Select>
              </FormControl>
              {formData?.arrivalPlaceType === 'address' ? (
                <TextField
                  fullWidth
                  label="Адреса"
                  variant="standard"
                  name="arrivalPlace"
                  onChange={handleInputChange}
                  value={formData.arrivalPlace}
                />
              ) : (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Місце висадки</InputLabel>
                  <Select
                    value={formData.arrivalPlace || ''}
                    onChange={selectChangeHandler}
                    name="arrivalPlace"
                    label="Країна">
                    <MenuItem value="">
                      <em>Виберіть тип висадки</em>
                    </MenuItem>
                    {disembarkingStops.map((stop: any) => (
                      <MenuItem key={`disembarking-${stop.stop_id}`} value={stop.stop_id}>
                        {stop.stop_name_ua}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          ) : null}
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div" color="primary">
            Кількість пасажирів
          </Typography>
          <TextField
            fullWidth
            label="Дорослих"
            variant="standard"
            name="adultPassengerAmount"
            onChange={handleInputChange}
            value={formData.adultPassengerAmount || ''}
          />
          <TextField
            fullWidth
            label="Дітей до 5 років"
            variant="standard"
            name="childPassengerAmount"
            onChange={handleInputChange}
            value={formData.childPassengerAmount || ''}
          />
          {/* @ts-ignore */}
          {formData?.trip?.transportType !== 'microBus' && (
            <TextField
              fullWidth
              label="дітей від 5 до 10 років"
              variant="standard"
              name="childMiddlePassengerAmount"
              onChange={handleInputChange}
              value={formData.childMiddlePassengerAmount || ''}
            />
          )}
          <TextField
            fullWidth
            label="Додаткова інформація"
            variant="outlined"
            name="note"
            multiline
            sx={{
              mt: 1,
              p: 0.5,
            }}
            rows={4}
            onChange={handleInputChange}
            value={formData.note || ''}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <Button color="primary" variant="contained" size="small" onClick={handleEditTripRequest}>
          Зберегти
        </Button>
        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Скасувати
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default TripItemUsersEditModal
