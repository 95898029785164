import { api } from '@/api'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

// ? interfaces
import { ILoginFormDTO } from '@/interfaces/auth'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

const requestCreator = apiCreator(PREFIXES.ADMINS)

export const auth_api = {
  login: (loginData: ILoginFormDTO) => requestCreator(api, 'login', 'POST', loginData),
  logout: () => requestCreator(api, 'logout', 'GET'),
}
