export const buttonStyle = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px 10px",
    color: "white",
    textDecoration: "none",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
        backgroundColor: "black",
    },
};

export const barStyle = {
    m: "10px",
    top: "10px",
    width: "calc(100% - 20px)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "100%",
    flexWrap: {
        xs: "wrap",
        xl: "nowrap",
    },
};

export const boxLinkStyle = {
    height: "40px",
    display: "flex",
    alignItems: "center",
    mr: "10px",
    "&:hover": {
        backgroundColor: "black",
    },
};

export const boxLinkStyleGlobal = {
    height: "40px",
    display: "flex",
    alignItems: "center",
    mr: "10px",
    overflowX: "scroll",
    overflowY: "hidden",
    width: {
        xs: "100%",
        lg: "auto",
    },
    "&::-webkit-scrollbar": {
        height: "3px!important",
        width: "3px!important",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "white!important",
    },
};
