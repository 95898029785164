// ? interfaces
import { IDriver } from '@/interfaces/dirvers'

// ? store
import { driversStore } from '@/store/driverStore'

// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'

interface IProps {
  driver: IDriver
}

const DriverTabelRow = ({ driver }: IProps) => {
  const { editDriver, deleteDriver } = driversStore()

  const handleEditDriver = (driver: IDriver) => {
    editDriver.handleOpenModal(driver)
  }

  const handleDeleteDriver = (driver: IDriver) => {
    deleteDriver.handleOpenModal(driver)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {driver.user_id}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {driver.pib}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {driver.phone}
        </Typography>
      </TableCell>

      {/* <TableCell align="left">
        <Typography
          variant="body2"
          color={driver?.driverFeatures ? 'text.secondary' : 'error'}
          sx={{
            cursor: 'pointer',
          }}>
          {driver?.badHabit || 'Не вказано'}
        </Typography>
      </TableCell> */}

      {/* <TableCell align="left">
        <Typography
          variant="body2"
          color={driver?.driverFeatures ? 'text.secondary' : 'error'}
          sx={{
            cursor: 'pointer',
          }}>
          {driver?.driverFeatures || 'Не вказано'}
        </Typography>
      </TableCell> */}

      <TableCell align="left">
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={() => handleEditDriver(driver)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteDriver(driver)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default DriverTabelRow
