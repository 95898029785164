import { TableRow, TableCell } from '@mui/material'
import React from 'react'

const RequestsTableHeader = () => {
  const headerTitles = [
    {
      title: 'Дата відбуття',
      name: '',
    },
    {
      title: 'Номер рейсу',
      name: '',
    },
    {
      title: 'Маршрут',
      name: '',
    },
    {
      title: 'ПІБ',
      name: '',
    },
    {
      title: "Спосіб зв'язку",
      name: '',
    },
    {
      title: 'Номер телефону',
      name: '',
    },
    {
      title: 'Кількість багажу',
      name: '',
    },
    {
      title: 'Кількість дорослих',
      name: '',
    },
    {
      title: 'Кількість дитячих',
      name: '',
    },
    {
      title: 'Нотатка',
      name: '',
    },
    {
      title: 'Статус',
      name: '',
    },
    {
      title: 'Дії',
      name: '',
    },
  ]
  return (
    <TableRow>
      {headerTitles.map(({ title }) => (
        <TableCell
          key={title}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default RequestsTableHeader
