// ? interfaces
import { ICountryCreateDTO, Icountry } from '@/interfaces/country'
import { IErrorValidation } from '@/interfaces/general'

interface ICOUNTRY_VALIDATION {
  CREATE: (data: ICountryCreateDTO) => IErrorValidation[]
  EDIT: (data: Partial<Icountry>) => IErrorValidation[]
  DELETE: (id: string) => IErrorValidation[]
}

const requiredTranslates = ['UA']

export const COUNTRY_VALIDATION: ICOUNTRY_VALIDATION = {
  CREATE: ({ name_eng, name_ukr }: ICountryCreateDTO) => {
    const errors: IErrorValidation[] = []

    if (!name_ukr) {
      errors.push({
        field: 'name_ukr',
        message: 'Введіть назву країни українською',
      })
    }

    if (!name_eng) {
      errors.push({
        field: 'name_eng',
        message: 'Введіть назву країни англійською',
      })
    }

    // if (!translates) {
    //     errors.push({
    //         field: "translates",
    //         message: "Переклади обов'язкові для заповнення"
    //     })
    // }

    // if (translates && translates.length < requiredTranslates.length) {
    //     errors.push({
    //         field: "translates",
    //         message: `Переклади мають бути заповнені для мов: ${requiredTranslates.join(", ")}`
    //     })
    // }

    return errors
  },
  EDIT: (data: Partial<Icountry>) => {
    const errors: IErrorValidation[] = []

    if (Object.keys(data).length === 0) {
      errors.push({
        field: 'data',
        message: 'Дані для редагування не передані',
      })
    }

    return errors
  },
  DELETE: (id: string) => {
    const errors: IErrorValidation[] = []

    if (!id) {
      errors.push({
        field: 'id',
        message: 'Не передано id для видалення',
      })
    }

    return errors
  },
}
