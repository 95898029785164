import {BrowserRouter} from "react-router-dom";

import {ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Message from "@/components/UI/Message";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";

interface GlobalProps {
    children: JSX.Element;
}

const Global = ({children}: GlobalProps) => {
    const [darkTheme, setDarkTheme] = useState<any>(
        createTheme({
            palette: {
                mode: "dark",
                primary: {
                    main: "#ECAC1A",
                },
                error: {
                    main: "#ff0000",
                    dark: "rgba(255,0,0,0.3)",
                },
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            transition: "all 0.3s ease-in-out",
                        },
                    },
                },
            },
        })
    );

    useEffect(() => {
        setDarkTheme(
            createTheme({
                palette: {
                    mode: "dark",
                    primary: {
                        main: "#ECAC1A",
                    },
                    error: {
                        main: "#ff0000",
                        dark: "rgba(255,0,0,0.3)",
                    },
                },
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                transition: "all 0.3s ease-in-out",
                            },
                        },
                    },
                },
            })
        );
    }, []);

    return (
        <BrowserRouter>
            <ThemeProvider theme={darkTheme ? darkTheme : {}}>
                <CssBaseline/>
                <Message/>

                <Box>{children}</Box>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default Global;
