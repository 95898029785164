export const createUserBtnContainer = {
    display: "flex",
    justifyContent: 'space-between',
    my: 1.5,
    ".createUserBtn": {
        ".addIcon": {
            transition: "all 0.3s ease-in-out",
        },
        "&:hover": {
            ".addIcon": {
                transform: "rotate(90deg)",
            },
        },
    },
};

export const tableHeadContainer = {
    position: "sticky",
    top: 0,
    zIndex: 1,
};

export const tableHeadExportBtn = {
    ".downloadIcon": {
        transition: "all 0.3s ease-in-out",
    },
    "&:hover": {
        ".downloadIcon": {
            transform: "scale(1.3)",
        }
    }
}
