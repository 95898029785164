import { create } from 'zustand'
import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { Itown } from '@/interfaces/town'

const { town_api } = REQ

interface IStore {
  towns: Itown[]
  oneTown: Itown
  isLoading: boolean
  townMeta: IMeta
  createTown: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    createTownApiHandler: (body: Partial<Itown>) => Promise<any>
  }
  editTown: {
    isOpenModal: boolean
    editedItem: Itown | null
    handleOpenModal: (item: Itown) => void
    handleCloseModal: () => void
    editTownApiHandler: (id: string, body: Partial<Itown>) => Promise<any>
  }
  deleteTown: {
    isOpenModal: boolean
    deletedItem: Itown | null
    handleOpenModal: (item: Itown) => void
    handleCloseModal: () => void
    deleteTownApiHandler: (id: string) => Promise<any>
  }
  getAllTowns: ({ count, page }: IMeta) => Promise<any>
  getTownById: (id: string) => Promise<any>
}

export const townStore = create<IStore>((set) => ({
  towns: [],
  oneTown: {} as Itown,
  townMeta: {
    count: 10,
    page: 1,
    total: 0,
  },
  isLoading: false,

  createTown: {
    isOpenModal: false,
    handleOpenModal: () => {
      set((state) => ({
        ...state,
        createTown: {
          ...state.createTown,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createTown: {
          ...state.createTown,
          isOpenModal: false,
        },
      }))
    },
    createTownApiHandler: async (body: Partial<Itown>) => {
      const response = await town_api.createTown(body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 201) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  editTown: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: Itown) => {
      set((state) => ({
        ...state,
        editTown: {
          ...state.editTown,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editTown: {
          ...state.editTown,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editTownApiHandler: async (id: string, body: Partial<Itown>) => {
      const response = await town_api.editTown(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteTown: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item: Itown) => {
      set((state) => ({
        ...state,
        deleteTown: {
          ...state.deleteTown,
          isOpenModal: true,
          deletedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteTown: {
          ...state.deleteTown,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteTownApiHandler: async (id: string) => {
      const response = await town_api.deleteTown(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  getAllTowns: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await town_api.getAllTown()

    const { status, data } = response

    set((state) => ({ ...state, isLoading: false }))

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    set((state) => ({ ...state, towns: data.cities }))
    return response
  },
  getTownById: async (id) => {
    const response = await town_api.getById(id)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, oneTown: data }))
      return response
    }
  },
}))
