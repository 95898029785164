import {api} from "@/api";
import {IMeta} from "@/interfaces/general";
import {IPlan} from "@/interfaces/planning";

export const planning_tasks_api = {
    getAllPlanningTasks: async (raceId: string, {page, count}: IMeta) => {
        return await api.get("planning-task/getPaginate", {
            params: {
                page,
                count,
                filter: raceId ? JSON.stringify(raceId) : null,
            },
        });
    },

    createTask: async (body: Partial<IPlan>) => {
        console.log("CRT", body)
        return await api.post("planning-task/createTask", body);
    },

    editTask: async (id: string, body: {}) => {
        console.log("UPD:", id, body)
        return await api.patch(`planning-task/${id}`, body)
    },

    deleteTask: async (id: string) => {
        return await api.delete(`planning-task/${id}`);
    },

    getById: async (id: string) => {
        console.log("getById:", id)
        return await api.get(`planning-task/getOne/${id}`);
    }
};
