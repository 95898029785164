// ? hooks
import React, { useEffect, useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? stores
import { townStore } from '@/store/townStore'
import { messageStore } from '@/store/store'
import { countryStore } from '@/store/countryStore'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { DataGrid } from '@mui/x-data-grid'
import { Add } from '@mui/icons-material'
import { validateForm } from '@/utils/formValidation'

const EditTownModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { countryMeta, getAllCountries, countries } = countryStore()

  const { editTown, getAllTowns, townMeta } = townStore()
  const { isOpenModal, handleCloseModal, editedItem } = editTown

  const [formValues, setFormValues] = useState({} as any)
  const [cityStops, setCityStops] = useState([] as any)

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    country_id: { required: true },
    name_ukr: { required: true },
    name_eng: { required: true },
  }

  // ? API handlers
  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const editTownApiHandler = useAsyncWrapper(editTown.editTownApiHandler, 'editTownApiHandler')

  const getTownsApiHandler = useAsyncWrapper(getAllTowns, 'getTownsApiHandler')
  // ? API handlers

  // ? handlers
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)
  const selectChangeHandler = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void
  // ? handlers

  useEffect(() => {
    const transformedCityStops = editedItem?.cityStops?.map((item: any) => {
      return {
        id: item._id,
        name: item.name,
        'name-UA': item.translates?.find((t: any) => t.code === 'UA')?.translate,
      }
    })

    setCityStops(transformedCityStops)
  }, [editedItem])

  useEffect(() => {
    if (cityStops?.length > 0) {
      const transformedCityStops = cityStops.map((item: any) => {
        return {
          name: item.name,
          translates: [
            {
              code: 'UA',
              translate: item['name-UA'],
            },
          ],
        }
      })

      setFormValues({
        ...formValues,
        cityStops: transformedCityStops,
      })
    }
  }, [cityStops])

  const editTownHandler = async () => {
    setIsLoading(true)

    const id = editedItem?.city_id

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      setIsLoading(false)
      return
    }

    const { error: editTownError } = await editTownApiHandler([id, formValues])

    if (editTownError) {
      setIsLoading(false)
      return false
    }

    const { error: getTownsError } = await getTownsApiHandler([townMeta])

    if (getTownsError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Успішно відредаговано',
      isOpen: true,
    })
    handleCloseModal()
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      country_id: editedItem?.city_country_id,
      name_eng: editedItem?.name_eng,
      name_ukr: editedItem?.name_ukr,
    })

    const fetchData = async () => {
      const { error: getCountriesError } = await getCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getCountriesError) {
        return false
      }
    }

    fetchData()
  }, [])

  const processCityStopUpdate = (newRow: any) => {
    const updatedRow = { ...newRow }
    setCityStops(cityStops.map((row: any) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleCreateCityStop = () => {
    const id = cityStops?.length ? cityStops?.length + 1 : 0
    setCityStops((oldRows: any) =>
      Array.isArray(oldRows) ? [...oldRows, { id, name: '', 'name-UA': '' }] : [{ id, name: '', 'name-UA': '' }]
    )
  }

  return (
    <ModalContainer
      width={800}
      isOpen={isOpenModal}
      handleClose={handleCloseModal}
      title={`Редагування міста ${editedItem?.name_ukr}`}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.country_id} fullWidth variant="standard">
            <InputLabel>Країна</InputLabel>
            <Select
              onChange={selectChangeHandler}
              value={formValues.country_id || editedItem?.city_country_id}
              name="country_id"
              label="Країна">
              <MenuItem value="initial">Оберіть країну</MenuItem>
              {countries?.map((country: any) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.name_ukr}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.country_id && <FormHelperText>{formErrors?.country_id}</FormHelperText>}
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Назва міста англійською</InputLabel>
            <TextField
              name="name_eng"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.name_eng || editedItem?.name_eng}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.name_eng}
              helperText={formErrors?.name_eng}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Назва міста українською</InputLabel>
            <TextField
              name="name_ukr"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.name_ukr || editedItem?.name_ukr}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.name_ukr}
              helperText={formErrors?.name_ukr}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            mt: '10px',
            height: 375,
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'flex-start',
            gap: '20px',
          }}>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleCreateCityStop}>
            Створити зупинку
          </Button>
          <DataGrid
            rows={cityStops ?? []}
            columns={[
              {
                field: 'name',
                headerName: 'Назва зупинки англійською',
                editable: true,
                sortable: false,
                flex: 1,
              },
              {
                field: 'name-UA',
                headerName: 'Назва зупинки українською',
                editable: true,
                sortable: false,
                flex: 1,
              },
            ]}
            localeText={{ noRowsLabel: 'Зупинок поки немає' }}
            getRowId={(row: any) => row.id}
            processRowUpdate={processCityStopUpdate}
            editMode="row"
            disableColumnMenu
            hideFooterSelectedRowCount
            sx={{
              width: '100%',
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <LoadingButton color="primary" variant="contained" size="small" onClick={editTownHandler} loading={isLoading}>
            Зберегти
          </LoadingButton>

          <Button onClick={handleCloseModal} color="error" variant="contained" size="small">
            Відмінити
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default EditTownModal
