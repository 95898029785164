// ? components
import { TableRow, TableCell } from '@mui/material'

const headerTitles = [
  {
    title: 'ID', // ? "ID"
    name: 'id',
  },
  {
    title: 'ПІБ',
    name: 'pib',
  },
  {
    title: 'Номер телефону',
    name: 'phone',
  },
  // {
  //     title: "Шкідливі звички",
  //     name: "badHabit",
  // },
  // {
  //     title: "Особливості водія",
  //     name: "driverFeatures",
  // },
  {
    title: 'Дії',
    name: 'actions',
  },
]

const DriverTabelHeader = () => {
  return (
    <TableRow>
      {headerTitles.map(({ title, name }) => (
        <TableCell
          key={title}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default DriverTabelHeader
