import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TableContainer, Table, TableHead, TableBody, Button, Menu, MenuItem } from '@mui/material'
import { Box } from '@mui/system'
import TripItemUsersHeader from './TripItemUsersHeader'
import TripItemUsersPagination from './TripItemUsersPagination'
import { messageStore } from '@/store/store'
import { tripRequestsStore } from '@/store/tripRequestsStore'
import { useParams } from 'react-router-dom'
import TripItemUsersRow from './TripItemUsersRow'
import { ImportExport } from '@mui/icons-material'
import TripItemUsersEditModal from './modals/TripItemUsersEditModal'
import PDFViewModal from './modals/PDFViewModal'
import TripItemDeclineModal from '../TripItemRequests/modals/TripItemDeclineModal'

const TripItemUsers = () => {
  const { t } = useTranslation()
  const { tripId } = useParams()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openPDFDocMenu = Boolean(anchorEl)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const { getTripRequests, tripRequests, exportExcel, editModal } = tripRequestsStore()

  const { addMessage } = messageStore()

  const handleExportToExcel = () => {
    if (!tripId) {
      addMessage({
        isOpen: true,
        message: 'Щось пішло не так',
        severity: 'error',
      })
      return false
    }

    exportExcel(Number(tripId))
  }

  const handlePreviewPDF = () => {
    if (!tripId) {
      addMessage({
        isOpen: true,
        message: 'Щось пішло не так',
        severity: 'error',
      })
      return false
    }

    setIsOpenModal(true)

    // exportPDF(Number(tripId), 'booked', 'preview')
  }

  const handleExportToPDF = () => {
    if (!tripId) {
      addMessage({
        isOpen: true,
        message: 'Щось пішло не так',
        severity: 'error',
      })
      return false
    }

    // exportPDF(Number(tripId), 'booked', 'download')
  }

  const handlePDFDocMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePDFDocMenuClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (tripId) {
      const fetchData = async () => {
        const { error: getAllTripRequestsError } = await getTripRequests({
          status: 'booked',
          trip_id: Number(tripId),
        })

        if (getAllTripRequestsError) {
          console.log(getAllTripRequestsError)

          addMessage({
            isOpen: true,
            message: 'Щось пішло не так',
            severity: 'error',
          })
        }
      }

      fetchData().catch(console.error)
    }
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      <TripItemDeclineModal status="booked" />
      <Grid item xs={12}>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="exportBtn"
            startIcon={<ImportExport className="exportIcon" />}
            onClick={handleExportToExcel}>
            {t('tripRequestsPage.exportToExcel')}
          </Button>
          <Button
            id="pdfdoc-button"
            variant="contained"
            color="primary"
            size="small"
            className="pdfDocBtn"
            startIcon={<ImportExport className="exportIcon" />}
            aria-controls={openPDFDocMenu ? 'pdfdoc-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openPDFDocMenu ? 'true' : undefined}
            onClick={handlePDFDocMenuClick}>
            {t('tripRequestsPage.infoTabel.title')}
          </Button>
          <Menu
            id="pdfdoc-menu"
            anchorEl={anchorEl}
            open={openPDFDocMenu}
            onClose={handlePDFDocMenuClose}
            MenuListProps={{
              'aria-labelledby': 'pdfdoc-button',
            }}>
            <MenuItem onClick={handlePreviewPDF}>{t('tripRequestsPage.infoTabel.previewBtn')}</MenuItem>
            <MenuItem onClick={handleExportToPDF}>{t('tripRequestsPage.infoTabel.downloadBtn')}</MenuItem>
          </Menu>
        </Box>
        {editModal.isOpen && <TripItemUsersEditModal />}
        <PDFViewModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
        <TableContainer>
          <Table
            stickyHeader
            sx={{
              maxHeight: 'calc(100vh - 250px)',
              width: '100%',
              maxWidth: '100%',
              overflow: 'scroll',
              mt: 1,
            }}>
            <TableHead>
              <TripItemUsersHeader />
            </TableHead>
            <TableBody>
              {tripRequests.map((tripRequest) => (
                <TripItemUsersRow tripRequest={tripRequest} key={tripRequest?.order_trip_id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TripItemUsersPagination />
      </Grid>
    </Grid>
  )
}

export default TripItemUsers
