import { useEffect } from 'react'

import { AppBar, Box, Typography, useTheme } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'

import {
  Logout,
  AdminPanelSettings,
  Info,
  BusAlert,
  Tour,
  DriveEta,
  LocationCity,
  WorkOutlined,
  Article,
  Newspaper,
  List,
  SupervisedUserCircleSharp,
  Place,
  Group,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'

import { barStyle, buttonStyle, boxLinkStyle, boxLinkStyleGlobal } from './styles'

import { adminStore } from '@/store/adminStore'
import { messageStore } from '@/store/store'
import { USER_INFO_TOKEN } from '@/constants/constants'

const GlobalNavigation = () => {
  const { t } = useTranslation()

  const i18nTranslateNavigationString = 'global.adminNavigation'

  const admin = JSON.parse(localStorage.getItem(USER_INFO_TOKEN) ?? '{}')

  const theme = useTheme()
  const navigate = useNavigate()

  const { logout } = adminStore()
  const { addMessage } = messageStore()

  // const handleChange = (event: SelectChangeEvent) => {
  //   i18n.changeLanguage(event.target.value);

  // };

  const logoutHandler = () => {
    logout()

    addMessage({
      isOpen: true,
      message: 'Admin has successfully logged out',
      severity: 'success',
    })
    navigate('/auth')
  }

  useEffect(() => {
    // if (currentAdmin?.settings?.language) {
    //   i18n.changeLanguage(currentAdmin.settings.language);
    // }
  }, [])

  return (
    <AppBar position="sticky" sx={barStyle}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          width: '100%',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}>
        <Box
          sx={{
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'black',
            padding: '0 10px',
            borderRadius: '5px',
            justifyContent: 'center',
            mr: 1,
            mb: {
              xs: 1,
              md: 0,
            },
            width: {
              xs: '100%',
              md: 'auto',
            },
          }}>
          <Typography variant="h6" sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
            {process.env.REACT_APP_DEV_BASE_BRANDNAME || 'NO Brand Name'}
          </Typography>
        </Box>
        <Box sx={boxLinkStyleGlobal}>
          <Box sx={boxLinkStyle}>
            <NavLink
              to={'/'}
              style={({ isActive }) => ({
                ...buttonStyle,
                ...(isActive
                  ? {
                      backgroundColor: 'black',
                      color: theme.palette.primary.main,
                      padding: '8px 10px',
                      width: '100%',
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }
                  : null),
              })}>
              <List color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                Запити на бронювання
              </Typography>
            </NavLink>
          </Box>
          <Box sx={boxLinkStyle}>
            <NavLink
              to={'/races'}
              style={({ isActive }) => ({
                ...buttonStyle,
                ...(isActive
                  ? {
                      backgroundColor: 'black',
                      color: theme.palette.primary.main,
                      padding: '8px 10px',
                      width: '100%',
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }
                  : null),
              })}>
              <Tour color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {t(`${i18nTranslateNavigationString}.races`)}
              </Typography>
            </NavLink>
          </Box>
          <Box sx={boxLinkStyle}>
            <NavLink
              to={'/transports'}
              style={({ isActive }) => ({
                ...buttonStyle,
                ...(isActive
                  ? {
                      backgroundColor: 'black',
                      color: theme.palette.primary.main,
                      padding: '8px 10px',
                      width: '100%',
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }
                  : null),
              })}>
              <BusAlert color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {t(`${i18nTranslateNavigationString}.transport`)}
              </Typography>
            </NavLink>
          </Box>

          <Box sx={boxLinkStyle}>
            <NavLink
              to={'/drivers'}
              style={({ isActive }) => ({
                ...buttonStyle,
                ...(isActive
                  ? {
                      backgroundColor: 'black',
                      color: theme.palette.primary.main,
                      padding: '8px 10px',
                      width: '100%',
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }
                  : null),
              })}>
              <DriveEta color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {t(`${i18nTranslateNavigationString}.drivers`)}
              </Typography>
            </NavLink>
          </Box>
          {`name === "root"` && (
            <Box sx={boxLinkStyle}>
              <NavLink
                to={'/stops'}
                style={({ isActive }) => ({
                  ...buttonStyle,
                  ...(isActive
                    ? {
                        backgroundColor: 'black',
                        color: theme.palette.primary.main,
                        padding: '8px 10px',
                        width: '100%',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                      }
                    : null),
                })}>
                <Place color="primary" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Зупинки
                </Typography>
              </NavLink>
            </Box>
          )}
          {`name === "root"` && (
            <Box sx={boxLinkStyle}>
              <NavLink
                to={'/towns'}
                style={({ isActive }) => ({
                  ...buttonStyle,
                  ...(isActive
                    ? {
                        backgroundColor: 'black',
                        color: theme.palette.primary.main,
                        padding: '8px 10px',
                        width: '100%',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                      }
                    : null),
                })}>
                <LocationCity color="primary" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Міста
                </Typography>
              </NavLink>
            </Box>
          )}
          {`name === "root"` && (
            <Box sx={boxLinkStyle}>
              <NavLink
                to={'/countries'}
                style={({ isActive }) => ({
                  ...buttonStyle,
                  ...(isActive
                    ? {
                        backgroundColor: 'black',
                        color: theme.palette.primary.main,
                        padding: '8px 10px',
                        width: '100%',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                      }
                    : null),
                })}>
                <WorkOutlined color="primary" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Країни
                </Typography>
              </NavLink>
            </Box>
          )}
          <Box sx={boxLinkStyle}>
            <NavLink
              to={'/users'}
              style={({ isActive }) => ({
                ...buttonStyle,
                ...(isActive
                  ? {
                      backgroundColor: 'black',
                      color: theme.palette.primary.main,
                      padding: '8px 10px',
                      width: '100%',
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }
                  : null),
              })}>
              <Group color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {t(`${i18nTranslateNavigationString}.users`)}
              </Typography>
            </NavLink>
          </Box>

          {/* {`name === "root"` && (
            <Box sx={boxLinkStyle}>
              <NavLink
                to={'/admins'}
                style={({ isActive }) => ({
                  ...buttonStyle,
                  ...(isActive
                    ? {
                        backgroundColor: 'black',
                        color: theme.palette.primary.main,
                        padding: '8px 10px',
                        width: '100%',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                      }
                    : null),
                })}>
                <AdminPanelSettings color="primary" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {t(`${i18nTranslateNavigationString}.admins`)}
                </Typography>
              </NavLink>
            </Box>
          )} */}

          {/* {`name === "root"` && (
            <Box sx={boxLinkStyle}>
              <NavLink
                to={'/consultations'}
                style={({ isActive }) => ({
                  ...buttonStyle,
                  ...(isActive
                    ? {
                        backgroundColor: 'black',
                        color: theme.palette.primary.main,
                        padding: '8px 10px',
                        width: '100%',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                      }
                    : null),
                })}>
                <Info color="primary" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Консультації
                </Typography>
              </NavLink>
            </Box>
          )} */}
          {/* 
          {`name === "root"` && (
            <Box sx={boxLinkStyle}>
              <NavLink
                to={'/news'}
                style={({ isActive }) => ({
                  ...buttonStyle,
                  ...(isActive
                    ? {
                        backgroundColor: 'black',
                        color: theme.palette.primary.main,
                        padding: '8px 10px',
                        width: '100%',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                      }
                    : null),
                })}>
                <Newspaper color="primary" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Новини
                </Typography>
              </NavLink>
            </Box>
          )} */}
          {/* 
          {`name === "root"` && (
            <Box sx={boxLinkStyle}>
              <NavLink
                to={'/blog'}
                style={({ isActive }) => ({
                  ...buttonStyle,
                  ...(isActive
                    ? {
                        backgroundColor: 'black',
                        color: theme.palette.primary.main,
                        padding: '8px 10px',
                        width: '100%',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                      }
                    : null),
                })}>
                <Article color="primary" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Блог
                </Typography>
              </NavLink>
            </Box>
          )} */}

          {/* <Box sx={boxLinkStyle}>
            <NavLink
              // to={"/timeTable"}
              to={'/users'}
              style={({ isActive }) => ({
                ...buttonStyle,
                ...(isActive
                  ? {
                      backgroundColor: 'black',
                      color: theme.palette.primary.main,
                      padding: '8px 10px',
                      width: '100%',
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                    }
                  : null),
              })}>
              <SupervisedUserCircleSharp color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                Користувачі
              </Typography>
            </NavLink>
          </Box> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-end',
          mt: {
            xs: 1,
            md: 0,
          },
          flexWrap: 'wrap',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mr: 2,
          }}>
          <Typography variant="body2" sx={{ mr: 1, fontSize: 12 }}>
            {/* Admin: {currentAdmin?.email} */}
            Admin: {admin.pib}
          </Typography>
        </Box>
        {/* <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={{
            width: "100px",
          }}
        >
          <Select value={"en"} label="Age" onChange={handleChange}>
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"ru"}>Русский</MenuItem>
          </Select>
        </FormControl> */}
        <Box onClick={() => logoutHandler()} sx={buttonStyle}>
          <Logout color="primary" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {t(`${i18nTranslateNavigationString}.logout`)}
          </Typography>
        </Box>
      </Box>
    </AppBar>
  )
}

export default GlobalNavigation
