import { api } from '@/api'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.ADMINS)

export const admins_api = {
  getAllAdmins: async (meta: any, filterQuery?: any) =>
    requestCreator(api, 'paginate', 'GET', {
      page: meta.page,
      count: meta.count,
      filter: filterQuery ? JSON.stringify(filterQuery) : null,
    }),

  registerAdmin: async (body: Partial<any>) => requestCreator(api, 'create', 'POST', body),
  deleteAdmin: async (id: string) => requestCreator(api, `${id}`, 'DELETE'),
  checkAuth: () => requestCreator(api, 'protected', 'GET'),
}
