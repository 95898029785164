// ? hooks
import { useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? store
import { driversStore } from '@/store/driverStore'
import { messageStore } from '@/store/store'

// ? validation
import { DRIVER_VALIDATION } from '@/validation/driver'

// ? components
import { Typography, Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

const DriverDeleteModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { deleteDriver, getAllDrivers, driversMeta } = driversStore()
  const { isOpenModal, handleCloseModal, deletedItem } = deleteDriver

  const deleteDriverApiHandler = useAsyncWrapper(deleteDriver.deleteDriverApiHandler, 'deleteDriverApiHandler')

  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')

  const deleteDriverValidation = useValidation(DRIVER_VALIDATION.DELETE)

  const deleteRaceHandler = async () => {
    setIsLoading(true)
    const id = deletedItem?.user_id

    if (!deleteDriverValidation(id)) {
      setIsLoading(false)
      return false
    }

    const { error: deleteDriverError } = await deleteDriverApiHandler([id])

    if (deleteDriverError) {
      setIsLoading(false)
      return false
    }

    const { error: getDriversError } = await getDriversApiHandler([driversMeta])

    if (getDriversError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Водій успішно видалений',
      isOpen: true,
    })

    setIsLoading(false)
    handleCloseModal()
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleCloseModal} title="Видалення водія">
      <Typography variant="h4" color="text.primary">
        {deletedItem?.firstName} {deletedItem?.lastName}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton loading={isLoading} color="primary" variant="contained" size="small" onClick={deleteRaceHandler}>
          Видалити
        </LoadingButton>

        <Button onClick={handleCloseModal} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Закрити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DriverDeleteModal
