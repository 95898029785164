import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {
    Grid,
    Button,
    Box,
    Typography,
    FormControlLabel,
    Switch,
    useTheme, FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField, RadioGroup, Radio,
} from "@mui/material";
import {West} from "@mui/icons-material";
import {racesStore} from "@/store/racesStore";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import {getTranslateValue} from "@/utils/getTranslateValue";
import {DataGrid, useGridApiRef} from "@mui/x-data-grid";
import {LoadingButton} from "@mui/lab";
import {tripsStore} from "@/store/tripStore";
import {townStore} from "@/store/townStore";
import {messageStore} from "@/store/store";
import "./styles.css";
import {Currency, TariffType} from "@/constants/constants";
import {inputsChanger} from "@/utils/inputsChanger";
import {validateForm} from "@/utils/formValidation";
import {formatDate} from "@/utils/formatDate";

const TripTariffs = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const params = useParams();
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(false);
    const [isShowSegments, setIsShowSegments] = useState(false);

    const [rowsData, setRowsData] = useState<any[]>([]);
    const [columnsData, setColumnsData] = useState<any[]>([]);

    const {oneRace, getRaceById, tariffsModal, racesMeta} = racesStore();
    const {editedItem, handleCloseModal} = tariffsModal;

    const {currentTrip, getTripById, editTrip} = tripsStore();
    const {towns, townMeta, getAllTowns} = townStore();

    const {addMessage} = messageStore();

    const [formValues, setFormValues] = useState<any>({});

    const [formErrors, setFormErrors] = useState<any>();
    const validationRules = {
        currency: {required: true},
        tariffType: {required: true},
        insuranceFee: {required: true},
    };

    const editTripApiHandler = useAsyncWrapper(
        editTrip.editTripApiHandler,
        "editTripApiHandler"
    );

    const getRaceByIdApiHandler = useAsyncWrapper(
        getRaceById,
        "getRaceByIdApiHandler"
    );

    const getAllTownsApiHandler = useAsyncWrapper(
        getAllTowns,
        "getAllTownsApiHandler"
    );

    const editTripTariffsHandler = async () => {
        setIsLoading(true);

        const formErrors = validateForm(formValues, validationRules)
        if (Object.keys(formErrors).length !== 0) {
            setFormErrors(formErrors);
            return;
        }

        const data = {
            editType: "editTripTariffs",
            ...formValues
        }

        console.log("data", data)

        const {error: editTripError} = await editTripApiHandler([
            currentTrip?._id,
            data,
        ]);

        if (editTripError) {
            setIsLoading(false);
            return;
        }

        const {error: getRaceByIdError} = await getRaceById(
            params.raceId ?? ''
        );

        if (getRaceByIdError) {
            console.log(getRaceByIdError);
        }

        setIsLoading(false);

        addMessage({
            severity: "success",
            message: "Тарифи успішно оновлено",
            isOpen: true,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const {error: getRaceByIdError} = await getRaceById(
                params.raceId ?? ''
            );

            if (getRaceByIdError) {
                console.log(getRaceByIdError);
            }

            const {error: getTripByIdError} = await getTripById(
                params.tripId ?? '',
                'middleStep'
            );

            if (getTripByIdError) {
                console.log(getTripByIdError);
            }

            const {error: getAllTownsError} = await getAllTownsApiHandler([
                {
                    ...townMeta,
                    count: 1000,
                },
            ]);

            if (getAllTownsError) return;
        };

        fetchData();
    }, []);

    useEffect(() => {
        setFormValues({
            price: currentTrip?.price,
            currency: currentTrip?.currency || 'euro',
            tariffType: currentTrip?.tariffType || 'fixed',
            insuranceFee: currentTrip?.insuranceFee || '',
            insuranceFeeType: currentTrip?.insuranceFeeType || 'percentage',
            chainTrips: currentTrip?.chainTrips || []
        });

        let chainTrips = currentTrip?.chainTrips;
        chainTrips?.sort((a, b) => {
            if (a?.tripId && b?.tripId) {
                return a.tripId.localeCompare(b.tripId);
            }

            if (a?.tripId) return -1;
            if (b?.tripId) return 1;
            return 0;
        });

        const fromCities = Array.from(new Set(chainTrips?.map((item: any) => getTranslateValue(item.fromLocationCity, "ua"))));
        const toCities = Array.from(new Set(chainTrips?.map((item: any) => getTranslateValue(item.toLocationCity, "ua"))));

        const columns = [
            {field: 'fromLocationCity', headerName: 'від  \\  до', width: 300, editable: false, sortable: false},
            ...toCities.map((city: any) => ({
                field: city.replace(/ /g, ''),
                headerName: city,
                width: 100,
                editable: true,
                sortable: false,
            })),
        ];

        setColumnsData(columns);

        const rows: any[] = fromCities.map((fromCity: any, index) => {
            const row: any = {id: index, fromLocationCity: fromCity};
            toCities.forEach((toCity: any) => {
                const route = chainTrips?.find((item: any) => {
                        return getTranslateValue(item.fromLocationCity, "ua") === fromCity && getTranslateValue(item.toLocationCity, "ua") === toCity;
                    }
                );

                const reverseRoute = chainTrips?.find((item: any, index: number) => {
                        return getTranslateValue(item.fromLocationCity, "ua") === toCity && getTranslateValue(item.toLocationCity, "ua") === fromCity;
                    }
                );

                if (getTranslateValue(currentTrip?.fromLocationCity, "ua") === fromCity && getTranslateValue(currentTrip?.toLocationCity, "ua") === toCity) {
                    row[toCity.replace(/ /g, '')] = currentTrip ? currentTrip.price : 0;
                } else if ((fromCity.replace(/ /g, '') === toCity.replace(/ /g, '')) || route === undefined) {
                    row[toCity.replace(/ /g, '')] = null;
                } else {
                    row[toCity.replace(/ /g, '')] = route ? route.price : 0;
                }

            });
            return row;
        });

        setRowsData(rows);

        console.log("rows", rows)

    }, [currentTrip]);

    const handleInputChange = inputsChanger(
        "input",
        formValues,
        setFormValues
    ) as (e: any) => void;

    const handleChangeSelect = inputsChanger(
        "selectChanger",
        formValues,
        setFormValues
    ) as (e: any) => void;

    const handleRadioChange = (e: any) => {
        setFormValues({...formValues, [e.target.name]: e.target.value})
    };

    const handleSwitchChange = (event: any) => {
        setIsShowSegments(event.target.checked);
    };

    const processTripTariffsUpdate = (newRow: any) => {
        const updatedRow = {...newRow};
        let chainTrips: any[] | undefined = [];

        console.log("updatedRow", updatedRow)
        let price = currentTrip?.price;
        Object.keys(updatedRow).forEach((key) => {
            if (updatedRow.fromLocationCity === getTranslateValue(currentTrip?.fromLocationCity, "ua").replace(/ /g, '') &&
                updatedRow[getTranslateValue(currentTrip?.toLocationCity, "ua").replace(/ /g, '')]) {
                price = updatedRow[getTranslateValue(currentTrip?.toLocationCity, "ua").replace(/ /g, '')];
            }

            chainTrips = currentTrip?.chainTrips?.map((item: any) => {
                if (getTranslateValue(item.fromLocationCity, "ua") === updatedRow.fromLocationCity
                    && getTranslateValue(item.toLocationCity, "ua").replace(/ /g, '') === key) {
                    item.price = updatedRow[getTranslateValue(item.toLocationCity, "ua").replace(/ /g, '')];
                }
                return item;
            })
        });

        setFormValues({
            ...formValues,
            price: Number(price),
            chainTrips: chainTrips
        })

        return updatedRow;
    };

    console.log("formValues", formValues)

    return (
        <Grid container spacing={1} sx={{pr: 1}}>

            <Grid item xs={12}>

                <Box sx={{
                    my: 1,
                    display: 'flex',
                    align: 'center',
                    gap: '10px'
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="createUserBtn"
                        startIcon={<West className="goBack"/>}
                        onClick={() => navigate(-1)}
                    >
                        {t("common.goBack")}
                    </Button>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        {
                            `Рейси / ${oneRace?.raceNum ?? ''} 
                            ${getTranslateValue(oneRace?.fromLocationCity, "ua") ?? ''} - 
                            ${getTranslateValue(oneRace?.toLocationCity, "ua") ?? ''} 
                            / Поїздка: ${
                                (currentTrip?.departureDate && currentTrip?.arrivalDate)
                                    ? (formatDate(currentTrip?.departureDate, true) + ' - ' + formatDate(currentTrip?.arrivalDate, true))
                                    : ''
                            } / Тарифи`
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        gap: "20px"
                    }}
                >

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                            gap: "20px"
                        }}
                    >
                        {/*<FormControlLabel*/}
                        {/*    control={*/}
                        {/*        <Switch*/}
                        {/*            checked={isShowSegments}*/}
                        {/*            onChange={handleSwitchChange}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*    label="Показати приховані сегменти"*/}
                        {/*/>*/}
                    </Box>

                    <Box
                        sx={{
                            maxWidth: "700px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "20px"
                        }}
                    >
                        <FormControl error={!!formErrors?.currency} fullWidth variant="standard">
                            <InputLabel>Валюта</InputLabel>
                            <Select
                                value={
                                    formValues?.currency ||
                                    "euro"
                                }
                                name="currency"
                                onChange={handleChangeSelect}
                            >
                                {
                                    Object.entries(Currency).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {formErrors?.currency && <FormHelperText>{formErrors?.currency}</FormHelperText>}
                        </FormControl>

                        <FormControl error={!!formErrors?.tariffType} fullWidth variant="standard">
                            <InputLabel>Фіксований</InputLabel>
                            <Select
                                value={
                                    formValues?.tariffType ||
                                    "fixed"
                                }
                                name="tariffType"
                                onChange={handleChangeSelect}
                            >
                                {
                                    Object.entries(TariffType).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {formErrors?.tariffType && <FormHelperText>{formErrors?.tariffType}</FormHelperText>}
                        </FormControl>
                    </Box>

                    <Box
                        sx={{
                            maxWidth: "700px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "20px"
                        }}
                    >
                        <FormControl fullWidth variant="standard">
                            <InputLabel shrink>Страховий збір</InputLabel>
                            <TextField
                                name="insuranceFee"
                                variant="standard"
                                type="number"
                                value={formValues?.insuranceFee || "initial"}
                                onChange={handleInputChange}
                                sx={{
                                    mt: "16px"
                                }}
                                error={!!formErrors?.insuranceFee}
                                helperText={formErrors?.insuranceFee}
                            />
                        </FormControl>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexFlow: "column",
                                justifyContent: "flex-start"
                            }}
                        >
                            <RadioGroup
                                aria-label="insuranceFeeType"
                                name="insuranceFeeType"
                                value={formValues?.insuranceFeeType || "percentage"}
                                onChange={handleRadioChange}
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexFlow: "row",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <FormControlLabel value="percentage" control={<Radio/>} label="в %"/>
                                <FormControlLabel value="fixed" control={<Radio/>} label="фіксований"/>
                            </RadioGroup>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            mt: "30px",
                            width: "100%",
                            display: "flex",
                            flexFlow: "column",
                            justifyContent: "center"
                        }}
                    >
                        <InputLabel shrink>Таблиця вартості проїзду</InputLabel>
                        <Box
                            sx={{
                                width: "max-content",
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                        >
                            <DataGrid
                                apiRef={apiRef}
                                rows={rowsData}
                                columns={columnsData}
                                localeText={{noRowsLabel: "Маршрутів поки немає"}}
                                getRowId={(row: any) => row.id}
                                processRowUpdate={processTripTariffsUpdate}
                                editMode="row"
                                disableColumnMenu
                                hideFooterSelectedRowCount
                                autoHeight={true}
                                className="tariffsTabel"
                                sx={{
                                    width: "max-content"
                                }}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            mt: 1,
                        }}
                    >
                        <LoadingButton
                            // loading={isLoading}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={editTripTariffsHandler}
                        >
                            Зберегти
                        </LoadingButton>
                    </Box>
                </Box>

            </Grid>
        </Grid>
    );
};

export default TripTariffs;
