import { api } from '@/api'
import { ITrip } from '@/interfaces/trip'

export const trips_api = {
  createTrip: (data: Partial<ITrip>) => {
    const { trip_id } = data
    return api.post(`/trip/admin/createTrip/${trip_id}`, data)
  },

  getTrips: (flight_id?: number, trip_id?: number) => {
    return api.get('/trips', {
      params: {
        flight_id,
        trip_id,
      },
    })
  },

  getTripById: (id: string, aggrType: string, idType: string) => {
    return api.get(`/trip/admin/${id}`, {
      params: {
        id,
        aggrType,
        idType,
      },
    })
  },

  editTrip: (id: string, data: Partial<ITrip>) => {
    return api.patch(`/trip/admin/${id}`, data)
  },

  deleteTrip: (id: number) => {
    return api.delete(`/trips/${id}`)
  },
}
