import {create} from "zustand";
import {REQ} from "@/api";
import {IMeta} from "@/interfaces/general";

const {consultations_api} = REQ;

interface IStore {
    consultations: any;
    isLoading: boolean;
    consultationMeta: IMeta;

    getAllConsultations: ({count, page}: IMeta) => Promise<any>;
}

export const consultationStore = create<IStore>((set) => ({
    consultations: [],
    isLoading: false,
    consultationMeta: {
        count: 10,
        page: 1,
        total: 0,
    },

    getAllConsultations: async (meta) => {
        set((state) => ({...state, isLoading: true}));
        const response = await consultations_api.getAllConsultations(meta);

        const {status, data} = response;

        set((state) => ({...state, isLoading: false}));

        if (!status || !data) {
            throw new Error("No status code or data returned from server.");
        }

        if (status !== 200) {
            throw new Error(data);
        } else {
            set((state) => ({
                ...state,
                consultations: data.data,
                consultationMeta: data.meta,
            }));
            return response;
        }
    },
}));
