import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface IProps {
  message: string
  filterQuery: any
  setFilterQuery: (filterQuery: any) => void
}

const NoDataHelper = ({ message, filterQuery, setFilterQuery }: IProps) => {
  const [filterQueryKeys, setFilterQueryKeys] = useState<string[]>([])

  const reorganizeFilterQuery = () => {
    const keys = Object.keys(filterQuery).filter((key) => {
      const value = filterQuery[key]

      if (Array.isArray(value)) {
        return value.length
      }

      if (typeof value === 'object' && value !== null) {
        return Object.keys(value).some((key) => {
          if (value[key] === null || value[key] === undefined) {
            return false
          } else {
            return value
          }
        })
      }

      return value
    })

    setFilterQueryKeys(keys)
  }

  useEffect(() => {
    reorganizeFilterQuery()
  }, [filterQuery])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontWeight: 'bold',
              fontSize: '10rem',
            }}>
            404
          </Typography>
          <Box
            sx={{
              maxWidth: {
                xs: '100%',
                md: '50%',
              },
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}>
            {filterQueryKeys.map((key, index) => {
              return (
                <Paper
                  key={index}
                  elevation={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: '100%',
                    minWidth: '150px',
                    m: 1,
                    px: 1,
                  }}>
                  <Typography
                    variant="body2"
                    align="left"
                    color="info.main"
                    sx={{
                      textTransform: 'capitalize',
                    }}>
                    {key}
                  </Typography>
                  <Tooltip title="Remove filter">
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => {
                        setFilterQuery({
                          ...filterQuery,
                          [key]: null,
                        })
                      }}
                      sx={{
                        ml: 1,
                      }}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Paper>
              )
            })}
          </Box>
          <Typography variant="h1" align="center" color="error.dark">
            {message}
          </Typography>

          <Typography variant="body2" align="center" color="info.main">
            Try to change filter query for search
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default NoDataHelper
