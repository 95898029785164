import { AxiosHeaders, AxiosInstance } from 'axios'

export const apiCreator = (prefix: string) => {
  if (!prefix) throw new Error('prefix is not defined')

  const request = async (
    api: AxiosInstance,
    url: string,
    method: string,
    body?: unknown,
    query?: unknown,

    headers?: AxiosHeaders
  ) => {
    if (!api) throw new Error('api is not defined')

    const fullUrl = url ? `${prefix}/${url}` : prefix

    switch (method) {
      case 'GET':
        return await api.get(fullUrl, { params: query, headers })
      case 'POST':
        return await api.post(fullUrl, body)
      case 'PUT':
        return await api.put(fullUrl, body)
      case 'PATCH':
        return await api.patch(fullUrl, body)
      case 'DELETE':
        return await api.delete(fullUrl)
      default:
        return await api.get(fullUrl)
    }
  }

  return request
}
