import { formatDate } from '@/utils/formatDate'

import { TableCell, TableRow, Typography, Tooltip, IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PageviewIcon from '@mui/icons-material/Pageview'

import { useTranslation } from 'react-i18next'

import { messageStore } from '@/store/store'
import { usersStore } from '@/store/usersStore'

interface IProps {
  row: any
}

const UserTableRow = ({ row }: IProps) => {
  const { t } = useTranslation()
  const i18nTranslateRowString = 'usersPage.userTable.row'

  const { setIsUserRacesModalOpen, setCurrentUser } = usersStore()

  const { addMessage } = messageStore()

  const { pib, email, phone, birth_date } = row

  const handleCopy = (value: string | number) => {
    navigator.clipboard.writeText(value.toString())
    addMessage({
      severity: 'success',
      message: t(`${i18nTranslateRowString}.copied`),
      isOpen: true,
    })
  }

  const handleOpenModal = () => {
    setCurrentUser(row)
    setIsUserRacesModalOpen(true)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        {pib || (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {
              t(`${i18nTranslateRowString}.noName`) //No name
            }
          </Typography>
        )}
      </TableCell>
      <TableCell align="left">
        {email ? (
          <Tooltip
            title={
              t(`${i18nTranslateRowString}.copyEmailTooltip`) //Copy email
            }>
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => handleCopy(email)}>
              <IconButton sx={{ ml: 1 }} size="small">
                <ContentCopyIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </IconButton>
              {email}
            </Typography>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              t(`${i18nTranslateRowString}.noEmailTooltip`) //No email
            }>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                cursor: 'pointer',
              }}>
              {
                t(`${i18nTranslateRowString}.noEmail`) //No email
              }
            </Typography>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="left">{phone}</TableCell>
      <TableCell align="left">
        {birth_date ? (
          formatDate(new Date(birth_date).getTime(), true)
        ) : (
          <Tooltip
            title={
              t(`${i18nTranslateRowString}.noDateTooltip`) //No date
            }>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                cursor: 'pointer',
              }}>
              {
                t(`${i18nTranslateRowString}.noDate`) //No date
              }
            </Typography>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="left">
        <IconButton size="small" color="warning" onClick={handleOpenModal}>
          <PageviewIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default UserTableRow
