import { create } from 'zustand'

import { api, REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { ITripDto, ITripRequest } from '@/interfaces/tripRequests'

const { tripRequests_api } = REQ

interface IStore {
  isLoading: boolean
  tripRequests: ITripRequest[]
  tripRequestsMeta: IMeta
  currentTripRequest: ITripRequest | null
  setCurrentTripRequest: (tripRequest: ITripRequest) => void
  searchParams: Partial<ITripRequest>
  setSearchParams: (searchParams: Partial<ITripRequest>) => void
  getTripRequests: ({
    status,
    trip_id,
    phone,
    pib,
  }: {
    status: string
    trip_id?: number
    phone?: string
    pib?: string
  }) => Promise<any>
  editTripRequest: (id: number, changes: Partial<ITripRequest>) => Promise<any>
  editModal: {
    isOpen: boolean
    tripRequest: ITripRequest | null
    handleOpen: (tripRequest: ITripRequest) => void
    handleClose: () => void
  }
  approveModal: {
    isOpen: boolean
    tripRequest: ITripRequest | null
    handleOpen: (tripRequest: ITripRequest) => void
    handleClose: () => void
  }
  declineModal: {
    isOpen: boolean
    tripRequest: ITripRequest | null
    handleOpen: (tripRequest: ITripRequest) => void
    handleClose: () => void
  }
  createModal: {
    isOpen: boolean
    handleOpen: () => void
    handleClose: () => void
  }
  pdfBlob: Blob | null
  exportExcel: (id: number) => Promise<any>
  // exportPDF: (id: number, status: string, type: string) => Promise<any>
  getTripRequestById: (id: number) => Promise<any>
  createTripRequest: (data: ITripDto) => Promise<any>
}

export const tripRequestsStore = create<IStore>((set) => ({
  tripRequests: [],
  isLoading: false,
  currentTripRequest: null,
  setCurrentTripRequest: (tripRequest) => {
    set((state) => ({ ...state, currentTripRequest: tripRequest }))
  },
  tripRequestsMeta: {
    count: 50,
    page: 1,
    total: 0,
  },
  searchParams: {},
  setSearchParams: (searchParams) => {
    set((state) => ({ ...state, searchParams }))
  },
  pdfBlob: null,
  editModal: {
    isOpen: false,
    tripRequest: null,
    handleOpen: (tripRequest) => {
      set((state) => ({
        ...state,
        editModal: {
          ...state.editModal,
          isOpen: true,
          tripRequest,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        editModal: {
          ...state.editModal,
          isOpen: false,
          tripRequest: null,
        },
      }))
    },
  },

  approveModal: {
    isOpen: false,
    tripRequest: null,
    handleOpen: (tripRequest) => {
      set((state) => ({
        ...state,
        approveModal: {
          ...state.approveModal,
          isOpen: true,
          tripRequest,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        approveModal: {
          ...state.approveModal,
          isOpen: false,
          tripRequest: null,
        },
      }))
    },
  },

  declineModal: {
    isOpen: false,
    tripRequest: null,
    handleOpen: (tripRequest) => {
      set((state) => ({
        ...state,
        declineModal: {
          ...state.declineModal,
          isOpen: true,
          tripRequest,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        declineModal: {
          ...state.declineModal,
          isOpen: false,
          tripRequest: null,
        },
      }))
    },
  },

  createModal: {
    isOpen: false,
    handleOpen: () => {
      set((state) => ({
        ...state,
        createModal: {
          ...state.createModal,
          isOpen: true,
        },
      }))
    },
    handleClose: () => {
      set((state) => ({
        ...state,
        createModal: {
          ...state.createModal,
          isOpen: false,
        },
      }))
    },
  },
  getTripRequests: async ({
    status,
    trip_id,
    phone,
    pib,
  }: {
    status: string
    trip_id?: number
    phone?: string
    pib?: string
  }) => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await tripRequests_api.getTripRequests({
      status,
      trip_id,
      phone,
      pib,
    })

    setTimeout(() => {
      set((state) => ({ ...state, isLoading: false }))
    }, 500)

    const { status: tripStatus, data } = response

    if (!tripStatus || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (tripStatus !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        tripRequests: data.orders,
      }))
      return response
    }
  },

  editTripRequest: async (id, changes) => {
    console.log('id', id)

    const response = await tripRequests_api.editTripRequest(id, changes)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      return response
    }
  },

  exportExcel: async (id: number) => {
    try {
      const response = await api.get(`/admin/orders`, {
        params: {
          trip_id: id,
          format: 'file',
          status: 'booked',
        },
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data)

        const newTab = window.open(url, '_blank')
        if (newTab) {
          newTab.focus()
        } else {
          alert('Please allow popups for this website')
        }
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      } else {
        throw new Error('Failed to download file')
      }
    } catch (error) {
      console.error('Error exporting to Excel:', error)
      // Handle the error appropriately
    }
  },

  // exportPDF: async (id, status, type) => {
  //   const response = await tripRequests_api.exportToPDF(id, status)

  //   const { status: statusCode, data } = response

  //   console.log('TEST', response.data)

  //   if (type === 'preview') {
  //     set((state) => ({ ...state, pdfBlob: response.data }))
  //   } else if (type === 'download') {
  //     const href = window.URL.createObjectURL(response.data)

  //     console.log('HREF', href)

  //     const anchorElement = document.createElement('a')

  //     anchorElement.href = href
  //     anchorElement.download = `passengers-info-${id}.pdf`

  //     document.body.appendChild(anchorElement)
  //     anchorElement.click()

  //     document.body.removeChild(anchorElement)
  //     window.URL.revokeObjectURL(href)
  //   }

  //   if (!statusCode || !data) {
  //     throw new Error('No status code or data returned from server.')
  //   }

  //   if (statusCode !== 200) {
  //     throw new Error(data)
  //   } else {
  //     return response
  //   }
  // },

  getTripRequestById: async (id) => {
    const response = await tripRequests_api.getTripRequestById(id)

    const { status, data } = response

    console.log('response', data)

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, currentTripRequest: data.data }))
      return response
    }
  },

  createTripRequest: async (data) => {
    const response = await tripRequests_api.createTripRequest(data)

    const { status, data: responseData } = response

    if (!status || !responseData) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error('Failed to create trip request')
    } else {
      return response
    }
  },
}))
