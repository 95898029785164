interface IPrefixes {
  COUNTRY: string
  ADMINS: string
  TOWN: string
  DRIVER: string
  CARS: string
  CONSULTATIONS: string
  NOTIFICATIONS: string
  TRIPS: string
  STOPS: string
}

export const PREFIXES: IPrefixes = {
  COUNTRY: 'countries',
  ADMINS: 'admin',
  TOWN: 'cities',
  STOPS: 'stops',
  DRIVER: 'drivers',
  CARS: 'cars',
  TRIPS: 'trips',
  CONSULTATIONS: 'consultation',
  NOTIFICATIONS: 'admin-notification',
}
