import {Add} from "@mui/icons-material";
import {Button, Grid} from "@mui/material";
import React from "react";
import {articlesStore} from "@/store/articlesStore";
import TablePaginationCustom from "@/components/UI/TablePaginationCustom/TablePaginationCustom";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import CreateArticleModal from "./modals/CreateArticleModal";
import EditArticleModal from "./modals/EditArticleModal";
import DeleteArticleModal from "./modals/DeleteArticleModal";
import PhotoArticleModal from "./modals/PhotoArticleModal";
import ArticlesFilterTabel from "./ArticlesFilterTabel";
import AdminArticleItem from "@/components/UI/AdminArticleItem/AdminArticleItem";

const ArticlesTabel = () => {
    const {
        articles,
        createArticle,
        articlesMeta,
        getAllArticles,
        filter,
    } = articlesStore();

    const getAllArticlesApiHandler = useAsyncWrapper(
        getAllArticles,
        "getAllArticlesApiHandler"
    );
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ArticlesFilterTabel/>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                }}
            >
                <CreateArticleModal/>
                <DeleteArticleModal/>
                <EditArticleModal/>
                <PhotoArticleModal/>

                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Add/>}
                    onClick={() => createArticle.handleOpenModal()}
                >
                    Додати статтю
                </Button>

                <TablePaginationCustom
                    getData={getAllArticlesApiHandler}
                    meta={articlesMeta}
                    filterQuery={filter}
                />
            </Grid>

            <Grid item container xs={12} spacing={2}>
                {articles.map((articleItem) => (
                    <AdminArticleItem key={articleItem._id} articlesItem={articleItem}/>
                ))}
            </Grid>
        </Grid>
    );
};

export default ArticlesTabel;
