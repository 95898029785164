import { api } from '@/api'

// ? interfaces
import { Itown } from '@/interfaces/town'

// ? routes prefixes
import { PREFIXES } from '@/routes/prefixes'

// ? utils
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.TOWN)

export const town_api = {
  getAllTown: async (country_id?: number) => await requestCreator(api, '', 'GET', undefined, { country_id }),

  createTown: async (body: Partial<Itown>) => await requestCreator(api, '', 'POST', body),

  editTown: async (id: string, body: Partial<Itown>) => await requestCreator(api, `${id}`, 'PUT', body),

  deleteTown: async (id: string) => await requestCreator(api, `${id}`, 'DELETE'),

  getById: async (id: string) => await requestCreator(api, `getOne/${id}`, 'GET'),
}
