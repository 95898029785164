// ? hooks
import { useEffect } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { townStore } from '@/store/townStore'

// ? components
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'

import TownTabelHeader from './TownTabelHeader'
import TownTabelRow from './TownTabelRow'

import CreateTownModal from './modals/CreateTownModal'
import EditTownModal from './modals/EditTownModal'
import DeleteTownModal from './modals/DeleteTownModal'

import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

// ? icons
import { Add } from '@mui/icons-material'

const TownTabel = () => {
  const { getAllTowns, towns, townMeta, createTown, editTown, isLoading, deleteTown } = townStore()

  const handleOpenCreateModal = () => {
    createTown.handleOpenModal()
  }

  const getTownsApiHandler = useAsyncWrapper(getAllTowns, 'getTownsApiHandler')

  useEffect(() => {
    const fetchData = async () => {
      const { error: getTownsError } = await getTownsApiHandler([townMeta])

      if (getTownsError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createTown.isOpenModal && <CreateTownModal />}
      {editTown.isOpenModal && <EditTownModal />}
      {deleteTown.isOpenModal && <DeleteTownModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Додати місто
          </Button>
        </Box>
        {towns?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <TownTabelHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  towns.map((item) => <TownTabelRow town={item} key={item?.city_id} />)
                ) : (
                  <LoadingTableData columns={7} rows={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Додати місто
            </Button>
          </Box>
        )}
        {towns && towns?.length !== 0 && <TablePaginationCustom getData={getTownsApiHandler} meta={townMeta} />}
      </Grid>
    </Grid>
  )
}
export default TownTabel
