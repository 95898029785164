// ? store
import { townStore } from '@/store/townStore'

// ? interfaces
import { Itown } from '@/interfaces/town'

// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'

interface IProps {
  town: Itown
}

const TownTabelRow = ({ town }: IProps) => {
  const { editTown, deleteTown } = townStore()

  const handleEditTown = (town: Itown) => {
    editTown.handleOpenModal(town)
  }

  const handleDeleteTown = (town: Itown) => {
    deleteTown.handleOpenModal(town)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {town?.city_id}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`EN: ${town?.name_eng}`}
          <br />
          {`UA: ${town?.name_ukr}`}
        </Typography>
      </TableCell>
      {/* <TableCell align="left">
        <Typography
          variant="body2"
          color={town?.isActive ? 'success.main' : 'error.main'}
          sx={{
            cursor: 'pointer',
          }}>
          {town?.isActive ? 'Активне' : 'Не активне'}
        </Typography>
      </TableCell> */}
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {town?.timezone}
        </Typography>
      </TableCell>
      {/* <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {formatDate(town?.updatedAt as string, true)}
        </Typography>
      </TableCell> */}
      {/* <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {formatDate(town?.createdAt as string, true)}
        </Typography>
      </TableCell> */}
      <TableCell align="left">
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={() => handleEditTown(town)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteTown(town)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default TownTabelRow
