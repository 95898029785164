import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./locales/en/translation.json";
import ua from "./locales/ua/translation.json";

export const resources = {
    en: {
        translation: en,
    },
    ua: {
        translation: ua,
    },
} as const;

i18n.use(initReactI18next).init({
    fallbackLng: "ua",
    resources,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});
