// ? hooks
import { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? stores
import { messageStore } from '@/store/store'
import { stopsStore } from '@/store/stopsStore'

import { townStore } from '@/store/townStore'

// ? interfaces
import { IStopDto } from '@/interfaces/stop'

// ? validations
import { COUNTRY_VALIDATION } from '@/validation/country'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField, InputLabel, Select, MenuItem } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

const initialState: IStopDto = {
  name_eng: '',
  name_ukr: '',
  bus_stop_location: null,
}

const CreateStopModal = () => {
  const { addMessage } = messageStore()
  const { createStop, getAllStops, isLoading } = stopsStore()
  const { getAllTowns, towns } = townStore()

  const [formValues, setFormValues] = useState(initialState)

  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)
  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const createCountryValidation = useValidation(COUNTRY_VALIDATION.CREATE)
  const createCountryApiHandler = useAsyncWrapper(createStop.createStopApiHandler, 'createCountryApiHandler')

  const getStopsApiHandler = useAsyncWrapper(getAllStops, 'getAllStopsHandler')
  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  const handleClose = () => {
    setFormValues(initialState)
    createStop.handleCloseModal()
  }

  const createCountryHandler = async () => {
    if (!createCountryValidation(formValues)) return

    const { error: createStopError } = await createCountryApiHandler([formValues])

    if (createStopError) {
      addMessage({
        severity: 'error',
        message: createStopError,
        isOpen: true,
      })
      return
    }

    const { error: getStopsError } = await getStopsApiHandler([])

    if (getStopsError) {
      addMessage({
        severity: 'error',
        message: getStopsError,
        isOpen: true,
      })
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно створено',
      isOpen: true,
    })

    handleClose()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTownsError } = await getAllTownsApiHandler([])

      if (getAllTownsError) return
    }

    fetchData()
  }, [])
  return (
    <ModalContainer width={400} isOpen={createStop.isOpenModal} handleClose={handleClose} title={'Додати Зупинку'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <TextField
          name="name_eng"
          onChange={inputChangeHandler}
          fullWidth
          label="Назва зупинки англійською"
          variant="standard"
        />
        <TextField
          name="name_ukr"
          onChange={inputChangeHandler}
          fullWidth
          label="Назва зупинки українською"
          variant="standard"
        />
        <InputLabel>Місто</InputLabel>
        <Select
          value={formValues?.bus_stop_location || 'initial'}
          name="bus_stop_location"
          onChange={handleChangeSelect}
          fullWidth
          variant="standard"
          label="Місто">
          <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
          {towns?.length
            ? towns.map((town) => {
                return (
                  <MenuItem key={town.city_id} value={town.city_id}>
                    {town.name_ukr}
                  </MenuItem>
                )
              })
            : null}
        </Select>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createCountryHandler}>
            Створити
          </LoadingButton>
          <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Відмінити
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateStopModal
