// ? hooks
import useCopy from "@/hooks/useCopy";

// ? store
import {adminStore} from "@/store/adminStore";

// ? interfaces
import {IAdmin} from "@/interfaces/auth";

// ? utils
import {formatDate} from "@/utils/formatDate";

// ? components
import {
    TableRow,
    TableCell,
    Typography,
    Tooltip,
    IconButton,
} from "@mui/material";

// ? icons
import {ContentCopy, Delete} from "@mui/icons-material";


interface IProps {
    row: IAdmin;
}

const AdminTableRow = ({row}: IProps) => {
    const {firstName, lastName, email, phoneNumber, status, createdAt} = row;

    const copyHandler = useCopy();

    const {deleteAdminModal} = adminStore();

    const handleDeleteAdmin = (admin: IAdmin) => {
        deleteAdminModal.toggleDeleteModal(admin);
    };

    return (
        <TableRow hover>
            <TableCell align="left">
                {firstName || (
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        Ім'я відсутнє
                    </Typography>
                )}
            </TableCell>
            <TableCell align="left">
                <Typography variant="body2">{lastName}</Typography>
            </TableCell>

            <TableCell align="left">
                {email ? (
                    <>
                        <Tooltip title="Скопіювати емейл">
                            <Typography
                                variant="body2"
                                sx={{
                                    cursor: "pointer",
                                }}
                                onClick={() => copyHandler(email)}
                            >
                                <IconButton sx={{ml: 1}} size="small">
                                    <ContentCopy
                                        sx={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </IconButton>
                                {email}
                            </Typography>
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip title="Імейл відсутній">
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            Імейл відсутній
                        </Typography>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell align="left">{phoneNumber}</TableCell>
            <TableCell align="left">{status}</TableCell>
            <TableCell align="left">
                {createdAt ? (
                    formatDate(new Date(createdAt).getTime(), true)
                ) : (
                    <Tooltip title="Немає дати">
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            Немає дати
                        </Typography>
                    </Tooltip>
                )}
            </TableCell>

            <TableCell align="left">
                <Tooltip title={"Видалити"}>
                    <IconButton
                        size="small"
                        color="error"
                        onClick={() => handleDeleteAdmin(row)}
                    >
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default AdminTableRow;
