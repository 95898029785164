import React from "react";
import {Backdrop, Modal, Box, Divider} from "@mui/material";

import Fade from "./Fade";
import ModalHeader from "./ModalHeader";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: '0 0 25px 5px rgba(0, 0, 0, .3)',
    p: 4,
};

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    width?: number;
    height?: number | string;
    children: React.ReactNode;
    styles?: any;
}

const ModalContainer = ({
                            isOpen,
                            handleClose,
                            children,
                            title,
                            width = 400,
                            height = 'auto',
                            styles
                        }: IProps) => {
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition={false}
            disableScrollLock={true}
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    TransitionComponent: Fade,
                },
            }}
        >
            <Fade in={isOpen}>
                <Box
                    sx={{
                        ...style,
                        maxWidth: width,
                        maxHeight: height,
                        width: "100%",
                        ...styles
                    }}
                >
                    <ModalHeader handleClose={handleClose} title={title}/>
                    <Divider
                        sx={{
                            my: 2,
                        }}
                    />
                    {children}
                </Box>
            </Fade>
        </Modal>
    );
};

export default ModalContainer;
