import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Grid,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material'
import { West } from '@mui/icons-material'
import { racesStore } from '@/store/racesStore'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { DataGrid, GridRenderEditCellParams, useGridApiContext, useGridApiRef } from '@mui/x-data-grid'
import { LoadingButton } from '@mui/lab'
import { messageStore } from '@/store/store'
import './styles.css'
import { Currency, TariffType } from '@/constants/constants'
import { inputsChanger } from '@/utils/inputsChanger'
import { validateForm } from '@/utils/formValidation'
import { IRace } from '@/interfaces/races'
import { formatDateString } from '@/utils/formatDate'

const mergePricesWithCities = (existingStops: any[], updatedStops: any[]) => {
  const mergedStops = existingStops.map((stop) => {
    const matchingStop = updatedStops.find((updatedStop) => updatedStop.stop_name_ua === stop.stop_name_ua)

    if (matchingStop) {
      stop.prices = matchingStop.prices
    }

    return stop
  })

  // Add any new stops that don't already exist
  updatedStops.forEach((updatedStop) => {
    if (!mergedStops.some((stop) => stop.stop_name_ua === updatedStop.stop_name_ua)) {
      mergedStops.push(updatedStop)
    }
  })

  return mergedStops
}

function CustomEditComponent(props: GridRenderEditCellParams) {
  const { id, value, field, hasFocus, colDef, row } = props
  const apiRef = useGridApiContext()
  const ref = React.useRef()

  const isSameCity = colDef.headerName === row.fromLocationCity

  useLayoutEffect(() => {
    if (hasFocus && !isSameCity) {
      // @ts-ignore
      ref.current.focus()
    }
  }, [hasFocus])

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    apiRef.current.setEditCellValue({ id, field, value: Number(newValue) })
  }

  if (isSameCity) return null

  // @ts-ignore
  return <TextField type="number" ref={ref} value={value} onChange={handleValueChange} />
}

const RaceTariffs = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const params = useParams()
  const apiRef = useGridApiRef()

  const [isLoading, setIsLoading] = useState(false)
  const [rowsData, setRowsData] = useState<any[]>([])
  const [columnsData, setColumnsData] = useState<any[]>([])

  const { editRace } = racesStore()
  const { addMessage } = messageStore()

  const [formValues, setFormValues] = useState<any>({})

  const [formErrors, setFormErrors] = useState<any>()

  const validationRules = {
    currency: { required: true },
    tariffType: { required: true },
    insuranceFee: { required: true },
  }

  const { getAllRaces, races, oneRace, setOneRace } = racesStore()

  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')
  const editRaceApiHandler = useAsyncWrapper(editRace.editRaceApiHandler, 'editRaceApiHandler')

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllRacesError } = await getAllRacesApiHandler([])

      if (getAllRacesError) {
        console.error(getAllRacesError)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const selectedRace = races.find((raceItem: IRace) => raceItem.flight_id === Number(params.raceId))

    if (selectedRace) {
      setOneRace(selectedRace)
    }
  }, [races])

  const editRaceTariffsHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      setIsLoading(false)
      return
    }

    // Generate updated stops
    const updatedStops = formValues.middleStep.reduce((acc: any, row: any) => {
      const fromCity = row.fromLocationCity

      const prices = Object.entries(row).reduce((priceAcc: any[], [key, value]) => {
        if (key !== 'fromLocationCity' && key !== 'id' && value !== '') {
          priceAcc.push(value)
        } else if (!value && key !== 'fromLocationCity' && key !== 'id') {
          priceAcc.push(null)
        }

        return priceAcc
      }, [])

      if (prices.length > 0) {
        acc.push({
          stop_name_ua: fromCity,
          prices: prices,
        })
      }

      return acc
    }, [])

    // Parse existing stops_along data
    const existingStops = JSON.parse(oneRace?.stops_along || '{}').stops || []

    // Merge existing stops with updated stops using mergePricesWithCities
    const mergedStops = mergePricesWithCities(existingStops, updatedStops)

    const stops_along = {
      stops: mergedStops,
      raceTariff: formValues.tariffType,
      insuranceFee: formValues.insuranceFee,
      insuranceFeeType: formValues.insuranceFeeType,
    }

    const data = {
      ...oneRace,
      currency: formValues.currency,
      stops_along: JSON.stringify(stops_along),
      begins_at: formatDateString(oneRace.begins_at),
      expires_at: formatDateString(oneRace.expires_at),
    }

    const { error: editRaceTariffsError } = await editRaceApiHandler([params.raceId, data])

    if (editRaceTariffsError) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)

    addMessage({
      severity: 'success',
      message: 'Тарифи успішно оновлено',
      isOpen: true,
    })
  }

  useEffect(() => {
    if (Object.keys(oneRace).length !== 0) {
      const racesData = typeof oneRace.stops_along === 'string' ? JSON.parse(oneRace.stops_along) : oneRace.stops_along
      const stops = racesData.stops || []

      const boardingStops = stops
        .filter(
          (stop: any) => stop.boardingStatus === 'BoardingAndDisembarking' || stop.boardingStatus === 'BoardingOnly'
        )
        .map((stop: any) => stop.stop_name_ua)

      const disembarkingStops = stops
        .filter(
          (stop: any) => stop.boardingStatus === 'DisembarkingOnly' || stop.boardingStatus === 'BoardingAndDisembarking'
        )
        .map((stop: any) => stop.stop_name_ua)

      const columns = [
        { field: 'fromLocationCity', headerName: 'Від \\ До', width: 300, editable: false, sortable: false },
        ...disembarkingStops.map((city: any, index: number) => ({
          field: `${index}`,
          headerName: city,
          width: 100,
          editable: true,
          renderEditCell: (params: GridRenderEditCellParams) => <CustomEditComponent {...params} />,
          sortable: false,
        })),
      ]

      const rows = boardingStops.map((fromCity: any, rowIndex: number) => {
        const row: { [key: string]: any } = { id: rowIndex, fromLocationCity: fromCity }

        disembarkingStops.forEach((toCity: any, colIndex: number) => {
          if (fromCity !== toCity) {
            const stop = stops.find((s: any) => s.stop_name_ua === fromCity)
            const price = stop?.prices ? stop.prices[colIndex] : oneRace.price
            row[`${colIndex}`] = price
          }

          if (fromCity === toCity) {
            row[`${colIndex}`] = null
          }
        })

        return row
      })

      setColumnsData(columns)
      setRowsData(rows)

      setFormValues({
        currency: oneRace.currency ?? 'UAH',
        tariffType: racesData.raceTariff ?? 'fixed',
        insuranceFee: racesData.insuranceFee ?? '',
        insuranceFeeType: racesData.insuranceFeeType ?? 'percentage',
        middleStep: rows,
      })
    }
  }, [oneRace])

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleRadioChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const processCityStopUpdate = (newRow: any) => {
    const updatedRow = { ...newRow }

    const updatedMiddleSteps = formValues.middleStep.map((row: any) => (row.id === updatedRow.id ? updatedRow : row))

    setFormValues({
      ...formValues,
      middleStep: updatedMiddleSteps,
    })

    return updatedRow
  }

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      <Grid item xs={12}>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            align: 'center',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            startIcon={<West className="goBack" />}
            onClick={() => navigate(-1)}>
            {t('common.goBack')}
          </Button>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            Рейси / Тарифи
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '20px',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'row wrap',
              justifyContent: 'space-between',
              gap: '20px',
            }}></Box>
          <Box
            sx={{
              maxWidth: '700px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
              flexFlow: 'row',
            }}>
            <FormControl error={!!formErrors?.currency} fullWidth variant="standard">
              <InputLabel>Валюта</InputLabel>
              <Select value={formValues?.currency || 'UAH'} name="currency" onChange={handleChangeSelect}>
                {Object.entries(Currency).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {formErrors?.currency && <FormHelperText>{formErrors?.currency}</FormHelperText>}
            </FormControl>
            <FormControl error={!!formErrors?.tariffType} fullWidth variant="standard">
              <InputLabel>Фіксований</InputLabel>
              <Select value={formValues?.tariffType || 'fixed'} name="tariffType" onChange={handleChangeSelect}>
                {Object.entries(TariffType).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {formErrors?.tariffType && <FormHelperText>{formErrors?.tariffType}</FormHelperText>}
            </FormControl>
          </Box>
          <Box
            sx={{
              maxWidth: '700px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px',
            }}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Страховий збір</InputLabel>
              <TextField
                name="insuranceFee"
                variant="standard"
                type="number"
                value={formValues?.insuranceFee || ''}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.insuranceFee}
                helperText={formErrors?.insuranceFee}
              />
            </FormControl>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-start',
              }}>
              <RadioGroup
                aria-label="insuranceFeeType"
                name="insuranceFeeType"
                value={formValues?.insuranceFeeType || 'percentage'}
                onChange={handleRadioChange}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-start',
                }}>
                <FormControlLabel value="percentage" control={<Radio />} label="в %" />
                <FormControlLabel value="fixed" control={<Radio />} label="фіксований" />
              </RadioGroup>
            </Box>
          </Box>
          <Box
            sx={{
              mt: '30px',
              width: '100%',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
            }}>
            <InputLabel shrink>Таблиця вартості проїзду</InputLabel>
            <Box
              sx={{
                width: 'max-content',
                display: 'flex',
                justifyContent: 'flex-start',
              }}>
              <DataGrid
                apiRef={apiRef}
                rows={rowsData}
                columns={columnsData}
                localeText={{ noRowsLabel: 'Маршрутів поки немає' }}
                getRowId={(row: any) => row.id}
                processRowUpdate={processCityStopUpdate}
                editMode="row"
                disableColumnMenu
                hideFooterSelectedRowCount
                autoHeight
                className="tariffsTabel"
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              mt: 1,
            }}>
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              size="small"
              onClick={editRaceTariffsHandler}>
              Зберегти
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default RaceTariffs
