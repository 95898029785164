// ? hooks
import React, { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { townStore } from '@/store/townStore'
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'

// ? components
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'
import { inputsChanger } from '@/utils/inputsChanger'
import { BoardingStatus } from '@/constants/constants'
import { useParams } from 'react-router-dom'
import { stops_api } from '@/api/stops.api'
import { IStop } from '@/interfaces/stop'
import { formatDateString } from '@/utils/formatDate'

const CreateRaceStopModal = () => {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const { createRaceStop, editRace, setOneRace } = racesStore()
  const { isOpenModal, editedItem, handleCloseModal } = createRaceStop

  const { addMessage } = messageStore()
  const { towns, getAllTowns } = townStore()

  const [formValues, setFormValues] = useState<any>({
    departure_location: '',
    stop_id: '',
    boardingStatus: '',
    stopPosition: '',
    arrivalTime: '',
    departureTime: '',
    arrivalDay: 1,
    departureDay: 1,
    platformNumber: '',
    distanceFromStart: 0,
    borderCross: false,
    changeTransfer: false,
    changeBus: false,
  })

  const [existingStops, setExistingStops] = useState([] as any)
  const [raceStops, setRaceStops] = useState<IStop[]>([])

  const editRaceStopApiHandler = useAsyncWrapper(editRace.editRaceApiHandler, 'editRaceStopApiHandler')
  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const createRaceStopHandler = async () => {
    setIsLoading(true)

    // If stops_along is already an array, use it; otherwise, initialize it as an empty array
    const stopsAlongArray = editedItem?.stops_along.stops ?? []

    // Find the stop position index
    const stopPositionIndex = stopsAlongArray.findIndex((stop: any) => stop.stop_id === formValues.stopPosition)

    // Get the new city stop details
    const cityStop = raceStops.find((stop) => stop.bus_stop_id === formValues.stop_id)
    const newStop = {
      stop_name_ua: cityStop?.bus_stop_name_ua,
      stop_name_en: cityStop?.bus_stop_name_en,
      ...formValues,
      city_id: formValues.departure_location,
      timezone: null,
    }

    // Insert the new stop at the correct position
    if (stopPositionIndex === -1 || stopPositionIndex === stopsAlongArray.length - 1) {
      stopsAlongArray.push(newStop)
    } else {
      stopsAlongArray.splice(stopPositionIndex + 1, 0, newStop)
    }

    // Remove duplicates if any
    const uniqueStopsArray = stopsAlongArray.filter(
      (stop: any, index: number, self: any) =>
        index === self.findIndex((s: any) => s.stop_name_ua === stop.stop_name_ua)
    )

    const data = {
      ...editedItem,
      stops_along: JSON.stringify({
        stops: uniqueStopsArray,
      }),
      begins_at: formatDateString(editedItem?.begins_at!),
      expires_at: formatDateString(editedItem?.expires_at!),
    }

    try {
      const { error: editError } = await editRaceStopApiHandler([Number(params.raceId), data])

      if (editError) {
        setIsLoading(false)
        throw new Error(editError)
      }

      // @ts-ignore
      setOneRace({
        ...editedItem,
        stops_along: {
          stops: uniqueStopsArray,
        },
      })

      addMessage({
        severity: 'success',
        message: 'Зупинку успішно створено',
        isOpen: true,
      })

      handleCloseForm()
    } catch (error) {
      console.error('Failed to create race stop', error)
      addMessage({
        severity: 'error',
        message: 'Не вдалося створити зупинку',
        isOpen: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await stops_api.getStops(formValues.departure_location)

      setRaceStops(data.stops)
    }

    fetchData()
  }, [formValues?.departure_location])

  useEffect(() => {
    const fetchData = async () => {
      console.log('editedItem', editedItem)
      const existingStops = editedItem?.stops_along?.stops.map((stop: any) => {
        return {
          // @ts-ignore
          bus_stop_id: stop.stop_id,
          bus_stop_name_ua: stop.stop_name_ua,
          bus_stop_name_en: stop.stop_name_en,
        }
      })

      setExistingStops(existingStops)
    }

    fetchData()
  }, [formValues?.departure_location, formValues?.stop_id])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTownsError } = await getAllTownsApiHandler([])

      if (getAllTownsError) {
        return
      }
    }

    fetchData()
  }, [])

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleCheckboxChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.checked })
  }

  console.log('existingStops', existingStops)

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleCloseForm} title="Додавання зупинки">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Населений пункт</InputLabel>
            <Select
              value={formValues?.departure_location || 'initial'}
              name="departure_location"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {towns?.length
                ? towns.map((town) => {
                    return (
                      <MenuItem key={town.city_id} value={town.city_id}>
                        {town.name_ukr}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel>Зупинка</InputLabel>
            <Select value={formValues?.stop_id || 'initial'} name="stop_id" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {raceStops?.length
                ? raceStops.map((cityStop) => {
                    return (
                      <MenuItem key={`cityStop-${cityStop.bus_stop_id}`} value={cityStop.bus_stop_id}>
                        {cityStop.bus_stop_name_ua}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Стан Посадка/Висадка</InputLabel>
            <Select value={formValues?.boardingStatus || 'initial'} name="boardingStatus" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {Object.entries(BoardingStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel>Створити після зупинки</InputLabel>
            <Select value={formValues?.stopPosition || 'initial'} name="stopPosition" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {existingStops?.length
                ? existingStops?.map((stop: any) => (
                    <MenuItem key={`stop-pos-${stop.bus_stop_id}`} value={stop.bus_stop_id}>
                      {stop.bus_stop_name_ua}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Прибуття</InputLabel>
            <TextField
              name="arrivalTime"
              variant="standard"
              type="time"
              value={formValues?.arrivalTime || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Відправлення</InputLabel>
            <TextField
              name="departureTime"
              variant="standard"
              type="time"
              value={formValues?.departureTime || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Доба рейсу для прибуття</InputLabel>
            <TextField
              name="arrivalDay"
              variant="standard"
              type="number"
              value={formValues?.arrivalDay || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Доба рейсу для відправлення</InputLabel>
            <TextField
              name="departureDay"
              variant="standard"
              type="number"
              value={formValues?.departureDay || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Номер платформи</InputLabel>
            <TextField
              name="platformNumber"
              variant="standard"
              value={formValues?.platformNumber || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Відстань від початкової</InputLabel>
            <TextField
              name="distanceFromStart"
              variant="standard"
              type="number"
              value={formValues?.distanceFromStart || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '20px',
          }}>
          <FormControlLabel
            control={<Checkbox name="borderCross" checked={formValues.borderCross} onChange={handleCheckboxChange} />}
            label="Кордон"
          />
          <FormControlLabel
            control={
              <Checkbox name="changeTransfer" checked={formValues.changeTransfer} onChange={handleCheckboxChange} />
            }
            label="Пересадка"
          />
          <FormControlLabel
            control={<Checkbox name="changeBus" checked={formValues.changeBus} onChange={handleCheckboxChange} />}
            label="Заміна автобуса"
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createRaceStopHandler}>
            Створити
          </LoadingButton>
          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateRaceStopModal
