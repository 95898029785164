import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box, Typography } from '@mui/material'
import { Add, West } from '@mui/icons-material'
import PlanningTabelHeader from './PlanningTabelHeader'
import PlanningTabelRow from './PlanningTabelRow'
import { racesStore } from '@/store/racesStore'
import RaceParamsModal from './modals/RaceParamsModal'
import CreateRacePlanningModal from './modals/CreateRacePlanningModal'
import DeleteRacePlanningModal from './modals/DeleteRacePlanningModal'
import { useTranslation } from 'react-i18next'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import { IPlan } from '@/interfaces/planning'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { planningStore } from '@/store/planningStore'

const PlanningTabel = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { raceId } = useParams()

  const [openCreateModal, setOpenCreateModal] = useState(false)

  const { getRaceById, oneRace } = racesStore()

  const { plans, planningMeta, isLoading, getAllRacePlans, addRaceParamsModal, createPlanModal, deletePlanModal } =
    planningStore()

  const getRaceByIdApiHandler = useAsyncWrapper(getRaceById, 'getRaceByIdApiHandler')

  const getAllRacePlansApiHandler = useAsyncWrapper(getAllRacePlans, 'getAllRacePlansApiHandler')

  const handleOpenCreateModal = () => {
    createPlanModal.handleOpenModal()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getRaceByIdError } = await getRaceById(raceId ?? '')

      if (getRaceByIdError) {
        console.log(getRaceByIdError)
      }

      const { error: getRacePlansError } = await getAllRacePlans(raceId ?? '', planningMeta)

      if (getRacePlansError) {
        console.log(getRacePlansError)
      }
    }

    fetchData()
  }, [raceId])

  console.log('planningMeta', planningMeta)

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {addRaceParamsModal?.isOpenModal && <RaceParamsModal />}
      {createPlanModal?.isOpenModal && <CreateRacePlanningModal />}
      {deletePlanModal?.isOpenModal && <DeleteRacePlanningModal />}

      <Grid item xs={12}>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            align: 'center',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            startIcon={<West className="goBack" />}
            onClick={() => navigate(-1)}>
            {t('common.goBack')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createPlanBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            {t('racePlanningPage.addBtn')}
          </Button>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`Рейси / ${oneRace?.race_number ?? ''} 
                            ${oneRace?.departure_location} - 
                            ${oneRace?.destination_location} 
                            / План змін`}
          </Typography>
        </Box>

        <TableContainer>
          <Table
            stickyHeader
            sx={{
              maxHeight: 'calc(100vh - 250px)',
              width: '100%',
              maxWidth: '100%',
              overflow: 'scroll',
              mt: 1,
            }}>
            <TableHead>
              <PlanningTabelHeader />
            </TableHead>
            <TableBody>
              {isLoading ? (
                <LoadingTableData rows={3} columns={3} />
              ) : (
                plans?.map((plan: IPlan, index: number) => {
                  return <PlanningTabelRow plan={plan} key={`race-plan-${index}`} />
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {plans.length > 0 ? (
          <TablePaginationCustom getData={getAllRacePlansApiHandler} meta={planningMeta} />
        ) : (
          <Box
            sx={{
              my: 3,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              align: 'center',
              gap: '10px',
            }}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{
                cursor: 'pointer',
              }}>
              Заплановані зміни відсутні
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createPlanBtn"
              onClick={handleOpenCreateModal}>
              {t('racePlanningPage.createBtn')}
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default PlanningTabel
