import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import useDebounce from "@/hooks/useDebounce";
import {articlesStore} from "@/store/articlesStore";
import {inputsChanger} from "@/utils/inputsChanger";
import {isObjectValuesEmpty} from "@/utils/isObjectValuesEmpty";
import {Close} from "@mui/icons-material";
import {
    Box,
    Paper,
    FormControl,
    Input,
    InputAdornment,
    Tooltip,
    IconButton,
    Divider,
    Button,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";
import React, {useEffect} from "react";

const ArticlesFilterTabel = () => {
    const {getAllArticles, articlesMeta, filter, setFilter} = articlesStore();

    const getAllArticlesApiHandler = useAsyncWrapper(getAllArticles, "getAllArticlesApiHandler");
    const debouncedSearchTerm = useDebounce(filter, 500) as any;

    const handleChangeInputs = inputsChanger("input", filter, setFilter) as any;

    const handleChangeSelect = inputsChanger(
        "selectChanger",
        filter,
        setFilter
    ) as any;

    const clearFilter = async () => {
        const {error} = await getAllArticlesApiHandler([articlesMeta, filter]);

        if (error) {
            return false;
        }

        setFilter({});
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            getAllArticlesApiHandler([articlesMeta, filter]);
        }
    }, [debouncedSearchTerm]);

    return (
        <Box
            sx={{
                width: "100%",
            }}
        >
            <Paper
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: {
                        xs: "wrap-reverse",
                        xl: "nowrap",
                    },
                }}
            >
                <Box
                    sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        maxWidth: "600px",
                        mr: "15px",
                        my: "2px",
                    }}
                >
                    <FormControl variant="standard" fullWidth>
                        <InputLabel
                            sx={{
                                ml: 1,
                            }}
                        >
                            Заголовок
                        </InputLabel>

                        <Input
                            placeholder="Пошук по заголовку"
                            size="small"
                            value={filter?.title || ""}
                            onChange={handleChangeInputs}
                            name="title"
                            sx={{ml: 1, my: 0, flex: 1}}
                            endAdornment={
                                <InputAdornment position="end">
                                    {filter?.title && filter?.title?.length > 0 && (
                                        <Tooltip title="Clear filter">
                                            <IconButton
                                                onClick={() =>
                                                    setFilter({
                                                        ...filter,
                                                        title: "",
                                                    })
                                                }
                                                edge="end"
                                                size="small"
                                            >
                                                <Close/>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel>Мова</InputLabel>
                        <Select
                            size="small"
                            fullWidth
                            name="language"
                            onChange={handleChangeSelect}
                            value={filter?.language || ""}
                            variant="standard"
                            label="Мова"
                            sx={{ml: 1, my: 0, flex: 1, minWidth: "100px"}}
                        >
                            <MenuItem value="">Всі мови</MenuItem>
                            <MenuItem value="UA">UA</MenuItem>
                            <MenuItem value="EN">EN</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl
                        fullWidth
                        sx={{
                            ml: 1,
                        }}
                    >
                        <InputLabel>Тип</InputLabel>
                        <Select
                            size="small"
                            fullWidth
                            name="type"
                            onChange={handleChangeSelect}
                            value={filter?.type || ""}
                            variant="standard"
                            label="Мова"
                            sx={{ml: 1, my: 0, flex: 1, minWidth: "100px"}}
                        >
                            <MenuItem value="">Всі</MenuItem>
                            <MenuItem value="news">Новини</MenuItem>
                            <MenuItem value="discount">Знижки</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl
                        fullWidth
                        sx={{
                            ml: 1,
                        }}
                    >
                        <InputLabel>Статус</InputLabel>
                        <Select
                            size="small"
                            fullWidth
                            name="isHidden"
                            onChange={handleChangeSelect}
                            value={filter?.isHidden || ""}
                            variant="standard"
                            label="Мова"
                            sx={{ml: 1, my: 0, flex: 1, minWidth: "100px"}}
                        >
                            <MenuItem value="">Всі</MenuItem>
                            <MenuItem value="false">Активні</MenuItem>
                            <MenuItem value="true">Приховані</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl
                        fullWidth
                        sx={{
                            ml: 1,
                        }}
                    >
                        <InputLabel>Підрозділ</InputLabel>
                        <Select
                            size="small"
                            fullWidth
                            name="entity"
                            onChange={handleChangeSelect}
                            value={filter?.entity || ""}
                            variant="standard"
                            label="Мова"
                            sx={{ml: 1, my: 0, flex: 1, minWidth: "100px"}}
                        >
                            <MenuItem value="">Всі</MenuItem>
                            <MenuItem value="passengerTransportation">
                                Пасажирські перевезення
                            </MenuItem>
                            <MenuItem value="cargoTransportation">
                                Вантажні перевезення
                            </MenuItem>
                        </Select>
                    </FormControl>

                    {!isObjectValuesEmpty(filter) && (
                        <>
                            <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
                            <Tooltip title="Очистити фільтр">
                                <Button size="small" onClick={clearFilter}>
                                    Очистити фільтр
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default ArticlesFilterTabel;
