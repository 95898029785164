import React from "react";
import ArticlesTabel from "@/components/Tabels/ArticlesTabel/ArticlesTabel";
import PageWrapper from "@/components/Wrappers/PageWrapper";

const Blog = () => {
    return (
        <PageWrapper>
            <ArticlesTabel/>
        </PageWrapper>
    );
};

export default Blog;
