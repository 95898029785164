import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Grid,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Box, Typography,
} from "@mui/material";
import {Add, West} from "@mui/icons-material";
import TripScheduleTabelHeader from "./TripScheduleTabelHeader";
import TripScheduleTabelRow from "./TripScheduleTabelRow";
import CreateRaceStopModal from "./modals/CreateRaceStopModal/CreateRaceStopModal";
import {racesStore} from "@/store/racesStore";
import EditRaceStopModal from "./modals/EditRaceStopModal/EditRaceStopModal";
import DeleteRaceStopModal from "./modals/DeleteRaceStopModal";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import TablePaginationCustom from "@/components/UI/TablePaginationCustom/TablePaginationCustom";
import LoadingTableData from "@/components/UI/LoadingTableData/LoadingTableData";
import {IRaceStop} from "@/interfaces/races";
import {getTranslateValue} from "@/utils/getTranslateValue";
import {tripsStore} from "@/store/tripStore";
import {formatDate} from "@/utils/formatDate";

const TripScheduleTabel = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const [openCreateModal, setOpenCreateModal] = useState(false);

    const {
        getRaceById,
        oneRace,
    } = racesStore();

    const {
        isLoading,
        currentTrip,
        getTripById,
        createTripRaceStop,
        editTripRaceStop,
        deleteTripRaceStop
    } = tripsStore();

    const getRaceByIdApiHandler = useAsyncWrapper(
        getRaceById,
        "getRaceByIdApiHandler"
    );

    const handleOpenCreateModal = () => {
        createTripRaceStop.handleOpenModal(currentTrip);
    };

    const fetchRaceTripData = async () => {
        const {error: getTripByIdError} = await getTripById(
            params.tripId ?? '',
            'raceStops'
        );

        if (getTripByIdError) {
            console.log(getTripByIdError);
        }
    }

    useEffect(() => {
        const fetchRaceData = async () => {
            const {error: getRaceByIdError} = await getRaceById(
                params.raceId ?? ''
            );

            if (getRaceByIdError) {
                console.log(getRaceByIdError);
            }
        }

        fetchRaceData();
        fetchRaceTripData();
    }, [params.raceId, params.tripId]);

    console.log("oneRace?.raceStops", currentTrip?.raceStops)

    return (
        <Grid container spacing={1} sx={{pr: 1}}>
            {createTripRaceStop?.isOpenModal && <CreateRaceStopModal/>}
            {editTripRaceStop?.isOpenModal && <EditRaceStopModal fetchData={fetchRaceTripData}/>}
            {deleteTripRaceStop?.isOpenModal && <DeleteRaceStopModal/>}

            <Grid item xs={12}>

                <Box sx={{
                    my: 1,
                    display: 'flex',
                    align: 'center',
                    gap: '10px'
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="createUserBtn"
                        startIcon={<West className="goBack"/>}
                        onClick={() => navigate(-1)}
                    >
                        {t("common.goBack")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="createUserBtn"
                        endIcon={<Add className="addIcon"/>}
                        onClick={handleOpenCreateModal}
                    >
                        {t("schedulePage.addStopPoint")}
                    </Button>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        {
                            `Рейси / ${oneRace?.raceNum ?? ''} 
                            ${getTranslateValue(oneRace?.fromLocationCity, "ua") ?? ''} - 
                            ${getTranslateValue(oneRace?.toLocationCity, "ua") ?? ''} 
                            / Поїздка: ${
                                (currentTrip?.departureDate && currentTrip?.arrivalDate)
                                    ? (formatDate(currentTrip?.departureDate, true) + ' - ' + formatDate(currentTrip?.arrivalDate, true))
                                    : ''
                            } / Розклад поїздки`
                        }
                    </Typography>
                </Box>

                <TableContainer>
                    <Table
                        stickyHeader
                        sx={{
                            maxHeight: "calc(100vh - 250px)",
                            width: "100%",
                            maxWidth: "100%",
                            overflow: "scroll",
                            mt: 1,
                        }}
                    >
                        <TableHead>
                            <TripScheduleTabelHeader/>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <LoadingTableData rows={5} columns={12}/>
                            ) : (
                                currentTrip?.raceStops?.map((stop: IRaceStop, index: number) => {
                                    return (
                                        <TripScheduleTabelRow stop={stop} key={`race-stop-${index}`}/>
                                    );
                                })
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {
                    /*
                    * <TablePaginationCustom
                    getData={getAllRacesApiHandler}
                    meta={racesMeta}
                  />
                    * */
                }
            </Grid>
        </Grid>
    );
};

export default TripScheduleTabel;
