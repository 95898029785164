import {create} from "zustand";

import {IMeta} from "@/interfaces/general";
import {IDriver} from "@/interfaces/dirvers";
import {news_api} from "@/api/news.api";

interface IStore {
    news: IDriver[];
    oneNew: IDriver;
    newsMeta: IMeta;
    filter: any;
    setFilter: (filter: any) => void;
    isLoading: boolean;
    createNew: {
        isOpenModal: boolean;
        handleOpenModal: () => void;
        handleCloseModal: () => void;
        createNewApiHandler: (body: Partial<IDriver>) => Promise<any>;
    };
    editNew: {
        isOpenModal: boolean;
        editedItem: IDriver | null;
        handleOpenModal: (item: IDriver) => void;
        handleCloseModal: () => void;
        editNewApiHandler: (id: string, body: Partial<IDriver>) => Promise<any>;
    };
    photoNew: {
        isOpenModal: boolean;
        editedItem: IDriver | null;
        handleOpenModal: (item: IDriver) => void;
        handleCloseModal: () => void;
        deletePhotoNew: (id: string, fileName: string) => Promise<any>;
        uploadPhotoNew: (id: string, body: any) => Promise<any>;
    };
    deleteNew: {
        isOpenModal: boolean;
        deletedItem: IDriver | null;
        handleOpenModal: (item: IDriver) => void;
        handleCloseModal: () => void;
        deleteNewApiHandler: (id: string) => Promise<any>;
    };
    getAllNews: ({count, page}: IMeta, filter: any) => Promise<any>;
    getNewById: (id: string) => Promise<any>;
}

export const newsStore = create<IStore>((set) => ({
    news: [],
    oneNew: {} as IDriver,
    filter: {},
    setFilter: (filter) => {
        set((state) => ({
            ...state,
            filter,
        }));
    },
    newsMeta: {
        count: 10,
        page: 1,
        total: 0,
    },
    isLoading: false,

    createNew: {
        isOpenModal: false,
        handleOpenModal: () => {
            set((state) => ({
                ...state,
                createNew: {
                    ...state.createNew,
                    isOpenModal: true,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                createNew: {
                    ...state.createNew,
                    isOpenModal: false,
                },
            }));
        },
        createNewApiHandler: async (body: Partial<IDriver>) => {
            const response = await news_api.createNew(body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 201) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },

    editNew: {
        isOpenModal: false,
        editedItem: null,
        handleOpenModal: (item: IDriver) => {
            set((state) => ({
                ...state,
                editNew: {
                    ...state.editNew,
                    isOpenModal: true,
                    editedItem: item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                editNew: {
                    ...state.editNew,
                    isOpenModal: false,
                    editedItem: null,
                },
            }));
        },
        editNewApiHandler: async (id, body) => {
            const response = await news_api.editNew(id, body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },
    photoNew: {
        isOpenModal: false,
        editedItem: null,
        handleOpenModal: (item: IDriver) => {
            set((state) => ({
                ...state,
                photoNew: {
                    ...state.photoNew,
                    isOpenModal: true,
                    editedItem: item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                photoNew: {
                    ...state.photoNew,
                    isOpenModal: false,
                    editedItem: null,
                },
            }));
        },
        deletePhotoNew: async (id, fileName) => {
            const response = await news_api.deletePhotoNew(id, fileName);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                set((state) => ({
                    ...state,
                    photoNew: {
                        ...state.photoNew,
                        editedItem: response.data,
                    },
                }));
                return response;
            }
        },
        uploadPhotoNew: async (id, body) => {
            const response = await news_api.uploadPhotoNew(id, body);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 201) {
                throw new Error(data);
            } else {
                set((state) => ({
                    ...state,
                    photoNew: {
                        ...state.photoNew,
                        editedItem: response.data,
                    },
                }));
                return response;
            }
        },
    },
    deleteNew: {
        isOpenModal: false,
        deletedItem: null,
        handleOpenModal: (item: IDriver) => {
            set((state) => ({
                ...state,
                deleteNew: {
                    ...state.deleteNew,
                    isOpenModal: true,
                    deletedItem: item,
                },
            }));
        },
        handleCloseModal: () => {
            set((state) => ({
                ...state,
                deleteNew: {
                    ...state.deleteNew,
                    isOpenModal: false,
                    deletedItem: null,
                },
            }));
        },
        deleteNewApiHandler: async (id) => {
            const response = await news_api.deleteNew(id);

            const {status, data} = response;

            if (!status || !data) {
                throw new Error("No status code or data returned from server.");
            }

            if (status !== 200) {
                throw new Error(data);
            } else {
                return response;
            }
        },
    },
    getAllNews: async ({count, page}: IMeta, filter) => {
        set((state) => ({...state, isLoading: true}));
        const response = await news_api.getAllNews({count, page}, filter);

        set((state) => ({...state, isLoading: false}));

        const {status, data} = response;

        if (!status || !data) {
            throw new Error("No status code or data returned from server.");
        }

        if (status !== 200) {
            throw new Error(data);
        } else {
            set((state) => ({
                ...state,
                news: data.data,
                newsMeta: data.meta,
            }));
        }
    },
    getNewById: async (id) => {
        const response = await news_api.getCurrentNew(id);

        const {status, data} = response;

        if (!status || !data) {
            throw new Error("No status code or data returned from server.");
        }

        if (status !== 200) {
            throw new Error(data);
        } else {
            set((state) => ({
                ...state,
                oneNew: data,
            }));
        }
    },
}));
