import {TableRow, TableCell, Skeleton} from "@mui/material";

interface IProps {
    rows: number;
    columns: number;
}

const LoadingTableData = ({rows, columns}: IProps) => {
    const convertNumberToArr = (num: number) => {
        const arr = [];
        for (let i = 0; i < num; i++) {
            arr.push(i);
        }
        return arr;
    };

    return (
        <>
            {convertNumberToArr(rows).map((row) => (
                <TableRow key={Math.random()}>
                    {convertNumberToArr(columns).map((column) => (
                        <TableCell align="left" key={Math.random()}>
                            <Skeleton variant="text" width={"100%"} height={50} animation="wave"/>
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );
};

export default LoadingTableData;
