import {usersStore} from "@/store/usersStore";

import {TablePagination} from "@mui/material";
import {useTranslation} from "react-i18next";

const UserTablePagination = () => {
    const {t} = useTranslation();
    const i18nTranslatePaginationString = "usersPage.userTable.pagination";

    const {meta, getUsers, filterQuery} = usersStore();

    const {total, page, rowsPerPage} = meta;

    const handleChangePage = (
        e: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        getUsers(
            {
                page: newPage + 1,
                count: rowsPerPage || 10,
                rowsPerPage: rowsPerPage,
            },
            filterQuery
        );
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        getUsers(
            {
                page: 1,
                count: +event.target.value,
                rowsPerPage: +event.target.value,
            },
            filterQuery
        );
    };

    return (
        <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            style={{paddingRight: "70px"}}
            count={total || 0}
            labelRowsPerPage={t(`${i18nTranslatePaginationString}.rowsPerPage`)}
            rowsPerPage={rowsPerPage || 10}
            page={page - 1 || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
                color: "primary.main",
            }}
        />
    );
};

export default UserTablePagination;
