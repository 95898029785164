import { tripRequestsStore } from '@/store/tripRequestsStore'
import { TablePagination } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const TripItemUsersPagination = () => {
  const { t } = useTranslation()
  const i18nTranslatePaginationString = 'usersPage.userTable.pagination'

  const { tripId } = useParams()

  const { tripRequestsMeta, getTripRequests, searchParams } = tripRequestsStore()

  const { page, count, total } = tripRequestsMeta

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (tripId) {
      getTripRequests({
        status: 'booked',
        trip_id: Number(tripId),
      })
    }
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    getTripRequests({
      status: 'booked',
      trip_id: Number(tripId),
    })
  }

  return (
    <TablePagination
      rowsPerPageOptions={[10, 20, 50, 100]}
      component="div"
      style={{ paddingRight: '70px' }}
      count={total || 0}
      labelRowsPerPage={t(`${i18nTranslatePaginationString}.rowsPerPage`)}
      rowsPerPage={count || 10}
      page={page - 1 || 0}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        color: 'primary.main',
      }}
    />
  )
}

export default TripItemUsersPagination
