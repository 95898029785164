import React, { useEffect, useState } from 'react'

import { Typography, Button, Box, Grid, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { tripRequestsStore } from '@/store/tripRequestsStore'
import { LoadingButton } from '@mui/lab'
import { inputsChanger } from '@/utils/inputsChanger'
import { useParams } from 'react-router-dom'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { tripsStore } from '@/store/tripStore'
import { messageStore } from '@/store/store'
import { ITripDto } from '@/interfaces/tripRequests'
import { townStore } from '@/store/townStore'

const initialState: ITripDto = {
  pib: '',
  phone: '',
  connectionType: 'Call',
  connectPhoneNumber: '',
  baggageAmount: 0,
  baggageAmountKg: 0,
  departureType: 'fixedPlace',
  departurePlace: '',
  arrivalPlaceType: 'fixedPlace',
  arrivalPlace: '',
  adultPassengerAmount: 1,
  childPassengerAmount: 0,
  childMiddlePassengerAmount: 0,
  note: '',
  fromLocationCity: null,
  raceNumber: 0,
  toLocationCity: null,
  price: 0,
  departureDate: '',
  arrivalDate: '',
  trip_id: 0,
}

const TripItemCreateModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentTrip, setCurrentTrip] = useState<any>(null)
  const [disembarkingStops, setDisembarkingStops] = useState<any>([])
  const [boardingStops, setBoardingStops] = useState<any>([])
  const [disembarkingCities, setDisembarkingCities] = useState<any>([])
  const [boardingCities, setBoardingCities] = useState<any>([])

  const { tripId } = useParams<{ tripId: string }>()

  const { createModal, createTripRequest, editTripRequest, getTripRequests } = tripRequestsStore()
  const { getAllTowns, towns, townMeta } = townStore()

  const { getAllTrips, trips } = tripsStore()

  const [formData, setFormData] = useState(initialState)

  const { addMessage } = messageStore()

  const getAllTripsApiHandler = useAsyncWrapper(getAllTrips, 'getAllTripsApiHandler')
  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')
  const createTripRequestApiHandler = useAsyncWrapper(createTripRequest, 'createTripRequestApiHandler')
  const editTripRequestApiHandler = useAsyncWrapper(editTripRequest, 'editTripRequestApiHandler')

  const { isOpen, handleClose } = createModal

  const handleInputChange = inputsChanger('input', formData, setFormData)
  const selectChangeHandler = inputsChanger('selectChanger', formData, setFormData) as (e: any) => void

  const handleCloseForm = () => {
    setFormData(initialState)
    handleClose()
  }

  const handleCreate = async () => {
    setIsLoading(true)

    const dataForCreation = {
      ...formData,
      baggageAmount: Number(formData.baggageAmount),
      adultPassengerAmount: Number(formData.adultPassengerAmount),
      childPassengerAmount: Number(formData.childPassengerAmount),
      childMiddlePassengerAmount: Number(formData.childMiddlePassengerAmount),
      departureDate: currentTrip?.departure_date,
      raceNumber: currentTrip?.race_number,
      arrivalDate: currentTrip?.departure_date,
      trip_id: currentTrip?.trip_id,
    }

    const { data } = await createTripRequestApiHandler([dataForCreation])

    const order_trip_id = data?.data?.data?.order_trip_id

    if (order_trip_id) {
      const { error: editTripRequestError } = await editTripRequestApiHandler([
        order_trip_id,
        {
          status: 'booked',
        },
      ])

      if (editTripRequestError) {
        setIsLoading(false)
        return
      }
    }

    addMessage({
      message: 'Бронювання створено успішно',
      severity: 'success',
      isOpen: true,
    })

    const { error: getAllTripRequestsError } = await getTripRequests({
      status: 'booked',
      trip_id: Number(tripId),
    })

    if (getAllTripRequestsError) {
      addMessage({
        isOpen: true,
        message: 'Помилка при завантаженні даних',
        severity: 'error',
      })
    }

    setIsLoading(false)
    handleCloseForm()
  }

  useEffect(() => {
    getAllTripsApiHandler([undefined, tripId])
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTownsError } = await getAllTownsApiHandler([
        {
          ...townMeta,
          count: 1000,
        },
      ])

      if (getAllTownsError) return
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (trips.length) {
      const currentTrip = trips[0]
      setCurrentTrip(currentTrip)

      // @ts-ignore
      const stopsObject = JSON.parse(currentTrip?.stops_along) || []

      const stops = stopsObject.stops

      if (stops?.length) {
        const disembarkingCities = stops
          .filter(
            (stop: any) =>
              stop.boardingStatus === 'DisembarkingOnly' || stop.boardingStatus === 'BoardingAndDisembarking'
          )
          .map((stop: any) => stop.city_id)

        const disembarkingCitiesData = towns.filter((town: any) => disembarkingCities.includes(town.city_id))

        setDisembarkingCities(disembarkingCitiesData)

        const boardingCities = stops
          .filter(
            (stop: any) => stop.boardingStatus === 'BoardingAndDisembarking' || stop.boardingStatus === 'BoardingOnly'
          )
          .map((stop: any) => stop.city_id)

        const boardingCitiesData = towns.filter((town: any) => boardingCities.includes(town.city_id))

        setBoardingCities(boardingCitiesData)
      }
    }
  }, [trips])

  useEffect(() => {
    if (trips.length) {
      const currentTrip = trips[0]

      // @ts-ignore
      const stopsObject = JSON.parse(currentTrip?.stops_along) || []

      const stops = stopsObject.stops

      if (stops?.length) {
        const boardingStops = stops.filter(
          (stop: any) =>
            (stop.boardingStatus === 'BoardingAndDisembarking' || stop.boardingStatus === 'BoardingOnly') &&
            stop.city_id === formData.fromLocationCity
        )

        setBoardingStops(boardingStops)

        const disembarkingStops = stops.filter(
          (stop: any) =>
            (stop.boardingStatus === 'DisembarkingOnly' || stop.boardingStatus === 'BoardingAndDisembarking') &&
            stop.city_id === formData.toLocationCity
        )

        setDisembarkingStops(disembarkingStops)
      }
    }
  }, [formData.fromLocationCity, formData.toLocationCity])

  return (
    <ModalContainer width={800} isOpen={isOpen} handleClose={handleCloseForm} title={'Створити нове бронювання'}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div" color="primary">
            Контактні дані
          </Typography>
          <TextField
            fullWidth
            label="ПІБ"
            variant="standard"
            name="pib"
            onChange={handleInputChange}
            value={formData.pib}
          />
          <TextField
            fullWidth
            label="Номер телефону"
            variant="standard"
            name="phone"
            onChange={handleInputChange}
            value={formData.phone}
          />
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color="primary"
            sx={{
              mt: 4,
            }}>
            Як з вами зв'язатися?
          </Typography>
          <FormControl fullWidth variant="standard">
            <InputLabel>Вид зв'язку</InputLabel>
            <Select
              value={formData.connectionType || ''}
              onChange={selectChangeHandler}
              name="connectionType"
              label="Країна">
              <MenuItem value="">
                <em>Виберіть тип зв'язку</em>
              </MenuItem>
              <MenuItem value="viber">
                <em>Viber</em>
              </MenuItem>
              <MenuItem value="telegram">
                <em>Telegram</em>
              </MenuItem>
              <MenuItem value="whatIsApp">
                <em>WhatsApp</em>
              </MenuItem>
              <MenuItem value="Call">
                <em>Звінок</em>
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Номер для зв'язку"
            variant="standard"
            name="connectPhoneNumber"
            onChange={handleInputChange}
            value={formData.connectPhoneNumber}
          />
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color="primary"
            sx={{
              mt: 4,
            }}>
            Кількість багажу
          </Typography>
          {currentTrip?.transportType === 'bus' ? (
            <FormControl fullWidth variant="standard">
              <InputLabel>Вага багажу</InputLabel>
              <Select
                value={formData.baggageAmount || ''}
                onChange={selectChangeHandler}
                name="baggageAmount"
                label="Країна">
                <MenuItem value="">
                  <em>Виберіть тип зв'язку</em>
                </MenuItem>
                <MenuItem value={21}>
                  <em>Більше 20кг</em>
                </MenuItem>
                <MenuItem value={19}>
                  <em>Менше 20кг</em>
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <TextField
              fullWidth
              label="Кількість багажу"
              variant="standard"
              name="baggageAmount"
              type="number"
              onChange={handleInputChange}
              value={formData.baggageAmount}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div" color="primary">
            Місце посадки
          </Typography>
          <FormControl fullWidth variant="standard">
            <InputLabel>Місто посадки</InputLabel>
            <Select
              value={formData.fromLocationCity || ''}
              onChange={selectChangeHandler}
              name="fromLocationCity"
              label="Країна">
              <MenuItem value="">
                <em>Виберіть місто</em>
              </MenuItem>
              {boardingCities.map((city: any) => (
                <MenuItem value={city.city_id}>{city.name_ukr}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {formData?.fromLocationCity ? (
            <>
              <FormControl fullWidth variant="standard">
                <InputLabel>Місце посадки</InputLabel>
                <Select
                  value={formData.departureType || ''}
                  onChange={selectChangeHandler}
                  name="departureType"
                  label="Країна">
                  <MenuItem value="">
                    <em>Виберіть тип посадки</em>
                  </MenuItem>
                  <MenuItem value="fixedPlace">
                    <em>Фіксоване місце посадки</em>
                  </MenuItem>
                  <MenuItem value="address">
                    <em>Адреса</em>
                  </MenuItem>
                </Select>
              </FormControl>
              {formData?.departureType === 'address' ? (
                <TextField
                  fullWidth
                  label="Адреса"
                  variant="standard"
                  name="departurePlace"
                  onChange={handleInputChange}
                  value={formData.departurePlace}
                />
              ) : (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Місце посадки</InputLabel>
                  <Select
                    value={formData.departurePlace || ''}
                    onChange={selectChangeHandler}
                    name="departurePlace"
                    label="Країна">
                    <MenuItem value="">
                      <em>Виберіть тип посадки</em>
                    </MenuItem>
                    {boardingStops.map((stop: any) => (
                      <MenuItem value={stop.stop_id}>{stop.stop_name_ua}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          ) : null}
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color="primary"
            sx={{
              mt: 4,
            }}>
            Місце висадки
          </Typography>
          <FormControl fullWidth variant="standard">
            <InputLabel>Місто висадки</InputLabel>
            <Select
              value={formData.toLocationCity || ''}
              onChange={selectChangeHandler}
              name="toLocationCity"
              label="Країна">
              <MenuItem value="">
                <em>Виберіть місто</em>
              </MenuItem>
              {disembarkingCities.map((city: any) => (
                <MenuItem value={city.city_id}>{city.name_ukr}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {formData?.toLocationCity ? (
            <>
              <FormControl fullWidth variant="standard">
                <InputLabel>Місце висадки</InputLabel>
                <Select
                  value={formData.arrivalPlaceType || ''}
                  onChange={selectChangeHandler}
                  name="arrivalPlaceType"
                  label="Країна">
                  <MenuItem value="">
                    <em>Виберіть тип висадки</em>
                  </MenuItem>
                  <MenuItem value="fixedPlace">
                    <em>Фіксоване місце висадки</em>
                  </MenuItem>
                  <MenuItem value="address">
                    <em>Адреса</em>
                  </MenuItem>
                </Select>
              </FormControl>
              {formData?.arrivalPlaceType === 'address' ? (
                <TextField
                  fullWidth
                  label="Адреса"
                  variant="standard"
                  name="arrivalPlace"
                  onChange={handleInputChange}
                  value={formData.arrivalPlace}
                />
              ) : (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Місце висадки</InputLabel>
                  <Select
                    value={formData.arrivalPlace || ''}
                    onChange={selectChangeHandler}
                    name="arrivalPlace"
                    label="Країна">
                    <MenuItem value="">
                      <em>Виберіть тип висадки</em>
                    </MenuItem>
                    {disembarkingStops.map((stop: any) => (
                      <MenuItem value={stop.stop_id}>{stop.stop_name_ua}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          ) : null}
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div" color="primary">
            Кількість пасажирів
          </Typography>
          <TextField
            fullWidth
            label="Дорослих"
            variant="standard"
            name="adultPassengerAmount"
            onChange={handleInputChange}
            value={formData.adultPassengerAmount}
          />
          <TextField
            fullWidth
            label="Дітей до 5 років"
            variant="standard"
            name="childPassengerAmount"
            onChange={handleInputChange}
            value={formData.childPassengerAmount}
          />
          {currentTrip?.transportType !== 'microBus' && (
            <TextField
              fullWidth
              label="дітей від 5 до 10 років"
              variant="standard"
              name="childMiddlePassengerAmount"
              onChange={handleInputChange}
              value={formData.childMiddlePassengerAmount}
            />
          )}

          <TextField
            fullWidth
            label="Додаткова інформація"
            variant="outlined"
            name="note"
            multiline
            sx={{
              mt: 1,
              p: 0.5,
            }}
            rows={4}
            onChange={handleInputChange}
            value={formData.note}
          />

          {/* <FormControl fullWidth variant="standard">
            <InputLabel>Статус заявки</InputLabel>
            <Select value={formData.status || ''} onChange={selectChangeHandler} name="status" label="Країна">
              <MenuItem value={'booked'}>
                <em>Заброньовано</em>
              </MenuItem>
              <MenuItem value={'pending'}>
                <em>На розгляді</em>
              </MenuItem>
            </Select>
          </FormControl> */}
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton color="primary" variant="contained" size="small" onClick={handleCreate} loading={isLoading}>
          Створити
        </LoadingButton>

        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Скасувати
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default TripItemCreateModal
