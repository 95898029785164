import {
    Box,
    Divider,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";

import {usersStore} from "@/store/usersStore";
import {useTranslation} from "react-i18next";
import UserNavigationRouting from "./UserNavigationRouting";

const UserNavigation = () => {
    const theme = useTheme();
    const {currentUser} = usersStore();

    const {t} = useTranslation();
    const langUserNavigationString = "userCabinet.userNavigation";


    return (
        <Paper
            sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
                p: 1,
            }}
        >
            {currentUser && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <Box
                            sx={{
                                mx: 1,
                            }}
                        >
                            <Typography variant="h6" color="primary" component="p">
                                {t(`${langUserNavigationString}.account`)} #
                                {currentUser.accountNumber}
                            </Typography>
                            <Typography
                                variant="h6"
                                component="p"
                                sx={{
                                    color: currentUser.online
                                        ? theme.palette.success.main
                                        : theme.palette.error.main,
                                }}
                            >
                                Status: {currentUser.online ? "Online" : "Offline"}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                mx: 1,
                            }}
                        >
                            <Typography variant="h6" component="p">
                                {" "}
                                {currentUser.email}
                            </Typography>
                            <Typography variant="h6" component="p">
                <span style={{textDecoration: "underline"}}>
                  {t(`${langUserNavigationString}.promoGroup`)}:
                </span>{" "}
                                {currentUser.promoGroup}
                            </Typography>
                        </Box>

                    </Box>

                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            mx: 1,
                        }}
                    />

                    <UserNavigationRouting/>
                </>
            )}
        </Paper>
    );
};

export default UserNavigation;
