// ? hooks
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { messageStore } from '@/store/store'
import { planningStore } from '@/store/planningStore'

// ? components
import { Box, Button, FormControl, InputLabel, TextField } from '@mui/material'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'
import { inputsChanger } from '@/utils/inputsChanger'
import { validateForm } from '@/utils/formValidation'

const CreateRacePlanningModal = () => {
  const { raceId } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const { planningMeta, getAllRacePlans, createPlanModal } = planningStore()
  const { isOpenModal, handleCloseModal } = createPlanModal

  const { addMessage } = messageStore()

  const [formValues, setFormValues] = useState<any>({
    startDate: '',
    raceId: raceId,
    note: '',
  })

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    startDate: { required: true },
    note: { required: false },
  }

  const createPlanApiHandler = useAsyncWrapper(createPlanModal.createPlanApiHandler, 'createPlanApiHandler')

  const getAllRacePlansApiHandler = useAsyncWrapper(getAllRacePlans, 'getAllRacePlansApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const createRacePlanningHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      return
    }

    const { error: createPlanError } = await createPlanApiHandler([{ ...formValues }])

    if (createPlanError) {
      setIsLoading(false)
      return
    }

    const { error: getRacePlansError } = await getAllRacePlans(raceId ?? '', planningMeta)

    if (getRacePlansError) {
      console.log(getRacePlansError)
    }

    setIsLoading(true)

    addMessage({
      severity: 'success',
      message: 'План змін успішно створено',
      isOpen: true,
    })

    handleCloseForm()
  }

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  return (
    <ModalContainer width={400} isOpen={isOpenModal} handleClose={handleCloseForm} title="Додати плановані зміни">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Початок дії змін</InputLabel>
            <TextField
              name="startDate"
              variant="standard"
              type="date"
              value={formValues?.startDate || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.fromDate}
              helperText={formErrors?.fromDate}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Примітка</InputLabel>
            <TextField
              name="note"
              variant="standard"
              value={formValues?.note || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            // loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createRacePlanningHandler}>
            Створити
          </LoadingButton>

          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateRacePlanningModal
