import {Typography} from '@mui/material';
import PageWrapper from '@/components/Wrappers/PageWrapper';
import PlanningScheduleTabel from '@/components/Tabels/PlanningScheduleTabel/PlanningScheduleTabel';

const PlanningSchedulePage = () => {
    return (
        <PageWrapper>
            {true ? (
                <PlanningScheduleTabel/>
            ) : (
                <Typography variant="h4" color="error">
                    You don&apos;t have permission to view traders
                </Typography>
            )}
        </PageWrapper>
    )
}

export default PlanningSchedulePage;