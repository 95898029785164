// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'
// ? stores
import { countryStore } from '@/store/countryStore'
import { messageStore } from '@/store/store'

// ? interfaces
import { Icountry } from '@/interfaces/country'

// ? validations
import { COUNTRY_VALIDATION } from '@/validation/country'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

const EditCountryModal = () => {
  const [formValues, setFormValues] = useState<Partial<Icountry>>({})
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { editCountry, getAllCountries, countryMeta } = countryStore()
  const { isOpenModal, handleCloseModal, editedItem } = editCountry

  // ? changers
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)

  // ? changers

  // ? api handlers
  const editCountryApiHandler = useAsyncWrapper(editCountry.editCountryApiHandler, 'editCountryApiHandler')

  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')
  // ? api handlers

  const editCountryValidation = useValidation(COUNTRY_VALIDATION.EDIT)

  const handleClose = () => {
    setFormValues({})
    handleCloseModal()
  }

  const editCountryHandler = async () => {
    setIsLoading(true)
    if (!editCountryValidation(formValues)) {
      setIsLoading(false)
      return
    }

    const data = {
      ...formValues,
      // isActive:
      //     typeof formValues?.isActive !== "undefined"
      //         ? formValues?.isActive.toString() === "true"
      //             ? true
      //             : false
      //         : editedItem?.isActive,
    }

    const { error: editCountryError } = await editCountryApiHandler([editedItem?.country_id, data])

    if (editCountryError) {
      setIsLoading(false)
      return
    }

    const { error: getCountriesError } = await getCountriesApiHandler([countryMeta])

    if (getCountriesError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно відредаговано',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleClose} title={'Редагування Країни'}>
      <TextField
        name="name_eng"
        onChange={inputChangeHandler}
        fullWidth
        value={formValues.name_eng || editedItem?.name_eng}
        label="Назва країни англійською"
        variant="standard"
      />

      <TextField
        name="name_ukr"
        onChange={inputChangeHandler}
        fullWidth
        label="Назва країни українською"
        value={formValues.name_ukr || editedItem?.name_ukr}
        variant="standard"
      />

      {/* <FormControl fullWidth variant="standard">
        <InputLabel>Статус</InputLabel>
        <Select
          defaultValue={editedItem?.isActive ? 'true' : 'false'}
          onChange={selectChangeHandler}
          name="isActive"
          label="Країна">
          <MenuItem value="true">
            <em>Активна</em>
          </MenuItem>
          <MenuItem value="false">
            <em>Деактивована</em>
          </MenuItem>
        </Select>
      </FormControl> */}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={editCountryHandler}>
          Відредагувати
        </LoadingButton>

        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default EditCountryModal
