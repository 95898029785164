// ? components
import { TableRow, TableCell } from '@mui/material'

const headerTitles = [
  {
    title: 'ID',
    name: 'id',
  },
  {
    title: 'Місто',
    name: 'name',
  },
  {
    title: 'Часовий пояс',
    name: 'timezone',
  },
  {
    title: 'Дії',
    name: 'actions',
  },
]

const TownTabelHeader = () => {
  return (
    <TableRow>
      {headerTitles?.length
        ? headerTitles.map(({ title, name }) => (
            <TableCell
              key={title}
              sx={{
                backgroundColor: '#222222 !important',
              }}>
              {title}
            </TableCell>
          ))
        : null}
    </TableRow>
  )
}

export default TownTabelHeader
