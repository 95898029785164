import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { messageStore } from '@/store/store'
import { tripRequestsStore } from '@/store/tripRequestsStore'
import { Typography, Button, Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

type TripItemDeclineModalProps = {
  status: string
}

const TripItemDeclineModal = ({ status }: TripItemDeclineModalProps) => {
  const { addMessage } = messageStore()
  const { tripId } = useParams<{ tripId: string }>()

  const { declineModal, searchParams, editTripRequest, getTripRequests, tripRequestsMeta } = tripRequestsStore()
  const { handleClose, isOpen, tripRequest } = declineModal

  const handleCloseForm = () => {
    handleClose()
  }

  const handleEditTripRequest = () => {
    if (tripRequest?.order_trip_id) {
      editTripRequest(tripRequest.order_trip_id, { status: 'canceled' })
        .then(() => {
          getTripRequests({
            trip_id: Number(tripId),
            status,
          })
            .then(() => {
              addMessage({
                isOpen: true,
                message: 'Бронювання відхилено',
                severity: 'success',
              })
              handleCloseForm()
            })
            .catch((err: any) => {
              console.log(err, 'err')
              addMessage({
                isOpen: true,
                message: 'Щось пішло не так',
                severity: 'error',
              })
            })
        })
        .catch((err: any) => {
          console.log(err, 'err')
          addMessage({
            isOpen: true,
            message: 'Щось пішло не так',
            severity: 'error',
          })
        })
    }
  }

  return (
    <ModalContainer width={800} isOpen={isOpen} handleClose={handleCloseForm} title={'Підтвердити бронювання'}>
      <Typography variant="subtitle1" color="text.primary">
        Ви впевнені що хочете відхилити бронювання?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <Button color="primary" variant="contained" size="small" onClick={handleEditTripRequest}>
          Так
        </Button>
        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Ні
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default TripItemDeclineModal
