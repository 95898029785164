import PageWrapper from "@/components/Wrappers/PageWrapper";
import AdminTabel from "@/components/Tabels/AdminTabel/AdminTabel";

const Admins = () => (
    <PageWrapper>
        <AdminTabel/>
    </PageWrapper>
);

export default Admins;
