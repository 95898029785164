// ? hooks
import React, { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { countryStore } from '@/store/countryStore'
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'

// ? components
import {
  Box,
  Button,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'

import { inputsChanger } from '@/utils/inputsChanger'
import { RaceRegularity, Weekdays } from '@/constants/constants'
import { validateForm } from '@/utils/formValidation'
import { town_api } from '@/api/town.api'
import { Itown } from '@/interfaces/town'
import { stops_api } from '@/api/stops.api'
import { IStop } from '@/interfaces/stop'
import { driversStore } from '@/store/driverStore'
import { transportStore } from '@/store/transportStore'
import { de } from 'date-fns/locale'

const CreateRaceModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [fromCity, setFromCity] = useState<Itown[]>()
  const [toCity, setToCity] = useState<Itown[]>()
  const [fromCityStops, setFromCityStops] = useState<IStop[]>([])
  const [toCityStops, setToCityStops] = useState<IStop[]>([])

  const { getAllRaces, createRace, racesMeta, createRaceModal } = racesStore()
  const { countries } = countryStore()

  const { isOpenModal, handleCloseModal } = createRaceModal

  const { addMessage } = messageStore()
  const { getAllCountries, countryMeta } = countryStore()
  const { drivers, getAllDrivers } = driversStore()
  const { getAllTransports, transports } = transportStore()

  const [formValues, setFormValues] = useState<any>({
    race_number: '',
    status: '0',
    departure_time: '',
    fromLocationCountry: '',
    departure_location: '',
    fromLocationCityStop: '',
    toLocationCountry: '',
    destination_location: '',
    toLocationCityStop: '',
    race_type: '',
    regularity: '',
    regularityValues: [],
    driver: '',
    vehicle_id: '',
    price: 0,
    currency: 'UAH',
    destination_time: '',
    expires_at: '',
    begins_at: '',
  })

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    race_number: { required: true },
    status: { required: true },
    departure_time: { required: true },
    departure_location: { required: true },
    fromLocationCityStop: { required: true },
    destination_location: { required: true },
    toLocationCityStop: { required: true },
    race_type: { required: true },
    regularity: { required: true },
    regularityValues: { notHaveCheckedValues: true },
    driver: { required: true },
    vehicle_id: { required: true },
    price: { required: true },
    currency: { required: true },
    destination_time: { required: true },
    expires_at: { required: true },
    begins_at: { required: true },
  }

  const [showWeekdaysFields, setShowWeekdaysFields] = useState<boolean>(false)

  const createRaceApiHandler = useAsyncWrapper(createRace, 'createRaceApiHandler')
  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')
  const getAllCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getAllCountriesApiHandler')
  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')
  const getAllTransportsApiHandler = useAsyncWrapper(getAllTransports, 'getAllTransportsApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const createRaceHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      setIsLoading(false)
      return
    }

    const departureStopNameUa = fromCityStops.find(
      (stop) => stop.bus_stop_id === formValues.fromLocationCityStop
    )?.bus_stop_name_ua

    const departureStopNameEn = fromCityStops.find(
      (stop) => stop.bus_stop_id === formValues.fromLocationCityStop
    )?.bus_stop_name_en

    const destinationStopNameUa = toCityStops.find(
      (stop) => stop.bus_stop_id === formValues.toLocationCityStop
    )?.bus_stop_name_ua

    const destinationStopNameEn = toCityStops.find(
      (stop) => stop.bus_stop_id === formValues.toLocationCityStop
    )?.bus_stop_name_en

    const stopsAlong = {
      stops: [
        {
          stop_id: formValues.fromLocationCityStop,
          city_id: formValues.departure_location,
          boardingStatus: 'BoardingOnly',
          departureTime: formValues.departure_time,
          stop_name_ua: departureStopNameUa,
          stop_name_en: departureStopNameEn,
          timezone: null,
          departureDay: 1,
          prices: [formValues.price],
        },
        {
          stop_id: formValues.toLocationCityStop,
          city_id: formValues.destination_location,
          boardingStatus: 'DisembarkingOnly',
          arrivalTime: formValues.destination_time,
          stop_name_ua: destinationStopNameUa,
          stop_name_en: destinationStopNameEn,
          arrivalDay: 1,
          timezone: null,
        },
      ],
    }

    console.log('stopsAlong', stopsAlong)
    const body = {
      ...formValues,
      destination_time: formValues.destination_time,
      stops_along: JSON.stringify(stopsAlong),
      destination_location: formValues.toLocationCityStop,
      departure_location: formValues.fromLocationCityStop,
      vehicle_id: Number(formValues.vehicle_id),
      driver: Number(formValues.driver),
      transplantation: formValues.race_type,
      price: Number(formValues.price),
      active: Number(formValues.status),
      regularity: formValues.regularityValues?.join(', '),
    }

    try {
      const { error: createRaceError } = await createRaceApiHandler([body])

      if (createRaceError) {
        setIsLoading(false)
        return
      }

      const { error: getAllRacesError } = await getAllRacesApiHandler([racesMeta])

      if (getAllRacesError) {
        setIsLoading(false)
        return
      }

      addMessage({
        severity: 'success',
        message: 'Рейс успішно створено',
        isOpen: true,
      })

      handleCloseForm()
    } catch (error) {
      console.error('Failed to create race', error)
      addMessage({
        severity: 'error',
        message: 'Не вдалося створити рейс',
        isOpen: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllCountriesError } = await getAllCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getAllCountriesError) return
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await town_api.getAllTown(formValues.fromLocationCountry)

      setFromCity(data.cities)
    }

    fetchData()
  }, [formValues?.fromLocationCountry])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await town_api.getAllTown(formValues.toLocationCountry)

      setToCity(data.cities)
    }

    fetchData()
  }, [formValues?.toLocationCountry])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await stops_api.getStops(formValues.departure_location)

      setFromCityStops(data.stops)
    }

    fetchData()
  }, [formValues?.departure_location])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await stops_api.getStops(formValues.destination_location)

      setToCityStops(data.stops)
    }

    fetchData()
  }, [formValues?.destination_location])

  useEffect(() => {
    if (formValues?.regularity === 'ByWeekdays') {
      setShowWeekdaysFields(true)
    } else {
      setShowWeekdaysFields(false)
    }
  }, [formValues?.regularity])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getDriversError } = await getDriversApiHandler([])

      if (getDriversError) {
        return false
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTransportsError } = await getAllTransportsApiHandler([])

      if (getAllTransportsError) {
        addMessage({
          severity: 'error',
          message: 'Помилка при завантаженні транспорту',
          isOpen: true,
        })
      }
    }

    fetchData()
  }, [])

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleCheckboxChange = (e: any) => {
    let name = e.target.name.split('-')
    if (name[0] === 'weekday') {
      let newArr = formValues.regularityValues ?? []
      if (e.target.checked) {
        newArr = [...newArr, name[1]]
      } else {
        newArr = newArr.filter((value: any) => value !== name[1])
      }
      setFormValues({
        ...formValues,
        regularityValues: newArr,
      })
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.checked })
    }
  }

  const handleRadioChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleCloseForm} title="Створення рейсу">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>№ рейсу</InputLabel>
          <TextField
            name="race_number"
            variant="standard"
            type="text"
            value={formValues?.race_number || ''}
            onChange={handleInputChange}
            sx={{
              mt: '16px',
            }}
            error={!!formErrors?.race_number}
            helperText={formErrors?.race_number}
          />
        </FormControl>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'flex-start',
            }}>
            <InputLabel shrink>Дійсний</InputLabel>
            <RadioGroup
              aria-label="status"
              name="status"
              value={formValues?.status || 'inactive'}
              onChange={handleRadioChange}
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-start',
              }}>
              <FormControlLabel value="1" control={<Radio />} label="Так" />
              <FormControlLabel value="0" control={<Radio />} label="Ні" />
            </RadioGroup>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Час відправлення</InputLabel>
            <TextField
              name="departure_time"
              variant="standard"
              type="time"
              value={formValues?.departure_time || '00:00'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.departure_time}
              helperText={formErrors?.departure_time}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Час прибуття</InputLabel>
            <TextField
              name="destination_time"
              variant="standard"
              type="time"
              value={formValues?.destination_time || '00:00'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.destination_time}
              helperText={formErrors?.destination_time}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Курсує з</InputLabel>
            <TextField
              name="begins_at"
              variant="standard"
              type="date"
              value={formValues?.begins_at || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.begins_at}
              helperText={formErrors?.begins_at}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Курсує по</InputLabel>
            <TextField
              name="expires_at"
              variant="standard"
              type="date"
              value={formValues?.expires_at || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.expires_at}
              helperText={formErrors?.expires_at}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.fromLocationCountry} fullWidth variant="standard">
            <InputLabel>Від країни</InputLabel>
            <Select
              value={formValues?.fromLocationCountry || 'initial'}
              name="fromLocationCountry"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {countries?.length
                ? countries.map((country) => {
                    return (
                      <MenuItem key={country.country_id} value={country.country_id}>
                        {country.name_ukr}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.fromLocationCountry && <FormHelperText>{formErrors?.fromLocationCountry}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.toLocationCountry} fullWidth variant="standard">
            <InputLabel>До країни</InputLabel>
            <Select
              value={formValues?.toLocationCountry || 'initial'}
              name="toLocationCountry"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {countries?.length
                ? countries.map((country) => {
                    return (
                      <MenuItem key={country.country_id} value={country.country_id}>
                        {country.name_ukr}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.toLocationCountry && <FormHelperText>{formErrors?.toLocationCountry}</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.departure_location} fullWidth variant="standard">
            <InputLabel>Від населеного пункту</InputLabel>
            <Select
              value={formValues?.departure_location || 'initial'}
              name="departure_location"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {fromCity?.length
                ? fromCity.map((town) => {
                    return (
                      <MenuItem key={town.city_id} value={town.city_id}>
                        {town.name_ukr}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.departure_location && <FormHelperText>{formErrors?.departure_location}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.destination_location} fullWidth variant="standard">
            <InputLabel>До населеного пункту</InputLabel>
            <Select
              value={formValues?.destination_location || 'initial'}
              name="destination_location"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {toCity?.length
                ? toCity.map((town) => {
                    return (
                      <MenuItem key={town.city_id} value={town.city_id}>
                        {town.name_ukr}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.destination_location && <FormHelperText>{formErrors?.destination_location}</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.fromLocationCityStop} fullWidth variant="standard">
            <InputLabel>Від зупинки</InputLabel>
            <Select
              value={formValues?.fromLocationCityStop || 'initial'}
              name="fromLocationCityStop"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {fromCityStops?.length
                ? fromCityStops.map((cityStop) => {
                    return (
                      <MenuItem key={cityStop.bus_stop_id} value={cityStop.bus_stop_id}>
                        {cityStop.bus_stop_name_ua}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.fromLocationCityStop && <FormHelperText>{formErrors?.fromLocationCityStop}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.toLocationCityStop} fullWidth variant="standard">
            <InputLabel>До зупинки</InputLabel>
            <Select
              value={formValues?.toLocationCityStop || 'initial'}
              name="toLocationCityStop"
              onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {toCityStops?.length
                ? toCityStops.map((cityStop) => {
                    return (
                      <MenuItem key={cityStop.bus_stop_id} value={cityStop.bus_stop_id}>
                        {cityStop.bus_stop_name_ua}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.toLocationCityStop && <FormHelperText>{formErrors?.toLocationCityStop}</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.race_type} fullWidth variant="standard">
            <InputLabel>Тип рейсу</InputLabel>
            <Select value={formValues?.race_type || 'initial'} name="race_type" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              <MenuItem value={1}>Рейс з автобільним перетином кордону (автобусом)</MenuItem>
              <MenuItem value={2}>Рейс з автомобільним перетином кордону (мінібусом)</MenuItem>
              <MenuItem value={3}>Рейс з пішим перетином кордону (автобусом)</MenuItem>
              <MenuItem value={4}>Рейс з пішим перетином кордону (мінібусом)</MenuItem>
            </Select>
            {formErrors?.race_type && <FormHelperText>{formErrors?.race_type}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.regularity} fullWidth variant="standard">
            <InputLabel>Регулярність</InputLabel>
            <Select value={formValues?.regularity || 'initial'} name="regularity" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {Object.entries(RaceRegularity).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.regularity && <FormHelperText>{formErrors?.regularity}</FormHelperText>}
          </FormControl>
        </Box>
        {showWeekdaysFields && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '5px',
            }}>
            <FormControl
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'row wrap',
                gap: '5px',
              }}
              error={!!formErrors?.regularityValues}>
              {Object.entries(Weekdays).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      name={`weekday-${key}`}
                      checked={formValues['regularityValues'].find((value: string) => value === key) ? true : false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={value}
                />
              ))}
              {formErrors?.regularityValues && (
                <FormHelperText sx={{ m: '0' }}>{formErrors?.regularityValues}</FormHelperText>
              )}
            </FormControl>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.driver} fullWidth variant="standard">
            <InputLabel>Водій</InputLabel>
            <Select value={formValues?.driver || 'initial'} name="driver" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {drivers?.length
                ? drivers.map((driver) => {
                    return (
                      <MenuItem key={driver.user_id} value={driver.user_id}>
                        {driver.pib}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.driver && <FormHelperText>{formErrors?.driver}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.vehicle_id} fullWidth variant="standard">
            <InputLabel>Транспорт</InputLabel>
            <Select value={formValues?.vehicle_id || 'initial'} name="vehicle_id" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {transports?.length
                ? transports.map((transport) => {
                    return (
                      <MenuItem key={transport.vehicle_id} value={transport.vehicle_id}>
                        {transport.vehicle_brand}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.vehicle_id && <FormHelperText>{formErrors?.vehicle_id}</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Ціна</InputLabel>
            <TextField
              name="price"
              variant="standard"
              type="number"
              value={formValues?.price || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.price}
              helperText={formErrors?.price}
            />
          </FormControl>
          <FormControl error={!!formErrors?.currency} fullWidth variant="standard">
            <InputLabel>Валюта</InputLabel>
            <Select value={formValues?.currency || 'initial'} name="currency" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              <MenuItem value={'UAH'}>UAH</MenuItem>
              <MenuItem value={'USD'}>USD</MenuItem>
              <MenuItem value={'EUR'}>EUR</MenuItem>
            </Select>
            {formErrors?.currency && <FormHelperText>{formErrors?.currency}</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createRaceHandler}>
            Створити
          </LoadingButton>
          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateRaceModal
