import { create } from 'zustand'

import { REQ } from '@/api'

const { users_api } = REQ

interface IUserStore {
  users: any[]
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  setUsers: (users: any[]) => void
  currentUser: any | null
  meta: any
  filterQuery: any
  setFilterQuery: (filterQuery: any) => void
  edit: {
    user: any | null
    isOpenModal: boolean
    setEditData: (user: any | null) => void
  }
  getUsers: (meta: any, filterQuery?: any | null | undefined) => Promise<any>
  getCurrentUser: (accountNumber: number) => Promise<any>
  setCurrentUser: (user: any) => void
  editCurrentUser: (accountNumber: number, body: Partial<any>) => Promise<any>
  isUserRacesModalOpen: boolean
  setIsUserRacesModalOpen: (isOpen: boolean) => void
}

export const usersStore = create<IUserStore>((set) => ({
  users: [],
  currentUser: null,
  setCurrentUser: (user) => {
    set((state) => ({ ...state, currentUser: user }))
  },
  isLoading: false,
  setIsLoading: (isLoading) => {
    set((state) => ({ ...state, isLoading }))
  },
  isUserRacesModalOpen: false,
  setIsUserRacesModalOpen: (isOpen) => {
    set((state) => ({ ...state, isUserRacesModalOpen: isOpen }))
  },
  setUsers: (users) => {
    set((state) => ({ ...state, users }))
  },
  meta: {
    total: 0,
    page: 1,
    rowsPerPage: 10,
  },
  filterQuery: {
    phone: '',
  },
  edit: {
    user: null,
    isOpenModal: false,
    setEditData: (user) => {
      if (user) {
        set((state) => ({
          ...state,
          edit: { ...state.edit, user, isOpenModal: true },
        }))
      } else {
        set((state) => ({
          ...state,
          edit: { ...state.edit, user: null, isOpenModal: false },
        }))
      }
    },
  },

  setFilterQuery: (filterQuery) => {
    set((state) => ({ ...state, filterQuery }))
  },
  getUsers: async (query, filterQuery) => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await users_api.getUsers(query, filterQuery)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        users: data.result,
        meta: {
          ...data.meta,
        },
        isLoading: false,
      }))
      return response
    }
  },
  getCurrentUser: async (accountNumber) => {
    const response = await users_api.getCurrentUser(accountNumber)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, currentUser: data }))
      return response
    }
  },
  editCurrentUser: async (accountNumber, body) => {
    const response = await users_api.editCurrentUser(accountNumber, body)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        currentUser: data,
        edit: { ...state.edit, isOpenModal: false },
      }))
      return response
    }
  },
}))
