import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Grid,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Switch,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  RadioGroup,
  Radio,
} from '@mui/material'
import { West } from '@mui/icons-material'
import { racesStore } from '@/store/racesStore'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { LoadingButton } from '@mui/lab'
import { tripsStore } from '@/store/tripStore'
import { townStore } from '@/store/townStore'
import { messageStore } from '@/store/store'
import './styles.css'
import { Currency, TariffType } from '@/constants/constants'
import { inputsChanger } from '@/utils/inputsChanger'
import { validateForm } from '@/utils/formValidation'
import { planningStore } from '@/store/planningStore'

const PlanningTariffs = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const params = useParams()
  const apiRef = useGridApiRef()

  const [isLoading, setIsLoading] = useState(false)
  const [isShowSegments, setIsShowSegments] = useState(false)

  const [rowsData, setRowsData] = useState<any[]>([])
  const [columnsData, setColumnsData] = useState<any[]>([])

  const { oneRace, getRaceById } = racesStore()
  const { onePlan, editPlan, editPlanModal, getPlanById } = planningStore()

  const { editedItem, handleCloseModal } = editPlanModal
  const { towns, townMeta, getAllTowns } = townStore()

  const { addMessage } = messageStore()

  const [formValues, setFormValues] = useState<any>({})

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    currency: { required: true },
    tariffType: { required: true },
    insuranceFee: { required: true },
  }

  const editPlanApiHandler = useAsyncWrapper(editPlanModal.editPlanApiHandler, 'editPlanApiHandler')

  const getRaceByIdApiHandler = useAsyncWrapper(getRaceById, 'getRaceByIdApiHandler')

  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  const editRaceTariffsHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      return
    }

    const data = {
      type: 'tariffs',
      tariffs: {
        currency: formValues?.currency,
        tariffType: formValues?.tariffType,
        insuranceFee: formValues?.insuranceFee,
        insuranceFeeType: formValues?.insuranceFeeType,
      },
      middleStep: formValues?.middleStep,
    }

    const { error: editPlanError } = await editPlanApiHandler([onePlan?._id, data])

    if (editPlanError) {
      setIsLoading(false)
      return
    }

    const { error: getPlanByIdError } = await getPlanById(params.planId ?? '')

    if (getPlanByIdError) {
      console.log(getPlanByIdError)
    }

    setIsLoading(false)

    addMessage({
      severity: 'success',
      message: 'Тарифи успішно оновлено',
      isOpen: true,
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getPlanByIdError } = await getPlanById(params.planId ?? '')

      if (getPlanByIdError) {
        console.log(getPlanByIdError)
      }

      const { error: getRaceByIdError } = await getRaceById(params.raceId ?? '')

      if (getRaceByIdError) {
        console.log(getRaceByIdError)
      }

      const { error: getAllTownsError } = await getAllTownsApiHandler([
        {
          ...townMeta,
          count: 1000,
        },
      ])

      if (getAllTownsError) return
    }

    fetchData()
  }, [])

  //   useEffect(() => {
  //     editPlanModal.handleOpenModal(onePlan)

  //     const middleStep = onePlan?.raceData?.middleStep ? onePlan?.raceData?.middleStep : oneRace?.middleStep;

  //     setFormValues({
  //         currency: onePlan?.raceData?.currency ? onePlan?.raceData?.currency : oneRace?.currency,
  //         tariffType: onePlan?.raceData?.tariffType ? onePlan?.raceData?.tariffType : oneRace?.tariffType,
  //         insuranceFee: onePlan?.raceData?.insuranceFee ? onePlan?.raceData?.insuranceFee : oneRace?.insuranceFee,
  //         insuranceFeeType: onePlan?.raceData?.insuranceFeeType ? onePlan?.raceData?.insuranceFeeType : oneRace?.insuranceFeeType,
  //         middleStep: middleStep
  //     });

  //     console.log('onePlan?.raceData', onePlan?.raceData)

  //     const fromCities = Array.from(new Set(middleStep?.map((item: any) => getTranslateValue(item.departureCity, "ua"))));
  //     const toCities = Array.from(new Set(middleStep?.map((item: any) => getTranslateValue(item.arrivalCity, "ua"))));

  //     const columns = [
  //       { field: 'fromLocationCity', headerName: 'від  \\  до', width: 300, editable: false, sortable: false },
  //       ...toCities.map((city: any) => ({
  //         field: city.replace(/ /g, ''),
  //         headerName: city,
  //         width: 100,
  //         editable: true,
  //         sortable: false,
  //       })),
  //     ]

  //     setColumnsData(columns)

  //     let availableCities: any = []
  //     const rows: any[] = fromCities.map((fromCity: any, index) => {
  //       const row: any = { id: index, fromLocationCity: fromCity }
  //       toCities.forEach((toCity: any) => {
  //         const route = middleStep.find(
  //           (item: any) =>
  //             getTranslateValue(item.departureCity, 'ua') === fromCity &&
  //             getTranslateValue(item.arrivalCity, 'ua') === toCity
  //         )

  //         if (!availableCities.includes(toCity.replace(/ /g, ''))) {
  //           availableCities.push(toCity.replace(/ /g, ''))
  //         }

  //         if (
  //           fromCity.replace(/ /g, '') === toCity.replace(/ /g, '') ||
  //           availableCities.includes(toCity.replace(/ /g, ''))
  //         ) {
  //           row[toCity.replace(/ /g, '')] = route ? route.price : null
  //         } else {
  //           row[toCity.replace(/ /g, '')] = route ? route.price : 0
  //         }
  //       })
  //       return row
  //     })

  //     setRowsData(rows)
  //   }, [oneRace, onePlan])

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleRadioChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const handleSwitchChange = (event: any) => {
    setIsShowSegments(event.target.checked)
  }

  const processCityStopUpdate = (newRow: any) => {
    const updatedRow = { ...newRow }
    console.log('updatedRow', updatedRow)
    //setCityStops(cityStops.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
    let newMiddleSteps: any[] = []
    Object.keys(updatedRow).forEach((key) => {
      newMiddleSteps = formValues.middleStep?.map((item: any) => {
        console.log('updatedRow t', getTranslateValue(item.arrivalCity, 'ua').replace(/ /g, ''), key)
        if (
          getTranslateValue(item.departureCity, 'ua') === updatedRow.fromLocationCity &&
          getTranslateValue(item.arrivalCity, 'ua').replace(/ /g, '') === key
        ) {
          item.price = updatedRow[getTranslateValue(item.arrivalCity, 'ua').replace(/ /g, '')]
        }
        return item
      })
    })

    setFormValues({
      ...formValues,
      middleStep: newMiddleSteps,
    })

    return updatedRow
  }

  console.log('formValues', rowsData)

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      <Grid item xs={12}>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            align: 'center',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            startIcon={<West className="goBack" />}
            onClick={() => navigate(-1)}>
            {t('common.goBack')}
          </Button>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`Рейси / ${oneRace?.race_number ?? ''} 
                            ${oneRace?.departure_location} - 
                            ${oneRace?.destination_location} 
                            / Планування / Тарифи`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '20px',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'row wrap',
              justifyContent: 'space-between',
              gap: '20px',
            }}>
            {/*<FormControlLabel*/}
            {/*    control={*/}
            {/*        <Switch*/}
            {/*            checked={isShowSegments}*/}
            {/*            onChange={handleSwitchChange}*/}
            {/*        />*/}
            {/*    }*/}
            {/*    label="Показати приховані сегменти"*/}
            {/*/>*/}
          </Box>

          <Box
            sx={{
              maxWidth: '700px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '20px',
            }}>
            <FormControl error={!!formErrors?.currency} fullWidth variant="standard">
              <InputLabel>Валюта</InputLabel>
              <Select value={formValues?.currency || 'euro'} name="currency" onChange={handleChangeSelect}>
                {Object.entries(Currency).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {formErrors?.currency && <FormHelperText>{formErrors?.currency}</FormHelperText>}
            </FormControl>

            <FormControl error={!!formErrors?.tariffType} fullWidth variant="standard">
              <InputLabel>Фіксований</InputLabel>
              <Select value={formValues?.tariffType || 'fixed'} name="tariffType" onChange={handleChangeSelect}>
                {Object.entries(TariffType).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {formErrors?.tariffType && <FormHelperText>{formErrors?.tariffType}</FormHelperText>}
            </FormControl>
          </Box>

          <Box
            sx={{
              maxWidth: '700px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px',
            }}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Страховий збір</InputLabel>
              <TextField
                name="insuranceFee"
                variant="standard"
                type="number"
                value={formValues?.insuranceFee || 'initial'}
                onChange={handleInputChange}
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.insuranceFee}
                helperText={formErrors?.insuranceFee}
              />
            </FormControl>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-start',
              }}>
              <RadioGroup
                aria-label="insuranceFeeType"
                name="insuranceFeeType"
                value={formValues?.insuranceFeeType || 'percentage'}
                onChange={handleRadioChange}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-start',
                }}>
                <FormControlLabel value="percentage" control={<Radio />} label="в %" />
                <FormControlLabel value="fixed" control={<Radio />} label="фіксований" />
              </RadioGroup>
            </Box>
          </Box>

          <Box
            sx={{
              mt: '30px',
              width: '100%',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
            }}>
            <InputLabel shrink>Таблиця вартості проїзду</InputLabel>
            <Box
              sx={{
                width: 'max-content',
                display: 'flex',
                justifyContent: 'flex-start',
              }}>
              <DataGrid
                apiRef={apiRef}
                rows={rowsData}
                columns={columnsData}
                localeText={{ noRowsLabel: 'Маршрутів поки немає' }}
                getRowId={(row: any) => row.id}
                processRowUpdate={processCityStopUpdate}
                editMode="row"
                disableColumnMenu
                hideFooterSelectedRowCount
                autoHeight={true}
                className="tariffsTabel"
                sx={{
                  width: 'max-content',
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              mt: 1,
            }}>
            <LoadingButton
              // loading={isLoading}
              color="primary"
              variant="contained"
              size="small"
              onClick={editRaceTariffsHandler}>
              Зберегти
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PlanningTariffs
