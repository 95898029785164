import {messageStore} from "@/store/store";

export default function useAsyncWrapper(fn: Function, type: string) {
    const {addMessage} = messageStore();
    const runFn = async (args: any[], hideMessage?: boolean) => {
        if (typeof fn !== "function") {
            throw new Error("Function is required");
        }

        let loading: boolean = true;
        let error: any = null;
        let data: any = null;

        await fn(...args)
            .then((response: any) => {
                data = response;
            })
            .catch((error: any) => {
                console.log("ERROR:", error, fn);
                if (!hideMessage) {
                    if (error?.response) {
                        addMessage({
                            severity: "error",
                            message:
                                `${error.response.data.statusCode} | ${error.response.data.message}` ||
                                "Something went wrong",
                            isOpen: true,
                        });
                    } else {
                        addMessage({
                            severity: "error",
                            message: `${error.statusCode} | ${error.message}` || "Something went wrong",
                            isOpen: true,
                        });
                    }

                }
            })
            .finally(() => {
                loading = false;
                if (window.location.search.includes("debug")) {
                    console.log("====================================");
                    console.log(type.toUpperCase());
                    console.log(loading, error, data);
                    console.log("====================================");
                }
            });

        return {loading, error, data};
    };

    return runFn;
}
