// ? hooks
import { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store

// ? components
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'

import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

import StopsTableHeader from './StopsTabelHeader'
import StopTabelRow from './StopsTabelRow'

import CreateStopModal from './modals/CreateStopModal'
import EditStopModal from './modals/EditStopModal'
import DeleteStopModal from './modals/DeleteStopModal'

// ? icons
import { Add } from '@mui/icons-material'
import { IStop } from '@/interfaces/stop'
import { stopsStore } from '@/store/stopsStore'
import { townStore } from '@/store/townStore'

const StopsTabel = () => {
  const { createStop, deleteStop, editStop, getAllStops, isLoading, stops } = stopsStore()
  const { getAllTowns } = townStore()

  const getAllStopsHandler = useAsyncWrapper(getAllStops, 'getAllStopsHandler')
  const getAllTownsHandler = useAsyncWrapper(getAllTowns, 'getAllTownsHandler')

  const handleOpenCreateModal = () => {
    createStop.handleOpenModal()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getStopError } = await getAllStopsHandler([])

      if (getStopError) {
        return false
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getTownsError } = await getAllTownsHandler([])

      if (getTownsError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createStop.isOpenModal && <CreateStopModal />}
      {editStop.isOpenModal && <EditStopModal />}
      {deleteStop.isOpenModal && <DeleteStopModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Створити нову зупинку
          </Button>
        </Box>
        {stops?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <StopsTableHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  stops.map((stop: IStop) => <StopTabelRow stop={stop} key={stop.bus_stop_id} />)
                ) : (
                  <LoadingTableData columns={5} rows={4} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Створити нову зупинку
            </Button>
          </Box>
        )}
        {/* {stops && stops?.length !== 0 && <TablePaginationCustom getData={getCountriesApiHandler} meta={countryMeta} />} */}
      </Grid>
    </Grid>
  )
}
export default StopsTabel
