import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { IRace } from '@/interfaces/races'
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'
import { formatDateString } from '@/utils/formatDate'
import { LoadingButton } from '@mui/lab'
import { Button, Box, Typography } from '@mui/material'
import React, { useState } from 'react'

const DeleteRaceStopModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { deleteRaceStop } = racesStore()
  const { addMessage } = messageStore()

  const { deletedItem, isOpenModal, handleCloseModal } = deleteRaceStop
  const { getAllRaces, races, setOneRace, editRace, oneRace } = racesStore()

  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')
  const editRaceApiHandler = useAsyncWrapper(editRace.editRaceApiHandler, 'editRaceApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const deleteRaceHandler = async () => {
    setIsLoading(true)

    if (!deletedItem?.stop_name_ua) {
      setIsLoading(false)
      return
    }

    const filteredStops = oneRace?.stops_along.stops.filter(
      (stop: any) => stop.stop_name_ua !== deletedItem.stop_name_ua
    )

    const { error: editRaceError } = await editRaceApiHandler([
      deletedItem.flight_id,
      {
        ...deletedItem,
        stops_along: JSON.stringify({ stops: filteredStops }),
        begins_at: formatDateString(deletedItem.begins_at),
        expires_at: formatDateString(deletedItem.expires_at),
      },
    ])

    if (editRaceError) {
      console.log(editRaceError)
    }

    const { error: getAllRacesError } = await getAllRacesApiHandler([])

    if (getAllRacesError) {
      console.log(getAllRacesError)
    }

    setOneRace({
      ...oneRace,
      stops_along: {
        ...oneRace.stops_along,
        stops: filteredStops,
      },
    })

    setIsLoading(true)

    addMessage({
      severity: 'success',
      message: 'Зупинку успішно видалено',
      isOpen: true,
    })

    handleCloseForm()
  }

  console.log('Loading...', isLoading)

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleCloseForm} title="Видалити зупинку">
      <Typography variant="subtitle1" color="text.primary"></Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton loading={isLoading} color="primary" variant="contained" size="small" onClick={deleteRaceHandler}>
          Видалити
        </LoadingButton>

        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Скасувати
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeleteRaceStopModal
