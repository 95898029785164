import {IMeta} from "@/interfaces/general";
import {TablePagination} from "@mui/material";
import React from "react";

interface IProps {
    getData: ([]) => void;
    meta: IMeta;
    filterQuery?: any;
}

const TablePaginationCustom = ({getData, meta, filterQuery}: IProps) => {
    const {total, page, count} = meta;

    const handleChangePage = (
        e: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        getData([
            {
                page: newPage + 1,
                count: count || 10,
                rowsPerPage: count,
            },
            filterQuery,
        ]);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        getData([
            {
                page: 1,
                count: +event.target.value,
                rowsPerPage: +event.target.value,
            },
            filterQuery,
        ]);
    };

    return (
        <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            style={{paddingRight: "70px"}}
            count={total || 0}
            labelRowsPerPage="Кількість рядків"
            rowsPerPage={count || 10}
            page={page - 1 || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
                color: "primary.main",
            }}
        />
    );
};

export default TablePaginationCustom;
