export const isObjectValuesEmpty = (filterQuery: any) => {
    if (filterQuery === null || filterQuery === undefined) {
        return true;
    }
    if (Object.keys(filterQuery).length === 0) {
        return true;
    }
    const result = Object.values(filterQuery).every((x) => {
        return isObjectEmpty(x);
    });

    return result;
};

const isObjectEmpty = (x: any) => {
    if (x === null || x === undefined || x === "") {
        return true;
    } else if (typeof x === "object") {
        return Object.values(x).every((y) => {
            if (y === null || y === undefined || y === "") {
                return true;
            } else {
                return false;
            }
        });
    }
    return false;
};
