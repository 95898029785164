import { ITransport } from '@/interfaces/transport'
import { transportStore } from '@/store/transportStore'
import { Edit, Delete } from '@mui/icons-material'
import { TableRow, TableCell, Typography, IconButton, Tooltip, Button } from '@mui/material'
import React from 'react'

interface IProps {
  transport: ITransport
}

const TRANSPORT_TYPES = {
  bus: 'Автобус',
  microBus: 'Микроавтобус',
} as any

const TransportsTabelRow = ({ transport }: IProps) => {
  const { editTransport, deleteTransport, photosTransport } = transportStore()

  const handleEditTransport = (transport: ITransport) => {
    editTransport.handleOpenModal(transport)
  }

  const handleDeleteTransport = (transport: ITransport) => {
    deleteTransport.handleOpenModal(transport)
  }

  const handlePhotosTransport = (transport: ITransport) => {
    photosTransport.handleOpenModal(transport)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {TRANSPORT_TYPES[transport.vehicle_type]}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {transport.vehicle_brand}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {transport.vehicle_license_plates}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {transport.vehicle_capacity}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {transport?.vehicle_amenities}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          <Button variant="contained" color="primary" onClick={() => handlePhotosTransport(transport)}>
            Переглянути
          </Button>
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={() => handleEditTransport(transport)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteTransport(transport)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default TransportsTabelRow
