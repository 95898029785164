import TownTabel from '@/components/Tabels/TownTabel/TownTabel'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import {Typography} from '@mui/material'

const Town = () => {
    return (
        <PageWrapper>
            {true ? (
                <TownTabel/>
            ) : (
                <Typography variant='h4' color='error'>
                    You don&apos;t have permission to view Town
                </Typography>
            )}
        </PageWrapper>
    )
}

export default Town

