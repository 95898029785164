import ModalContainer from "@/components/UI/Modal/ModalContainer";
import {newsStore} from "@/store/newsStore";
import {inputsChanger} from "@/utils/inputsChanger";
import {LoadingButton} from "@mui/lab";
import {
    TextField,
    Box,
    Select,
    MenuItem,
    FormControlLabel,
    Button,
    Switch,
} from "@mui/material";
import React, {useState} from "react";

const EditNewsModal = () => {
    const {editNew, getAllNews, newsMeta, filter} = newsStore();
    const {isOpenModal, handleCloseModal, editedItem, editNewApiHandler} =
        editNew;

    const [formValues, setFormValues] = useState<any>({});

    const [isLoading, setIsLoading] = useState(false);

    const handleChangeInput = inputsChanger("input", formValues, setFormValues);

    const handleChangeSelect = inputsChanger(
        "selectChanger",
        formValues,
        setFormValues
    );
    const handleChangeSwitch = inputsChanger(
        "switchChanger",
        formValues,
        setFormValues
    );

    const editNewsHandler = async () => {
        if (!editedItem?._id) return;

        setIsLoading(true);
        await editNewApiHandler(editedItem?._id, {
            ...formValues,
        });
        await getAllNews(newsMeta, filter);
        setIsLoading(false);
        handleCloseModal();
        setFormValues({});
    };

    return (
        <ModalContainer
            width={1000}
            isOpen={isOpenModal}
            handleClose={handleCloseModal}
            title="Створення новини"
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "80vh",
                    overflow: "auto",
                    p: 1,
                }}
            >
                <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    label="Заголовок"
                    onChange={handleChangeInput}
                    name="title"
                    variant="outlined"
                    value={formValues.title || editedItem?.title}
                    sx={{mt: 2}}
                />

                <TextField
                    fullWidth
                    minRows={3}
                    multiline
                    label="Опис"
                    onChange={handleChangeInput}
                    sx={{mt: 2}}
                    name="description"
                    value={formValues.description || editedItem?.description}
                    variant="outlined"
                />

                <Select
                    fullWidth
                    name="language"
                    onChange={handleChangeSelect}
                    value={formValues.language || editedItem?.language}
                    variant="standard"
                    sx={{
                        mt: 1,
                    }}
                >
                    <MenuItem value="UA">UA</MenuItem>
                    <MenuItem value="EN">EN</MenuItem>
                </Select>

                <Select
                    fullWidth
                    name="type"
                    onChange={handleChangeSelect}
                    variant="standard"
                    value={formValues.type || editedItem?.type}
                    sx={{
                        mt: 1,
                    }}
                >
                    <MenuItem value="news">Новина</MenuItem>
                    <MenuItem value="discount">Знижка</MenuItem>
                </Select>

                <Select
                    fullWidth
                    name="entity"
                    variant="standard"
                    onChange={handleChangeSelect}
                    value={formValues.entity || editedItem?.entity}
                    sx={{
                        mt: 1,
                    }}
                >
                    <MenuItem value="passengerTransportation">
                        Пасажирські перевезення
                    </MenuItem>
                    <MenuItem value="cargoTransportation">Вантажні перевезення</MenuItem>
                </Select>


                <Select
                    fullWidth
                    name="isHidden"
                    variant="standard"
                    onChange={handleChangeSelect}
                    value={formValues.isHidden || editedItem?.isHidden}
                    sx={{
                        mt: 1,
                    }}
                >
                    <MenuItem value="false">
                        Активно
                    </MenuItem>
                    <MenuItem value="true">
                        Сховано
                    </MenuItem>
                </Select>


                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={editNewsHandler}
                    >
                        Зберегти
                    </LoadingButton>
                    <Button
                        onClick={handleCloseModal}
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{ml: 1}}
                    >
                        Закрити
                    </Button>
                </Box>
            </Box>
        </ModalContainer>
    );
};

export default EditNewsModal;
