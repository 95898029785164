import {TableRow, TableCell} from "@mui/material";
import React from "react";


const headerTitles = [
    {
        title: "ID",
        name: "id",
    },
    {
        title: "Ім'я",
        name: "name",
    },
    {
        title: "Телефон",
        name: "translations",
    },
    {
        title: "Маршрут",
        name: "isActive",
    },
    {
        title: "Створено",
        name: "createdAt",
    },
];

const ConsultationTabelHeader = () => {
    return (
        <TableRow>
            {headerTitles?.length
                ? headerTitles.map(({title, name}) => (
                    <TableCell
                        key={title}
                        sx={{
                            backgroundColor: "#222222 !important",
                        }}
                    >
                        {title}
                    </TableCell>
                ))
                : null}
        </TableRow>
    );
};

export default ConsultationTabelHeader;
