import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { transportStore } from '@/store/transportStore'
import { Button, Box, useTheme, Paper, MobileStepper, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import { Delete, KeyboardArrowLeft, KeyboardArrowRight, Upload } from '@mui/icons-material'
import { messageStore } from '@/store/store'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { LoadingButton } from '@mui/lab'
import { transports_api } from '@/api/transports.api'

const PhotosTransportModal = () => {
  const hiddenFileInput = useRef(null)

  const { addMessage } = messageStore()

  const { photosTransport, transportMeta, getAllTransports } = transportStore()
  console.log('photosTransport', photosTransport)

  const { isOpenModal, handleCloseModal, transportItem } = photosTransport

  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  //   const [steps, setSteps] = useState<
  //     {
  //       path: string
  //       name: string
  //     }[]
  //   >([])

  const [photo, setPhoto] = useState<any>([])

  // ? API Handlers

  const getAllTransportsApiHandler = useAsyncWrapper(getAllTransports, 'getAllTransportsApiHandler')
  const deleteFileApiHandler = useAsyncWrapper(photosTransport.deleteFileApiHandler, 'deleteFileApiHandler')
  const addPhotoTransportApiHandler = useAsyncWrapper(photosTransport.addPhotoTransport, 'addPhotoTransportApiHandler')

  // ? API Handlers

  // ? Handlers

  useEffect(() => {
    const fetchData = async () => {
      if (photosTransport?.transportItem) {
        const { data } = await transports_api.getTransportPhotos(photosTransport.transportItem.vehicle_id)
        setPhoto(data.photo)
      }
    }

    fetchData()
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleCloseForm = () => {
    handleCloseModal()
  }

  // ? Handlers

  //   const handleDelete = async () => {
  //     setIsLoading(true)

  //     if (!transportItem?._id || !steps[activeStep]?.name) {
  //       setIsLoading(false)
  //       return
  //     }

  //     const { error: DeleteFileError } = await deleteFileApiHandler([transportItem._id, steps[activeStep].name])

  //     if (DeleteFileError) {
  //       setIsLoading(false)
  //       return
  //     }

  //     const { error: GetAllTransportsError } = await getAllTransportsApiHandler([transportMeta])

  //     if (GetAllTransportsError) {
  //       setIsLoading(false)
  //       return
  //     }

  //     addMessage({
  //       severity: 'success',
  //       message: 'Фото успішно видалено',
  //       isOpen: true,
  //     })

  //     setIsLoading(false)
  //     handleCloseForm()
  //   }

  const handleUpload = (event: any) => {
    if (hiddenFileInput?.current) {
      // @ts-ignore
      hiddenFileInput.current.click()
    }
  }

  const handleChange = async (event: any) => {
    setIsLoading(true)

    if (!transportItem?._id) {
      setIsLoading(false)
      return
    }

    const formData = new FormData()

    formData.append('files', new Blob(event.target.files))

    const { error: AddPhotoTransportError } = await addPhotoTransportApiHandler([transportItem._id, formData])

    if (AddPhotoTransportError) {
      setIsLoading(false)
      return
    }

    const { error: GetAllTransportsError } = await getAllTransportsApiHandler([transportMeta])

    if (GetAllTransportsError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Фото успішно додано',
      isOpen: true,
    })

    setIsLoading(false)

    handleCloseForm()
  }

  //   useEffect(() => {
  //     setSteps(transportItem?.files || [])
  //   }, [])

  return (
    <ModalContainer isOpen={isOpenModal} handleClose={handleCloseForm} title={'Фото транспорту'} width={600}>
      <Box sx={{ flexGrow: 1 }}>
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,
            bgcolor: 'background.default',
          }}>
          {photo ? null : (
            <Typography
              sx={{
                textAlign: 'center',
                width: '100%',
              }}>
              Немає фото
            </Typography>
          )}
        </Paper>
        <Box sx={{ width: '100%', p: 2 }}>
          {photo ? (
            <img
              style={{
                width: '100%',
              }}
              src={`data:image/png;base64,${photo}`}
              alt="photo"
            />
          ) : null}
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 1,
            }}>
            {steps?.length ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <LoadingButton
                  loading={isLoading}
                  color="error"
                  variant="contained"
                  size="small"
                  endIcon={<Delete />}
                  onClick={() => handleDelete()}>
                  Видалити
                </LoadingButton>

                <LoadingButton
                  loading={isLoading}
                  size="small"
                  variant="contained"
                  onClick={handleUpload}
                  color="success"
                  sx={{
                    ml: 1,
                  }}
                  endIcon={<Upload />}>
                  Загрузити ще
                </LoadingButton>
              </Box>
            ) : (
              <LoadingButton
                loading={isLoading}
                size="small"
                variant="contained"
                onClick={handleUpload}
                color="success"
                sx={{
                  ml: 1,
                }}
                endIcon={<Upload />}>
                Додати фото
              </LoadingButton>
            )}
          </Box>
          <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} /> */}
        </Box>

        {/* <MobileStepper
          variant="text"
          steps={steps.length}
          position="static"
          activeStep={steps.length ? activeStep : -1}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === steps.length - 1}>
              Далі
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Назад
            </Button>
          }
        /> */}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Закрити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default PhotosTransportModal
