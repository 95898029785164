import {ICountryTranslate} from "@/interfaces/country";
import {SelectChangeEvent} from "@mui/material";

export const inputsChanger = (type: string, state: any, setState: Function): any => {
    if (!setState) {
        return () => {
            alert("setState is not defined");
        };
    }

    switch (type) {
        case "input":
            return (e: any) => {
                const {name, value} = e.target;
                setState({...state, [name]: value});
            };
        case "inputWithTrim":
            return (e: any) => {
                const {name, value} = e.target;
                setState({...state, [name]: value.trim()});
            };
        case "inputCountryTranslate":
            return (e: any) => {
                const {name, value} = e.target;
                const lang = name.split("-")[1];

                if (!state?.translates) {
                    return setState({
                        ...state,
                        translates: [
                            {
                                code: lang,
                                translate: value,
                            },
                        ],
                    });
                } else {
                    const translates = state.translates.filter(
                        (item: ICountryTranslate) =>
                            item.code.toLocaleLowerCase() !== lang.toLocaleLowerCase()
                    );

                    setState({
                        ...state,
                        translates: [
                            ...translates,
                            {
                                code: lang,
                                translate: value,
                            },
                        ],
                    });
                }
            };
        case "inputMiddleStep":
            return (e: any) => {
                const splittedName = e.target.name.split("-");

                splittedName.shift();

                setState({
                    ...state,
                    middleStep: {
                        ...state.middleStep,
                        [`middleStep-${splittedName.join("-")}`]: e.target.value,
                    },
                });
            };
        case "switchMiddleStep":
            return (e: any) => {
                const splittedName = e.target.name.split("-");

                splittedName.shift();

                setState({
                    ...state,
                    middleStep: {
                        ...state.middleStep,
                        [`middleStep-${splittedName.join("-")}`]: e.target.checked,
                    },
                });
            };
        case "selectChanger":
            return (e: SelectChangeEvent) => {
                const {name, value} = e.target;
                setState({...state, [name]: value});
            };
        case "datePickerChanger":
            return (e: any, name: string) => {
                setState({...state, [name]: new Date(e).getTime()});
            };
        case 'switchChanger':
            return (e: React.ChangeEvent<HTMLInputElement>) => {
                const {name, checked} = e.target;
                setState({...state, [name]: checked});
            }
        default:
            (() => {
                alert("type is not defined");
            })();
    }
};
