import {articlesStore} from "@/store/articlesStore";
import {ArrowLeft, ArrowRight} from "@mui/icons-material";
import {Grid, Button, Typography, Divider} from "@mui/material";
import {Box} from "@mui/system";
import React, {useState} from "react";

interface IProps {
    articlesItem: any;
}

const AdminArticleItem = ({articlesItem}: IProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const {deleteArticle, editArticle, photoArticle} = articlesStore();

    return (
        <Grid item xs={4} key={articlesItem?._id}>
            <Box
                sx={{
                    width: "100%",
                    height: "200px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    },
                }}
            >
                <img
                    src={`${process.env.REACT_APP_DEV_BASE_API_URL}/${
                        articlesItem?.images[currentImageIndex]?.path || ""
                    }`}
                    alt=""
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: 1,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    startIcon={<ArrowLeft/>}
                    onClick={() => setCurrentImageIndex((prev) => prev - 1)}
                    disabled={currentImageIndex === 0}
                >
                    Попередня
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    onClick={() => setCurrentImageIndex((prev) => prev + 1)}
                    disabled={
                        currentImageIndex === articlesItem?.images?.length - 1 ||
                        articlesItem?.images?.length === 0
                    }
                    endIcon={<ArrowRight/>}
                >
                    Наступна
                </Button>
            </Box>
            <Typography
                color="primary"
                sx={{
                    maxHeight: "70px",
                    minHeight: "70px",
                    overflowY: "scroll",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap",
                    fontSize: "22px",
                    fontWeight: "bold",
                }}
            >
                {articlesItem?.title}
            </Typography>
            <Divider sx={{my: 1}}/>
            <Typography
                sx={{
                    minHeight: "200px",
                    maxHeight: "200px",
                    overflowY: "scroll",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap",
                }}

            >
                <div dangerouslySetInnerHTML={{__html: articlesItem?.description}}></div>
            </Typography>
            <Typography>Мова: {articlesItem?.language}</Typography>
            <Typography color={articlesItem?.isHidden ? "error" : "primary"}>
                Статус: {articlesItem?.isHidden ? "Сховано" : "Активно"}
            </Typography>
            <Typography>
                Тип: {articlesItem?.type === "news" ? "Новина" : "Знижка"}
            </Typography>
            <Typography>
                Підрозділ:{" "}
                {articlesItem?.entity === "passengerTransportation"
                    ? "Пасажирські перевезення"
                    : "Вантажні перевезення"}
            </Typography>

            <Button
                variant="contained"
                color="success"
                fullWidth
                sx={{
                    mt: 1,
                }}
                onClick={() => photoArticle.handleOpenModal(articlesItem)}
            >
                Керування фотографіями
            </Button>

            <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                    mt: 1,
                }}
                onClick={() => editArticle.handleOpenModal(articlesItem)}
            >
                Редагувати
            </Button>

            <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{
                    mt: 1,
                }}
                onClick={() => editArticle.handleOpenModal(articlesItem)}
            >
                Видалити
            </Button>
        </Grid>
    );
};

export default AdminArticleItem;
