import {api} from "@/api";

export const news_api = {
    getAllNews: async ({page, count}: any, filter: any | null | undefined) => {
        return await api.get("/news/admin/get-paginate", {
            params: {
                page: page,
                count,
                filter: filter ? JSON.stringify(filter) : null,
            },
        });
    },

    getCurrentNew: async (id: string) => {
        return await api.get(`/news/admin/get-one/${id}`);
    },
    editNew: async (id: string, body: any) => {
        return await api.patch(`/news/admin/edit/${id}`, body);
    },
    createNew: async (body: any) => {
        return await api.post(`/news/admin`, body);
    },

    deleteNew: async (id: string) => {
        return await api.delete(`/news/admin/delete/${id}`);
    },

    deletePhotoNew: async (id: string, fileName: string) => {
        return await api.delete(`/news/admin/delete-file/${id}/${fileName}`);
    },

    uploadPhotoNew: async (id: string, body: any) => {
        return await api.post(`/news/admin/add-files/${id}`, body);
    },
};
