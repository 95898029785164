import React, { useEffect, useState } from 'react'
import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { driversStore } from '@/store/driverStore'
import { transportStore } from '@/store/transportStore'
import { minutesToHoursTransformer, formatDate } from '@/utils/formatDate'
import {
  Button,
  Box,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import { inputsChanger } from '@/utils/inputsChanger'
import { getTranslateValue } from '@/utils/getTranslateValue'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { LoadingButton } from '@mui/lab'
import { tripsStore } from '@/store/tripStore'
import { messageStore } from '@/store/store'
import { validateForm } from '@/utils/formValidation'

interface ITransportTypes {
  microBus: string
  bus: string
  nodata: string

  [key: string]: any
}

const InfoTransportModal = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()

  const { oneTransport, transports, getAllTransports, transportMeta } = transportStore()

  const { getAllTrips, tripBusModal, tripsMeta, searchParams } = tripsStore()

  const { isOpenModal, editedItem, handleCloseModal } = tripBusModal

  const [formValues, setFormValues] = useState<any>({})

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    busNumber: { required: true },
    transportId: { required: true },
  }

  const handleClose = () => {
    handleCloseModal()
  }

  const getAllTransportsApiHandler = useAsyncWrapper(getAllTransports, 'getAllTransportsApiHandler')

  const getAllRaceTripsApiHandler = useAsyncWrapper(getAllTrips, 'getAllRaceTripsApiHandler')

  const editTripBusApiHandler = useAsyncWrapper(tripBusModal.editTripBusApiHandler, 'editTripBusApiHandler')

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTransportsError } = await getAllTransportsApiHandler([
        {
          ...transportMeta,
          count: 1000,
        },
      ])

      if (getAllTransportsError) {
        console.log(getAllTransportsError)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    setFormValues({
      ...formValues,
    })
  }, [transports])

  useEffect(() => {
    const foundTransport = transports.find((transport) => transport._id === formValues?.transportId)

    setFormValues({
      ...formValues,
      transportName: foundTransport?.name,
      transportType: foundTransport?.type,
    })
  }, [formValues?.transportId])

  const editTripTransportHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      return
    }

    const data = {
      editType: 'editTripTransport',
      ...formValues,
    }

    const { error: editTripBusError } = await editTripBusApiHandler([editedItem?.trip_id, data])

    if (editTripBusError) {
      setIsLoading(false)
      return
    }

    const { error: getAllTripsError } = await getAllTrips()

    if (getAllTripsError) {
      console.log(getAllTripsError)
    }

    setIsLoading(false)
    addMessage({
      severity: 'success',
      message: 'Поїздку успішно відредаговано',
      isOpen: true,
    })
    handleClose()
  }

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  console.log('formValues1', formValues)

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleClose} title="Інформація про транспорт">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Номер</InputLabel>
            <TextField
              name="busNumber"
              variant="standard"
              type="text"
              value={formValues?.busNumber || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.busNumber}
              helperText={formErrors?.busNumber}
            />
          </FormControl>

          <FormControl error={!!formErrors?.transportId} fullWidth variant="standard">
            <InputLabel>Автобус</InputLabel>
            <Select name="transportId" value={formValues?.transportId || 'initial'} onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {transports?.length
                ? transports.map((transport) => {
                    return (
                      <MenuItem key={transport._id} value={transport._id}>
                        {`${transport.name}, ${transport.seatsQuantity}`}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            {formErrors?.transportId && <FormHelperText>{formErrors?.transportId}</FormHelperText>}
          </FormControl>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton color="primary" variant="contained" size="small" onClick={editTripTransportHandler}>
            Зберегти
          </LoadingButton>

          <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Закрити
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default InfoTransportModal
