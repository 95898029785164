import {useParams} from "react-router-dom";
import {Grid} from "@mui/material";

import GlobalNavigation from "@/components/UI/GlobalNavigation/GlobalNavigation";
import UserNavigation from "@/components/User/UserNavigation";

interface PageProps {
    children: JSX.Element | JSX.Element[];
}

const PageWrapper = ({children}: PageProps) => {
    const {accountNumber} = useParams();


    return (
        <Grid
            container
            spacing={1}
            sx={{minHeight: "100vh", display: "flex", flexDirection: "column"}}
        >
            <Grid item xs={12}>
                <GlobalNavigation/>
            </Grid>
            <Grid item xs={12} sx={{mx: "10px", width: "calc(100% - 20px)"}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {accountNumber && <UserNavigation/>}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            height: "100%",
                        }}
                    >
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PageWrapper;
