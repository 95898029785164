import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IRace } from '@/interfaces/races'
import { racesStore } from '@/store/racesStore'
import { TableRow, TableCell, Typography, Collapse, Box, Button, Menu, MenuItem } from '@mui/material'

import { Delete, Edit, Tour, HourglassEmpty } from '@mui/icons-material'
import { townStore } from '@/store/townStore'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

interface IProps {
  race: IRace
}

const RacesTabelRow = ({ race }: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { editRace, deleteRace, raceSalesModal, tariffsModal } = racesStore()
  const { towns, townMeta, getAllTowns } = townStore()

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openEditsMenu = Boolean(anchorEl)
  // const [fromLocationData, setFromLocationData] = useState<any>()
  // const [toLocationData, setToLocationData] = useState<any>()
  // const [fromLocationCity, setFromLocationCity] = useState<any>()
  // const [toLocationCity, setToLocationCity] = useState<any>()

  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTownsError } = await getAllTownsApiHandler([
        {
          ...townMeta,
          count: 1000,
        },
      ])

      if (getAllTownsError) return
    }

    fetchData()
  }, [])

  const handleEditsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleEditsMenuClose = () => {
    setAnchorEl(null)
  }

  const handleRaceSchedule = (race: IRace) => {
    navigate(`/races/${race?.flight_id}/schedule/`)
  }

  const handleRaceTrips = (race: IRace) => {
    navigate(`/races/${race?.flight_id}/trips/`)
  }

  const handleRacePlanning = (race: IRace) => {
    navigate(`/races/${race?.flight_id}/planning/`)
  }

  const handleEditRace = (race: IRace) => {
    editRace.handleOpenModal(race)
  }

  const handleDeleteRace = (race: IRace) => {
    deleteRace.handleOpenModal(race)
  }

  const handleRaceSales = (race: IRace) => {
    raceSalesModal.handleOpenModal(race)
  }

  const handleRaceTariffs = (race: IRace) => {
    tariffsModal.handleOpenModal(race)
    navigate(`/races/${race?.flight_id}/tariffs/`)
  }

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {race?.race_number}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}
            noWrap>
            {race.departure_full_name}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {race.departure_time}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}
            noWrap>
            {race.destination_full_name}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {race.destination_time}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {race.status ? 'Активний' : 'Неактивний'}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {race?.price} {race.currency}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                my: 1,
                display: 'flex',
                gap: '10px',
              }}>
              <Button
                id="edits-button"
                variant="contained"
                color="primary"
                size="small"
                className="editsBtn"
                startIcon={<Edit className="editIcon" />}
                aria-controls={openEditsMenu ? 'edits-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openEditsMenu ? 'true' : undefined}
                onClick={handleEditsMenuClick}>
                {t('racesPage.raceMenu.edits')}
              </Button>
              <Menu
                id="edits-menu"
                anchorEl={anchorEl}
                open={openEditsMenu}
                onClose={handleEditsMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'edits-button',
                }}>
                <MenuItem onClick={() => handleEditRace(race)}>{t('racesPage.raceMenu.editsMenu.params')}</MenuItem>
                <MenuItem onClick={() => handleRaceSales(race)}>
                  {t('racesPage.raceMenu.editsMenu.saleAndBooking')}
                </MenuItem>
                <MenuItem onClick={() => handleRaceSchedule(race)}>
                  {t('racesPage.raceMenu.editsMenu.schedule')}
                </MenuItem>
                <MenuItem onClick={() => handleRaceTariffs(race)}>{t('racesPage.raceMenu.editsMenu.tariffs')}</MenuItem>
              </Menu>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="createUserBtn"
                startIcon={<Tour className="tripsIcon" />}
                onClick={() => handleRaceTrips(race)}>
                {t('racesPage.raceMenu.trips')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="createUserBtn"
                onClick={() => handleRacePlanning(race)}
                startIcon={<HourglassEmpty className="planningIcon" />}>
                {t('racesPage.raceMenu.planning')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="createUserBtn"
                startIcon={<Delete className="deleteIcon" />}
                onClick={() => handleDeleteRace(race)}>
                {t('racesPage.raceMenu.delete')}
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default RacesTabelRow
