// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tariffsTabel .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer {
    display: none;
}

/*.tariffsTabel .MuiDataGrid-cell {*/
/*    padding: 1px !important;*/
/*}*/

.tariffsTabel .MuiDataGrid-footerContainer {
    display: none;
}

.tariffsTabel .MuiDataGrid-cell:hover {
    backgroundColor: rgba(255, 255, 0, 0.2),
}

.tariffsTabel .MuiDataGrid-columnHeader {
    max-height: 300px;
    min-height: 100% !important;
    height: 100% !important;
}

.tariffsTabel .MuiDataGrid-columnHeader:hover {
    backgroundColor: rgba(255, 255, 0, 0.2),
}

.tariffsTabel .MuiDataGrid-columnHeadersInner > div {
    height: auto;
}

.tariffsTabel .MuiDataGrid-cell:focus: {
    outline: 'none',
}

.tariffsTabel .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.tariffsTabel .MuiDataGrid-columnHeader:not(:first-child) .MuiDataGrid-columnHeaderTitle {
    padding: 15px 0px;
    text-orientation: mixed;
    transform: translate(115%) rotate(180deg);
    transform-origin: left;
    writing-mode: vertical-lr;
}

.tariffsTabel .MuiDataGrid-cell:not([data-colindex="0"]) .MuiDataGrid-cellContent {
    width: 100%;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Tabels/TripTariffs/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA,oCAAoC;AACpC,+BAA+B;AAC/B,IAAI;;AAEJ;IACI,aAAa;AACjB;;AAEA;IACI;AACJ;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,yCAAyC;IACzC,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".tariffsTabel .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer {\n    display: none;\n}\n\n/*.tariffsTabel .MuiDataGrid-cell {*/\n/*    padding: 1px !important;*/\n/*}*/\n\n.tariffsTabel .MuiDataGrid-footerContainer {\n    display: none;\n}\n\n.tariffsTabel .MuiDataGrid-cell:hover {\n    backgroundColor: rgba(255, 255, 0, 0.2),\n}\n\n.tariffsTabel .MuiDataGrid-columnHeader {\n    max-height: 300px;\n    min-height: 100% !important;\n    height: 100% !important;\n}\n\n.tariffsTabel .MuiDataGrid-columnHeader:hover {\n    backgroundColor: rgba(255, 255, 0, 0.2),\n}\n\n.tariffsTabel .MuiDataGrid-columnHeadersInner > div {\n    height: auto;\n}\n\n.tariffsTabel .MuiDataGrid-cell:focus: {\n    outline: 'none',\n}\n\n.tariffsTabel .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainerContent {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.tariffsTabel .MuiDataGrid-columnHeader:not(:first-child) .MuiDataGrid-columnHeaderTitle {\n    padding: 15px 0px;\n    text-orientation: mixed;\n    transform: translate(115%) rotate(180deg);\n    transform-origin: left;\n    writing-mode: vertical-lr;\n}\n\n.tariffsTabel .MuiDataGrid-cell:not([data-colindex=\"0\"]) .MuiDataGrid-cellContent {\n    width: 100%;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
