import RequestsTable from '@/components/Tabels/Requests/RequestsTable'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import { Typography } from '@mui/material'
import React from 'react'

const Requests = () => {
  return (
    <PageWrapper>
      {true ? (
        <RequestsTable />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view traders
        </Typography>
      )}
    </PageWrapper>
  )
}

export default Requests
