import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'
import { LoadingButton } from '@mui/lab'
import { Button, Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const DeleteRaceModal = () => {
  const { t } = useTranslation()
  const raceModalTranslations = 'racesPage.deleteRaceModal'

  const [isLoading, setIsLoading] = useState(false)

  const { deleteRace, getAllRaces, racesMeta } = racesStore()
  const { addMessage } = messageStore()

  const { isOpenModal, deletedItem, handleCloseModal } = deleteRace

  const deleteRaceApiHandler = useAsyncWrapper(deleteRace.deleteRaceApiHandler, 'deleteRaceApiHandler')
  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const deleteRaceHandler = async () => {
    setIsLoading(true)
    if (!deletedItem || !deletedItem?.flight_id) {
      setIsLoading(false)
      return
    }

    const { error: deleteRaceError } = await deleteRaceApiHandler([deletedItem.flight_id])

    if (deleteRaceError) {
      setIsLoading(false)
      return
    }

    const { error: getAllRacesError } = await getAllRacesApiHandler([racesMeta])

    if (getAllRacesError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: t(`${raceModalTranslations}.successDeleting`),
      isOpen: true,
    })

    setIsLoading(false)
    handleCloseForm()
  }

  return (
    <ModalContainer
      width={800}
      isOpen={isOpenModal}
      handleClose={handleCloseForm}
      title={t(`${raceModalTranslations}.title`)}>
      <Typography variant="subtitle1" color="text.primary">
        {`№${deletedItem?.race_number}: ( ${deletedItem?.departure_full_name} ) - ( ${deletedItem?.destination_full_name} )`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton loading={isLoading} color="primary" variant="contained" size="small" onClick={deleteRaceHandler}>
          {t(`${raceModalTranslations}.deleteRace`)}
        </LoadingButton>

        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          {t(`${raceModalTranslations}.cancelDeleting`)}
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeleteRaceModal
