import { create } from 'zustand'
import { IStop, IStopDto } from '@/interfaces/stop'
import { stops_api } from '@/api/stops.api'

interface IStore {
  stops: IStop[]
  isLoading: boolean
  createStop: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    createStopApiHandler: (body: IStopDto) => Promise<any>
  }
  editStop: {
    isOpenModal: boolean
    editedItem: IStop | null
    handleOpenModal: (item: IStop) => void
    handleCloseModal: () => void
    editStopApiHandler: (id: number, body: IStopDto) => Promise<any>
  }
  deleteStop: {
    isOpenModal: boolean
    deletedItem: IStop | null
    handleOpenModal: (item: IStop) => void
    handleCloseModal: () => void
    deleteStopApiHandler: (id: number) => Promise<any>
  }
  getAllStops: () => Promise<any>
}

export const stopsStore = create<IStore>((set) => ({
  stops: [],
  isLoading: false,
  createStop: {
    isOpenModal: false,
    handleOpenModal: () => {
      set((state) => ({
        ...state,
        createStop: {
          ...state.createStop,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createStop: {
          ...state.createStop,
          isOpenModal: false,
        },
      }))
    },
    createStopApiHandler: async (body: IStopDto) => {
      const response = await stops_api.createStop(body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 201) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  editStop: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: IStop) => {
      set((state) => ({
        ...state,
        editStop: {
          ...state.editStop,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editStop: {
          ...state.editStop,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editStopApiHandler: async (id: number, body: IStopDto) => {
      const response = await stops_api.updateStop(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteStop: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item: IStop) => {
      set((state) => ({
        ...state,
        deleteStop: {
          ...state.deleteStop,
          isOpenModal: true,
          deletedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteStop: {
          ...state.deleteStop,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteStopApiHandler: async (id: number) => {
      const response = await stops_api.deleteStop(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  getAllStops: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await stops_api.getStops()

    const { status, data } = response

    set((state) => ({ ...state, isLoading: false }))

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    set((state) => ({ ...state, stops: data.stops }))
    return response
  },
}))
