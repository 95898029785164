import ModalContainer from "@/components/UI/Modal/ModalContainer";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import {racesStore} from "@/store/racesStore";
import {messageStore} from "@/store/store";
import {LoadingButton} from "@mui/lab";
import {Button, Box, Typography} from "@mui/material";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {planningStore} from "@/store/planningStore";

const DeleteRacePlanningModal = () => {
        const {raceId} = useParams();

        const [isLoading, setIsLoading] = useState(false);

        const {addMessage} = messageStore();

        const {
            planningMeta,
            getAllRacePlans,
            deletePlanModal,
        } = planningStore();

        const {isOpenModal, deletedItem, handleCloseModal} = deletePlanModal;

        const deletePlanApiHandler = useAsyncWrapper(
            deletePlanModal.deletePlanApiHandler,
            "deletePlanApiHandler"
        );

        const getAllRacePlansApiHandler = useAsyncWrapper(
            getAllRacePlans,
            "getAllRacePlansApiHandler"
        );

        const handleCloseForm = () => {
            handleCloseModal();
        };

        const deletePlanHandler = async () => {
            setIsLoading(true);

            const {error: deletePlanError} = await deletePlanApiHandler([
                deletedItem?._id,
            ]);

            if (deletePlanError) {
                setIsLoading(false);
                return;
            }

            const {error: getRacePlansError} = await getAllRacePlans(
                raceId ?? '',
                planningMeta
            );

            if (getRacePlansError) {
                console.log(getRacePlansError);
            }

            setIsLoading(true);

            addMessage({
                severity: "success",
                message: "Зупинку успішно видалено",
                isOpen: true,
            });

            handleCloseForm();
        };

        console.log("deletedItem", deletedItem);

        return (
            <ModalContainer
                width={800}
                isOpen={isOpenModal}
                handleClose={handleCloseForm}
                title="Видалити план змін"
            >
                <Typography variant="subtitle1" color="text.primary">

                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={deletePlanHandler}
                    >
                        Видалити
                    </LoadingButton>

                    <Button
                        onClick={handleCloseForm}
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{ml: 1}}
                    >
                        Скасувати
                    </Button>
                </Box>
            </ModalContainer>
        );
    }
;

export default DeleteRacePlanningModal;
