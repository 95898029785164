import React from 'react'

import { CONNECTION_TYPE, TRIP_REQUEST_STATUS } from '@/constants/constants'
import { ITripRequest } from '@/interfaces/tripRequests'
import { TableRow, TableCell, Typography, IconButton } from '@mui/material'

interface IProps {
  tripRequest: ITripRequest
}

const UserRacesRow = ({ tripRequest }: IProps) => {
  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.fromLocationCity.city_name_ua} {tripRequest?.departurePlace.bus_stop_name_ua} -{' '}
          {tripRequest.toLocationCity.city_name_ua} {tripRequest?.arrivalPlace.bus_stop_name_ua}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {CONNECTION_TYPE[tripRequest.connectionType]}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.phone}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.baggageAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.arrivalPlace.bus_stop_name_ua}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.adultPassengerAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.childPassengerAmount + tripRequest.childMiddlePassengerAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.note}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {TRIP_REQUEST_STATUS[tripRequest.status]}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default UserRacesRow
