// ? hooks
import { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? stores
import { messageStore } from '@/store/store'
import { stopsStore } from '@/store/stopsStore'

// ? interfaces
import { IStop } from '@/interfaces/stop'

// ? validations
import { COUNTRY_VALIDATION } from '@/validation/country'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField, InputLabel, Select, MenuItem } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { townStore } from '@/store/townStore'

const EditStopModal = () => {
  const [formValues, setFormValues] = useState<Partial<IStop>>({})
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { editStop, getAllStops } = stopsStore()
  const { isOpenModal, handleCloseModal, editedItem } = editStop
  const { getAllTowns, towns } = townStore()

  // ? changers
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)
  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  // ? changers

  // ? api handlers
  const editStopApiHandler = useAsyncWrapper(editStop.editStopApiHandler, 'editStopApiHandler')
  const getStopsApiHandler = useAsyncWrapper(getAllStops, 'getStopsApiHandler')
  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  const editCountryValidation = useValidation(COUNTRY_VALIDATION.EDIT)

  const handleClose = () => {
    setFormValues({})
    handleCloseModal()
  }

  const editCountryHandler = async () => {
    setIsLoading(true)
    if (!editCountryValidation(formValues)) {
      setIsLoading(false)
      return
    }

    const data = {
      ...formValues,
      // isActive:
      //     typeof formValues?.isActive !== "undefined"
      //         ? formValues?.isActive.toString() === "true"
      //             ? true
      //             : false
      //         : editedItem?.isActive,
    }

    const { error: editStopError } = await editStopApiHandler([editedItem?.bus_stop_id, data])

    if (editStopError) {
      setIsLoading(false)
      return
    }

    const { error: getStopsError } = await getStopsApiHandler([])

    if (getStopsError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно відредаговано',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTownsError } = await getAllTownsApiHandler([])

      if (getAllTownsError) return
    }

    fetchData()
  }, [])

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleClose} title={'Редагування Зупинки'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <TextField
          name="bus_stop_name_en"
          onChange={inputChangeHandler}
          fullWidth
          value={formValues.bus_stop_name_en || editedItem?.bus_stop_name_en}
          label="Назва зупинки англійською"
          variant="standard"
        />

        <TextField
          name="bus_stop_name_ua"
          onChange={inputChangeHandler}
          fullWidth
          label="Назва країни українською"
          value={formValues.bus_stop_name_ua || editedItem?.bus_stop_name_ua}
          variant="standard"
        />
        <InputLabel>Місто</InputLabel>
        <Select
          value={formValues?.bus_stop_location || editedItem?.bus_stop_location}
          name="bus_stop_location"
          onChange={handleChangeSelect}
          fullWidth
          variant="standard"
          label="Місто">
          <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
          {towns?.length
            ? towns.map((town) => {
                return (
                  <MenuItem key={town.city_id} value={town.city_id}>
                    {town.name_ukr}
                  </MenuItem>
                )
              })
            : null}
        </Select>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={editCountryHandler}>
          Відредагувати
        </LoadingButton>
        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default EditStopModal
