import { api } from '@/api'
import { IRace } from '@/interfaces/races'

export const races_api = {
  getAllRaces: async () => {
    return await api.get('flights')
  },

  createRace: async (body: Partial<IRace>) => {
    return await api.post('/flights', body)
  },

  editRace: async (id: number, body: Partial<IRace>) => {
    return await api.put(`flights/${id}`, body)
  },

  deleteRace: async (id: string) => {
    return await api.delete(`flights/${id}`)
  },

  getById: async (id: string) => {
    return await api.get(`flights/${id}`)
  },
}
