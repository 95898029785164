import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'
import { Add } from '@mui/icons-material'
import React, { useEffect } from 'react'
import RacesTabelHeader from './RacesTabelHeader'
import RacesTabelRow from './RacesTabelRow'
import { racesStore } from '@/store/racesStore'
import { useTranslation } from 'react-i18next'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

import CreateRaceModal from './modals/CreateRaceModal'
import EditRaceModal from './modals/EditRaceModal'
import DeleteRaceModal from './modals/DeleteRaceModal'
import RaceSalesModal from './modals/RaceSalesModal'
import TariffsModal from './modals/TariffsModal'

const RacesTabel = () => {
  const { t } = useTranslation()

  const {
    getAllRaces,
    races,
    racesMeta,
    isLoading,
    createRaceModal,
    editRace,
    deleteRace,
    raceSalesModal,
    tariffsModal,
  } = racesStore()

  const getAllRacesApiHandler = useAsyncWrapper(getAllRaces, 'getAllRacesApiHandler')

  const handleOpenCreateModal = () => {
    createRaceModal.handleOpenModal()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllRacesError } = await getAllRacesApiHandler([racesMeta])

      if (getAllRacesError) {
        console.log(getAllRacesError)
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createRaceModal?.isOpenModal && <CreateRaceModal />}
      {editRace?.isOpenModal && <EditRaceModal />}
      {deleteRace?.isOpenModal && <DeleteRaceModal />}
      {raceSalesModal?.isOpenModal && <RaceSalesModal />}
      {tariffsModal?.isOpenModal && <TariffsModal />}
      <Grid item xs={12}>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            align: 'center',
            gap: '10px',
          }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            {t('racesPage.createNewRace')}
          </Button>
        </Box>
        {races?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <RacesTabelHeader />
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <LoadingTableData rows={5} columns={8} />
                ) : (
                  races.map((race) => <RacesTabelRow race={race} key={race?.flight_id} />)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              {t('racesPage.createNewRace')}
            </Button>
          </Box>
        )}
        <TablePaginationCustom getData={getAllRacesApiHandler} meta={racesMeta} />
      </Grid>
    </Grid>
  )
}

export default RacesTabel
