import { useEffect, useState } from 'react'

import { Box, Divider, IconButton, Paper, Tooltip, Input, Button, FormControl, InputAdornment } from '@mui/material'
import { Close } from '@mui/icons-material'

import { useTranslation } from 'react-i18next'

import { messageStore } from '@/store/store'
import { usersStore } from '@/store/usersStore'
import { adminStore } from '@/store/adminStore'

import useDebounce from '@/hooks/useDebounce'
import { isObjectValuesEmpty } from '@/utils/isObjectValuesEmpty'

const UserTableFastFilter = () => {
  const { t } = useTranslation()

  const i18nTranslateHeaderString = 'usersPage.userTable.filter'
  const i18nTranslateErrorString = 'usersPage.userTable.errors'

  const { currentAdmin } = adminStore()
  const { addMessage } = messageStore()
  const { filterQuery, setFilterQuery, meta, getUsers, setIsLoading, setUsers } = usersStore()

  const debouncedSearchTerm = useDebounce(filterQuery, 500) as any

  const [, setIsSearching] = useState(false)

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterQuery({
      ...filterQuery,
      [e.target.name]: e.target.value.trimEnd(),
    })
  }

  const clearFilter = () => {
    setFilterQuery({
      pib: null,
      phone: null,
    })

    getUsers(meta, {}).catch((err: any) => {
      console.log(err, 'err')
    })
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true)

      if (currentAdmin) {
        getUsers(meta, filterQuery)
          .then((res: any) => {
            setIsSearching(false)
          })
          .catch((err: any) => {
            if (err?.response?.status !== 404) {
              console.log(err, 'catch')
              addMessage({
                severity: 'error',
                message: t(`${i18nTranslateErrorString}.wentWrong`),
                isOpen: true,
              })
            } else {
              setIsSearching(false)
              setUsers([])
            }
          })
          .finally(() => {
            setIsSearching(false)
            setIsLoading(false)
          })
      }
    }
  }, [debouncedSearchTerm])

  return (
    <Box
      sx={{
        width: '100%',
      }}>
      <Paper
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexWrap: {
            xs: 'wrap-reverse',
            xl: 'nowrap',
          },
        }}>
        <Box
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            maxWidth: '600px',
            mr: '15px',
            my: '2px',
          }}>
          <FormControl
            variant="standard"
            fullWidth
            sx={{
              width: '300px',
            }}>
            <Input
              placeholder={'ПІБ'}
              size="small"
              value={filterQuery?.pib || ''}
              onChange={handleChangeInput}
              name="pib"
              sx={{ ml: 1, my: 0, flex: 1 }}
              endAdornment={
                <InputAdornment position="end">
                  {filterQuery?.pib && filterQuery?.pib?.length > 0 && (
                    <Tooltip title="Clear filter">
                      <IconButton
                        onClick={() =>
                          setFilterQuery({
                            ...filterQuery,
                            pib: '',
                          })
                        }
                        edge="end"
                        size="small">
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            variant="standard"
            fullWidth
            sx={{
              width: '300px',
            }}>
            <Input
              placeholder={'Номер телефону'}
              size="small"
              value={filterQuery?.phone || ''}
              onChange={handleChangeInput}
              name="phone"
              sx={{ ml: 1, my: 0, flex: 1 }}
              endAdornment={
                <InputAdornment position="end">
                  {filterQuery?.phone && filterQuery?.phone?.length > 0 && (
                    <Tooltip title="Clear filter">
                      <IconButton
                        onClick={() =>
                          setFilterQuery({
                            ...filterQuery,
                            phone: '',
                          })
                        }
                        edge="end"
                        size="small">
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>

          {!isObjectValuesEmpty(filterQuery) && (
            <>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Tooltip title={t(`${i18nTranslateHeaderString}.clearFilters`) as string}>
                <Button size="small" onClick={clearFilter}>
                  {t(`${i18nTranslateHeaderString}.clearFilterAll`) as string}
                </Button>
              </Tooltip>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default UserTableFastFilter
