// ? hooks
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import useValidation from "@/hooks/useValidation";

// ? store
import {adminStore} from "@/store/adminStore";
import {messageStore} from "@/store/store";

// ? interfaces
import {ILoginForm} from "@/interfaces/auth";

// ? utils
import {inputsChanger} from "@/utils/inputsChanger";

// ? validation
import {AUTH_VALIDATION} from "@/validation/auth";

// ? components
import {LoadingButton} from "@mui/lab";
import {Box, Grid, Typography} from "@mui/material";

import InputPassword from "@/components/UI/Inputs/InputPassword/InputPassword";
import InputEmail from "@/components/UI/Inputs/InputEmail/InputEmail";

// ? icons
import {Fingerprint} from "@mui/icons-material";

const initialState: ILoginForm = {
    email: "",
    password: "",
};

const Auth = () => {
    const navigate = useNavigate();

    const {login} = adminStore();
    const {addMessage} = messageStore();

    const loginApiHandler = useAsyncWrapper(login, "loginApiHandler");
    const validationLoginHandler = useValidation(AUTH_VALIDATION.LOGIN);

    const [formValues, setFormValues] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeInputs = inputsChanger('input', formValues, setFormValues);

    const loginHandler = async () => {

        if (!validationLoginHandler(formValues)) {
            return false;
        }

        setIsLoading(true);


        const {error: loginError, loading} = await loginApiHandler([formValues]);
        setIsLoading(loading);

        if (loginError) {
            return false;
        }

        navigate("/");
        window.location.reload();

        addMessage({
            isOpen: true,
            message: "Ви успішно увійшли в систему!",
            severity: "success",
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
            }}
        >
            <form>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        maxWidth: "400px",
                        width: "100%",
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            component="legend"
                            color="primary"
                            sx={{textAlign: "center"}}
                        >
                            Авторизація
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputEmail
                            name="email"
                            label="Електронна пошта"
                            handleChange={handleChangeInputs}
                            value={formValues.email}
                            required
                            tabIndex={1}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputPassword
                            name="password"
                            label="Пароль"
                            handleChange={handleChangeInputs}
                            value={formValues.password}
                            required
                            tabIndex={2}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                loading={isLoading}
                                onClick={loginHandler}
                                endIcon={<Fingerprint/>}
                            >
                                Увійти
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default Auth;
