import { api } from '@/api'
import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { ITransport } from '@/interfaces/transport'
import { messageStore } from '@/store/store'
import { transportStore } from '@/store/transportStore'
import { inputsChanger } from '@/utils/inputsChanger'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const EditTransportModal = () => {
  const { t } = useTranslation()

  const { editTransport, getAllTransports, transportMeta } = transportStore()
  const { editedItem, handleCloseModal, isOpenModal } = editTransport
  const [file, setFile] = useState<File | null>(null)

  const { addMessage } = messageStore()

  const [formValues, setFormValues] = useState<Partial<ITransport>>({
    type: editedItem?.vehicle_type,
    vehicle_brand: editedItem?.vehicle_brand || '',
    vehicle_license_plates: editedItem?.vehicle_license_plates,
    seatsQuantity: editedItem?.vehicle_capacity || 0,
    vehicle_amenities: Array.isArray(editedItem?.vehicle_amenities)
      ? editedItem?.vehicle_amenities
      : editedItem?.vehicle_amenities.split(', '),
    vehicle_photo: editedItem?.vehicle_photo,
  })
  const [loading, setLoading] = useState(false)

  // ? API Handlers
  const getAllTransportsApiHandler = useAsyncWrapper(getAllTransports, 'getAllTransportsApiHandler')
  const editTransportApiHandler = useAsyncWrapper(editTransport.editTransportApiHandler, 'editTransportApiHandler')
  // ? API Handlers

  // ? Handlers
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target as HTMLInputElement
    if (type === 'file' && files) {
      setFile(files[0])
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: type === 'number' ? Number(value) : value,
      }))
    }
  }

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleCloseForm = () => {
    setFormValues({} as Partial<ITransport>)
    handleCloseModal()
  }

  const editBusHandler = async () => {
    setLoading(true)

    if (!editedItem?.vehicle_id) return

    const formData = new FormData()
    formData.append('vehicle_type', formValues.type)
    formData.append('vehicle_brand', formValues.vehicle_brand ?? '')
    formData.append('vehicle_license_plates', formValues.vehicle_license_plates ?? '')
    formData.append('vehicle_capacity', (formValues.vehicle_capacity || 0).toString())
    formData.append(
      'vehicle_amenities',
      Array.isArray(formValues.vehicle_amenities)
        ? formValues.vehicle_amenities.join(', ')
        : formValues.vehicle_amenities || ''
    )

    if (file) formData.append('vehicle_photo', file)

    try {
      const { status } = await api.put(`/cars/${editedItem.vehicle_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (status !== 200) {
        throw new Error('Failed to create transport')
      }

      const { error: getAllTransportsError } = await getAllTransportsApiHandler([transportMeta])

      if (getAllTransportsError) {
        throw new Error(getAllTransportsError.message)
      }

      addMessage({
        severity: 'success',
        message: t('transportsPage.editBusSuccess'),
        isOpen: true,
      })
      handleCloseForm()
    } catch (error) {
      console.error('Error:', error)
      addMessage({
        severity: 'error',
        message: 'Failed to update transport',
        isOpen: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return editedItem?.vehicle_id ? (
    <ModalContainer
      isOpen={isOpenModal}
      handleClose={handleCloseForm}
      title={t('transportsPage.editBusTitle')}
      width={600}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
        }}>
        <FormControl fullWidth variant="standard">
          <InputLabel>{t('transportsPage.type')}</InputLabel>
          <Select value={formValues?.type} name="vehicle_type" onChange={handleChangeSelect}>
            <MenuItem value={'bus'}>Автобус</MenuItem>
            <MenuItem value={'microBus'}>Мікроавтобус</MenuItem>
            <MenuItem value={'car'}>Легковий автомобіль</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label={t('transportsPage.name')}
          onChange={handleChangeInput}
          defaultValue={editedItem?.vehicle_brand}
          name="vehicle_brand"
          variant="standard"
          placeholder={'Mercedes-Benz Sprinter'}
        />
        <TextField
          fullWidth
          label={t('transportsPage.busNumber')}
          onChange={handleChangeInput}
          name="vehicle_license_plates"
          defaultValue={formValues?.vehicle_license_plates}
          variant="standard"
          placeholder="AA0000AA"
        />
        <TextField
          fullWidth
          label={t('transportsPage.quantitySeats')}
          onChange={handleChangeInput}
          name="vehicle_capacity"
          variant="standard"
          defaultValue={formValues?.seatsQuantity}
          placeholder="24"
          type="number"
        />
        <FormControl fullWidth variant="standard">
          <InputLabel>{t('transportsPage.amenities')}</InputLabel>
          <Select value={formValues?.vehicle_amenities} name="vehicle_amenities" onChange={handleChangeSelect} multiple>
            <MenuItem value="WIFI">WIFI</MenuItem>
            <MenuItem value="Відкидні сидіння">Відкидні сидіння</MenuItem>
            <MenuItem value="Подушки/пледи">Подушки/пледи</MenuItem>
            <MenuItem value="Зарядки">Зарядки</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth variant="standard">
          <TextField
            type="file"
            id="vehicle_photo"
            name="vehicle_photo"
            onChange={handleChangeInput}
            style={{ marginTop: '8px' }}
          />
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton loading={loading} color="primary" variant="contained" size="small" onClick={editBusHandler}>
            {t('transportsPage.editBus')}
          </LoadingButton>

          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            {t('transportsPage.cancelCreation')}
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  ) : null
}

export default EditTransportModal
