import React, {useEffect} from "react";
import ConsultationTabelHeader from "./ConsultationTabelHeader";
import ConsultationTabelRow from "./ConsultationTabelRow";
import LoadingTableData from "@/components/UI/LoadingTableData/LoadingTableData";
import TablePaginationCustom from "@/components/UI/TablePaginationCustom/TablePaginationCustom";
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
} from "@mui/material";
import {consultationStore} from "@/store/consultationStore";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";

const ConsultationTabel = () => {
    const {consultations, getAllConsultations, consultationMeta, isLoading} =
        consultationStore();

    const getAllConsultationsApiHandler = useAsyncWrapper(
        getAllConsultations,
        "getAllConsultationsApiHandler"
    );

    useEffect(() => {
        const fetchData = async () => {
            const {error} = await getAllConsultationsApiHandler([consultationMeta]);

            if (error) {
                return false;
                console.log(error, "error");
            }
        };

        fetchData();
    }, []);

    return (
        <Grid container spacing={1} sx={{pr: 1}}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table
                        stickyHeader
                        sx={{
                            maxHeight: "calc(100vh - 250px)",
                            width: "100%",
                            maxWidth: "100%",
                            overflow: "scroll",
                            mt: 1,
                        }}
                    >
                        <TableHead>
                            <ConsultationTabelHeader/>
                        </TableHead>
                        <TableBody>
                            {!isLoading ? (
                                consultations.map((consultation: any) => (
                                    <ConsultationTabelRow
                                        consultation={consultation}
                                        key={consultation._id}
                                    />
                                ))
                            ) : (
                                <LoadingTableData columns={7} rows={5}/>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePaginationCustom
                    getData={getAllConsultationsApiHandler}
                    meta={consultationMeta}
                />
            </Grid>
        </Grid>
    );
};

export default ConsultationTabel;
