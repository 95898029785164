import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import { Grid, Button, TableContainer, Table, TableHead, TableBody } from '@mui/material'
import { Box } from '@mui/system'
import { Add } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import TransportTabelHeader from './TransportTabelHeader'
import { useTranslation } from 'react-i18next'
import TransportsTabelRow from './TransportsTabelRow'
import CreateTransportModal from './modals/CreateTransportModal'
import { transportStore } from '@/store/transportStore'
import { messageStore } from '@/store/store'
import EditTransportModal from './modals/EditTransportModal'
import DeleteTransportModal from './modals/DeleteTransportModal'
import PhotosTransportModal from './modals/PhotosTransportModal'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import { ITransport } from '@/interfaces/transport'

const TransportsTabel = () => {
  const { t } = useTranslation()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const { getAllTransports, transports, transportMeta, editTransport, deleteTransport, photosTransport, isLoading } =
    transportStore()
  const { addMessage } = messageStore()

  const getAllTransportsApiHandler = useAsyncWrapper(getAllTransports, 'getAllTransportsApiHandler')

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTransportsError } = await getAllTransportsApiHandler([transportMeta])

      if (getAllTransportsError) {
        addMessage({
          severity: 'error',
          message: t('errors.wentWrong'),
          isOpen: true,
        })
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {isCreateModalOpen && <CreateTransportModal isOpen={isCreateModalOpen} handleClose={handleCloseCreateModal} />}

      {editTransport.isOpenModal && <EditTransportModal />}

      {deleteTransport.isOpenModal && <DeleteTransportModal />}

      {photosTransport.isOpenModal && <PhotosTransportModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            {t('transportsPage.createNewTransport')}
          </Button>
        </Box>

        {transports?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <TransportTabelHeader />
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <LoadingTableData rows={5} columns={10} />
                ) : (
                  transports.map((transport: ITransport) => (
                    <TransportsTabelRow transport={transport} key={transport.vehicle_id} />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              {t('transportsPage.createNewTransport')}
            </Button>
          </Box>
        )}
        {transports && transports.length !== 0 && (
          <TablePaginationCustom getData={getAllTransportsApiHandler} meta={transportMeta} />
        )}
      </Grid>
    </Grid>
  )
}

export default TransportsTabel
