// ? components
import { TableRow, TableCell } from '@mui/material'

const headerTitles = [
  {
    title: 'ID',
  },
  {
    title: 'Місто',
  },
  {
    title: 'Зупинка',
  },
  {
    title: 'Дії',
  },
]

const StopsTableHeader = () => {
  return (
    <TableRow>
      {headerTitles?.length
        ? headerTitles.map(({ title }) => (
            <TableCell
              key={title}
              sx={{
                backgroundColor: '#222222 !important',
              }}>
              {title}
            </TableCell>
          ))
        : null}
    </TableRow>
  )
}

export default StopsTableHeader
