import React, { useEffect, useState } from 'react'
import RequestsTableHeader from './RequestsTableHeader'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  FormControlLabel,
  Switch,
  Box,
  useTheme,
  Tab,
  Input,
} from '@mui/material'
import RequestsTableRow from './RequestsTableRow'
import { tripRequestsStore } from '@/store/tripRequestsStore'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import TripItemApproveModal from '../TripItemRequests/modals/TripItemApproveModal'
import TripItemDeclineModal from '../TripItemRequests/modals/TripItemDeclineModal'

const RequestsTable = () => {
  const theme = useTheme()
  const [isReloading, setIsReloading] = useState(false)

  const [value, setValue] = useState('created')
  const [searchPIB, setSearchPIB] = useState('')
  const [searchPhone, setSearchPhone] = useState('')

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const { getTripRequests, tripRequestsMeta, isLoading, tripRequests } = tripRequestsStore()

  const getTripRequestsApiHandler = useAsyncWrapper(getTripRequests, 'getTripRequests')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsReloading(event.target.checked)
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getTripRequestsError } = await getTripRequestsApiHandler([
        {
          status: value,
          phone: searchPhone,
          pib: searchPIB,
        },
      ])

      if (getTripRequestsError) {
        return
      }
    }

    fetchData()
  }, [value, searchPhone, searchPIB])

  const getRequestInInterval = async () => {
    const { error: getTripRequestsError } = await getTripRequestsApiHandler([value, searchPhone, searchPIB])

    const timeOut = setTimeout(() => {
      getRequestInInterval()
      clearTimeout(timeOut)
    }, 60000)

    if (getTripRequestsError) {
      return
    }
  }

  useEffect(() => {
    if (isReloading) {
      getRequestInInterval()
    }
  }, [isReloading, value, searchPhone, searchPIB])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      <TripItemApproveModal />
      <TripItemDeclineModal status="created" />
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch checked={isReloading} onChange={handleChange} />}
          label="Автооновлення через 60сек"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            mt: 1,
            mb: 1,
          }}>
          <Box
            sx={{
              height: '10px',
              width: '10px',
              borderRadius: '50%',
              backgroundColor: theme.palette.info.main,
            }}></Box>
          Локальний час компютера
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            mt: 1,
            mb: 1,
          }}>
          <Box
            sx={{
              height: '10px',
              width: '10px',
              borderRadius: '50%',
              backgroundColor: theme.palette.success.main,
            }}></Box>
          Час у місті відправлення / прибуття
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            mt: 1,
            mb: 1,
          }}>
          <Input placeholder="Пошук по ПІБ" value={searchPIB} onChange={(e) => setSearchPIB(e.target.value)} />
          <Input placeholder="Пошук по телефону" value={searchPhone} onChange={(e) => setSearchPhone(e.target.value)} />
        </Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab}>
              <Tab label="Нові замовлення" value="created" />
              <Tab label="Підтвержені" value="booked" />
              <Tab label="В процесі" value="ongoing" />
              <Tab label="Завершені" value="completed" />
              <Tab label="Відхилені" value="canceled" />
            </TabList>
          </Box>
          <TabPanel
            value="created"
            sx={{
              p: 0,
            }}>
            <TableContainer>
              <Table
                stickyHeader
                sx={{
                  maxHeight: 'calc(100vh - 250px)',
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'scroll',
                  mt: 1,
                }}>
                <TableHead>
                  <RequestsTableHeader />
                </TableHead>
                <TableBody>
                  {!isLoading ? (
                    tripRequests?.map((tripRequest) => (
                      <RequestsTableRow tripRequest={tripRequest} key={tripRequest.order_trip_id} />
                    ))
                  ) : (
                    <LoadingTableData columns={12} rows={5} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              getData={getTripRequestsApiHandler}
              meta={tripRequestsMeta}
              filterQuery={{ status: value }}
            />
          </TabPanel>
          <TabPanel
            value="booked"
            sx={{
              p: 0,
            }}>
            <TableContainer>
              <Table
                stickyHeader
                sx={{
                  maxHeight: 'calc(100vh - 250px)',
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'scroll',
                  mt: 1,
                }}>
                <TableHead>
                  <RequestsTableHeader />
                </TableHead>
                <TableBody>
                  {!isLoading ? (
                    tripRequests?.map((tripRequest) => (
                      <RequestsTableRow tripRequest={tripRequest} key={tripRequest.order_trip_id} />
                    ))
                  ) : (
                    <LoadingTableData columns={12} rows={5} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              getData={getTripRequestsApiHandler}
              meta={tripRequestsMeta}
              filterQuery={{ status: value }}
            />
          </TabPanel>
          <TabPanel
            value="ongoing"
            sx={{
              p: 0,
            }}>
            <TableContainer>
              <Table
                stickyHeader
                sx={{
                  maxHeight: 'calc(100vh - 250px)',
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'scroll',
                  mt: 1,
                }}>
                <TableHead>
                  <RequestsTableHeader />
                </TableHead>
                <TableBody>
                  {!isLoading ? (
                    tripRequests?.map((tripRequest) => (
                      <RequestsTableRow tripRequest={tripRequest} key={tripRequest.order_trip_id} />
                    ))
                  ) : (
                    <LoadingTableData columns={12} rows={5} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              getData={getTripRequestsApiHandler}
              meta={tripRequestsMeta}
              filterQuery={{ status: value }}
            />
          </TabPanel>
          <TabPanel
            value="completed"
            sx={{
              p: 0,
            }}>
            <TableContainer>
              <Table
                stickyHeader
                sx={{
                  maxHeight: 'calc(100vh - 250px)',
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'scroll',
                  mt: 1,
                }}>
                <TableHead>
                  <RequestsTableHeader />
                </TableHead>
                <TableBody>
                  {!isLoading ? (
                    tripRequests?.map((tripRequest) => (
                      <RequestsTableRow tripRequest={tripRequest} key={tripRequest.order_trip_id} />
                    ))
                  ) : (
                    <LoadingTableData columns={12} rows={5} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              getData={getTripRequestsApiHandler}
              meta={tripRequestsMeta}
              filterQuery={{ status: value }}
            />
          </TabPanel>
          <TabPanel
            value="canceled"
            sx={{
              p: 0,
            }}>
            <TableContainer>
              <Table
                stickyHeader
                sx={{
                  maxHeight: 'calc(100vh - 250px)',
                  width: '100%',
                  maxWidth: '100%',
                  overflow: 'scroll',
                  mt: 1,
                }}>
                <TableHead>
                  <RequestsTableHeader />
                </TableHead>
                <TableBody>
                  {!isLoading ? (
                    tripRequests?.map((tripRequest) => (
                      <RequestsTableRow tripRequest={tripRequest} key={tripRequest.order_trip_id} />
                    ))
                  ) : (
                    <LoadingTableData columns={12} rows={5} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              getData={getTripRequestsApiHandler}
              meta={tripRequestsMeta}
              filterQuery={{ status: value }}
            />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  )
}

export default RequestsTable
