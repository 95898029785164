import Admins from '@/pages/Admins/Admins'
import Consultations from '@/pages/Consultations/Consultations'
import Country from '@/pages/Country/Country'
import Drivers from '@/pages/Drivers/Drivers'
import Error404 from '@/pages/Error404/Error404'
import News from '@/pages/News/News'
import Blog from '@/pages/Blog/Blog'
import Races from '@/pages/Races/Races'
import RaceTripsPage from '@/pages/RaceTripsPage/RaceTripsPage'
import RaceTariffsPage from '@/pages/RaceTariffsPage/RaceTariffsPage'
import RacePlanningPage from '@/pages/RacePlanningPage/RacePlanningPage'
import PlanningSchedulePage from '@/pages/PlanningSchedulePage/PlanningSchedulePage'
import PlanningTariffsPage from '@/pages/PlanningTariffsPage/PlanningTariffsPage'
import TripSalesControlPage from '@/pages/TripSalesControlPage/TripSalesControlPage'
import TripTariffsPage from '@/pages/TripTariffsPage/TripTariffsPage'
import SchedulePage from '@/pages/SchedulePage/SchedulePage'
import TripSchedulePage from '@/pages/TripSchedulePage/TripSchedulePage'
import Town from '@/pages/Town/Town'
import Transports from '@/pages/Transports/Transports'
import TripItemPage from '@/pages/TripItemPage/TripItemPage'
import Users from '@/pages/Users/Users'
import Stops from '@/pages/Stops/Stops'
import Requests from '@/pages/Requests/Requests'

export const ROUTES = [
  {
    path: '/',
    element: <Requests />,
  },
  {
    path: '/admins',
    element: <Admins />,
  },
  {
    path: '/transports',
    element: <Transports />,
  },
  {
    path: '/races',
    element: <Races />,
  },
  {
    path: '/races/:raceId/trips',
    element: <RaceTripsPage />,
  },
  {
    path: '/races/:raceId/tariffs',
    element: <RaceTariffsPage />,
  },
  {
    path: '/races/:raceId/trips/:tripId/sales-control',
    element: <TripSalesControlPage />,
  },
  {
    path: '/races/:raceId/trips/:tripId/tariffs',
    element: <TripTariffsPage />,
  },
  {
    path: '/races/:raceId/schedule',
    element: <SchedulePage />,
  },
  {
    path: '/races/:raceId/trips/:tripId/schedule',
    element: <TripSchedulePage />,
  },
  {
    path: '/:tripId/requests',
    element: <TripItemPage />,
  },
  {
    path: '/races/:raceId/planning',
    element: <RacePlanningPage />,
  },
  {
    path: '/races/:raceId/planning/:planId/schedule',
    element: <PlanningSchedulePage />,
  },
  {
    path: '/races/:raceId/planning/:planId/tariffs',
    element: <PlanningTariffsPage />,
  },
  {
    path: '/drivers',
    element: <Drivers />,
  },
  {
    path: '/countries',
    element: <Country />,
  },
  {
    path: '/towns',
    element: <Town />,
  },
  {
    path: '/stops',
    element: <Stops />,
  },
  {
    path: '/consultations',
    element: <Consultations />,
  },
  {
    path: '/news',
    element: <News />,
  },
  {
    path: '/blog',
    element: <Blog />,
  },
  {
    path: '/users',
    element: <Users />,
  },
  {
    path: '*',
    element: <Error404 />,
  },
]
