import { create } from 'zustand'
import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { Icountry } from '@/interfaces/country'

const { country_api } = REQ

interface IStore {
  countries: Icountry[]
  countryCodes: any[]
  oneCountry: Icountry
  isLoading: boolean
  countryMeta: IMeta
  createCountry: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    createCountryApiHandler: (body: Partial<Icountry>) => Promise<any>
  }
  editCountry: {
    isOpenModal: boolean
    editedItem: Icountry | null
    handleOpenModal: (item: Icountry) => void
    handleCloseModal: () => void
    editCountryApiHandler: (id: string, body: Partial<Icountry>) => Promise<any>
  }
  deleteCountry: {
    isOpenModal: boolean
    deletedItem: Icountry | null
    handleOpenModal: (item: Icountry) => void
    handleCloseModal: () => void
    deleteCountryApiHandler: (id: string) => Promise<any>
  }
  getAllCountries: () => Promise<any>
  getCountryById: (id: string) => Promise<any>
  getCountryCodes: () => Promise<any>
}

export const countryStore = create<IStore>((set) => ({
  countries: [],
  countryCodes: [],
  oneCountry: {} as Icountry,
  isLoading: false,
  countryMeta: {
    count: 10,
    page: 1,
    total: 0,
  },

  createCountry: {
    isOpenModal: false,
    handleOpenModal: () => {
      set((state) => ({
        ...state,
        createCountry: {
          ...state.createCountry,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createCountry: {
          ...state.createCountry,
          isOpenModal: false,
        },
      }))
    },
    createCountryApiHandler: async (body: Partial<Icountry>) => {
      const response = await country_api.createCountry(body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 201) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  editCountry: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: Icountry) => {
      set((state) => ({
        ...state,
        editCountry: {
          ...state.editCountry,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editCountry: {
          ...state.editCountry,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editCountryApiHandler: async (id: string, body: Partial<Icountry>) => {
      const response = await country_api.editCountry(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteCountry: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item: Icountry) => {
      set((state) => ({
        ...state,
        deleteCountry: {
          ...state.deleteCountry,
          isOpenModal: true,
          deletedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteCountry: {
          ...state.deleteCountry,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteCountryApiHandler: async (id: string) => {
      const response = await country_api.deleteCountry(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  getAllCountries: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await country_api.getAllCountries()

    const { status, data } = response

    set((state) => ({ ...state, isLoading: false }))

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    set((state) => ({
      ...state,
      countries: data.countries,
    }))
    return response
  },
  getCountryById: async (id) => {
    const response = await country_api.getById(id)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, oneCountry: data }))
      return response
    }
  },

  getCountryCodes: async () => {
    const response = await country_api.getCountryCodes()

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, countryCodes: data }))
      return response
    }
  },
}))
