import {Box, CircularProgress, Typography} from "@mui/material";
import {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

const styles = {
    minHeight: "100vh",
    minWidth: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
};

const imageStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    mb: 2,
    overflow: "hidden",
};

const Loader = () => {
    const {t} = useTranslation();

    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        if (Math.random() > 0.95) {
            setIsShown(true);
        }
    }, []);

    return (
        <Box sx={styles}>
            {isShown && (
                <Box sx={imageStyle}>
                    <img
                        src={`/loaders/loader3.gif`}
                        alt="loader"
                        style={{
                            width: "300px",
                        }}
                    />
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {!isShown && <CircularProgress/>}
                <Typography color="primary" variant="h4" sx={{ml: 2}}>
                    {t("global.loader.loading")}
                </Typography>
            </Box>
        </Box>
    );
};

export default Loader;
