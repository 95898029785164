import {TableRow, TableCell} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const PlanningScheduleHeader = () => {
    const {t} = useTranslation();

    const i18nTranslateHeaderString = "schedulePage";

    const headerTitles = [
        {
            title: t(`${i18nTranslateHeaderString}.settlement`), // ? "ID"
            name: "settlement",
        },
        {
            title: t(`${i18nTranslateHeaderString}.stopPoint`), // ? "звідки"
            name: "stopPoint",
        },
        {
            title: t(`${i18nTranslateHeaderString}.distance`), // ? "куди"
            name: "distance",
        },
        {
            title: t(`${i18nTranslateHeaderString}.arrivalTime`), // ? "звідки"
            name: "arrivalTime",
        },
        {
            title: t(`${i18nTranslateHeaderString}.departureTime`), // ? "куди"
            name: "departureTime",
        },
        {
            title: t(`${i18nTranslateHeaderString}.arrivalDay`), // ? "звідки"
            name: "arrivalDay",
        },
        {
            title: t(`${i18nTranslateHeaderString}.departureDay`), // ? "куди"
            name: "departureDay",
        },
        {
            title: t(`${i18nTranslateHeaderString}.platformNumber`), // ?"статус"
            name: "platformNumber",
        },
        {
            title: t(`${i18nTranslateHeaderString}.status`), // ?"статус"
            name: "status",
        },
        {
            title: t(`${i18nTranslateHeaderString}.borderCross`), // ?"статус"
            name: "borderCross",
        },
        {
            title: t(`${i18nTranslateHeaderString}.changeTransfer`), // ?"статус"
            name: "changeTransfer",
        },
        {
            title: t(`${i18nTranslateHeaderString}.changeBus`), // ?"статус"
            name: "changeBus",
        }
    ];
    return (
        <TableRow>
            {headerTitles.map(({title, name}) => (
                <TableCell
                    key={name}
                    align="center"
                    sx={{
                        backgroundColor: "#222222 !important",
                    }}
                >
                    {title}
                </TableCell>
            ))}
        </TableRow>
    );
};

export default PlanningScheduleHeader;
