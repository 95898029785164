import {useState} from "react";
import {Alert, LinearProgress, Snackbar, Box} from "@mui/material";


import {messageStore} from "@/store/store";

interface propTypes {
    item: any;
    index: number;
    last: boolean;
}

const MessageItem = ({item, index = 0, last}: propTypes) => {
    const {clearMessages} = messageStore();

    const [isOpen, setIsOpen] = useState(item.isOpen);

    const closeAllMessages = (reason: string): void => {
        if (reason === "timeout") {
            clearMessages();
        }
    };

    const handleClose = (reason: string): void => {
        if (reason === "timeout") {
            setIsOpen(false);
            if (last) {
                closeAllMessages("timeout");
            }
        }
    };

    return (
        <Snackbar
            sx={{
                mt: `${60 * index + 1}px`,
                width: `100%`,
                maxWidth: "300px",
            }}
            open={isOpen}
            autoHideDuration={3000}
            onClose={(e, reason) => handleClose(reason)}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
        >
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Alert
                    onClose={() => setIsOpen(false)}
                    severity={item.severity}
                    sx={{width: "100%"}}
                >
                    {item.message}
                </Alert>
                <LinearProgress color={item.severity}/>
            </Box>
        </Snackbar>
    );
};

export default MessageItem;
