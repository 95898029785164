// ? hooks
import React, { useEffect, useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? stores
import { countryStore } from '@/store/countryStore'
import { townStore } from '@/store/townStore'
import { messageStore } from '@/store/store'

// ? interfaces
import { ITownCreateDTO } from '@/interfaces/town'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { Icountry } from '@/interfaces/country'
import { validateForm } from '@/utils/formValidation'
import { timezones } from '@/constants/constants'
import { stops_api } from '@/api/stops.api'

const initialState: ITownCreateDTO = {
  country_id: null,
  name_eng: '',
  name_ukr: '',
  timezone: '',
}

const CreateTownModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()

  const { countryMeta, getAllCountries, countries } = countryStore()

  const { createTown, getAllTowns, townMeta } = townStore()
  const { isOpenModal, handleCloseModal } = createTown

  const [formValues, setFormValues] = useState(initialState)
  const [cityStops, setCityStops] = useState([] as any)

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    country_id: { required: true },
    timezone: { required: true },
    name_eng: { required: true },
    name_ukr: { required: true },
  }

  const handleClose = () => {
    setFormValues(initialState)
    handleCloseModal()
  }

  const createTownApiHandler = useAsyncWrapper(createTown.createTownApiHandler, 'createTownApiHandler')
  const addCityStopApiHandler = useAsyncWrapper(stops_api.createStop, 'addCityStopApiHandler')

  const getTownsApiHandler = useAsyncWrapper(getAllTowns, 'getTownsApiHandler')
  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  // ? handlers
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)
  const selectChangeHandler = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void
  // ? handlers

  const createTownHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      setIsLoading(false)
      return
    }

    const { error: createTownError, data } = await createTownApiHandler([formValues])

    if (createTownError) {
      setIsLoading(false)
      return
    }

    const city_id = data.data.city_id

    const addCityStopPromises = cityStops.map((stop: any) => {
      return addCityStopApiHandler([{ bus_stop_location: city_id, ...stop }])
    })

    const addCityStopResults = await Promise.allSettled(addCityStopPromises)

    const addCityStopError = addCityStopResults.find((result: any) => result.status === 'rejected')

    if (addCityStopError) {
      setIsLoading(false)
      return
    }

    const { error: getTownsError } = await getTownsApiHandler([townMeta])

    if (getTownsError) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)

    addMessage({
      severity: 'success',
      message: 'Успішно створено',
      isOpen: true,
    })

    handleClose()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getCountriesError } = await getCountriesApiHandler([
        {
          ...countryMeta,
          count: 1000,
        },
      ])

      if (getCountriesError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleClose} title={'Додати місто'}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl error={!!formErrors?.country} fullWidth variant="standard">
            <InputLabel>Країна</InputLabel>
            <Select value={formValues.country_id || ''} onChange={selectChangeHandler} name="country_id" label="Країна">
              <MenuItem value="">
                <em>Виберіть країну</em>
              </MenuItem>
              {countries?.map((country: Icountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.name_ukr}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.country && <FormHelperText>{formErrors?.country}</FormHelperText>}
          </FormControl>
          <FormControl error={!!formErrors?.timezone} fullWidth variant="standard">
            <InputLabel>Часова зона</InputLabel>
            <Select
              value={formValues?.timezone || ''}
              onChange={selectChangeHandler}
              name="timezone"
              label="Часова зона">
              <MenuItem value="">
                <em>Виберіть часову зону</em>
              </MenuItem>
              {timezones?.map(({ value, label }: { value: string; label: string }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.timeZone && <FormHelperText>{formErrors?.timezone}</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Назва міста англійською</InputLabel>
            <TextField
              name="name_eng"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.name_eng}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.name_eng}
              helperText={formErrors?.name_eng}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Назва міста українською</InputLabel>
            <TextField
              name="name_ukr"
              onChange={inputChangeHandler}
              value={formValues.name_ukr}
              fullWidth
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.['name_ukr']}
              helperText={formErrors?.['name_ukr']}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={createTownHandler}>
            Створити
          </LoadingButton>

          <Button onClick={handleClose} color="error" variant="contained" size="small">
            Відмінити
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default CreateTownModal
