// ? hooks
import { useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? Store
import { adminStore } from '@/store/adminStore'

// ? Routes
import { ROUTES } from '@/routes/routes'

// ? Pages
import Auth from '@/pages/Auth/Auth'

// ? Components
import Loader from '@/components/UI/Loader/Loader'

const App = () => {
  const navigate = useNavigate()
  const { currentAdmin, checkAuth, setCurrentAdmin } = adminStore()
  const [isLoading, setIsLoading] = useState(true)

  const checkAuthApiHandler = useAsyncWrapper(checkAuth, 'checkAuth')

  useEffect(() => {
    const fetchData = async () => {
      const { error, loading } = await checkAuthApiHandler([])

      setTimeout(() => {
        setIsLoading(loading)
      }, 1000)

      if (error) {
        console.log(error)
        setCurrentAdmin(null)
        return navigate('/auth')
      }
    }

    fetchData()
  }, [])

  return (
    <Routes>
      {!isLoading && currentAdmin ? (
        <>
          {ROUTES.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </>
      ) : (
        <Route path="*" element={isLoading ? <Loader /> : <Auth />} />
      )}
    </Routes>
  )
}

export default App
