import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { tripsStore } from '@/store/tripStore'
import { messageStore } from '@/store/store'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { LoadingButton } from '@mui/lab'
import { Button, Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const DeleteTripModal = () => {
  const { t } = useTranslation()
  const params = useParams()
  const raceModalTranslations = 'raceTripsPage.deleteTripModal'

  const [isLoading, setIsLoading] = useState(false)

  const { getAllTrips, tripsMeta, searchParams, deleteTrip } = tripsStore()
  const { addMessage } = messageStore()

  const { isOpenModal, deletedItem, handleCloseModal } = deleteTrip

  const deleteTripApiHandler = useAsyncWrapper(deleteTrip.deleteTripApiHandler, 'deleteRaceApiHandler')

  const getAllRaceTripsApiHandler = useAsyncWrapper(getAllTrips, 'getAllRaceTripsApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const deleteTripHandler = async () => {
    setIsLoading(true)
    if (!deletedItem || !deletedItem?.trip_id) {
      setIsLoading(false)
      return
    }

    const { error: deleteRaceError } = await deleteTripApiHandler([deletedItem.trip_id])

    if (deleteRaceError) {
      setIsLoading(false)
      return
    }

    const { error: getAllTripsError } = await getAllTrips(Number(params.raceId))

    if (getAllTripsError) {
      console.log(getAllTripsError)
    }

    addMessage({
      severity: 'success',
      message: t(`${raceModalTranslations}.successDeleting`),
      isOpen: true,
    })

    setIsLoading(false)
    handleCloseForm()
  }

  return (
    <ModalContainer
      width={800}
      isOpen={isOpenModal}
      handleClose={handleCloseForm}
      title={t(`${raceModalTranslations}.title`)}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton loading={isLoading} color="primary" variant="contained" size="small" onClick={deleteTripHandler}>
          {t(`${raceModalTranslations}.deleteRace`)}
        </LoadingButton>

        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          {t(`${raceModalTranslations}.cancelDeleting`)}
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeleteTripModal
