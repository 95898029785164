// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? stores
import { countryStore } from '@/store/countryStore'
import { messageStore } from '@/store/store'

// ? validations
import { COUNTRY_VALIDATION } from '@/validation/country'

// ? components
import { Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

const DeleteCountryModal = () => {
  const { addMessage } = messageStore()
  const { deleteCountry, getAllCountries, countryMeta } = countryStore()
  const { isOpenModal, handleCloseModal, deletedItem } = deleteCountry

  const [isLoading, setIsLoading] = useState(false)

  const deleteCountryApiHandler = useAsyncWrapper(deleteCountry.deleteCountryApiHandler, 'deleteCountryApiHandler')

  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')

  const deleteValidation = useValidation(COUNTRY_VALIDATION.DELETE)

  const deleteCountryHandler = async () => {
    setIsLoading(true)
    const id = deletedItem?.country_id

    if (!deleteValidation(id)) {
      setIsLoading(false)
      return false
    }

    const { error: deleteCountryError } = await deleteCountryApiHandler([id])

    if (deleteCountryError) {
      setIsLoading(false)
      return false
    }

    const { error: getCountriesError } = await getCountriesApiHandler([countryMeta])

    if (getCountriesError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Успішно видалено',
      isOpen: true,
    })
    setIsLoading(false)

    handleCloseModal()
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleCloseModal} title={'Видалення країни'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={deleteCountryHandler}>
          Видалити
        </LoadingButton>
        <Button onClick={handleCloseModal} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeleteCountryModal
