import CountryTabel from '@/components/Tabels/CountryTabel/CountryTabel'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import {Typography} from '@mui/material'

const Country = () => {
    return (
        <PageWrapper>
            {true ? (
                <CountryTabel/>
            ) : (
                <Typography variant='h4' color='error'>
                    You don&apos;t have permission to view Country
                </Typography>
            )}
        </PageWrapper>
    )
}

export default Country

