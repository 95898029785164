import {Box, Tabs} from "@mui/material";
import {SetStateAction, useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';
import LinkTab from "../UI/LinkTab/LinkTab";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const UserNavigationRouting = () => {
    const location = useLocation();

    const {accountNumber} = useParams<{ accountNumber: string }>();
    const [activeRoute, setActiveRoute] = useState(
        location.pathname.split("/")[3] || "user"
    );

    const {t} = useTranslation();
    const langUserNavigationString = "userCabinet.userNavigation";

    const setCurrentUserActiveRoute = () => {
        const routes = ["transactions", "orders", "documents"];

        const route = routes.find((route) => {
            return location.pathname.includes(route);
        });

        setActiveRoute(route || "user");
    };


    const handleChange = (e: any, newValue: SetStateAction<string>) => {
        setActiveRoute(newValue);
    };

    useEffect(() => {
        setCurrentUserActiveRoute();
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                maxWidth: {xs: "100%"},
            }}
        >
            <Tabs
                value={activeRoute}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
            >
                <LinkTab value="user" href={`/`} label="All users"/>
                <LinkTab value="user" href={`/user/${accountNumber}`} label="User"/>
                <LinkTab
                    value="transactions"
                    href={`/user/${accountNumber}/transactions`}
                    label={t(`${langUserNavigationString}.transactionsLabel`)}
                />
                <LinkTab
                    value="orders"
                    href={`/user/${accountNumber}/orders`}
                    label={t(`${langUserNavigationString}.ordersLabel`)}
                />
                <LinkTab
                    value="documents"
                    href={`/user/${accountNumber}/documents`}
                    label={t(`${langUserNavigationString}.documentsLabel`)}
                />
            </Tabs>

        </Box>
    );
};

export default UserNavigationRouting;
