import {api} from "@/api";

// ? utils
import {apiCreator} from "@/utils/apiCreator";

// ? routes prefixes
import {PREFIXES} from "@/routes/prefixes";

const requestCreator = apiCreator(PREFIXES.NOTIFICATIONS);

export const notifications_api = {
    getAllNotifications: async (meta: any) =>
        requestCreator(api, "admin/get-paginate", "GET", {
            page: meta.page,
            count: meta.count,
        }),
};
