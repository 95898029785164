// ? interfaces
import { Icountry } from '@/interfaces/country'

// ? store
import { countryStore } from '@/store/countryStore'

// ? utils
import { formatDate } from '@/utils/formatDate'

// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'

interface IProps {
  country: Icountry
}

const CountryTabelRow = ({ country }: IProps) => {
  const { country_id, name_eng, name_ukr } = country

  const { editCountry, deleteCountry } = countryStore()

  const handleEditCountry = (country: Icountry) => {
    editCountry.handleOpenModal(country)
  }

  const handleDeleteCountry = (country: Icountry) => {
    deleteCountry.handleOpenModal(country)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {country_id}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`EN: ${name_eng}`}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`UA: ${name_ukr}`}
        </Typography>
      </TableCell>

      {/* <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {translates?.length ? translateString : null}
        </Typography>
      </TableCell> */}

      {/* <TableCell align="left"> */}
      {/* <Typography
            variant="body2"
            color={isActive ? 'success.main' : 'error.main'}
            sx={{
              cursor: 'pointer',
            }}>
            {isActive ? 'Активна' : 'Не активна'}
          </Typography>
        </TableCell> */}
      {/* 
        <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {formatDate(updatedAt as string, true)}
          </Typography>
        </TableCell> */}
      {/* <TableCell align="left">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {formatDate(createdAt as string, true)}
          </Typography>
        </TableCell> */}
      <TableCell align="left">
        <Tooltip title="Відредагувати">
          <IconButton color="warning" onClick={() => handleEditCountry(country)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Видалити">
          <IconButton color="error" onClick={() => handleDeleteCountry(country)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default CountryTabelRow
