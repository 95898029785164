import {useNavigate} from "react-router-dom";
import {Tab} from "@mui/material";

const LinkTab = (props: { href: string; value: string; label: string }) => {
    const navigate = useNavigate();

    return (
        <Tab
            component="a"
            onClick={(e) => {
                e.preventDefault();
                navigate(props.href);
            }}
            {...props}
        />
    );
};

export default LinkTab;
