// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: gray;
}

.date-range-custom {
    background-color: #222222;
    color: #fff;
    width: 100%;
}

.date-range-custom .rdrDayNumber span {
    color: #fff;
}

.rdrDateDisplayWrapper,
.rdrDateInput {
    background-color: #222222;
    color: #fff;
}

.rdrDateInput {
    border: 1px solid #fff;
}

.MuiMenu-list {
    max-height: 300px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,iCAAiC;AACrC;;AAEA;IACI,UAAU;AACd;;AAEA,UAAU;AACV;IACI,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA,WAAW;AACX;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA,oBAAoB;AACpB;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;;IAEI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: \"Roboto\", sans-serif;\n}\n\n::-webkit-scrollbar {\n    width: 7px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n    box-shadow: inset 0 0 5px grey;\n    border-radius: 5px;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n    background: gray;\n    border-radius: 5px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n    background: gray;\n}\n\n.date-range-custom {\n    background-color: #222222;\n    color: #fff;\n    width: 100%;\n}\n\n.date-range-custom .rdrDayNumber span {\n    color: #fff;\n}\n\n.rdrDateDisplayWrapper,\n.rdrDateInput {\n    background-color: #222222;\n    color: #fff;\n}\n\n.rdrDateInput {\n    border: 1px solid #fff;\n}\n\n.MuiMenu-list {\n    max-height: 300px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
