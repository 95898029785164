import {Add} from "@mui/icons-material";
import {Button, Grid} from "@mui/material";
import React from "react";
import CreateNewsModal from "./modals/CreateNewsModal";
import {newsStore} from "@/store/newsStore";
import TablePaginationCustom from "@/components/UI/TablePaginationCustom/TablePaginationCustom";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import DeleteNewsModal from "./modals/DeleteNewsModal";
import EditNewsModal from "./modals/EditNewsModal";
import PhotoNewsModal from "./modals/PhotoNewsModal";
import NewsFilterTabel from "./NewsFilterTabel";
import AdminArticleItem from "@/components/UI/AdminNewsItem/AdminNewsItem";

const NewsTabel = () => {
    const {
        news,
        createNew,
        newsMeta,
        getAllNews,
        filter,
    } = newsStore();

    const getAllNewsApiHandler = useAsyncWrapper(
        getAllNews,
        "getAllNewsApiHandler"
    );


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <NewsFilterTabel/>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                }}
            >
                <CreateNewsModal/>
                <DeleteNewsModal/>
                <EditNewsModal/>
                <PhotoNewsModal/>

                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<Add/>}
                    onClick={() => createNew.handleOpenModal()}
                >
                    Додати новину
                </Button>

                <TablePaginationCustom
                    getData={getAllNewsApiHandler}
                    meta={newsMeta}
                    filterQuery={filter}
                />
            </Grid>

            <Grid item container xs={12} spacing={2}>
                {news.map((newsItem) => (
                    <AdminArticleItem key={newsItem._id} newsItem={newsItem}/>
                ))}
            </Grid>
        </Grid>
    );
};

export default NewsTabel;
