// ? hooks
import React, { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { racesStore } from '@/store/racesStore'
import { messageStore } from '@/store/store'

// ? components
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

import { LoadingButton } from '@mui/lab'
import { inputsChanger } from '@/utils/inputsChanger'
import { BoardingStatus } from '@/constants/constants'
import { useParams } from 'react-router-dom'
import { formatDateString } from '@/utils/formatDate'
import { IStop } from '@/interfaces/stop'

const EditRaceStopModal = () => {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const { editRace, oneRace, editRaceStop, setOneRace } = racesStore()
  const { isOpenModal, editedItem, raceStop, handleCloseModal } = editRaceStop

  const { addMessage } = messageStore()

  const editRaceStopApiHandler = useAsyncWrapper(editRace.editRaceApiHandler, 'editRaceStopApiHandler')

  const [formValues, setFormValues] = useState<any>({
    boardingStatus: raceStop?.boardingStatus || '',
    arrivalTime: raceStop?.arrivalTime || '',
    departureTime: raceStop?.departureTime || '',
    arrivalDay: raceStop?.arrivalDay || 1,
    departureDay: raceStop?.departureDay || 1,
    platformNumber: raceStop?.platformNumber || '',
    distanceFromStart: raceStop?.distanceFromStart || 0,
    borderCross: raceStop?.borderCross || false,
    changeTransfer: raceStop?.changeTransfer || false,
    changeBus: raceStop?.changeBus || false,
  })

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const editRaceStopHandler = async () => {
    const stopKey = raceStop.stop_name_ua

    if (stopKey && editedItem) {
      const stop = editedItem.stops_along.stops.find((stop: any) => stop.stop_name_ua === stopKey)

      if (!stop) {
        return
      }

      setIsLoading(true)

      const updatedStopsAlong = {
        stops: editedItem.stops_along.stops.map((stop: any) => {
          if (stop.stop_name_ua === stopKey) {
            return {
              ...stop,
              ...formValues,
            }
          }

          return stop
        }),
      }

      const updatedStopsAlongString = JSON.stringify(updatedStopsAlong)

      const updatedRace = {
        ...editedItem,
        stops_along: updatedStopsAlongString,
        begins_at: formatDateString(editedItem?.begins_at),
        expires_at: formatDateString(editedItem?.expires_at),
      }

      try {
        const { error: editError } = await editRaceStopApiHandler([Number(params.raceId), updatedRace])

        if (editError) {
          setIsLoading(false)
          throw new Error(editError)
        }

        // @ts-ignore
        setOneRace({
          ...editedItem,
          stops_along: JSON.parse(updatedRace.stops_along),
        })

        addMessage({
          severity: 'success',
          message: 'Зупинку успішно відредаговано',
          isOpen: true,
        })
      } catch (error) {
        console.error('Failed to create race stop', error)
        addMessage({
          severity: 'error',
          message: 'Не вдалося відредагувати зупинку',
          isOpen: true,
        })
      } finally {
        setIsLoading(false)
        handleCloseForm()
      }
    }
  }

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleInputChange = inputsChanger('input', formValues, setFormValues) as (e: any) => void

  const handleCheckboxChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.checked })
  }

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleCloseForm} title="Редагування зупинки">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Стан Посадка/Висадка</InputLabel>
            <Select value={formValues?.boardingStatus || 'initial'} name="boardingStatus" onChange={handleChangeSelect}>
              <MenuItem value={'initial'}>Виберіть зі списку</MenuItem>
              {Object.entries(BoardingStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Прибуття</InputLabel>
            <TextField
              name="arrivalTime"
              variant="standard"
              type="time"
              value={formValues?.arrivalTime || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Відправлення</InputLabel>
            <TextField
              name="departureTime"
              variant="standard"
              type="time"
              value={formValues?.departureTime || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Доба рейсу для прибуття</InputLabel>
            <TextField
              name="arrivalDay"
              variant="standard"
              type="number"
              value={formValues?.arrivalDay || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Доба рейсу для відправлення</InputLabel>
            <TextField
              name="departureDay"
              variant="standard"
              type="number"
              value={formValues?.departureDay || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Номер платформи</InputLabel>
            <TextField
              name="platformNumber"
              variant="standard"
              value={formValues?.platformNumber || ''}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Відстань від початкової</InputLabel>
            <TextField
              name="distanceFromStart"
              variant="standard"
              type="number"
              value={formValues?.distanceFromStart || 'initial'}
              onChange={handleInputChange}
              sx={{
                mt: '16px',
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '20px',
          }}>
          <FormControlLabel
            control={<Checkbox name="borderCross" checked={formValues.borderCross} onChange={handleCheckboxChange} />}
            label="Кордон"
          />
          <FormControlLabel
            control={
              <Checkbox name="changeTransfer" checked={formValues.changeTransfer} onChange={handleCheckboxChange} />
            }
            label="Пересадка"
          />
          <FormControlLabel
            control={<Checkbox name="changeBus" checked={formValues.changeBus} onChange={handleCheckboxChange} />}
            label="Заміна автобуса"
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            size="small"
            onClick={editRaceStopHandler}>
            Зберегти
          </LoadingButton>
          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default EditRaceStopModal
