// ? hooks
import { useEffect } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? store
import { driversStore } from '@/store/driverStore'

// ? interfaces

// ? utils

// ? components
import { Box, Grid, Button, Table, TableHead, TableBody, TableContainer } from '@mui/material'

import DriverTabelHeader from './DriverTabelHeader'
import DriverTabelRow from './DriverTabelRow'

import DriverCreateModal from './modals/DriverCreateModal'
import DriverEditModal from './modals/DriverEditModal'
import DriverDeleteModal from './modals/DriverDeleteModal'

import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

// ? icons
import { Add } from '@mui/icons-material'

const DriverTabel = () => {
  const { drivers, driversMeta, createDriver, getAllDrivers, deleteDriver, editDriver, isLoading } = driversStore()

  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')

  const handleOpenCreateModal = () => {
    createDriver.handleOpenModal()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getDriversError } = await getDriversApiHandler([driversMeta])

      if (getDriversError) {
        return false
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {createDriver.isOpenModal && <DriverCreateModal />}
      {editDriver.isOpenModal && <DriverEditModal />}
      {deleteDriver.isOpenModal && <DriverDeleteModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Створити водія
          </Button>
        </Box>

        {drivers?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <DriverTabelHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  drivers.map((driver, index) => <DriverTabelRow driver={driver} key={index} />)
                ) : (
                  <LoadingTableData columns={7} rows={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Немає водіїв</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Створити водія
            </Button>
          </Box>
        )}

        <TablePaginationCustom getData={getDriversApiHandler} meta={driversMeta} />
      </Grid>
    </Grid>
  )
}

export default DriverTabel
