import ModalContainer from "@/components/UI/Modal/ModalContainer";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import {racesStore} from "@/store/racesStore";
import {messageStore} from "@/store/store";
import {LoadingButton} from "@mui/lab";
import {Button, Box, Typography} from "@mui/material";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {tripsStore} from "@/store/tripStore";

const DeleteRaceStopModal = () => {
    const params = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const {addMessage} = messageStore();

    const {getTripById, deleteTripRaceStop} = tripsStore();
    const {handleOpenModal, deletedItem, raceStopId, isOpenModal, handleCloseModal} = deleteTripRaceStop;

    const deleteTripRaceStopsApiHandler = useAsyncWrapper(
        deleteTripRaceStop.deleteTripRaceStopsApiHandler,
        "deleteTripRaceStopsApiHandler"
    );

    const handleCloseForm = () => {
        handleCloseModal();
    };

    const deleteRaceHandler = async () => {
        setIsLoading(true);

        if (!deletedItem || !deletedItem?._id) {
            setIsLoading(false);
            return;
        }

        const data = {
            editType: "deleteTripRaceStop",
            deleteRaceStopId: raceStopId
        }

        const {error: deleteRaceStopError} = await deleteTripRaceStopsApiHandler([
            deletedItem._id,
            data
        ]);

        if (deleteRaceStopError) {
            setIsLoading(false);
            return;
        }

        const {error: getRaceByIdError} = await getTripById(
            params.tripId ?? '',
            'raceStops'
        );

        if (getRaceByIdError) {
            console.log(getRaceByIdError);
        }

        setIsLoading(true);

        addMessage({
            severity: "success",
            message: "Зупинку успішно видалено",
            isOpen: true,
        });

        handleCloseForm();
    };

    console.log("Loading...", isLoading);

    return (
        <ModalContainer
            width={800}
            isOpen={isOpenModal}
            handleClose={handleCloseForm}
            title="Видалити зупинку"
        >
            <Typography variant="subtitle1" color="text.primary">

            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 1,
                }}
            >
                <LoadingButton
                    loading={isLoading}
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={deleteRaceHandler}
                >
                    Видалити
                </LoadingButton>

                <Button
                    onClick={handleCloseForm}
                    color="error"
                    variant="contained"
                    size="small"
                    sx={{ml: 1}}
                >
                    Скасувати
                </Button>
            </Box>
        </ModalContainer>
    );
};

export default DeleteRaceStopModal;
