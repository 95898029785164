import FileUploader from "@/components/UI/FileUploader/FileUploader";
import ModalContainer from "@/components/UI/Modal/ModalContainer";
import {newsStore} from "@/store/newsStore";
import {LoadingButton} from "@mui/lab";
import {Typography, Button} from "@mui/material";
import {Box} from "@mui/system";
import React, {useState} from "react";

const PhotoNewsModal = () => {
    const {getAllNews, newsMeta, filter, photoNew} = newsStore();
    const {isOpenModal, handleCloseModal, editedItem, deletePhotoNew} =
        photoNew;

    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<any>([]);
    const [formValues, setFormValues] = useState<any>({});

    const handleUploadPhoto = async () => {
        if (!editedItem?._id) return;

        const formData = new FormData();

        for (const key in formValues) {
            formData.append(key, formValues[key]);
        }

        files.forEach((file: any) => {
            const blob = new Blob([file], {type: file.type});

            formData.append("images", blob);
        });

        setIsLoading(true);
        await photoNew.uploadPhotoNew(editedItem?._id, formData);
        setIsLoading(false);
        setFiles([]);
    }

    const deletePhotoHandler = async (fileName: string) => {
        if (!editedItem?._id) return;

        setIsLoading(true);
        await deletePhotoNew(editedItem?._id, fileName);
        setIsLoading(false);
    };

    const handleCloseModalHandler = async () => {
        await getAllNews(newsMeta, filter);
        handleCloseModal();
        setFiles([]);
    };

    return (
        <ModalContainer
            width={1000}
            isOpen={isOpenModal}
            handleClose={handleCloseModalHandler}
            title="Керування фотографіями новини"
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "80vh",
                    overflow: "auto",
                    p: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                    }}
                >
                    {editedItem?.images &&
                    editedItem?.images.map((image: any, index: number) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    maxWidth: "300px",
                                    overflow: "hidden",
                                    m: 1,
                                    p: 1,
                                    "& img": {
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                    },
                                }}
                            >
                                <img
                                    src={`${process.env.REACT_APP_DEV_BASE_API_URL}/${image?.path}`}
                                    alt=""
                                />
                                <LoadingButton
                                    loading={isLoading}
                                    color="error"
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    onClick={() => deletePhotoHandler(image.name)}
                                >
                                    Видалити фото
                                </LoadingButton>
                            </Box>
                        );
                    })}
                </Box>

                <FileUploader setFiles={setFiles} files={files}/>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                    }}
                >

                    {
                        files.length > 0 && (<Button
                            onClick={handleUploadPhoto}
                            color="success"
                            variant="contained"
                            fullWidth
                            size="small"
                            sx={{ml: 1}}
                        >
                            Завантажити фото
                        </Button>)
                    }

                    <Button
                        onClick={handleCloseModalHandler}
                        color="error"
                        variant="contained"
                        fullWidth
                        size="small"
                        sx={{ml: 1}}
                    >
                        Закрити модальне вікно
                    </Button>
                </Box>
            </Box>
        </ModalContainer>
    );
};

export default PhotoNewsModal;
