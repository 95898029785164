// ? hooks
import { useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? stores
import { townStore } from '@/store/townStore'
import { messageStore } from '@/store/store'

// ? validations
import { TOWN_VALIDATION } from '@/validation/town'

// ? components
import { Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

const DeleteTownModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { addMessage } = messageStore()
  const { deleteTown, getAllTowns, townMeta } = townStore()
  const { isOpenModal, handleCloseModal, deletedItem } = deleteTown

  // ? API handlers
  const deleteTownApiHandler = useAsyncWrapper(deleteTown.deleteTownApiHandler, 'deleteTownApiHandler')
  const getTownsApiHandler = useAsyncWrapper(getAllTowns, 'getTownsApiHandler')
  const deleteTownValidation = useValidation(TOWN_VALIDATION.DELETE)
  // ? API handlers

  const handleClose = () => {
    handleCloseModal()
  }

  const deleteTownHandler = async () => {
    setIsLoading(true)

    const id = deletedItem?.city_id
    if (!deleteTownValidation(id)) {
      return false
    }

    const { error: deleteTownError } = await deleteTownApiHandler([id])

    if (deleteTownError) {
      setIsLoading(false)
      return false
    }

    const { error: getTownsError } = await getTownsApiHandler([townMeta])

    if (getTownsError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Успішно видалено',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  return (
    <ModalContainer
      width={800}
      isOpen={isOpenModal}
      handleClose={handleClose}
      title={`Видалення ${deletedItem?.name_ukr}`}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton loading={isLoading} color="error" variant="contained" size="small" onClick={deleteTownHandler}>
          Видалити
        </LoadingButton>

        <Button onClick={handleClose} color="primary" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeleteTownModal
