import { create } from 'zustand'

import { REQ } from '@/api'
import { ITransport, ITransportCreateDTO } from '@/interfaces/transport'
import { IMeta } from '@/interfaces/general'

const { transports_api } = REQ

interface IStore {
  isLoading: boolean
  transports: ITransport[]
  oneTransport: ITransport
  transportMeta: IMeta
  infoTransportModal: boolean
  setInfoTransportModal: (value: boolean) => void
  createTransport: (body: ITransportCreateDTO) => Promise<any>
  getAllTransports: () => Promise<any>
  editTransport: {
    editedItem: ITransport | null
    handleOpenModal: (item: ITransport) => void
    handleCloseModal: () => void
    isOpenModal: boolean
    editTransportApiHandler: (id: string, body: Partial<ITransport>) => Promise<any>
  }
  deleteTransport: {
    deletedItem: ITransport | null
    handleOpenModal: (item: ITransport) => void
    handleCloseModal: () => void
    isOpenModal: boolean
    deleteTransportApiHandler: (id: string) => Promise<any>
  }
  photosTransport: {
    transportItem: ITransport | null
    handleOpenModal: (item: ITransport) => void
    handleCloseModal: () => void
    isOpenModal: boolean
    deleteFileApiHandler: (transportId: string, fileName: string) => Promise<any>
    addPhotoTransport: (transportId: string, body: any) => Promise<any>
  }
  getTransportById: (id: string) => Promise<any>
}

export const transportStore = create<IStore>((set) => ({
  isLoading: false,
  transports: [],
  oneTransport: {} as ITransport,
  transportMeta: {
    page: 1,
    count: 10,
    total: 0,
  },
  infoTransportModal: false,
  setInfoTransportModal: (value) => {
    set((state) => ({ ...state, infoTransportModal: value }))
  },
  editTransport: {
    editedItem: null,
    handleOpenModal: (item) => {
      set((state) => ({
        ...state,
        editTransport: {
          ...state.editTransport,
          editedItem: item,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editTransport: {
          ...state.editTransport,
          isOpenModal: false,
        },
      }))
    },
    isOpenModal: false,
    editTransportApiHandler: async (id, body) => {
      const response = await transports_api.updateTransport(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deleteTransport: {
    deletedItem: null,
    handleOpenModal: (item) => {
      set((state) => ({
        ...state,
        deleteTransport: {
          ...state.deleteTransport,
          deletedItem: item,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteTransport: {
          ...state.deleteTransport,
          isOpenModal: false,
        },
      }))
    },
    isOpenModal: false,
    deleteTransportApiHandler: async (id) => {
      const response = await transports_api.deleteTransport(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  photosTransport: {
    transportItem: null,
    handleOpenModal: (item) => {
      set((state) => ({
        ...state,
        photosTransport: {
          ...state.photosTransport,
          transportItem: item,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        photosTransport: {
          ...state.photosTransport,
          isOpenModal: false,
          transportItem: null,
        },
      }))
    },
    isOpenModal: false,
    deleteFileApiHandler: async (transportId, fileName) => {
      const response = await transports_api.deletePhotoTransport(transportId, fileName)

      const { status, data } = response

      set((state) => ({
        ...state,
        photosTransport: {
          ...state.photosTransport,
          transportItem: null,
          isOpenModal: false,
        },
      }))

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },

    addPhotoTransport: async (transportId, body) => {
      const response = await transports_api.addPhotoTransport(transportId, body)

      const { status, data } = response

      set((state) => ({
        ...state,
        photosTransport: {
          ...state.photosTransport,
          isOpenModal: false,
          transportItem: null,
        },
      }))

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 201) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  createTransport: async (body) => {
    const response = await transports_api.createTransport(body)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 201) {
      throw new Error(data)
    } else {
      return response
    }
  },
  getAllTransports: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await transports_api.getAllTransports()

    const { status, data } = response

    if (!status || !data) {
      set((state) => ({ ...state, isLoading: false }))
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      set((state) => ({ ...state, isLoading: false }))

      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        transports: data.cars,
      }))
      setTimeout(() => {
        set((state) => ({ ...state, isLoading: false }))
      }, 400)
      return response
    }
  },
  getTransportById: async (id) => {
    const response = await transports_api.getById(id)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        oneTransport: data,
      }))
      return response
    }
  },
}))
