import React, { useEffect, useState } from 'react'
import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { driversStore } from '@/store/driverStore'
import { formatDate } from '@/utils/formatDate'
import { Button, Box, FormControl, InputLabel, Select, MenuItem, IconButton, FormHelperText } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { IDriver } from '@/interfaces/dirvers'
import { LoadingButton } from '@mui/lab'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { tripsStore } from '@/store/tripStore'
import { messageStore } from '@/store/store'
import { validateForm } from '@/utils/formValidation'

const InfoDriverModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { addMessage } = messageStore()

  const { drivers, getAllDrivers, driversMeta, infoDriverModal, setInfoDriverModal } = driversStore()

  const { getAllTrips, tripDriversModal, tripsMeta, searchParams } = tripsStore()

  const { isOpenModal, editedItem, handleCloseModal } = tripDriversModal

  const [rows, setRows] = useState<number[]>([Date.now()])
  const [selectedOptions, setSelectedOptions] = useState<Record<number, IDriver>>({})
  const [formValues, setFormValues] = useState<any>([])

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {}

  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')

  const editTripDriversApiHandler = useAsyncWrapper(
    tripDriversModal.editTripDriversApiHandler,
    'editTripDriversApiHandler'
  )

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllDriversError } = await getDriversApiHandler([
        {
          ...driversMeta,
          count: 1000,
        },
      ])

      if (getAllDriversError) {
        console.log(getAllDriversError)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    let driver
    setFormValues([])
    for (const key in selectedOptions) {
      if (selectedOptions.hasOwnProperty(key)) {
        driver = drivers?.find((driver) => driver?._id === selectedOptions[key]._id)
        if (driver) {
          setFormValues([...formValues, driver])
        }
      }
    }

    const newRows = Object.keys(selectedOptions).map(Number)
    setRows([...newRows])
  }, [selectedOptions])

  // useEffect(() => {
  //   const newSelectedOptions = editedItem?.drivers?.reduce<Record<number, IDriver>>((acc, driver, index) => {
  //     const newId = Date.now() + index
  //     const { createdAt, updatedAt, __v, ...driverInfo } = driver
  //     acc[newId] = driverInfo
  //     return acc
  //   }, {})

  //   console.log('newSelectedOptions', newSelectedOptions, editedItem?.drivers)

  //   if (newSelectedOptions) {
  //     setSelectedOptions(newSelectedOptions)
  //   }
  // }, [editedItem])

  const handleAddRow = (): void => {
    const newRow: number = Date.now()
    setRows([...rows, newRow])
    // setSelectedOptions({
    //     ...selectedOptions,
    //     [newRow]: {
    //         firstName: '',
    //         lastName: '',
    //         phoneNumber: '',
    //         badHabit: '',
    //         driverFeatures: ''
    //     }
    // });
  }

  const handleChange = (id: number, field: keyof IDriver, value: string): void => {
    const updatedOptions = { ...selectedOptions, [id]: { ...selectedOptions[id], [field]: value } }
    setSelectedOptions(updatedOptions)
  }

  const handleDeleteRow = (id: number): void => {
    const newRows = rows.filter((row) => row !== id)
    setRows(newRows)
    const { [id]: removed, ...newOptions } = selectedOptions
    setSelectedOptions(newOptions)
  }

  const editTripDriversHandler = async () => {
    setIsLoading(true)

    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)
      return
    }

    const { error: editTripDriversError } = await editTripDriversApiHandler([
      editedItem?.trip_id,
      {
        drivers: formValues,
      },
    ])

    if (editTripDriversError) {
      setIsLoading(false)
      return
    }

    const { error: getAllTripsError } = await getAllTrips()

    if (getAllTripsError) {
      console.log(getAllTripsError)
    }

    setIsLoading(false)
    addMessage({
      severity: 'success',
      message: 'Поїздку успішно відредаговано',
      isOpen: true,
    })
    handleClose()
  }

  const handleClose = () => {
    handleCloseModal()
  }

  console.log('formValues', formValues, selectedOptions)

  return (
    <ModalContainer width={700} isOpen={isOpenModal} handleClose={handleClose} title="Інформація про водіїв">
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            mt: 1,
          }}>
          {rows?.map((row, index) => (
            <Box
              key={row}
              sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                alignItems: 'flex-end',
                mb: 2,
              }}>
              <FormControl fullWidth variant="standard">
                <InputLabel id={`select-label-${row}`}>Водій {index + 1}</InputLabel>
                <Select
                  labelId={`select-label-${row}`}
                  id={`select-${row}`}
                  value={selectedOptions[row]?._id || 'initial'}
                  onChange={(e) => handleChange(row, '_id', e.target.value)}>
                  <MenuItem value="initial">Виберіть зі списку</MenuItem>
                  {drivers?.length
                    ? drivers.map((driver) => {
                        return (
                          <MenuItem key={driver._id} value={driver._id}>
                            {`${driver?.firstName ?? '-'} ${driver?.lastName ?? '-'}`}
                          </MenuItem>
                        )
                      })
                    : null}
                </Select>
              </FormControl>
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Box>
          ))}
          <Button
            variant="contained"
            onClick={handleAddRow}
            sx={{
              mt: 3,
            }}>
            Додати рядок
          </Button>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton color="primary" variant="contained" size="small" onClick={editTripDriversHandler}>
            Зберегти
          </LoadingButton>

          <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            Скасувати
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default InfoDriverModal
