import { TableRow, TableCell } from '@mui/material'
import React from 'react'

const TripItemUsersHeader = () => {
  const headerTitles = [
    {
      title: 'ПІБ', // ? "ID"
      name: '',
    },
    {
      title: 'Маршрут', // ? "звідки"
      name: '',
    },
    {
      title: "Спосіб зв'язку", // ? "звідки"
      name: '',
    },
    {
      title: 'Номер телефону', // ? "звідки"
      name: '',
    },
    {
      title: 'Кількість багажу', // ? "куди"
      name: '',
    },
    {
      title: 'Кастомна адреса виїзду', // ? "куди"
      name: '',
    },
    {
      title: 'Кількість дорослих', // ?"ціна"
      name: '',
    },
    {
      title: 'Кількість дитячих', // ?"статус"
      name: '',
    },
    {
      title: 'Нотатка', // ?"статус"
      name: '',
    },
    {
      title: 'Статус', // ?"статус"
      name: '',
    },
    {
      title: 'Дії', // ?"статус"
      name: '',
    },
  ]
  return (
    <TableRow>
      {headerTitles.map(({ title }) => (
        <TableCell
          key={title}
          sx={{
            backgroundColor: '#222222 !important',
          }}>
          {title}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default TripItemUsersHeader
