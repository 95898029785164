import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ModalContainer from "@/components/UI/Modal/ModalContainer";
import {LoadingButton} from "@mui/lab";
import {Button, Box, Typography} from "@mui/material";
import {Document, Page, pdfjs} from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {tripRequestsStore} from "@/store/tripRequestsStore";

interface IProps {
    isOpenModal: boolean;
    setIsOpenModal: (status: boolean) => void;
}

const PDFViewModal = ({isOpenModal, setIsOpenModal}: IProps) => {
    const {t} = useTranslation();
    const raceModalTranslations = "racesPage.deleteRaceModal";

    const [isLoading, setIsLoading] = useState(false);
    const [numPages, setNumPages] = useState<any>(null);

    const {
        pdfBlob
    } = tripRequestsStore();

    const onDocumentLoadSuccess = ({numPages: any}: any) => {
        setNumPages(numPages);
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    const handleCloseForm = () => {
        setIsOpenModal(false);
    };

    console.log("numPages", numPages)

    return (
        <ModalContainer
            width={1100}
            isOpen={isOpenModal}
            handleClose={handleCloseForm}
            title={t("tripRequestsPage.previewModalTitle")}
        >
            <Box
                sx={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        maxHeight: "500px",
                        display: "flex",
                        justifyContent: "center",
                        mt: 1,
                        overflowY: "scroll",
                    }}
                >
                    <Document
                        file={pdfBlob}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={'Завантаження даних...'}
                        noData={'Дані не знайдено.'}
                        error={'Помилка завантаження даних.'}
                    >
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    scale={1.7}
                                />
                            ),
                        )}
                    </Document>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                    }}
                >
                    <Button
                        onClick={handleCloseForm}
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{ml: 1}}
                    >
                        {t("tripRequestsPage.closeBtn")}
                    </Button>
                </Box>
            </Box>
        </ModalContainer>
    );
};

export default PDFViewModal;
