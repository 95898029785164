import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {
    Grid,
    Button,
    Box,
    Typography,
    FormControlLabel,
    Switch,
    useTheme,
} from "@mui/material";
import {West} from "@mui/icons-material";
import {racesStore} from "@/store/racesStore";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";
import {getTranslateValue} from "@/utils/getTranslateValue";
import {DataGrid, useGridApiRef} from "@mui/x-data-grid";
import {LoadingButton} from "@mui/lab";
import {tripsStore} from "@/store/tripStore";
import {townStore} from "@/store/townStore";
import {messageStore} from "@/store/store";
import {formatDate} from "@/utils/formatDate";
import "./styles.css";

const TripSalesControl = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const params = useParams();
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(false);
    const [isShowSegments, setIsShowSegments] = useState(false);

    const colors = [
        theme.palette.success.main,
        theme.palette.warning.main,
    ];

    const [rowsData, setRowsData] = useState<any[]>([]);
    const [columnsData, setColumnsData] = useState<any[]>([]);

    const {oneRace, getRaceById} = racesStore();
    const {currentTrip, getTripById, tripSalesControl} = tripsStore();
    const {townMeta, getAllTowns} = townStore();

    const {handleOpenModal, editedItem, handleCloseModal} = tripSalesControl;

    const {addMessage} = messageStore();

    const [formValues, setFormValues] = useState<any>({
        currency: 'euro',
        tariffType: 'fixed',
        insuranceFee: '',
        insuranceFeeType: 'percentage'
    });

    const editTripSalesControlApiHandler = useAsyncWrapper(
        tripSalesControl.editTripSalesControlApiHandler,
        "editTripSalesControlApiHandler"
    );

    const getAllTownsApiHandler = useAsyncWrapper(
        getAllTowns,
        "getAllTownsApiHandler"
    );

    useEffect(() => {
        const fetchRaceData = async () => {
            const {error: getRaceByIdError} = await getRaceById(
                params.raceId ?? ''
            );

            if (getRaceByIdError) {
                console.log(getRaceByIdError);
            }

            const {error: getTripByIdError} = await getTripById(
                params.tripId ?? '',
                'middleStep'
            );

            if (getTripByIdError) {
                console.log(getTripByIdError);
            }
        }

        fetchRaceData()
            .catch(console.error);
    }, [params.raceId, params.tripId]);

    const handleCloseForm = () => {
        handleCloseModal();
    };

    const editTripSalesControlHandler = async () => {
        setIsLoading(true);

        const data = {
            editType: "editTripSales",
            ...formValues
        }

        const {error: editTripSalesControlError} = await editTripSalesControlApiHandler([
            currentTrip?._id,
            data,
        ]);

        if (editTripSalesControlError) {
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        addMessage({
            severity: "success",
            message: "Тарифи успішно оновлено",
            isOpen: true,
        });
        handleCloseForm();
    };

    useEffect(() => {
        setFormValues(editedItem);

        const fetchData = async () => {
            const {error: getAllTownsError} = await getAllTownsApiHandler([
                {
                    ...townMeta,
                    count: 1000,
                },
            ]);

            if (getAllTownsError) return;
        };

        fetchData();
    }, []);

    const processTripSalesUpdate = (newRow: any) => {
        const updatedRow = {...newRow};
        let chainTrips: any[] | undefined = [];

        let status = currentTrip?.status;
        Object.keys(updatedRow).forEach((key) => {
            if (updatedRow[getTranslateValue(currentTrip?.toLocationCity, "ua").replace(/ /g, '')]?.id === currentTrip?.tripId) {
                status = updatedRow[getTranslateValue(currentTrip?.toLocationCity, "ua").replace(/ /g, '')].status;
            }

            chainTrips = currentTrip?.chainTrips?.map((item: any) => {
                if (item?.tripId === updatedRow[getTranslateValue(item?.toLocationCity, "ua").replace(/ /g, '')]?.id
                    && getTranslateValue(item.toLocationCity, "ua").replace(/ /g, '') === key) {

                    item.status = updatedRow[getTranslateValue(item.toLocationCity, "ua").replace(/ /g, '')].status;
                }
                return item;
            })
        });

        setFormValues({
            ...formValues,
            status: status,
            chainTrips: chainTrips
        });

        return updatedRow;
    };

    useEffect(() => {
        setFormValues({});

        let chainTrips = currentTrip?.chainTrips;
        chainTrips?.sort((a, b) => {
            if (a?.tripId && b?.tripId) {
                return a.tripId.localeCompare(b.tripId);
            }

            if (a?.tripId) return -1;
            if (b?.tripId) return 1;
            return 0;
        });

        console.log("chainTrips", chainTrips)

        const fromCities = Array.from(new Set(chainTrips?.map((item: any) => getTranslateValue(item.fromLocationCity, "ua"))));
        const toCities = Array.from(new Set(chainTrips?.map((item: any) => getTranslateValue(item.toLocationCity, "ua"))));

        console.log("fromCities", fromCities, toCities)
        if (fromCities && toCities) {
            const columns = [
                {field: 'fromLocationCity', headerName: 'від  \\  до', width: 300, sortable: false},
                ...toCities.map(city => ({
                    field: city,
                    headerName: city,
                    width: 80,
                    sortable: false,
                    focus: false,
                    renderCell: (params: any) => {
                        let bgColor = theme.palette.grey["800"];
                        let disable = false;
                        console.log("params.field", params.field, params.row[params.field])
                        if (params.row[params.field.replace(/ /g, '')]?.price === 0) {
                            bgColor = theme.palette.error.main;
                            disable = true;
                        } else if (params.row[params.field.replace(/ /g, '')]?.status === 'active') {
                            bgColor = colors[0];
                        } else if (params.row[params.field.replace(/ /g, '')]?.status === 'inactive') {
                            bgColor = colors[1];
                        } else if (params.row[params.field.replace(/ /g, '')]?.price === null) {
                            disable = true;
                        }

                        const onClick = (e: any) => {
                            e.stopPropagation();
                            if (!disable && params.row[params.field.replace(/ /g, '')]) {
                                let newStatus = '';
                                if (params.row[params.field.replace(/ /g, '')]?.status === 'active') {
                                    newStatus = 'inactive';
                                } else if (params.row[params.field.replace(/ /g, '')]?.status === 'inactive') {
                                    newStatus = 'active';
                                } else {
                                    return
                                }

                                console.log("params1", params)
                                const updatedRow = {
                                    id: params.id,
                                    fromLocationCity: params.row.fromLocationCity,
                                    [params.field.replace(/ /g, '')]: {
                                        ...params.row[params.field.replace(/ /g, '')],
                                        status: newStatus
                                    }
                                };

                                params.api.updateRows([updatedRow]);

                                processTripSalesUpdate(updatedRow);
                            }
                        };

                        return (
                            params.value !== null
                                ? <button
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        border: "none",
                                        backgroundColor: bgColor
                                    }}
                                    onClick={onClick}
                                ></button>
                                : ''
                        )
                    },
                })),
            ];

            setColumnsData(columns);

            const rows: any[] = fromCities.map((fromCity: any, index) => {
                const row: any = {id: index, fromLocationCity: fromCity};
                toCities.forEach((toCity: any) => {
                    const route = chainTrips?.find((item: any) => {
                            return getTranslateValue(item.fromLocationCity, "ua") === fromCity && getTranslateValue(item.toLocationCity, "ua") === toCity;
                        }
                    );

                    const reverseRoute = chainTrips?.find((item: any, index: number) => {
                            return getTranslateValue(item.fromLocationCity, "ua") === toCity && getTranslateValue(item.toLocationCity, "ua") === fromCity;
                        }
                    );

                    if (getTranslateValue(currentTrip?.fromLocationCity, "ua") === fromCity && getTranslateValue(currentTrip?.toLocationCity, "ua") === toCity) {
                        row[toCity.replace(/ /g, '')] = {
                            id: currentTrip?.tripId,
                            status: currentTrip ? currentTrip?.status : 'inactive',
                            price: currentTrip ? currentTrip?.price : 0
                        }
                    } else if ((fromCity.replace(/ /g, '') === toCity.replace(/ /g, '')) || (route === undefined)) {
                        row[toCity.replace(/ /g, '')] = {
                            id: route?.tripId,
                            status: '',
                            price: null
                        }
                    } else {
                        row[toCity.replace(/ /g, '')] = {
                            id: route?.tripId,
                            status: route ? route?.status : 'inactive',
                            price: route ? route?.price : 0
                        }
                    }

                });
                return row;
            });

            setRowsData(rows);

            console.log("rows", rows)
        }
    }, [currentTrip]);

    const handleSwitchChange = (event: any) => {
        setIsShowSegments(event.target.checked);
    };

    console.log("formValues", formValues)

    return (
        <Grid container spacing={1} sx={{pr: 1}}>

            <Grid item xs={12}>

                <Box sx={{
                    my: 1,
                    display: 'flex',
                    align: 'center',
                    gap: '10px'
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="createUserBtn"
                        startIcon={<West className="goBack"/>}
                        onClick={() => navigate(-1)}
                    >
                        {t("common.goBack")}
                    </Button>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        {
                            `Рейси / ${oneRace?.raceNum ?? ''} 
                            ${getTranslateValue(oneRace?.fromLocationCity, "ua") ?? ''} - 
                            ${getTranslateValue(oneRace?.toLocationCity, "ua") ?? ''} 
                            / Поїздка: ${
                                (currentTrip?.departureDate && currentTrip?.arrivalDate)
                                    ? (formatDate(currentTrip?.departureDate, true) + ' - ' + formatDate(currentTrip?.arrivalDate, true))
                                    : ''
                            } / Керування продажем по сегментам`
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px"
                    }}
                >

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                            gap: "20px"
                        }}
                    >
                        {/*<FormControlLabel*/}
                        {/*    control={*/}
                        {/*        <Switch*/}
                        {/*            checked={isShowSegments}*/}
                        {/*            onChange={handleSwitchChange}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*    label="Показати приховані сегменти"*/}
                        {/*/>*/}
                        <Box
                            sx={{
                                display: "flex",
                                gap: "20px"
                            }}
                        >
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                mt: 1,
                                mb: 1,
                            }}>
                                <Box
                                    sx={{
                                        height: "20px",
                                        width: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: theme.palette.success.main,
                                    }}
                                >
                                </Box>
                                Продаж відкритий
                            </Box>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                mt: 1,
                                mb: 1,
                            }}>
                                <Box
                                    sx={{
                                        height: "20px",
                                        width: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: theme.palette.warning.main,
                                    }}
                                >
                                </Box>
                                Продаж закритий
                            </Box>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                mt: 1,
                                mb: 1,
                            }}>
                                <Box
                                    sx={{
                                        height: "20px",
                                        width: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: theme.palette.grey["800"],
                                    }}
                                >
                                </Box>
                                Сегмент недоступний відповідно до розкладу
                            </Box>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                mt: 1,
                                mb: 1,
                            }}>
                                <Box
                                    sx={{
                                        height: "20px",
                                        width: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: theme.palette.error.main,
                                    }}
                                >
                                </Box>
                                Відсутні тарифи
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexFlow: "column",
                            justifyContent: "center"
                        }}
                    >
                        <Box
                            sx={{
                                width: "max-content",
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                        >
                            <DataGrid
                                apiRef={apiRef}
                                rows={rowsData}
                                columns={columnsData}
                                localeText={{noRowsLabel: "Маршрутів поки немає"}}
                                getRowId={(row: any) => row.id}
                                processRowUpdate={processTripSalesUpdate}
                                editMode="row"
                                disableColumnMenu
                                hideFooterSelectedRowCount
                                autoHeight={true}
                                className="salesControlTabel"
                                sx={{
                                    width: "max-content"
                                }}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                            mt: 1,
                        }}
                    >
                        <LoadingButton
                            // loading={isLoading}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={editTripSalesControlHandler}
                        >
                            Зберегти
                        </LoadingButton>
                    </Box>
                </Box>

            </Grid>
        </Grid>
    );
};

export default TripSalesControl;
