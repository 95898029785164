// ? interfaces
import { IErrorValidation } from '@/interfaces/general'
import { ITownCreateDTO, Itown } from '@/interfaces/town'

interface ITOWN_VALIDATION {
  CREATE: (data: ITownCreateDTO) => IErrorValidation[]
  EDIT: (data: Partial<Itown>) => IErrorValidation[]
  DELETE: (id: string) => IErrorValidation[]
}

const requiredTranslates = ['UA']

export const TOWN_VALIDATION: ITOWN_VALIDATION = {
  CREATE: ({ name_eng, name_ukr, country_id }: ITownCreateDTO) => {
    const errors: IErrorValidation[] = []

    if (!name_eng) {
      errors.push({
        field: 'name_eng',
        message: 'Не передано назву міста англійською',
      })
    }

    if (!name_ukr) {
      errors.push({
        field: 'name_ukr',
        message: 'Не передано назву міста українською',
      })
    }

    if (!country_id) {
      errors.push({
        field: 'country_id',
        message: 'Не передано країну',
      })
    }

    return errors
  },
  EDIT: (data: Partial<Itown>) => {
    const errors: IErrorValidation[] = []

    if (Object.keys(data).length === 0) {
      errors.push({
        field: 'data',
        message: 'Дані для редагування не передані',
      })
    }

    return errors
  },
  DELETE: (id: string) => {
    const errors: IErrorValidation[] = []

    if (!id) {
      errors.push({
        field: 'id',
        message: 'Не передано id для видалення',
      })
    }

    return errors
  },
}
