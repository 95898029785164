import { create } from 'zustand'

import { IMeta } from '@/interfaces/general'
import { IDriver } from '@/interfaces/dirvers'
import { drivers_api } from '@/api/drivers.api'

interface IStore {
  drivers: IDriver[]
  oneDriver: IDriver
  driversMeta: IMeta
  isLoading: boolean
  infoDriverModal: boolean
  setInfoDriverModal: (value: boolean) => void
  createDriver: {
    isOpenModal: boolean
    handleOpenModal: () => void
    handleCloseModal: () => void
    createDriverApiHandler: (body: Partial<IDriver>) => Promise<any>
  }
  editDriver: {
    isOpenModal: boolean
    editedItem: IDriver | null
    handleOpenModal: (item: IDriver) => void
    handleCloseModal: () => void
    editDriverApiHandler: (id: string, body: Partial<IDriver>) => Promise<any>
  }
  deleteDriver: {
    isOpenModal: boolean
    deletedItem: IDriver | null
    handleOpenModal: (item: IDriver) => void
    handleCloseModal: () => void
    deleteDriverApiHandler: (id: string) => Promise<any>
  }
  getAllDrivers: () => Promise<any>
  getDriverById: (id: string) => Promise<any>
}

export const driversStore = create<IStore>((set) => ({
  drivers: [],
  oneDriver: {} as IDriver,
  driversMeta: {
    count: 10,
    page: 1,
    total: 0,
  },
  isLoading: false,
  infoDriverModal: false,
  setInfoDriverModal: (value) => {
    set((state) => ({ ...state, infoDriverModal: value }))
  },
  createDriver: {
    isOpenModal: false,
    handleOpenModal: () => {
      set((state) => ({
        ...state,
        createDriver: {
          ...state.createDriver,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        createDriver: {
          ...state.createDriver,
          isOpenModal: false,
        },
      }))
    },
    createDriverApiHandler: async (body: Partial<IDriver>) => {
      const response = await drivers_api.createDriver(body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 201) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  editDriver: {
    isOpenModal: false,
    editedItem: null,
    handleOpenModal: (item: IDriver) => {
      set((state) => ({
        ...state,
        editDriver: {
          ...state.editDriver,
          isOpenModal: true,
          editedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editDriver: {
          ...state.editDriver,
          isOpenModal: false,
          editedItem: null,
        },
      }))
    },
    editDriverApiHandler: async (id, body) => {
      const response = await drivers_api.editDriver(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },
  deleteDriver: {
    isOpenModal: false,
    deletedItem: null,
    handleOpenModal: (item: IDriver) => {
      set((state) => ({
        ...state,
        deleteDriver: {
          ...state.deleteDriver,
          isOpenModal: true,
          deletedItem: item,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deleteDriver: {
          ...state.deleteDriver,
          isOpenModal: false,
          deletedItem: null,
        },
      }))
    },
    deleteDriverApiHandler: async (id) => {
      const response = await drivers_api.deleteDriver(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },
  getAllDrivers: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await drivers_api.getAllDrivers()

    set((state) => ({ ...state, isLoading: false }))

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        drivers: data.drivers,
      }))
    }
  },
  getDriverById: async (id) => {
    const response = await drivers_api.getById(id)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        oneDriver: data,
      }))
    }
  },
}))
