import { CONNECTION_TYPE, TRIP_REQUEST_STATUS } from '@/constants/constants'
import { ITripRequest } from '@/interfaces/tripRequests'
import { tripRequestsStore } from '@/store/tripRequestsStore'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { Close, Edit } from '@mui/icons-material'
import { TableRow, TableCell, Typography, IconButton, Tooltip } from '@mui/material'
import React from 'react'

interface IProps {
  tripRequest: ITripRequest
}

const TripItemUsersRow = ({ tripRequest }: IProps) => {
  const { editModal, declineModal } = tripRequestsStore()

  const handleOpenEdit = (tripRequest: ITripRequest) => {
    editModal.handleOpen(tripRequest)
  }

  const handleDecline = (tripRequest: ITripRequest) => {
    declineModal.handleOpen(tripRequest)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.pib}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.fromLocationCity.city_name_ua} {tripRequest?.departurePlace.bus_stop_name_ua} -{' '}
          {tripRequest.toLocationCity.city_name_ua} {tripRequest?.arrivalPlace.bus_stop_name_ua}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {CONNECTION_TYPE[tripRequest.connectionType]}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.phone}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.baggageAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.arrivalPlace.bus_stop_name_ua}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.adultPassengerAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.childPassengerAmount + tripRequest.childMiddlePassengerAmount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {tripRequest.note}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {TRIP_REQUEST_STATUS[tripRequest.status]}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <IconButton size="small" color="warning" onClick={() => handleOpenEdit(tripRequest)}>
          <Edit />
        </IconButton>

        <Tooltip title="Скасувати">
          <IconButton color="error" onClick={() => handleDecline(tripRequest)}>
            <Close />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default TripItemUsersRow
