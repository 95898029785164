import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { racesStore } from '@/store/racesStore'
import { planningStore } from '@/store/planningStore'
import { messageStore } from '@/store/store'
import { LoadingButton } from '@mui/lab'
import { Button, Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

const DeleteRaceStopModal = () => {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const { getRaceById } = racesStore()
  const { addMessage } = messageStore()

  const { onePlan, editPlan, deleteRaceStop, getPlanById } = planningStore()
  const { deletedItem, raceStopId, isOpenModal, handleCloseModal } = deleteRaceStop

  const deleteRaceStopsApiHandler = useAsyncWrapper(
    deleteRaceStop.deleteRaceStopsApiHandler,
    'deleteRaceStopsApiHandler'
  )

  const getRaceByIdApiHandler = useAsyncWrapper(getRaceById, 'getRaceByIdApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const deleteRaceHandler = async () => {
    setIsLoading(true)

    if (!deletedItem || !deletedItem?._id) {
      setIsLoading(false)
      return
    }

    const data = {
      type: 'deleteRaceStop',
      deleteRaceStopId: raceStopId,
    }

    console.log('Deleted', raceStopId)

    const { error: deleteRaceStopError } = await deleteRaceStopsApiHandler([onePlan._id, data])

    if (deleteRaceStopError) {
      setIsLoading(false)
      return
    }

    const { error: getPlanByIdError } = await getPlanById(params.planId ?? '')

    if (getPlanByIdError) {
      console.log(getPlanByIdError)
    }

    setIsLoading(true)

    addMessage({
      severity: 'success',
      message: 'Зупинку успішно видалено',
      isOpen: true,
    })

    handleCloseForm()
  }

  console.log('Loading...', isLoading)

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleCloseForm} title="Видалити зупинку">
      <Typography variant="subtitle1" color="text.primary"></Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton loading={isLoading} color="primary" variant="contained" size="small" onClick={deleteRaceHandler}>
          Видалити
        </LoadingButton>

        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Скасувати
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeleteRaceStopModal
