import DriverTabel from "@/components/Tabels/DriverTabel/DriverTabel";
import PageWrapper from "@/components/Wrappers/PageWrapper";
import {Typography} from "@mui/material";
import React from "react";

const Drivers = () => {
    return (
        <PageWrapper>
            {true ? (
                <DriverTabel/>
            ) : (
                <Typography variant="h4" color="error">
                    You don&apos;t have permission to view traders
                </Typography>
            )}
        </PageWrapper>
    );
};

export default Drivers;
