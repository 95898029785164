import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRaceStop } from '@/interfaces/races'
import { Itown } from '@/interfaces/town'
import { racesStore } from '@/store/racesStore'
import { getTranslateValue } from '@/utils/getTranslateValue'
import { TableRow, TableCell, Typography, Collapse, Box, Button, Checkbox } from '@mui/material'

import { Delete, Edit } from '@mui/icons-material'
import { townStore } from '@/store/townStore'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { BoardingStatus } from '@/constants/constants'
import { tripsStore } from '@/store/tripStore'

interface IProps {
  stop: IRaceStop
}

const TripScheduleTabelRow = ({ stop }: IProps) => {
  const { t } = useTranslation()

  const { currentTrip, editTripRaceStop, deleteTripRaceStop } = tripsStore()
  const { towns, townMeta, getAllTowns } = townStore()

  const [open, setOpen] = useState(false)
  const [boardingStatus, setBoardingStatus] = useState('')

  const [city, setCity] = useState<any>()

  const getAllTownsApiHandler = useAsyncWrapper(getAllTowns, 'getAllTownsApiHandler')

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllTownsError } = await getAllTownsApiHandler([
        {
          ...townMeta,
          count: 1000,
        },
      ])

      if (getAllTownsError) return
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (towns && stop?.fromLocationCity !== undefined) {
      const town = towns.find((town: Itown) => town?._id === stop?.fromLocationCity)
      setCity(town)
    }
  }, [towns, stop])

  useEffect(() => {
    if (stop?.boardingStatus in BoardingStatus) {
      setBoardingStatus(BoardingStatus[stop?.boardingStatus as keyof typeof BoardingStatus])
    }
  }, [stop?.boardingStatus])

  const handleOpenEditStopModal = () => {
    editTripRaceStop.handleOpenModal(currentTrip, stop?._id)
  }

  const handleOpenDeleteStopModal = () => {
    deleteTripRaceStop.handleOpenModal(currentTrip, stop?._id)
  }

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${getTranslateValue(city, 'ua')}`}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${getTranslateValue(
              city?.cityStops?.find((s: any) => s._id === stop?.cityStop),
              'ua'
            )}`}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${stop?.distanceFromStart ?? ''}`}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${stop?.arrivalTime ?? ''}`}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${stop?.departureTime ?? ''}`}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${stop?.arrivalDay ?? ''}`}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${stop?.departureDay ?? ''}`}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {`${stop?.platformNumber ?? ''}`}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
            }}>
            {boardingStatus}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Checkbox name="borderCross" checked={stop.borderCross} disabled />
        </TableCell>

        <TableCell align="center">
          <Checkbox name="changeTransfer" checked={stop.changeTransfer} disabled />
        </TableCell>

        <TableCell align="center">
          <Checkbox name="changeBus" checked={stop.changeBus} disabled />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                display: 'flex',
                gap: '10px',
              }}>
              {/*<Button*/}
              {/*    id="edits-button"*/}
              {/*    variant="contained"*/}
              {/*    color="primary"*/}
              {/*    size="small"*/}
              {/*    className="addBeforeStop"*/}
              {/*    startIcon={<North className="addBeforeIcon" />}*/}
              {/*    aria-controls={openEditsMenu ? 'edits-menu' : undefined}*/}
              {/*    aria-haspopup="true"*/}
              {/*    aria-expanded={openEditsMenu ? 'true' : undefined}*/}
              {/*    onClick={handleEditsMenuClick}*/}
              {/*>*/}
              {/*    {t("schedulePage.scheduleMenu.addBefore")}*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*    variant="contained"*/}
              {/*    color="primary"*/}
              {/*    size="small"*/}
              {/*    className="addAfterStop"*/}
              {/*    startIcon={<South className="addAfterIcon"/>}*/}
              {/*>*/}
              {/*    {t("schedulePage.scheduleMenu.addAfter")}*/}
              {/*</Button>*/}
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="editRaceStop"
                startIcon={<Edit className="editIcon" />}
                onClick={handleOpenEditStopModal}>
                {t('schedulePage.scheduleMenu.edit')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="deleteRaceStop"
                startIcon={<Delete className="deleteIcon" />}
                onClick={handleOpenDeleteStopModal}>
                {t('schedulePage.scheduleMenu.delete')}
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default TripScheduleTabelRow
