import {newsStore} from "@/store/newsStore";
import {ArrowLeft, ArrowRight} from "@mui/icons-material";
import {Grid, Button, Typography, Divider} from "@mui/material";
import {Box} from "@mui/system";
import React, {useState} from "react";

interface IProps {
    newsItem: any;
}

const AdminNewsItem = ({newsItem}: IProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const {deleteNew, editNew, photoNew} = newsStore();

    return (
        <Grid item xs={4} key={newsItem?._id}>
            <Box
                sx={{
                    width: "100%",
                    height: "200px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    },
                }}
            >
                <img
                    src={`${process.env.REACT_APP_DEV_BASE_API_URL}/${
                        newsItem?.images[currentImageIndex]?.path || ""
                    }`}
                    alt=""
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: 1,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    startIcon={<ArrowLeft/>}
                    onClick={() => setCurrentImageIndex((prev) => prev - 1)}
                    disabled={currentImageIndex === 0}
                >
                    Попередня
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    onClick={() => setCurrentImageIndex((prev) => prev + 1)}
                    disabled={
                        currentImageIndex === newsItem?.images?.length - 1 ||
                        newsItem?.images?.length === 0
                    }
                    endIcon={<ArrowRight/>}
                >
                    Наступна
                </Button>
            </Box>
            <Typography
                color="primary"
                sx={{
                    maxHeight: "70px",
                    minHeight: "70px",
                    overflowY: "scroll",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap",
                    fontSize: "22px",
                    fontWeight: "bold",
                }}
            >
                {newsItem?.title}
            </Typography>
            <Divider sx={{my: 1}}/>
            <Typography
                sx={{
                    minHeight: "200px",
                    maxHeight: "200px",
                    overflowY: "scroll",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap",
                }}
            >
                {newsItem?.description}
            </Typography>
            <Typography>Мова: {newsItem?.language}</Typography>
            <Typography color={newsItem?.isHidden ? "error" : "primary"}>
                Статус: {newsItem?.isHidden ? "Сховано" : "Активно"}
            </Typography>
            <Typography>
                Тип: {newsItem?.type === "news" ? "Новина" : "Знижка"}
            </Typography>
            <Typography>
                Підрозділ:{" "}
                {newsItem?.entity === "passengerTransportation"
                    ? "Пасажирські перевезення"
                    : "Вантажні перевезення"}
            </Typography>

            <Button
                variant="contained"
                color="success"
                fullWidth
                sx={{
                    mt: 1,
                }}
                onClick={() => photoNew.handleOpenModal(newsItem)}
            >
                Керування фотографіями
            </Button>

            <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                    mt: 1,
                }}
                onClick={() => editNew.handleOpenModal(newsItem)}
            >
                Редагувати
            </Button>

            <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{
                    mt: 1,
                }}
                onClick={() => deleteNew.handleOpenModal(newsItem)}
            >
                Видалити
            </Button>
        </Grid>
    );
};

export default AdminNewsItem;
