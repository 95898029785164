import TransportsTabel from '@/components/Tabels/TransportsTabel/TransportsTabel'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import {Typography} from '@mui/material'

const Transports = () => {
    return (
        <PageWrapper>
            {true ? (
                <TransportsTabel/>
            ) : (
                <Typography variant="h4" color="error">
                    You don&apos;t have permission to view traders
                </Typography>
            )}
        </PageWrapper>
    )
}

export default Transports