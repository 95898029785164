// ? hooks
import React, {useEffect, useState} from "react";
import useAsyncWrapper from "@/hooks/useAsyncWrapper";

// ? store
import {countryStore} from "@/store/countryStore";
import {townStore} from "@/store/townStore";
import {racesStore} from "@/store/racesStore";
import {messageStore} from "@/store/store";

// ? components
import {
    Box,
    Button, Checkbox,
    Divider,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from "@mui/material";

import ModalContainer from "@/components/UI/Modal/ModalContainer";

import {LoadingButton} from "@mui/lab";
import useValidation from "@/hooks/useValidation";
import {RACE_VALIDATION} from "@/validation/race";
import {inputsChanger} from "@/utils/inputsChanger";
import {getTranslateValue} from "@/utils/getTranslateValue";
import {RaceRegularity, RaceType, Weekdays} from "@/constants/constants";
import {validateForm} from "@/utils/formValidation";

const RaceSalesModal = () => {
    const [isLoading, setIsLoading] = useState(false);

    const {getAllRaces, racesMeta, raceSalesModal} = racesStore();

    const {isOpenModal, editedItem, handleCloseModal} = raceSalesModal;

    const {addMessage} = messageStore();

    const [formValues, setFormValues] = useState<any>({
        saleDepth: 0
    });

    const [cityStops, setCityStops] = useState<any>([]);
    const [showWeekdaysFields, setShowWeekdaysFields] = useState<boolean>(false);

    const [formErrors, setFormErrors] = useState<any>();
    const validationRules = {
        saleDepth: {required: true},
    };

    const editRaceSalesApiHandler = useAsyncWrapper(
        raceSalesModal.editRaceSalesApiHandler,
        "editRaceSalesApiHandler"
    );

    const getAllRacesApiHandler = useAsyncWrapper(
        getAllRaces,
        "getAllRacesApiHandler"
    );

    const handleCloseForm = () => {
        handleCloseModal();
    };

    const editRaceSalesHandler = async () => {
        setIsLoading(true);

        const formErrors = validateForm(formValues, validationRules)
        if (Object.keys(formErrors).length !== 0) {
            setFormErrors(formErrors);
            return;
        }

        const data = {
            type: "editRaceSales",
            ...formValues
        }

        const {error: editRaceSalesError} = await editRaceSalesApiHandler([
            editedItem?._id,
            data
        ]);

        if (editRaceSalesError) {
            setIsLoading(false);
            return;
        }

        const {error: getAllRacesError} = await getAllRacesApiHandler([
            racesMeta,
        ]);

        if (getAllRacesError) {
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        addMessage({
            severity: "success",
            message: "Рейс успішно відредаговано",
            isOpen: true,
        });
        handleCloseForm();
    };

    useEffect(() => {
        setFormValues({saleDepth: editedItem?.saleDepth});
    }, []);

    const handleInputChange = inputsChanger(
        "input",
        formValues,
        setFormValues
    ) as (e: any) => void;

    console.log("formValues", formValues)

    return (
        <ModalContainer
            width={700}
            isOpen={isOpenModal}
            handleClose={handleCloseForm}
            title="Продаж/бронь"
        >
            <Box
                sx={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px"
                    }}
                >
                    <FormControl fullWidth variant="standard">
                        <InputLabel shrink>Глибина продажу</InputLabel>
                        <TextField
                            name="saleDepth"
                            variant="standard"
                            type="number"
                            value={formValues?.saleDepth ?? 0}
                            onChange={handleInputChange}
                            sx={{
                                mt: "16px"
                            }}
                            error={!!formErrors?.saleDepth}
                            helperText={formErrors?.saleDepth}
                        />
                    </FormControl>
                </Box>

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                    }}
                >
                    <LoadingButton
                        // loading={isLoading}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={editRaceSalesHandler}
                    >
                        Зберегти
                    </LoadingButton>

                    <Button
                        onClick={handleCloseForm}
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{ml: 1}}
                    >
                        Скасувати
                    </Button>
                </Box>
            </Box>
        </ModalContainer>
    );
};

export default RaceSalesModal;
