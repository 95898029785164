import { Close } from '@mui/icons-material'
import { IconButton, Typography, Box, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

interface IProps {
  setFiles: (files: any) => void
  files: any[]
}

const FileUploader = ({ setFiles, files }: IProps) => {
  const [preview, setPreview] = useState<any[]>([])

  const onDrop = useCallback((acceptedFiles: any) => {
    console.log(acceptedFiles)
    setFiles(acceptedFiles)
    setPreview(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    )
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const removeFile = (e: any, file: any) => {
    e.stopPropagation()
    setFiles(files.filter((f: any) => f.name !== file))
    setPreview(preview.filter((f: any) => f.name !== file))
  }

  useEffect(() => {
    if (files.length === 0) {
      setPreview([])
    }
  }, [files])

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: '1px dashed #ccc',
        borderRadius: '4px',
        padding: '16px',
        marginTop: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Перетягніть файли сюди ...</p>
      ) : (
        <Box>
          <p>Перетягніть файли сюди, або натисніть, щоб вибрати файли</p>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}>
            {preview.map((file) => (
              <Box
                key={file.name}
                sx={{
                  width: '100px',
                  height: '100px',
                  overflow: 'hidden',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  m: 1,
                }}>
                <Tooltip title="Видалити" arrow placement="top">
                  <IconButton
                    onClick={(e) => removeFile(e, file.name)}
                    sx={{
                      position: 'absolute',
                    }}
                    color="error">
                    <Close />
                  </IconButton>
                </Tooltip>
                <img
                  src={file.preview}
                  style={{
                    width: '100%',
                  }}
                  alt={file.name}
                />
                <Typography
                  variant="caption"
                  sx={{
                    display: 'block',
                    textAlign: 'center',
                  }}>
                  {file.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default FileUploader
