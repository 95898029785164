import {api} from "@/api";

export const articles_api = {
    getAllArticles: async ({page, count}: any, filter: any | null | undefined) => {
        return await api.get("/articles/admin/get-paginate", {
            params: {
                page: page,
                count,
                filter: filter ? JSON.stringify(filter) : null,
            },
        });
    },

    getCurrentArticle: async (id: string) => {
        return await api.get(`/articles/admin/get-one/${id}`);
    },
    editArticle: async (id: string, body: any) => {
        return await api.patch(`/articles/admin/edit/${id}`, body);
    },
    createArticle: async (body: any) => {
        console.log("body", body.get('description'))
        return await api.post(`/articles/admin/create`, body);
    },

    deleteArticle: async (id: string) => {
        return await api.delete(`/articles/admin/delete/${id}`);
    },

    deletePhotoArticle: async (id: string, fileName: string) => {
        return await api.delete(`/articles/admin/delete-file/${id}/${fileName}`);
    },

    uploadPhotoArticle: async (id: string, body: any) => {
        return await api.post(`/articles/admin/add-files/${id}`, body);
    },
};
