// ? interfaces
import { IStop } from '@/interfaces/stop'

// ? store
import { stopsStore } from '@/store/stopsStore'

// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'
import { townStore } from '@/store/townStore'
import { useEffect, useState } from 'react'

interface IProps {
  stop: IStop
}

const StopTabelRow = ({ stop }: IProps) => {
  const { bus_stop_id, bus_stop_name_en, bus_stop_name_ua, bus_stop_location } = stop
  const [city, setCity] = useState<string>('')

  const { editStop, deleteStop } = stopsStore()
  const { towns } = townStore()

  const handleEditCountry = (stop: IStop) => {
    editStop.handleOpenModal(stop)
  }

  const handleDeleteCountry = (stop: IStop) => {
    deleteStop.handleOpenModal(stop)
  }

  useEffect(() => {
    const city = towns.find((town) => town.city_id === bus_stop_location)
    if (city) {
      setCity(city.name_ukr)
    }
  }, [])

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {bus_stop_id}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {city}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {`EN: ${bus_stop_name_en}`}
          <br />
          {`UA: ${bus_stop_name_ua}`}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Відредагувати">
          <IconButton color="warning" onClick={() => handleEditCountry(stop)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Видалити">
          <IconButton color="error" onClick={() => handleDeleteCountry(stop)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default StopTabelRow
