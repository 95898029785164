// ? interfaces
import {ILoginForm, IRegisterFormDTO} from "@/interfaces/auth";
import {IErrorValidation} from "@/interfaces/general";

// ? validation utils
import {isEmail} from "./general";


interface IAUTH_VALIDATION {
    LOGIN: (data: ILoginForm) => IErrorValidation[];
    REGISTER: (data: IRegisterFormDTO) => IErrorValidation[];
}

export const AUTH_VALIDATION: IAUTH_VALIDATION = {
    LOGIN: ({email, password}: ILoginForm) => {
        const errors: IErrorValidation[] = [];
        if (!email) {
            errors.push({
                field: "email",
                message: `Поле "email" обов'язкове для заповнення`
            });
        }
        if (!password) {
            errors.push({
                field: "password",
                message: `Поле "password" обов'язкове для заповнення`
            });
        }

        if (!isEmail(email)) {
            errors.push({
                field: "email",
                message: `Поле "email" має бути валідним email-ом`
            });
        }

        return errors;
    },
    REGISTER: (data: IRegisterFormDTO) => {
        const errors: IErrorValidation[] = [];
        if (!data.firstName) {
            errors.push({
                field: "firstName",
                message: `Поле "firstName" обов'язкове для заповнення`
            });
        }
        if (!data.lastName) {
            errors.push({
                field: "lastName",
                message: `Поле "lastName" обов'язкове для заповнення`
            });
        }
        if (!data.email) {
            errors.push({
                field: "email",
                message: `Поле "email" обов'язкове для заповнення`
            });
        }
        if (!data.password) {
            errors.push({
                field: "password",
                message: `Поле "password" обов'язкове для заповнення`
            });
        }
        if (!data.phoneNumber) {
            errors.push({
                field: "phoneNumber",
                message: `Поле "phoneNumber" обов'язкове для заповнення`
            });
        }

        if (!isEmail(data.email)) {
            errors.push({
                field: "email",
                message: `Поле "email" має бути валідним email-ом`
            });
        }

        return errors;
    }
}