// ? hooks
import { useState } from 'react'

import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? store
import { driversStore } from '@/store/driverStore'
import { messageStore } from '@/store/store'

// ? interfaces
import { IDriverCreateDTO } from '@/interfaces/dirvers'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? validation
import { DRIVER_VALIDATION } from '@/validation/driver'

// ? components
import { Box, Button, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'

const initialState: IDriverCreateDTO = {
  pib: '',
  // lastName: "",
  phone: '',
  // badHabit: "",
  // driverFeatures: "",
}

const DriverCreateModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState(initialState)

  const { addMessage } = messageStore()
  const { getAllDrivers, driversMeta, createDriver } = driversStore()
  const { isOpenModal, handleCloseModal } = createDriver

  const handleChangeInput = inputsChanger('input', formValues, setFormValues)

  const driverValidation = useValidation(DRIVER_VALIDATION.CREATE)

  const createDriverApiHandler = useAsyncWrapper(createDriver.createDriverApiHandler, 'createDriverApiHandler')

  const getDriversApiHandler = useAsyncWrapper(getAllDrivers, 'getDriversApiHandler')

  const handleCloseForm = () => {
    setFormValues(initialState)
    handleCloseModal()
  }

  const createDriverHandler = async () => {
    setIsLoading(true)

    if (!driverValidation(formValues)) {
      setIsLoading(false)
      return false
    }

    const { error: createDriverError } = await createDriverApiHandler([formValues])

    if (createDriverError) {
      setIsLoading(false)
      return false
    }

    const { error: getDriversError } = await getDriversApiHandler([driversMeta])

    if (getDriversError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Водій успішно створений',
      isOpen: true,
    })

    handleCloseForm()
    setIsLoading(false)
  }

  return (
    <ModalContainer width={600} isOpen={isOpenModal} handleClose={handleCloseForm} title="Створення водія">
      <TextField
        fullWidth
        label="ПІБ"
        onChange={handleChangeInput}
        name="pib"
        variant="standard"
        value={formValues.pib}
        placeholder={'Ivan'}
      />

      <TextField
        fullWidth
        label="Номер телефону"
        onChange={handleChangeInput}
        value={formValues.phone}
        name="phone"
        variant="standard"
        placeholder={'380991800928'}
      />

      {/* <TextField
        fullWidth
        label="Шкідливі звички"
        onChange={handleChangeInput}
        name="badHabit"
        value={formValues.badHabit}
        multiline
        variant="standard"
        placeholder={'Куріння 20 сигарет на день'}
      />

      <TextField
        fullWidth
        label="Особливості водія"
        onChange={handleChangeInput}
        name="driverFeatures"
        value={formValues.driverFeatures}
        variant="standard"
        multiline
        placeholder={'Куріння 20 сигарет на день'}
      /> */}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={createDriverHandler}>
          Створити
        </LoadingButton>
        <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Закрити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DriverCreateModal
