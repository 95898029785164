import {createRoot} from "react-dom/client";
import App from "@/App";
// ?range datepicker styles
import "react-date-range/dist/styles.css"; //? main css file
import "react-date-range/dist/theme/default.css"; //? theme css file

import "./styles/global.css";

import Global from "@/components/Wrappers/Global";

import './localization/i18n';

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Global>
        <App/>
    </Global>
);
