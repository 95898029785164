import React from "react";
import NewsTabel from "@/components/Tabels/NewsTabel/NewsTabel";
import PageWrapper from "@/components/Wrappers/PageWrapper";

const News = () => {
    return (
        <PageWrapper>
            <NewsTabel/>
        </PageWrapper>
    );
};

export default News;
